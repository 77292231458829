<template>
    <div class="container mx-auto px-4 py-8 shadow-lg rounded-lg">
      <h2 class="text-2xl font-semibold mb-6 text-center">ประวัติการใช้อะไหล่และรถที่เกี่ยวข้อง</h2>
  
      <div class="search-section mb-6 flex flex-col md:flex-row items-center gap-4">
        <select 
          v-model="searchType" 
          class="border rounded-md px-4 py-2 w-full md:w-auto"
        >
          <option value="part">ค้นหาโดยอะไหล่</option>
          <option value="vehicle">ค้นหาโดยทะเบียนรถ</option>
        </select>
  
        <div class="relative w-full md:w-96">
  <input 
    type="text" 
    v-model="searchQuery" 
    :placeholder="searchPlaceholder" 
    class="border rounded-md px-4 py-2 flex-1 w-full"
    @input="currentPage = 1"
    @focus="showSuggestions = true"
    @blur="hideSuggestionsWithDelay"
    @keyup.enter="handleEnter"
  />
  
  <!-- Suggestions Dropdown -->
  <ul 
  v-if="showSuggestions && filteredSuggestions && filteredSuggestions.length > 0" 
  class="absolute z-10 bg-white border rounded-md shadow-lg mt-2 max-h-40 overflow-y-auto w-full"
>
  <li 
    v-for="(suggestion, index) in filteredSuggestions" 
    :key="index"
    @click="selectSuggestion(suggestion)"
    class="px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer"
  >
    {{ suggestion }}
  </li>
</ul>
</div>



  
<button 
  @click="handleSearch"
  class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md"
>
  ค้นหา
</button>
      </div>
  
      <div v-if="loading" class="text-center">
        <p class="text-blue-600">กำลังโหลดข้อมูล...</p>
      </div>
  
      <div v-if="!loading && results.length > 0" class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200 shadow overflow-hidden rounded-lg">
            <thead class="bg-gray-50">
  <tr>
    <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">ทะเบียนรถ</th>
    <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">อะไหล่</th>
    <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">จำนวน</th>
    <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่</th>
    <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">เลขที่ PR</th>
    <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">เลขที่ PO</th>
    <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
  </tr>
</thead>
<tbody class="bg-white divide-y divide-gray-200">
  <tr v-for="(item, index) in paginatedResults" :key="index">
    <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.licensePlate }}</td>
    <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.partName }}</td>
    <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.quantity }}</td>
    <td class="px-6 py-4 whitespace-nowrap text-center">{{ formatDate(item.date) }}</td>
    <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.prNumber }}</td>
    <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.poNumbers }}</td>
    <td class="px-6 py-4 whitespace-nowrap text-center">
      <button
        v-if="item.partImage"
        @click="viewImage(item.partImage)"
        class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md"
      >
        ดูรูปอะไหล่
      </button>
    </td>
  </tr>
</tbody>
        </table>
      </div>


      <div class="flex justify-between items-center mt-4">
  <button 
    @click="prevPage" 
    :disabled="currentPage === 1" 
    class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
  >
    ย้อนกลับ
  </button>
  
  <span>หน้า {{ currentPage }} จาก {{ totalPages }}</span>
  
  <button 
    @click="nextPage" 
    :disabled="currentPage === totalPages" 
    class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
  >
    ถัดไป
  </button>
</div>


      <div v-if="!loading && results.length === 0" class="text-center mt-6">
        <p class="text-gray-600">ไม่พบข้อมูลสำหรับการค้นหา</p>
      </div>
    </div>

<div 
  v-if="showImageModal" 
  class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-4 py-6"
>
  <div 
    class="relative bg-white rounded-lg shadow-lg p-4 md:p-6 max-w-md sm:max-w-lg md:max-w-2xl lg:max-w-4xl w-full"
  >
    <img 
      :src="modalImage" 
      alt="Part Image" 
      class="w-full h-auto max-h-[80vh] rounded"
    />
    <button 
      @click="closeImageModal" 
      class="absolute top-2 right-2 bg-red-500 hover:bg-red-600 text-white rounded-full w-10 h-10 flex items-center justify-center"
    >
      &times;
    </button>
  </div>
</div>


  </template>
  
  <script>
  import Swal from 'sweetalert2';
  import axios from 'axios';
  
  export default {
    data() {
      return {
        searchType: 'part', // part or vehicle
        searchQuery: '',
        results: [],
        suggestions: [], // คำที่เป็นไปได้
        showSuggestions: false, // ควบคุมการแสดง Suggestions
        loading: false,
        modalImage: null, // เก็บ URL รูปภาพที่จะแสดง
        showImageModal: false, // ควบคุมการแสดงผล Modal
        currentPage: 1, // หน้าปัจจุบัน
    itemsPerPage: 10, // จำนวนรายการต่อหน้า
      };
    },
    computed: {
        filteredSuggestions() {
    if (!this.results || !this.results.length || !this.searchQuery) return [];
    const lowerCaseQuery = this.searchQuery.toLowerCase().trim();
    return [...new Set(this.results.map(item => 
      this.searchType === 'part' 
        ? item.partName 
        : item.licensePlate
    ).filter(suggestion => 
      suggestion && suggestion.toLowerCase().includes(lowerCaseQuery)
    ))];
  },
      searchPlaceholder() {
        return this.searchType === 'part'
          ? 'กรอกชื่อหรือรหัสอะไหล่'
          : 'กรอกทะเบียนรถ';
      },
      filteredResults() {
    const lowerCaseQuery = this.searchQuery.toLowerCase().trim(); // แปลงคำค้นหาเป็นตัวพิมพ์เล็ก
    return this.results.filter(item => {
      // กรองข้อมูลโดยตรวจสอบคำค้นหาในทะเบียนรถหรือชื่ออะไหล่
      return (
        (item.licensePlate && item.licensePlate.toLowerCase().includes(lowerCaseQuery)) || 
        (item.partName && item.partName.toLowerCase().includes(lowerCaseQuery))
      );
    });
  },
      paginatedResults() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredResults.slice(start, end); // ตัดรายการตามหน้าที่กำหนด
  },
  totalPages() {
    return Math.ceil(this.filteredResults.length / this.itemsPerPage); // จำนวนหน้าตามผลลัพธ์ที่กรอง
  },
    },
    methods: {
        handleSearch() {
    this.playSound(); // เล่นเสียงก่อน
    this.fetchHistory(); // เรียกค้นหาหลังจากนั้น
  },
        async handleEnter() {
    // เล่นเสียงเมื่อกด Enter
    this.playSound();
    // เรียกฟังก์ชันค้นหา
    await this.fetchHistory();
  },

  playSound() {
  try {
    const audioUrl = '/sounds/click.wav';
    const audio = new Audio(audioUrl);

    // ตรวจสอบว่าเบราว์เซอร์รองรับ
    if (!audio.canPlayType('audio/mpeg') && !audio.canPlayType('audio/wav')) {
      throw new Error('รูปแบบไฟล์เสียงไม่รองรับ');
    }

    audio.play().catch(error => {
      console.error('Error playing sound:', error);
      alert('ไม่สามารถเล่นเสียงได้ กรุณาตรวจสอบ URL หรือการตั้งค่าของเบราว์เซอร์.');
    });
  } catch (error) {
    console.error('Error initializing sound:', error);
  }
  },
        selectSuggestion(suggestion) {
  this.searchQuery = suggestion; // ตั้งค่า `searchQuery` ตามคำแนะนำที่เลือก
  this.showSuggestions = false; // ซ่อน Suggestions
  this.playSound();
  // แสดง Toast แจ้งความสำเร็จ
  Swal.fire({
    icon: 'success',
    title: 'เลือกคำแนะนำสำเร็จ',
    text: `คุณได้เลือก "${suggestion}"`,
    timer: 3000,
    toast: true,
    position: 'top-right',
    showConfirmButton: false,
  });
  this.fetchHistory();
},

  hideSuggestionsWithDelay() {
    // ใช้ Timeout เพื่อรอให้การคลิกใน Dropdown ทำงานก่อนซ่อน
    setTimeout(() => {
      this.showSuggestions = false;
    }, 200);
  },
        prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  },
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  },
        transformImageUrl(imageUrl) {
    if (!imageUrl) return null; // ถ้าไม่มีรูปภาพให้คืนค่า null
    const fileName = imageUrl.split('/').pop(); // ดึงชื่อไฟล์จาก URL
    return `https://brkmeeting-room.online/${fileName}`; // สร้าง URL ใหม่
  },
  viewImage(imageUrl) {
    const transformedUrl = this.transformImageUrl(imageUrl); // แปลง URL ก่อนเปิด Modal
    this.modalImage = transformedUrl;
    this.showImageModal = true;
  },
  closeImageModal() {
    this.modalImage = null;
    this.showImageModal = false;
  },

async fetchHistory() {
  if (!this.searchQuery.trim()) {
    Swal.fire({
      icon: 'warning',
      title: 'ข้อมูลไม่ครบ',
      text: 'กรุณากรอกข้อมูลสำหรับการค้นหา',
    });
    return;
  }

  this.loading = true; // เริ่มการโหลดข้อมูล
  this.results = []; // ล้างผลลัพธ์ก่อนการค้นหาใหม่

  try {
    const response = await axios.get(
      `https://brk-group.org/api/history`,
      {
        params: {
          type: this.searchType,
          query: this.searchQuery.trim(), // ตัดช่องว่างที่ไม่จำเป็นออก
        },
      }
    );

    if (response.data && response.data.length > 0) {
      this.results = response.data;

      // แสดง Toast แจ้งความสำเร็จ
      Swal.fire({
        icon: 'success',
        title: 'ค้นหาสำเร็จ',
        text: `พบข้อมูลทั้งหมด ${response.data.length} รายการ`,
        timer: 3000,
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
      });
    } else {
      // กรณีไม่พบข้อมูล
      Swal.fire({
        icon: 'info',
        title: 'ไม่พบข้อมูล',
        text: 'ไม่มีข้อมูลที่ตรงกับการค้นหาของคุณ',
      });
    }
  } catch (error) {
    console.error('Error fetching history:', error);

    // ตรวจสอบประเภทข้อผิดพลาด
    if (error.response) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.response.data.error || 'ไม่สามารถดึงข้อมูลได้ในขณะนี้',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์',
      });
    }
  } finally {
    this.loading = false; // ปิดการโหลด
  }
}
,
// ฟังก์ชันสำหรับฟอร์แมตวันที่
formatDate(dateString) {
  if (!dateString) return '-';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0'); // เติม 0 ถ้าวันมีหลักเดียว
  const month = String(date.getMonth() + 1).padStart(2, '0'); // เดือนเริ่มจาก 0 ต้อง +1
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
},


    },
  };
  </script>
  
  <style scoped>
  .search-section input {
    min-width: 250px;
  }
  
  @media (max-width: 768px) {
    .search-section {
      flex-direction: column;
      align-items: stretch;
    }
  
    .search-section input {
      width: 100%;
    }
  }
  @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}
  </style>
  