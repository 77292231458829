import { initializeApp, getApps, getApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyBV3iMXmkp1V0EzyA5zTKclWe7s_d3_wJQ",
  authDomain: "purchasebrk-2514a.firebaseapp.com",
  projectId: "purchasebrk-2514a",
  storageBucket: "purchasebrk-2514a.firebasestorage.app",
  messagingSenderId: "749236924675",
  appId: "1:749236924675:web:0fb937416e71fd9eb57936",
  measurementId: "G-V1EM3YVFVT"
};

let messaging = null;
let app = null;

const initializeFirebaseMessaging = async () => {
  try {
    // ตรวจสอบการรองรับ Messaging
    const isMessagingSupported = await isSupported();
    
    if (!isMessagingSupported) {
      console.warn('Firebase Messaging ไม่รองรับเบราว์เซอร์นี้');
      return null;
    }

    // ตรวจสอบ browser environment และ service worker
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
      messaging = getMessaging(app);
      
      console.log('Firebase Messaging initialized successfully');
      return messaging;
    }
  } catch (error) {
    console.error('Error initializing Firebase Messaging:', error);
    return null;
  }
};
// ตรวจสอบว่าอยู่ใน browser environment และ service worker สามารถใช้งานได้
if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
  try {
    app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
    messaging = getMessaging(app);
    console.log('Firebase Messaging initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase Messaging:', error);
  }
}
// Initialize Firebase
if (typeof window !== 'undefined') {
  try {
    app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
    messaging = getMessaging(app);
    console.log('Firebase Messaging initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase Messaging:', error);
  }
}
initializeFirebaseMessaging();
// ฟังก์ชันสำหรับขอ FCM Token
export const getMessagingToken = async (vapidKey) => {
  if (!messaging) {
    console.error('Messaging is not initialized');
    return null;
  }
  
  try {
    const currentToken = await getToken(messaging, { vapidKey });
    if (currentToken) {
      console.log('FCM Token obtained:', currentToken.slice(0, 10) + '...');
      return currentToken;
    }
    console.warn('No FCM Token available');
    return null;
  } catch (error) {
    console.error('Error getting FCM Token:', error);
    return null;
  }
};
// Show Windows Native Notification
// const showWindowsNotification = (title, options) => {
//   try {
//     const notification = new Notification(title, options);
    
//     notification.onclick = (event) => {
//       event.preventDefault();
//       console.log('Notification clicked:', options.data);
//       if (options.data?.url) {
//         window.open(options.data.url, '_blank');
//       }
//     };

//     return true;
//   } catch (error) {
//     console.error('Error showing Windows notification:', error);
//     return false;
//   }
// };

const showWindowsNotification = (title, options) => {
  try {
    const notification = new Notification(title, options);

    notification.onclick = (event) => {
      event.preventDefault();
      console.log('Notification clicked:', options.data);
      if (options.data?.url) {
        // เปิด URL ที่ระบุไว้ใน data
        window.open(options.data.url, '_blank');
      }
    };

    return true;
  } catch (error) {
    console.error('Error showing Windows notification:', error);
    return false;
  }
};
// ฟังก์ชันสำหรับการจัดการข้อความใน Foreground
export const onMessageListener = () => {
  return new Promise((resolve) => {
    if (!messaging) {
      console.error('Messaging not initialized for message listener');
      resolve(null);
      return;
    }

//     onMessage(messaging, (payload) => {
//       console.log('Received foreground message:', payload);
      
//       if (Notification.permission === 'granted') {
//         const notificationOptions = {
//           body: payload.notification.body,
//           icon: '/icon.webp',
//           badge: '/icon.webp',
//           requireInteraction: true,
//           silent: false,
//           data: payload.data
//         };

//         const shown = showWindowsNotification(
//           payload.notification.title,
//           notificationOptions
//         );

//         if (shown) {
//           console.log('Windows notification shown successfully');
//         } else {
//           console.warn('Failed to show Windows notification, falling back to alert');
//           alert(`${payload.notification.title}\n${payload.notification.body}`);
//         }
//       } else {
//         console.warn('Notification permission not granted');
//       }

//       resolve(payload);
//     });
//   });
// };
onMessage(messaging, (payload) => {
  console.log('Received foreground message:', payload);
  
  if (Notification.permission === 'granted') {
    const prId = payload.data?.prId;
    const prType = payload.data?.purchaseType || 'stock';
    const url = `/pr-detail/${prId}?currentPage=1&prType=${prType}`;

    const notificationOptions = {
      body: payload.notification.body,
      icon: '/icon.webp',
      badge: '/icon.webp',
      requireInteraction: true,
      silent: false,
      data: { url } // เพิ่ม URL ใน data
    };

    const shown = showWindowsNotification(
      payload.notification.title,
      notificationOptions
    );

    if (shown) {
      console.log('Windows notification shown successfully');
    } else {
      console.warn('Failed to show Windows notification, falling back to alert');
      alert(`${payload.notification.title}\n${payload.notification.body}`);
    }
  } else {
    console.warn('Notification permission not granted');
  }

  resolve(payload);
});
});
};
// ฟังก์ชันสำหรับขอ Notification Permission
export const requestNotificationPermission = async () => {
  try {
    if (!messaging) {
      console.error('Messaging is not initialized');
      return null;
    }

    console.log('Requesting Notification Permission...');
    const permission = await Notification.requestPermission();
    console.log('Notification permission status:', permission);

    if (permission !== 'granted') {
      console.warn('Notification permission denied');
      return null;
    }

    const token = await getMessagingToken(
      "BGLtzh9ViUgr2Zr4e1V9sJdkpPRsAJkjmRZreEt9csRi_yiXjAwuq4zyksEh76tPCcPAB_x19feQusOIa7NU7nQ"
    );
    if (token) {
      // เพิ่มบรรทัดนี้เพื่อเก็บ token
      localStorage.setItem('fcmToken', token);
      console.log('FCM Token stored:', token);
    }
    return token;
  } catch (error) {
    console.error('Error in requestNotificationPermission:', error);
    return null;
  }
};

// Export necessary functions and objects
export { messaging, app };

// ตรวจสอบการเชื่อมต่อ Firebase เมื่อไฟล์ถูกโหลด
if (app) {
  console.log('Firebase initialized with config:', {
    projectId: firebaseConfig.projectId,
    messagingSenderId: firebaseConfig.messagingSenderId
  });
} else {
  console.warn('Firebase initialization failed');
}