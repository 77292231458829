<template>
    <TransportLayout>
    <div class="container mx-auto px-4 py-6">
      <!-- Loading Overlay -->
      <!-- <div v-if="isLoading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div> -->
      <div v-if="isLoading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="flex flex-col items-center">
        <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        <p class="mt-4 text-white text-lg">Loading, please wait...</p>
      </div>
</div>
      <!-- Header -->
      <div class="flex justify-between items-center mb-4">
        <h1 class="text-2xl font-bold">จัดการข้อมูลน้ำมัน</h1>
        <div class="flex items-center justify-end">
    <button
      @click="openModal()"
      class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none flex items-center mr-2"
    >
      <i class="fas fa-plus mr-2"></i>เพิ่มข้อมูลน้ำมัน
    </button>
    
    <!-- <button 
  @click="confirmDeleteMultiple" 
  class="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded focus:outline-none flex items-center"
  :disabled="selectedRecords.length === 0"
>
  <i class="fas fa-trash mr-2"></i>
  ลบรายการที่เลือก ({{ selectedRecords.length }})
</button> -->
  </div>
      </div>
  


      <div class="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
  <!-- วันที่ -->
  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">วันที่</label>
    <input
      type="date"
      v-model="filters.date"
      @change="onFilterChange"
      class="w-full border rounded px-3 py-2"
    />
  </div>

  <!-- หน่วยงาน -->
  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">หน่วยงาน</label>
    <select
      v-model="filters.department"
      @change="onFilterChange"
      class="w-full border rounded px-3 py-2"
    >
      <option value="">ทั้งหมด</option>
      <option
        v-for="dept in departments"
        :key="dept.id"
        :value="dept.id"
      >{{ dept.name }}</option>
    </select>
  </div>

  <!-- กลุ่มงาน -->
  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">กลุ่มงาน</label>
    <select
      v-model="filters.group"
      @change="onFilterChange"
      class="w-full border rounded px-3 py-2"
    >
      <option value="">ทั้งหมด</option>
      <option
        v-for="group in groups"
        :key="group.id"
        :value="group.id"
      >{{ group.name }}</option>
    </select>
  </div>

  <!-- ชื่อพนักงานขับรถ -->
  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">ชื่อพนักงานขับรถ</label>
    <input
      type="text"
      v-model="filters.driver"
      @input="onFilterChange"
      placeholder="ค้นหา"
      class="w-full border rounded px-3 py-2"
    />
  </div>
  
  <!-- ปุ่มเคลียร์ข้อมูล -->
  <div class="flex justify-center items-center">
    <button 
      @click="clearFilters"
      class="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
    >
      เคลียร์ข้อมูลการค้นหา
    </button>
  </div>

   <!-- ปุ่ม Verify -->
   <div v-if="selectedRecords.length > 0" class="py-4">
    <button 
      @click="openVerifyModal" 
      class="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
    >
      Verify
    </button>
  </div>
  <div>
  <label class="block text-gray-700 text-sm font-bold mb-2">สถานะ Verified</label>
  <select
    v-model="filters.verified"
    @change="onFilterChange"
    class="w-full border rounded px-3 py-2"
  >
    <option value="">ทั้งหมด</option>
    <option value="1">ยืนยันแล้ว</option>
    <option value="0">ยังไม่ยืนยัน</option>
  </select>
</div>
</div>

  
  <!-- ตาราง -->
<div class="bg-white shadow-md rounded-lg overflow-x-auto">
  <table v-if="fuelRecords.length > 0" class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-100">
      <tr>
        <th class="px-4 py-3 text-center">
          <input type="checkbox" @change="toggleSelectAll" :checked="isAllSelected" />
        </th>
        <th class="px-4 py-3 text-center">วันที่</th>
        <th class="px-4 py-3 text-center">เลขที่บิล</th>
        <th class="px-4 py-3 text-center">คู่รถ</th>
        <th class="px-4 py-3 text-center">จำนวนลิตร</th>
        <th class="px-4 py-3 text-center">ราคาต่อลิตร</th>
        <th class="px-4 py-3 text-center">สถานที่เติมน้ำมัน</th>
        <th class="px-4 py-3 text-center">รวมระยะทาง</th>
        <th class="px-4 py-3 text-center">การกระทำ</th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr v-for="record in fuelRecords" :key="record.id" class="hover:bg-gray-50"  :class="{'bg-green-100': record.verified === 1, 'hover:bg-green-200': record.verified === 1}">
        <td class="px-4 py-4 text-center">
          <input 
            type="checkbox" 
            v-model="selectedRecords" 
            :value="record.id"
            :disabled="record.verified === 1"
            v-if="record.verified !== 1"
          />
        </td>
        <td class="px-4 py-4">{{ formatDate(record.record_date) }}</td>
        <td class="px-4 py-4">{{ record.bill_number }}</td>
        <!-- <td class="px-4 py-4">{{ record.vehicle_pair_name }}</td> -->
        <td class="px-4 py-4">
  <span v-if="record.is_cleaning_parts === 1">
    ล้างอะไหล่ - {{ record.cleaning_location || 'ไม่ทราบสถานที่' }}
  </span>
  <span v-else>
    {{ record.vehicle_pair_name || 'ไม่ระบุ' }}
  </span>
</td>
        <td class="px-4 py-4 text-center">{{ record.fuel_volume }} ลิตร</td>
        <td class="px-4 py-4 text-center">{{ record.price_per_liter }} บาท</td>
        <td class="px-4 py-4">{{ record.fuel_station }}</td>
        <td class="px-4 py-4 text-center">{{ record.distance }} กม.</td>
        <td class="px-4 py-4 text-center space-x-2">
          <button   @click="viewDetails(record)" class="text-blue-500 hover:text-blue-700">
            <i class="fas fa-eye"></i>
          </button>
          <button v-if="record.verified !== 1" @click="openModal(record)" class="text-green-500 hover:text-green-700">
            <i class="fas fa-edit"></i>
          </button>
          <button v-if="record.verified !== 1" @click="confirmDelete(record.id)" class="text-red-500 hover:text-red-700">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  
  <!-- ข้อความไม่พบข้อมูล -->
  <div v-else class="text-center py-4 text-gray-500">
    ไม่พบข้อมูลที่ค้นหา
  </div>

 <!-- Pagination Controls -->
 <div class="flex justify-between items-center py-4">
    <div>
      <label for="itemsPerPage" class="mr-2">รายการต่อหน้า:</label>
      <select v-model="itemsPerPage" @change="changeItemsPerPage" id="itemsPerPage" class="border rounded px-3 py-2">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="150">150</option>
        <option value="300">300</option>
      </select>
    </div>

    <!-- Navigation Buttons -->
    <div>
      <button 
        @click="goToPage(currentPage - 1)" 
        :disabled="currentPage === 1" 
        class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
      >
        ก่อนหน้า
      </button>
      <span class="px-4">{{ currentPage }} / {{ totalPages }}</span>
      <button 
        @click="goToPage(currentPage + 1)" 
        :disabled="currentPage === totalPages" 
        class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
      >
        ถัดไป
      </button>
    </div>
  </div>


</div>



  
      <!-- Form Modal -->
      <div>
  <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 sm:p-0">
    <div class="bg-white p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-4xl mx-auto my-8 max-h-[90vh] overflow-y-auto relative">
      <h2 class="text-lg sm:text-xl font-bold mb-4 sticky top-0 bg-white pt-2">
        {{ editingRecord ? "แก้ไขข้อมูลน้ำมัน" : "เพิ่มข้อมูลน้ำมัน" }}
      </h2>
      
      <form @submit.prevent="saveRecord" class="space-y-4">
        <!-- Main form grid -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4">
          <!-- หน่วยงาน -->
          <div>
            <label class="block text-sm font-bold text-gray-700 mb-2">หน่วยงาน *</label>
            <select 
              v-model="form.department_id" 
              required 
              class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
            >
              <option value="">-- เลือกหน่วยงาน --</option>
              <option v-for="dept in departments" :key="dept.id" :value="dept.id">
                {{ dept.name }}
              </option>
            </select>
          </div>

          <!-- กลุ่มงาน -->
          <div>
            <label class="block text-sm font-bold text-gray-700 mb-2">กลุ่มงาน *</label>
            <select 
              v-model="form.group_id" 
              required 
              class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
            >
              <option value="">-- เลือกกลุ่มงาน --</option>
              <option v-for="group in groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>

          <!-- Checkbox ล้างอะไหล่ -->
          <div class="md:col-span-2">
            <label class="block text-sm font-bold text-gray-700 mb-2 flex items-center">
              <input type="checkbox" v-model="form.is_cleaning_parts" class="mr-2"/>
              ล้างอะไหล่
            </label>
          </div>
  <!-- เลขที่บิล -->
      <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">เลขที่บิล *</label>
              <input 
                type="text" 
                v-model="form.bill_number" 
                required 
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150" 
              />
            </div>
             <!-- พนักงานขับรถ -->
             <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">พนักงานขับรถ *</label>
              <vue-multiselect
                v-model="form.selectedDrivers"
                :options="drivers"
                :multiple="false"
                :close-on-select="false"
                :clear-on-select="false"
                :searchable="true"
                placeholder="เลือกพนักงานขับรถ"
                :custom-label="driverLabel"
                track-by="id"
                select-label="เลือก"
                selected-label="เลือกแล้ว"
                deselect-label="ลบ"
                class="multiselect-custom"
              >
                <template #option="{ option }">
                  {{ driverLabel(option) }}
                </template>
              </vue-multiselect>
            </div>
              <!-- วันที่และเวลา -->
              <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">วันที่บันทึก *</label>
              <input 
                type="date" 
                v-model="form.record_date" 
                required 
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150" 
              />
            </div>
            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">เวลาที่จ่าย *</label>
              <input
                type="text"
                v-model="form.dispensing_time"
                @input="handleTimeInput"
                maxlength="5"
                required
                placeholder="00:00"
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
                title="กรุณาใส่เวลาในรูปแบบ 00:00"
              />
              <span v-if="form.dispensing_time && !validateTimeInput()" class="text-red-500 text-sm">
                กรุณาใส่เวลาในรูปแบบ 00:00 (ตัวอย่าง: 09:30)
              </span>
            </div>
            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">ผู้จ่าย *</label>
              <select 
                v-model="form.paid_by" 
                required 
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              >
                <option value="">-- เลือกผู้จ่าย --</option>
                <option v-for="payer in payers" :key="payer" :value="payer">
                  {{ payer }}
                </option>
              </select>
            </div>

              <!-- หัวจ่ายที่ -->
              <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">หัวจ่ายที่ *</label>
              <select 
                v-model="form.dispenser_number" 
                required 
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              >
                <option value="">-- เลือกหัวจ่าย --</option>
                <option value="1">หัวจ่ายที่ 1</option>
                <option value="2">หัวจ่ายที่ 2</option>
              </select>
            </div>

          <!-- Conditional form sections -->
          <template v-if="form.is_cleaning_parts">
            <div >
              <label class="block text-sm font-bold text-gray-700 mb-2">สถานที่ล้าง *</label>
              <select 
                v-model="form.cleaning_location" 
                required 
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              >
                <option value="">-- เลือกสถานที่ล้าง --</option>
                <option value="อู่ลานกระบือ">อู่ลานกระบือ</option>
              </select>
            </div>
          </template>

          <template v-else>
           

            <!-- คู่รถ -->
            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">คู่รถ *</label>
              <vue-multiselect
                v-model="form.selectedVehiclePairs"
                :options="vehiclePairs"
                :multiple="false"
                :close-on-select="false"
                :clear-on-select="false"
                :searchable="true"
                placeholder="เลือกคู่รถ"
                :custom-label="vehiclePairLabel"
                track-by="id"
                select-label="เลือก"
                selected-label="เลือกแล้ว"
                deselect-label="ลบ"
                class="multiselect-custom"
              >
                <template #option="{ option }">
                  {{ vehiclePairLabel(option) }}
                </template>
              </vue-multiselect>
            </div>

            <!-- สถานที่เติมน้ำมัน -->
            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">สถานที่เติมน้ำมัน *</label>
              <input 
                type="text" 
                v-model="form.fuel_station" 
                required 
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150" 
              />
            </div>

          
            <!-- เลขไมล์ -->
            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">เลขไมล์เริ่ม (กม.) *</label>
              <input
                type="number"
                v-model="form.odometer_start"
                required
                step="0.01"
                min="0"
                @input="checkNegative('odometer_start')"
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              />
            </div>

            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">เลขไมล์จบ (กม.) *</label>
              <input
                type="number"
                v-model="form.odometer_end"
                required
                step="0.01"
                min="0"
                @input="checkNegative('odometer_end')"
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              />
            </div>
          </template>
            <!-- มิเตอร์ -->
            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">มิเตอร์ก่อนเติม *</label>
              <input
                type="number"
                v-model="form.meter_before"
                required
                step="0.01"
                min="0"
                @input="checkNegative('meter_before')"
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              />
            </div>

            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">มิเตอร์หลังเติม *</label>
              <input
                type="number"
                v-model="form.meter_after"
                required
                step="0.01"
                min="0"
                @input="checkNegative('meter_after')"
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              />
            </div>

            <!-- จำนวนและราคา -->
            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">จำนวนลิตร *</label>
              <input
                type="number"
                v-model="form.fuel_volume"
                required
                step="0.01"
                min="0"
                @input="checkNegative('fuel_volume')"
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              />
            </div>

            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">ราคาต่อลิตร *</label>
              <input
                type="number"
                v-model="form.price_per_liter"
                required
                step="0.01"
                min="0"
                @input="checkNegative('price_per_liter')"
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
              />
            </div>

            <!-- ราคารวมและผู้จ่าย -->
            <div>
              <label class="block text-sm font-bold text-gray-700 mb-2">ราคารวม</label>
              <input 
                type="number" 
                :value="totalPrice" 
                disabled 
                class="w-full border rounded px-3 py-2 bg-gray-100" 
              />
            </div>

           
          

           
          

            <!-- แนบไฟล์ -->
            <div class="md:col-span-2">
              <label class="block text-sm font-bold text-gray-700 mb-2">แนบไฟล์ (รูปบิล)</label>
              <div v-if="form.attachment" class="mb-2">
                <p class="text-sm text-gray-600">
                  ไฟล์แนบเก่า: 
                  <a 
                    :href="form.attachment && typeof form.attachment === 'string' ? 'https://brkmeeting-room.online/Fuel/' + form.attachment.split('/').pop() : ''" 
                    class="text-blue-500 hover:text-blue-700 hover:underline transition duration-150" 
                    target="_blank"
                  >
                    ดูไฟล์
                  </a>
                </p>
              </div>
              <input 
                type="file" 
                @change="handleFileUpload" 
                accept="image/*" 
                class="w-full border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file
                :bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" 
              />
            </div>
    
        </div>
 <!-- Footer Buttons -->
 <div class="mt-6 flex justify-end space-x-3">
          <button 
            type="button" 
            @click="closeModal" 
            class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            ยกเลิก
          </button>
          <button 
            type="submit" 
            :disabled="isLoading" 
            class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {{ isLoading ? 'กำลังบันทึก...' : 'บันทึก' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>




<!-- Details Modal -->
<div v-if="showDetailsModal" className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
  <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
    <div className="p-4 sm:p-6">
      <h2 className="text-lg sm:text-xl font-bold mb-4">รายละเอียดการเติมน้ำมัน</h2>
      <div className="space-y-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
          <div v-for="field in orderedFields" :key="field" className="border-b pb-2">
            <span className="font-bold text-sm sm:text-base">{{ getThaiLabel(field) }}:</span>
            <span className="ml-2 text-sm sm:text-base break-words">{{ displayRecord[field] }}</span>
          </div>
        </div>
        
        <!-- File attachment section -->
        <div v-if="selectedRecord.attachment" className="mt-3">
          <p className="text-xs sm:text-sm text-gray-600">
            <span className="font-bold">ไฟล์แนบ: </span>
            <a 
              :href="'https://brkmeeting-room.online/Fuel/' + selectedRecord.attachment.split('/').pop()" 
              target="_blank" 
              className="text-blue-500 hover:text-blue-700 inline-flex items-center"
            >
              <i className="fas fa-eye mr-1"></i>
              <span>ดูไฟล์</span>
            </a>
          </p>
        </div>

        <div className="mt-4 flex justify-end">
          <button 
            @click="showDetailsModal = false" 
            className="px-3 py-1.5 sm:px-4 sm:py-2 bg-gray-500 text-white text-sm sm:text-base rounded hover:bg-gray-600 transition-colors duration-200"
          >
            ปิด
          </button>
        </div>
      </div>
    </div>
  </div>
</div>



 <!-- Modal Verify -->
 <div v-if="isVerifyModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
      <h2 class="text-lg font-bold mb-4">Verify Fuel Records</h2>
      <form @submit.prevent="verifyRecords">
        <div class="space-y-4">
          <div>
            <label class="block text-sm font-bold text-gray-700 mb-2">แนบไฟล์บิลน้ำมัน *</label>
            <input type="file" @change="handleFileUploadBill" accept="image/*,application/pdf" class="w-full border rounded px-3 py-2" />
            <div v-if="attachmentPreview" class="mt-4">
              <span class="block text-sm text-gray-500">ไฟล์ที่แนบ:</span>
              <iframe 
                v-if="isPDF" 
                :src="attachmentPreview" 
                class="w-full h-64 border" 
                frameborder="0"
              ></iframe>
              <img 
                v-else 
                :src="attachmentPreview" 
                alt="ตัวอย่างไฟล์" 
                class="w-full max-h-64 mt-2"
              />
            </div>
          </div>
        </div>

        <div class="mt-4 flex justify-end space-x-2">
          <button type="button" @click="closeVerifyModal" class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600">
            ยกเลิก
          </button>
          <button type="submit" :disabled="isLoading" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            {{ isLoading ? 'กำลังบันทึก...' : 'ยืนยัน' }}
          </button>
        </div>
      </form>
    </div>
  </div>

    </div>

  </TransportLayout>
  </template>
  
  <script>
import axios from "axios";
import Swal from "sweetalert2";
import { format } from 'date-fns';
import { th } from 'date-fns/locale';
import VueMultiselect from 'vue-multiselect'
import TransportLayout from '@/components/transport/TransportSidebar.vue';
export default {
    components: {
    VueMultiselect,
    TransportLayout
  },
  data() {
    return {
      
      payers: ['ศิรประภา', 'กัลลภา'],
      isLoading: false,
      isLoadingMeter: false,
      isModalOpen: false,
      showDetailsModal: false,
      editingRecord: null,
      selectedRecord: null,
      fuelRecords: [],
      departments: [],
      groups: [],
      drivers: [],
      vehiclePairs: [],
      selectedDrivers: [],
      selectedVehiclePairs: [],
      isAllSelected: false,
      selectedRecords: [],
    isVerifyModalOpen: false,
    attachmentFile: null,
    attachmentPreview: null,
    isPDF: false,
      filters: {
        date: "",
        department: "",
        group: "",
        driver: "",
        verified: "" // ค่าเริ่มต้นคือ "ทั้งหมด"
      },
      originalFuelRecords: [], // เก็บข้อมูลต้นฉบับ
      currentPage: 1,  // หน้าที่แสดงอยู่ในขณะนี้
      itemsPerPage: 10, // จำนวนรายการที่แสดงต่อหน้า
      totalItems: 0, // จำนวนรายการทั้งหมด
      totalPages: 0,
      requesters: [],
      form: {
        is_cleaning_parts: false,
      cleaning_location: '',
        department_id: "",
        group_id: "",
        selectedDrivers: [],  // เปลี่ยนเป็น array แทน null
        selectedVehiclePairs: [],  // เปลี่ยนเป็น array แทน null
        fuel_station: "",
        bill_number: "",
        odometer_start: "",
        odometer_end: "",
        meter_before: "",
        meter_after: "",
        fuel_volume: "",
        price_per_liter: "",
        paid_by: "",
        record_date: new Date().toISOString().split('T')[0], // Default to today's date
        attachment: null,
        attachmentUrl: '', // URL ของไฟล์ที่สามารถดาวน์โหลด
        selectedRequester: null,
      dispenser_number: '',
      dispensing_time: '',
      isLoadingOdometer: false,
      },
       // กำหนดลำดับการแสดงผลฟิลด์
       fieldOrder: [
        'department_name',
        'group_name',
        'vehicle_pair_name',
        'driver_name',
        'bill_number',
        'fuel_station',
        'fuel_volume',
        'price_per_liter',
        'total_cost',
        'odometer_start',
        'odometer_end',
        'distance',
        'fuel_efficiency',
        'meter_before',
        'meter_after',
        'paid_by',
        'record_date',
        'updated_at',
        'created_by',
        'updated_by'
      ]
    };
  },

  computed: {
    // filteredRecord() {
    //   return Object.fromEntries(
    //     Object.entries(this.selectedRecord).filter(
    //       ([key]) => key !== 'id' && key !== 'vehicle_pair_id'
    //     )
    //   );0
    
    shouldWatch() {
      return !this.editingRecord
    },
    displayRecord() {
      if (!this.selectedRecord) return {};

      // Create a new object without id and vehicle_pair_id
      const filtered = {};
      
     // Add all fields except those we want to exclude
     for (const [key, value] of Object.entries(this.selectedRecord)) {
        if (key !== 'id' && key !== 'vehicle_pair_id' && 
            key !== 'department_id' && key !== 'group_id' && 
            key !== 'driver_id') {
          filtered[key] = value;
        }
      }

      // Add department_name and group_name
      if (this.selectedRecord.department_id) {
        const department = this.departments.find(
          dept => dept.id === this.selectedRecord.department_id
        );
        filtered.department_name = department?.name || "ไม่ทราบหน่วยงาน";
      }

      if (this.selectedRecord.group_id) {
        const group = this.groups.find(
          grp => grp.id === this.selectedRecord.group_id
        );
        filtered.group_name = group?.name || "ไม่ทราบกลุ่มงาน";
      }

      // Add driver_name with full name format
      if (this.selectedRecord.driver_id) {
        const driver = this.drivers.find(
          drv => drv.id === this.selectedRecord.driver_id
        );
        filtered.driver_name = this.formatDriverName(driver);
      }

  // Format dates specifically for display
  if (filtered.created_at) {
        filtered.created_at = this.formatDate(filtered.created_at);
      }
        // Format dates specifically for display
  if (filtered.record_date) {
        filtered.record_date = this.formatDate(filtered.record_date);
      }
      if (filtered.updated_at) {
        filtered.updated_at = this.formatDate(filtered.updated_at);
      }
      return filtered;
    },
    orderedFields() {
      return this.fieldOrder.filter(field => field in this.displayRecord);
    },
    filteredRecord() {
      // กรองฟิลด์ที่ไม่ต้องการ
      const filtered = Object.fromEntries(
        Object.entries(this.selectedRecord || {}).filter(
          ([key]) => key !== 'id' && key !== 'vehicle_pair_id'
        )
      );

      // แทนที่ department_id และ group_id ด้วยชื่อ
      if (this.selectedRecord?.department_id) {
        filtered.department_name = this.departments.find(department => department.id === this.selectedRecord.department_id)?.name || "ไม่ทราบหน่วยงาน";
      }
      if (this.selectedRecord?.group_id) {
        filtered.group_name = this.groups.find(group => group.id === this.selectedRecord.group_id)?.name || "ไม่ทราบกลุ่มงาน";
      }

      return filtered;
    },
    // ฟิลเตอร์ข้อมูลที่จะแสดงในหน้าปัจจุบัน
    paginatedFuelRecords() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.fuelRecords.slice(start, end);
    },
    safeRecords() {
      return this.fuelRecords?.filter(record => record != null) || [];
    },
    totalPrice() {
      if (this.form.fuel_volume && this.form.price_per_liter) {
        return (this.form.fuel_volume * this.form.price_per_liter).toFixed(2);
      }
      return "0.00";
    },
    driversWithFullName() {
      return this.drivers.map(driver => ({
        ...driver,
        fullName: `${driver.prefix}${driver.firstName} ${driver.lastName} - ${driver.department}`
      }))
    },
  },

  methods: {
    // Frontend - Method to fetch previous meter reading
  // Frontend
  async fetchPreviousMeter() {
  if (!this.form.record_date || !this.form.dispenser_number) {
    return;
  }

  try {
    this.isLoadingMeter = true;
    const response = await axios.get('https://brk-group.org/api/ts/transport/fuel-records/previous-meter', {
      params: {
        record_date: this.form.record_date,
        dispenser_number: this.form.dispenser_number
      }
    });

    // ถ้าพบข้อมูลและมีค่า meter_after
    if (response.data.success && response.data.data && response.data.data.meter_after) {
      const { meter_after, record_date, dispensing_time } = response.data.data;
      
      // อัพเดทค่ามิเตอร์เริ่มต้น
      this.form.meter_before = meter_after;
      
      const formattedDate = new Date(record_date).toLocaleDateString('th-TH');
      const message = `ดึงข้อมูลมิเตอร์ล่าสุดของหัวจ่ายที่ ${this.form.dispenser_number} (${meter_after.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })} ลิตร) จากวันที่ ${formattedDate} เวลา ${dispensing_time}`;
      
      await Swal.fire({
        title: 'ดึงข้อมูลสำเร็จ',
        text: message,
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 6000
      });
    } else {
      // กรณีไม่พบข้อมูล
      this.form.meter_before = 0.00;
      
      // ต้องใช้ await เพื่อให้แน่ใจว่า Swal จะแสดง
      await Swal.fire({
        title: 'ไม่พบข้อมูลเดิม',
        text: `ไม่พบข้อมูลมิเตอร์เดิมของหัวจ่ายที่ ${this.form.dispenser_number} ค่าเริ่มต้นถูกตั้งเป็น 0.00`,
        icon: 'info',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,  // เพิ่ม progress bar
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
    }
  } catch (error) {
    console.error('Error fetching previous meter:', error);
    
    // กำหนดค่าเริ่มต้นในกรณีเกิด error
    this.form.meter_before = 0.00;
    
    // แสดงข้อความ error ที่เหมาะสม
    let errorMessage = 'ไม่สามารถดึงข้อมูลมิเตอร์ล่าสุดได้';
    if (error.response) {
      errorMessage = error.response.data.message || errorMessage;
    }
    
    // ใช้ await สำหรับ error notification ด้วย
    await Swal.fire({
      title: 'ผิดพลาด',
      text: errorMessage,
      icon: 'error',
      timer: 3000,
      showConfirmButton: false,
      toast: true,
      position: 'top-end'
    });
  } finally {
    this.isLoadingMeter = false;
  }
},

      // เพิ่ม method สำหรับดึงข้อมูลเลขไมล์ล่าสุด
      async fetchLastOdometer(vehiclePairId) {
      try {
        this.isLoadingOdometer = true;
        const response = await axios.get(`https://brk-group.org/api/ts/fuel-records/last-odometer/${vehiclePairId}`);
        
        if (response.data.success && response.data.data) {
          const { odometer_end, record_date, dispensing_time } = response.data.data;
          
          // อัพเดทค่าเลขไมล์เริ่ม
          this.form.odometer_start = odometer_end || 0;
          
          // แสดง Tooltip หรือ notification เพื่อแจ้งข้อมูล
          const formattedDate = new Date(record_date).toLocaleDateString('th-TH');
          const message = `ดึงข้อมูลเลขไมล์ล่าสุด (${odometer_end.toLocaleString()} กม.) จากวันที่ ${formattedDate} เวลา ${dispensing_time}`;
          
          Swal.fire({
            title: 'ดึงข้อมูลสำเร็จ',
            text: message,
            icon: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 6000
          });
        } else {
        // แสดง Tooltip กรณีไม่มีข้อมูล
        Swal.fire({
          title: 'ไม่พบข้อมูลเดิม',
          text: 'ไม่พบข้อมูลเลขไมล์เดิม ค่าเริ่มต้นถูกตั้งเป็น 0',
          icon: 'info',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        });
      }
      } catch (error) {
        console.error('Error fetching last odometer:', error);
        Swal.fire({
          title: 'ผิดพลาด',
          text: 'ไม่สามารถดึงข้อมูลเลขไมล์ล่าสุดได้',
          icon: 'error'
        });
      } finally {
        this.isLoadingOdometer = false;
      }
    },
    requesterLabel(requester) {
    return `${requester.prefix} ${requester.firstName} ${requester.lastName} - ${requester.department}/${requester.group}`;
  },
  formatTime(time) {
    if (!time) return '';
    return time.replace(/[^0-9]/g, '').replace(/^(\d{0,2})(\d{0,2}).*/, (_, h, m) => {
      if (!h) return '';
      if (!m) return h;
      return `${h.padStart(2, '0')}.${m.padStart(2, '0')}`;
    });
  },

  handleTimeInput(event) {
  let value = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

  // Handle backspace/delete
  if (value.length < this.form.dispensing_time.replace(/[^0-9]/g, '').length) {
    // If backspacing from HH:M to HH:, remove the colon too
    if (this.form.dispensing_time.endsWith(':') && value.length === 2) {
      this.form.dispensing_time = value;
    } else {
      this.form.dispensing_time = value;
    }
    return;
  }

  // Limit to 4 digits
  value = value.substring(0, 4);

  // Format and validate as user types
  if (value.length >= 2) {
    let hours = parseInt(value.substring(0, 2));
    
    // Validate hours
    if (hours > 23) {
      hours = 23;
      value = '23' + value.substring(2);
    }

    if (value.length >= 4) {
      let minutes = parseInt(value.substring(2, 4));
      
      // Validate minutes
      if (minutes > 59) {
        minutes = 59;
        value = value.substring(0, 2) + '59';
      }

      // Format as HH:MM
      this.form.dispensing_time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    } else {
      // Just format hours with colon
      this.form.dispensing_time = `${hours.toString().padStart(2, '0')}${value.length > 2 ? ':' + value.substring(2) : ''}`;
    }
  } else {
    this.form.dispensing_time = value;
  }
},

// Improved time validation with strict format checking
validateTimeInput() {
  if (!this.form.dispensing_time) return false;

  // Strict HH:MM format validation
  const timePattern = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
  const isValid = timePattern.test(this.form.dispensing_time);

  if (!isValid) {
    // You can add additional handling here if needed
    console.warn('Invalid time format:', this.form.dispensing_time);
  }

  return isValid;
},
    checkNegative(field) {
    // กำหนดชื่อฟิลด์ที่จะแสดงในข้อความแจ้งเตือน
    const fieldNames = {
      odometer_start: "เลขไมล์เริ่ม",
      odometer_end: "เลขไมล์จบ",
      meter_before: "มิเตอร์ก่อนเติม",
      meter_after: "มิเตอร์หลังเติม",
      fuel_volume: "จำนวนลิตร",
      price_per_liter: "ราคาต่อลิตร"
    };

    if (this.form[field] < 0) {
      this.form[field] = 0; // รีเซ็ตค่าติดลบเป็น 0
      Swal.fire("ผิดพลาด", `${fieldNames[field]} ต้องไม่ติดลบ`, "error");
    }
  },
   // Method สำหรับการยืนยันการลบหลายรายการ
async confirmDeleteMultiple() {
  if (this.selectedRecords.length === 0) {
    await Swal.fire({
      icon: 'warning',
      title: 'กรุณาเลือกรายการ',
      text: 'โปรดเลือกรายการที่ต้องการลบอย่างน้อย 1 รายการ',
      confirmButtonText: 'ตกลง'
    });
    return;
  }

  const result = await Swal.fire({
    title: "ยืนยันการลบ",
    text: `คุณแน่ใจหรือไม่ที่จะลบข้อมูล ${this.selectedRecords.length} รายการ?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "ลบ",
    cancelButtonText: "ยกเลิก"
  });

  if (result.isConfirmed) {
    await this.deleteMultipleRecords();
  }
},

// Method สำหรับการลบหลายรายการ
async deleteMultipleRecords() {
  try {
    Swal.fire({
      title: 'กำลังลบข้อมูล...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const response = await axios.delete('https://brk-group.org/api/ts/fuel-records/bulk-deletee', {
      data: {
        ids: this.selectedRecords
      }
    });
    
    if (response.data && response.data.success) {
      await Swal.fire({
        icon: 'success',
        title: 'ลบข้อมูลสำเร็จ!',
        text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
        timer: 1500,
        showConfirmButton: false
      });
      this.selectedRecords = [];
      this.fetchRecords();
      this.$emit('records-deleted');
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: response.data?.message || 'ไม่สามารถลบข้อมูลได้',
        confirmButtonText: 'ตกลง'
      });
    }
  } catch (error) {
    console.error('Error deleting records:', error);
    await Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด!',
      text: error.response?.data?.message || 'ไม่สามารถลบข้อมูลได้',
      confirmButtonText: 'ตกลง'
    });
  }
}
,
    toggleSelectAll() {
    if (this.isAllSelected) {
      this.selectedRecords = [];
    } else {
      this.selectedRecords = this.fuelRecords.map(record => record.id);
    }
    this.isAllSelected = !this.isAllSelected;
  },
  openVerifyModal() {
    this.isVerifyModalOpen = true;
  },
  closeVerifyModal() {
    this.isVerifyModalOpen = false;
    this.attachmentFile = null;
    this.attachmentPreview = null;
    this.isPDF = false;
  },
  handleFileUploadBill(event) {
    const file = event.target.files[0];
    this.attachmentFile = file;

    if (file.type === "application/pdf") {
      this.isPDF = true;
    } else {
      this.isPDF = false;
    }

    this.attachmentPreview = URL.createObjectURL(file);
  },
  async verifyRecords() {
    if (!this.attachmentFile) {
      Swal.fire("ผิดพลาด", "กรุณาแนบไฟล์บิลน้ำมัน", "error");
      return;
    }
    if (!this.selectedRecords || this.selectedRecords.length === 0) {
    Swal.fire("ผิดพลาด", "กรุณาเลือกอย่างน้อย 1 รายการเพื่อ Verify", "error");
    return;
  }
    this.isLoading = true;

    const formData = new FormData();
    formData.append("attachmentbill", this.attachmentFile);
    formData.append("recordIds", JSON.stringify(this.selectedRecords));

    try {
      const response = await axios.post("https://brk-group.org/api/ts/fuel-records/verify", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (response.data.success) {
        Swal.fire("สำเร็จ", "บันทึกการ Verify สำเร็จ", "success");
        this.closeVerifyModal();
        this.fetchRecords(); // โหลดข้อมูลใหม่
      } else {
        Swal.fire("ผิดพลาด", response.data.message || "ไม่สามารถบันทึกข้อมูลได้", "error");
      }
    } catch (error) {
      console.error("Error verifying records:", error);
      Swal.fire("ผิดพลาด", "เกิดข้อผิดพลาดในการ Verify", "error");
    } finally {
      this.isLoading = false;
    }
  },
    formatDriverName(driver) {
      if (!driver) return "ไม่ทราบชื่อผู้ขับ";
      return `${driver.prefix}${driver.firstName} ${driver.lastName}`.trim();
    },
    onFilterChange() {
    this.currentPage = 1;  // รีเซ็ตหน้าไปที่หน้า 1
    this.fetchRecords();  // เรียกฟังก์ชัน fetchRecords เพื่อดึงข้อมูลใหม่
  },
  
    clearFilters() {
      // รีเซ็ตค่าของฟิลด์ทั้งหมดใน filters
      this.filters = {
        date: '',
        department: '',
        group: '',
        driver: '',
        verified: '',
      };
      this.currentPage = 1;  // รีเซ็ตหน้าไปที่หน้า 1
      // เรียกใช้ฟังก์ชัน fetchRecords เพื่อรีเฟรชข้อมูลหลังจากล้างข้อมูล
      this.fetchRecords();
    },
    applyFilters() {
      this.fuelRecords = this.originalFuelRecords.filter(record => {
        const matchesDate = this.filters.date
          ? record.record_date.startsWith(this.filters.date)
          : true;
        const matchesDepartment = this.filters.department
          ? record.department_id == this.filters.department
          : true;
        const matchesGroup = this.filters.group
          ? record.group_id == this.filters.group
          : true;
        const matchesDriver = this.filters.driver
          ? record.driver_name.toLowerCase().includes(this.filters.driver.toLowerCase())
          : true;

        return matchesDate && matchesDepartment && matchesGroup && matchesDriver;
      });
    },
    getInitialFormState() {
      return {
        department_id: "",
        group_id: "",
        fuel_station: "",
        bill_number: "",
        odometer_start: "",
        odometer_end: "",
        meter_before: "",
        meter_after: "",
        fuel_volume: "",
        price_per_liter: "",
        paid_by: "",
        attachment: null,
        record_date: '', // ฟิลด์วันที่
        selectedDrivers: [], // Initialize as empty array
        selectedVehiclePairs: [], // Initialize as empty array
      };
    },
    driverLabel(driver) {
      if (!driver) return '';
      return `${driver.prefix}${driver.firstName} ${driver.lastName} - ${driver.department}`;
    },
    async handleVehiclePairSelect(selectedVehicle) {
      if (selectedVehicle && selectedVehicle.id) {
        await this.fetchLastOdometer(selectedVehicle.id);
      } else {
        // รีเซ็ตค่าเลขไมล์เมื่อไม่ได้เลือกรถ
        this.form.odometer_start = '';
      }
    },
    // เพิ่มเมธอดสำหรับ format ข้อความแสดงคู่รถ
    vehiclePairLabel(pair) {
      if (!pair) return '';
      return `${pair.headLicensePlate} - ${pair.trailerLicensePlate} (${pair.departmentName})`;
    },
    formatDate(date) {
      if (!date) return "-";
  try {
    // แปลงวันที่ให้เป็นวัน/เดือน/ปี เวลา
    return format(new Date(date), 'dd/MM/yyyy HH:mm', { 
      locale: th,  // ใช้ locale ภาษาไทย
      timeZone: 'Asia/Bangkok'  // ตั้งค่า time zone เป็น Bangkok
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return "-";
  }
},
async fetchRecords() {
  try {
    this.isLoading = true;

    // สร้าง query string จากฟิลเตอร์ใน `this.filters`
    const params = new URLSearchParams();

    if (this.filters.date) params.append("date", this.filters.date);
    if (this.filters.department) params.append("department", this.filters.department);
    if (this.filters.group) params.append("group", this.filters.group);
    if (this.filters.driver) params.append("driver", this.filters.driver);
    if (this.filters.verified !== "") params.append("verified", this.filters.verified); // เพิ่มฟิลเตอร์ verified
 // เพิ่มค่าของ page และ itemsPerPage ไปใน params
    params.append("page", this.currentPage); // หน้าแสดง
    params.append("itemsPerPage", this.itemsPerPage); // จำนวนรายการต่อหน้า
    // ส่ง query string ไปยัง API
    const response = await axios.get(`https://brk-group.org/api/ts/fuel-records?${params.toString()}`);

    // ตรวจสอบผลลัพธ์ที่ได้จาก API
    if (response.data?.success) {
      this.fuelRecords = response.data.data || [];
      this.totalItems = response.data.totalItems || 0; // เก็บจำนวนรายการทั้งหมดจาก API
      
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    } else {
      // ไม่ต้องแจ้งเตือน แค่ตั้งค่า `fuelRecords` เป็นอาร์เรย์ว่าง
      this.fuelRecords = [];
      this.totalItems = 0;
      this.totalPages = 0;
    }
  } catch (error) {
    console.error("Error fetching records:", error);
    this.fuelRecords = [];
    this.totalItems = 0;
    this.totalPages = 0;
    // Swal.fire("ผิดพลาด", "ไม่สามารถดึงข้อมูลได้", "error");
  } finally {
    this.isLoading = false;
  }
}

,
changeItemsPerPage() {
      this.currentPage = 1; // รีเซ็ตหน้าปัจจุบันเมื่อเปลี่ยนจำนวนรายการต่อหน้า
      this.fetchRecords();
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchRecords();
      }
    },
    async fetchDrivers() {
        try {
            const response = await axios.get("https://brk-group.org/api/ts/employees");
            this.drivers = response.data; // เก็บข้อมูลพนักงานทั้งหมด
        } catch (error) {
            console.error("Error fetching drivers:", error);
            Swal.fire("ผิดพลาด", "ไม่สามารถดึงข้อมูลพนักงานขับรถได้", "error");
        }
    },

    async fetchVehiclePairs() {
        try {
            const response = await axios.get("https://brk-group.org/api/ts/vehicle-pairs");
            this.vehiclePairs = response.data;
        } catch (error) {
            console.error("Error fetching vehicle pairs:", error);
            Swal.fire("ผิดพลาด", "ไม่สามารถดึงข้อมูลคู่รถได้", "error");
        }
    },
    validateForm() {
  // ตรวจสอบฟิลด์พื้นฐานที่จำเป็นในทุกกรณี
  const baseRequiredFields = [
    'department_id',
    'group_id',
    'bill_number',
    'fuel_volume',
    'price_per_liter',
    'paid_by',
    'record_date',
    'dispensing_time'
  ];

  // ตรวจสอบฟิลด์เพิ่มเติมตามเงื่อนไข is_cleaning_parts
  if (this.form.is_cleaning_parts) {
    // กรณีล้างอะไหล่
    if (!this.form.cleaning_location) {
      Swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
        text: 'กรุณาระบุสถานที่ล้าง',
        confirmButtonText: 'ตกลง'
      });
      return false;
    }
  } else {
    // กรณีเติมน้ำมัน - ตรวจสอบฟิลด์เพิ่มเติม
    const fuelRequiredFields = [
      'fuel_station',
      'odometer_start',
      'odometer_end',
      'meter_before',
      'meter_after'
    ];
    
    // รวมฟิลด์ที่ต้องตรวจสอบทั้งหมด
    const allRequiredFields = [...baseRequiredFields, ...fuelRequiredFields];
    
    // ตรวจสอบฟิลด์ที่จำเป็นทั้งหมด
    const missingFields = allRequiredFields.filter(field => {
      const value = this.form[field];
      return value === undefined || value === null || value === '';
    });

    if (missingFields.length > 0) {
      // แปลงชื่อฟิลด์เป็นภาษาไทยเพื่อแสดงให้ผู้ใช้เข้าใจ
      const fieldTranslations = {
        department_id: 'หน่วยงาน',
        group_id: 'กลุ่มงาน',
        fuel_station: 'สถานที่เติมน้ำมัน',
        bill_number: 'เลขที่บิล',
        odometer_start: 'เลขไมล์เริ่ม',
        odometer_end: 'เลขไมล์จบ',
        meter_before: 'มิเตอร์ก่อนเติม',
        meter_after: 'มิเตอร์หลังเติม',
        fuel_volume: 'จำนวนลิตร',
        price_per_liter: 'ราคาต่อลิตร',
        paid_by: 'ผู้จ่าย',
        record_date: 'วันที่บันทึก',
        dispensing_time: 'เวลาที่จ่าย'
      };

      const missingFieldsText = missingFields
        .map(field => fieldTranslations[field] || field)
        .join(', ');

      Swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
        text: `กรุณากรอก: ${missingFieldsText}`,
        confirmButtonText: 'ตกลง'
      });
      return false;
    }

    // ตรวจสอบความถูกต้องของค่าตัวเลข
    if (parseFloat(this.form.odometer_end) < parseFloat(this.form.odometer_start)) {
      Swal.fire({
        icon: 'error',
        title: 'ข้อมูลไม่ถูกต้อง',
        text: 'เลขไมล์จบต้องมากกว่าเลขไมล์เริ่ม',
        confirmButtonText: 'ตกลง'
      });
      return false;
    }

    if (parseFloat(this.form.meter_after) < parseFloat(this.form.meter_before)) {
      Swal.fire({
        icon: 'error',
        title: 'ข้อมูลไม่ถูกต้อง',
        text: 'มิเตอร์หลังเติมต้องมากกว่ามิเตอร์ก่อนเติม',
        confirmButtonText: 'ตกลง'
      });
      return false;
    }
  }

  // ตรวจสอบรูปแบบเวลา
  if (!this.validateTimeInput()) {
    Swal.fire({
      icon: 'warning',
      title: 'รูปแบบเวลาไม่ถูกต้อง',
      text: 'กรุณากรอกเวลาในรูปแบบ HH:MM',
      confirmButtonText: 'ตกลง'
    });
    return false;
  }

  return true;
},
async saveRecord() {
  try {
    Swal.fire({
      title: 'กำลังบันทึกข้อมูล...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    this.isLoading = true;

    if (!this.validateForm()) {
      return;
    }

    const fuelPrice = this.form.fuel_volume * this.form.price_per_liter;
    const formData = new FormData();

    Object.keys(this.form).forEach(key => {
      if (
        key === 'selectedDrivers' ||
        key === 'selectedVehiclePairs' ||
        key === 'selectedRequester'
      ) {
        return;
      }

      if (this.form[key] !== null && this.form[key] !== undefined) {
        formData.append(key, this.form[key]);
      }
    });

    if (this.form.selectedDrivers?.id) {
      formData.append('driver_id', this.form.selectedDrivers.id.toString());
    }

    if (this.form.selectedVehiclePairs?.id) {
      formData.append('vehicle_pair_id', this.form.selectedVehiclePairs.id.toString());
    }
    if (this.form.is_cleaning_parts) {
      // ส่งค่าที่เกี่ยวข้องกับการล้างอะไหล่
      formData.append('is_cleaning_parts', 1);
      formData.append('cleaning_location', this.form.cleaning_location || '');
    } else {
      formData.append('is_cleaning_parts', '0');
      
      // ตรวจสอบและส่งค่าที่จำเป็นสำหรับการเติมน้ำมัน
      if (!this.form.fuel_station) {
        throw new Error('กรุณาระบุสถานที่เติมน้ำมัน');
      }
      if (!this.form.odometer_start) {
        throw new Error('กรุณาระบุเลขไมล์เริ่มต้น');
      }
      if (!this.form.odometer_end) {
        throw new Error('กรุณาระบุเลขไมล์สิ้นสุด');
      }
    }

const dispensingTime = Array.isArray(this.form.dispensing_time) ? this.form.dispensing_time[0] : this.form.dispensing_time;
    formData.append('dispenser_number', this.form.dispenser_number);
    formData.append('dispensing_time', dispensingTime);
    formData.append('fuel_price', fuelPrice.toString());

    const response = this.editingRecord
      ? await axios.put(
          // `https://brk-group.org/api/ts/fuel-records/${this.editingRecord.id}`,
          `https://brk-group.org/api/ts/fuel-records/${this.editingRecord.id}`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
      : await axios.post(
          // 'https://brk-group.org/api/ts/fuel-records',
          'https://brk-group.org/api/ts/fuel-records',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );

    if (response.data.success) {
      await Swal.fire({
        icon: 'success',
        title: 'สำเร็จ!',
        text: this.editingRecord
          ? 'แก้ไขข้อมูลเรียบร้อยแล้ว'
          : 'บันทึกข้อมูลเรียบร้อยแล้ว',
        timer: 1500,
        showConfirmButton: false
      });

      await this.fetchRecords();
      this.closeModal();
    }
  } catch (error) {
    console.error('Error saving record:', error);
    await Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด!',
      text: error.response?.data?.message || 'ไม่สามารถบันทึกข้อมูลได้',
      confirmButtonText: 'ตกลง'
    });
  } finally {
    this.isLoading = false;
  }
},

    async confirmDelete(id) {
      const result = await Swal.fire({
        title: "ยืนยันการลบ",
        text: "คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก"
      });

      if (result.isConfirmed) {
        await this.deleteRecord(id);
      }
    },

    async deleteRecord(id) {
  try {
    const result = await Swal.fire({
      icon: 'warning',
      title: 'คุณแน่ใจหรือไม่?',
      text: 'ข้อมูลที่ถูกลบจะไม่สามารถกู้คืนได้',
      showCancelButton: true,
      confirmButtonText: 'ใช่, ลบเลย',
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6'
    })

    if (result.isConfirmed) {
      Swal.fire({
        title: 'กำลังลบข้อมูล...',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      const response = await axios.delete(`https://brk-group.org/api/ts/fuel-records/${id}`)
      
      if (response.data && response.data.success) {
        await Swal.fire({
          icon: 'success',
          title: 'ลบข้อมูลสำเร็จ!',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          timer: 1500,
          showConfirmButton: false
        })
        this.fetchRecords();
        this.$emit('record-deleted')
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: response.data?.message || 'ไม่สามารถลบข้อมูลได้',
          confirmButtonText: 'ตกลง'
        })
      }
    }
  } catch (error) {
    console.error('Error deleting record:', error)
    await Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด!',
      text: error.response?.data?.message || 'ไม่สามารถลบข้อมูลได้',
      confirmButtonText: 'ตกลง'
    })
  }
},

    viewDetails(record) {
      this.selectedRecord = { ...record };
      this.showDetailsModal = true;
    },
    async loadRequesters() {
    try {
      const response = await axios.get('https://brk-group.org/api/ts/employees');
      // Filter only active employees if needed
      this.requesters = response.data.filter(emp => emp.status === 'active');
    } catch (error) {
      console.error('Error loading requesters:', error);
      // Show error notification if needed
    }
  },
  async openModal(record = null) {
  try {
    console.log("Loading data for departments, groups, drivers, vehicle pairs, and requesters...");
    await Promise.all([
      this.fetchDepartments(),
      this.fetchGroups(),
      this.fetchDrivers(),
      this.fetchVehiclePairs(),
      this.loadRequesters()
    ]);

    this.isModalOpen = true;

    if (record) {
      this.editingRecord = record;

      // Handle attachment if exists
      if (record.attachment) {
        this.form.attachment = record.attachment;
        this.form.attachmentUrl = record.attachmentUrl;
      }

    // Format time to HH:mm
    if (record.dispensing_time) {  // แก้จาก record.time เป็น record.dispensing_time
        const timeParts = record.dispensing_time.split(':');
        // Always take only the first two parts (HH:mm) regardless of input format
        this.form.dispensing_time = timeParts.slice(0, 2).join(':');
      }

      // Handle record_date formatting
      if (record.record_date) {
        // Ensure date is in YYYY-MM-DD format
        const date = new Date(record.record_date);
        if (!isNaN(date.getTime())) {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          this.form.record_date = `${year}-${month}-${day}`;
        } else {
          // If date parsing fails, use the original value
          this.form.record_date = record.record_date;
        }
      } else {
        this.form.record_date = '';
      }

      // Handle driver and vehicle pair selections
      const driverIds = record.driver_id ? [record.driver_id.toString()] : [];
      const vehiclePairIds = record.vehicle_pair_id ? [record.vehicle_pair_id.toString()] : [];

      this.form.selectedDrivers = this.drivers.filter(d => driverIds.includes(d.id.toString()));
      this.form.selectedVehiclePairs = this.vehiclePairs.filter(v => vehiclePairIds.includes(v.id.toString()));

      // Set form data
      this.form = {
        ...record,
        selectedDrivers: this.form.selectedDrivers,
        selectedVehiclePairs: this.form.selectedVehiclePairs,
        record_date: this.form.record_date,
        dispensing_time: this.form.dispensing_time,
        selectedRequester: this.requesters.find(req => req.id === record.requester_id) || null
      };
      console.log("Form data after setting:", this.form);
    } else {
      this.form = this.getInitialFormState();
      console.log("Form initialized with empty state:", this.form);
    }
  } catch (error) {
    console.error("Error opening modal:", error);
    Swal.fire("ผิดพลาด", "ไม่สามารถโหลดข้อมูลได้", "error");
  }
},




    resetForm() {
      this.form = {
        department_id: "",
        group_id: "",
        selectedDrivers: null,
        selectedVehiclePairs: null,
        fuel_station: "",
        bill_number: "",
        odometer_start: "",
        odometer_end: "",
        meter_before: "",
        meter_after: "",
        fuel_volume: "",
        price_per_liter: "",
        paid_by: "",
        attachment: null,
      }
    },
    async editRecord(record) {
      this.editingRecord = record
      this.form = {
        department_id: record.department_id,
        group_id: record.group_id,
        selectedDrivers: record.driver,
        selectedVehiclePairs: record.vehicle_pair,
        fuel_station: record.fuel_station,
        bill_number: record.bill_number,
        odometer_start: record.odometer_start,
        odometer_end: record.odometer_end,
        meter_before: record.meter_before,
        meter_after: record.meter_after,
        fuel_volume: record.fuel_volume,
        price_per_liter: record.price_per_liter,
        paid_by: record.paid_by,
        attachment: null,
      }
      this.isModalOpen = true
    },


    async fetchDepartments() {
        try {
            const response = await axios.get("https://brk-group.org/api/ts/departments");
            this.departments = response.data; // ไม่ต้องเช็ค success เพราะ API ส่งข้อมูลมาตรงๆ
        } catch (error) {
            console.error("Error fetching departments:", error);
            Swal.fire("ผิดพลาด", "ไม่สามารถดึงข้อมูลหน่วยงานได้", "error");
        }
    },

    async fetchGroups() {
        try {
            const response = await axios.get("https://brk-group.org/api/ts/groups");
            this.groups = response.data; // ไม่ต้องเช็ค success เพราะ API ส่งข้อมูลมาตรงๆ
        } catch (error) {
            console.error("Error fetching groups:", error);
            Swal.fire("ผิดพลาด", "ไม่สามารถดึงข้อมูลกลุ่มงานได้", "error");
        }
    },
    async closeModal() {
      // ถามยืนยันก่อนปิด Modal ถ้ามีการกรอกข้อมูล
      if (this.hasFormData()) {
        const result = await Swal.fire({
          icon: 'warning',
          title: 'คุณแน่ใจหรือไม่?',
          text: 'ข้อมูลที่กรอกจะไม่ถูกบันทึก',
          showCancelButton: true,
          confirmButtonText: 'ใช่, ปิดได้เลย',
          cancelButtonText: 'ยกเลิก',
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6'
        })

        if (!result.isConfirmed) {
          return
        }
      }

      this.isModalOpen = false
      this.editingRecord = null
      this.resetForm()
    },
    hasFormData() {
      return Object.keys(this.form).some(key => {
        if (key === 'attachment') {
          return this.form[key] !== null
        }
        if (key === 'selectedDrivers' || key === 'selectedVehiclePairs') {
          return this.form[key] !== null
        }
        return this.form[key] !== ""
      })
    },
    
    async handleFileUpload(event) {
      const file = event.target.files[0]
      if (file) {
        this.form.attachment = file
        this.form.attachmentUrl = URL.createObjectURL(file);
      }
    },

    formatValue(key, value) {
      if (key === 'created_at' || key === 'updated_at') {
        return this.formatDate(value);
      }
      if (typeof value === 'number') {
        return value.toLocaleString('th-TH');
      }
      return value;
    },

    getThaiLabel(key) {
      const labels = {
        department_name: "หน่วยงาน",
        group_name: "กลุ่มงาน",
        driver_name: "พนักงานขับรถ",
        fuel_station: "สถานที่เติมน้ำมัน",
        bill_number: "เลขที่บิล",
        odometer_start: "เลขไมล์เริ่ม",
        odometer_end: "เลขไมล์จบ",
        meter_before: "มิเตอร์ก่อนเติม",
        meter_after: "มิเตอร์หลังเติม",
        fuel_volume: "จำนวนลิตร",
        price_per_liter: "ราคาต่อลิตร",
        total_cost: "ราคารวม",
        distance: "ระยะทาง",
        fuel_efficiency: "อัตราสิ้นเปลือง",
        paid_by: "ผู้จ่าย",
        record_date: "วันที่บันทึก",
        updated_at: "วันที่แก้ไข",
        attachment: "ไฟล์แนบ", // ตัวอย่างเพิ่มเติม
        created_by: "คนที่สร้าง",
        updated_by: "คนที่แก้ไข",
        vehicle_pair_name: "คู่รถ",
      };
      return labels[key] || key;
    },
  },

  async mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    try {
      this.isLoading = true;
      await Promise.all([
      this.fetchRecords(),
        this.fetchDepartments(),
        this.fetchGroups(),
        this.fetchDrivers(),
        this.fetchVehiclePairs(),
        this.loadRequesters(),
      ]);
    } catch (error) {
      console.error("Error in mounted:", error);
      Swal.fire("ผิดพลาด", "เกิดข้อผิดพลาดในการโหลดข้อมูล", "error");
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    'form.is_cleaning_parts': function(newValue) {
    if (newValue) {
      this.form.selectedVehiclePairs = null
      this.form.fuel_station = ''
      this.form.cleaning_location = ''
      this.form.odometer_start = ''
      this.form.odometer_end = ''
    }
  },
    'form.record_date': {
      handler() {
        // จะทำงานเฉพาะตอนเพิ่มข้อมูลใหม่เท่านั้น
        if (this.shouldWatch && this.form.dispenser_number) {
          this.fetchPreviousMeter();
        }
      }
    },
    
    'form.dispenser_number': {
      handler() {
        if (this.shouldWatch && this.form.record_date) {
          this.fetchPreviousMeter();
        }
      }
    },

    'form.selectedVehiclePairs': {
      handler(newVal) {
        if (this.shouldWatch) {
          this.handleVehiclePairSelect(newVal);
        }
      }
    },
  //   'form.record_date'() {
  //   if (this.form.dispenser_number) {
  //     this.fetchPreviousMeter();
  //   }
  // },
  // 'form.dispenser_number'() {
  //   if (this.form.record_date) {
  //     this.fetchPreviousMeter();
  //   }
  // },
  //   'form.selectedVehiclePairs'(newValue) {
  //     this.handleVehiclePairSelect(newValue);
  //   },
    'filters': {
      deep: true,
      handler() {
        this.fetchRecords();
        this.applyFilters();
      }
    }
  }
};
</script>
<style scoped>
/* ปรับแต่ง style เพิ่มเติม */
/* .multiselect {
  border-radius: 0.375rem;
}

.multiselect__tags {
  border-radius: 0.375rem;
  padding: 0.5rem;
  min-height: 42px;
}

.multiselect__select {
  height: 40px;
}

.multiselect__input, .multiselect__single {
  margin-bottom: 0;
}

.multiselect--active {
  border-radius: 0.375rem;
} */

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.border-blue-500 {
  border-color: #3b82f6; /* เปลี่ยนสีตามที่ต้องการ */
}
</style>