<template>
    <div class="container mx-auto mt-10">
      <div class="bg-blue-600 p-4 text-white">
          <h3 class="text-lg font-semibold mb-0">รายงานสถานะใบสั่งซื้อ</h3>
        </div>
      <!-- แผนภูมิ -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div class="bg-white shadow-md rounded-lg p-6">
          <h4 class="text-lg font-semibold mb-4">สถานะใบสั่งซื้อ</h4>
          <div ref="statusChart" style="height: 400px;"></div>
          <p v-if="!statusChartData">Loading...</p>
        </div>
        <div class="bg-white shadow-md rounded-lg p-6">
          <h4 class="text-lg font-semibold mb-4">ยอดรวมตามผู้จำหน่าย</h4>
          <div ref="supplierChart" style="height: 400px;"></div>
          <p v-if="!supplierChartData">Loading...</p>
        </div>
      </div>
  
      <!-- การ์ดข้อมูล -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div class="bg-blue-500 text-white p-4 rounded-lg shadow">
          <h4 class="text-lg font-semibold">ใบสั่งซื้อทั้งหมด</h4>
          <p class="text-2xl">{{ orders.length }}</p>
        </div>
        <div class="bg-green-500 text-white p-4 rounded-lg shadow">
          <h4 class="text-lg font-semibold">ยอดรวมทั้งหมด</h4>
          <p class="text-2xl">{{ formatAmount(totalAmount) }}</p>
        </div>
        <div class="bg-yellow-500 text-white p-4 rounded-lg shadow">
          <h4 class="text-lg font-semibold">ผู้จำหน่ายทั้งหมด</h4>
          <p class="text-2xl">{{ suppliersCount }}</p>
        </div>
      </div>
  
      <!-- ตารางข้อมูลใบสั่งซื้อ -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
     
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <div>
              <input 
                type="text" 
                v-model="search" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="ค้นหาจากใบสั่งซื้อหรือผู้จำหน่าย" 
              />
            </div>
            <div>
              <input 
                type="date" 
                v-model="dateFrom" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="From date" 
              />
            </div>
            <div>
              <input 
                type="date" 
                v-model="dateTo" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="To date" 
              />
            </div>
            <div class="text-right">
              <button 
                class="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                @click="fetchData"
              >
              <i class="fas fa-search"></i> <!-- ไอคอนค้นหา -->
              </button>
            </div>
          </div>
  
          <div class="overflow-x-auto">
            <table class="min-w-full bg-white border border-gray-300 rounded-lg">
              <thead>
                <tr class="bg-gray-800 text-white">
                  <th class="py-2 px-4 border-b border-gray-200">เลขที่ใบสั่งซื้อ</th>
                  <th class="py-2 px-4 border-b border-gray-200">วันที่เปิดใบสั่งซื้อ</th>
                  <th class="py-2 px-4 border-b border-gray-200">เลขที่ใบขอซื้อ</th>
                  <th class="py-2 px-4 border-b border-gray-200">ผู้จำหน่าย</th>
                  <th class="py-2 px-4 border-b border-gray-200">หน่วยงานที่รับ</th>
                  <th class="py-2 px-4 border-b border-gray-200">ยอดรวม</th>
                  <th class="py-2 px-4 border-b border-gray-200">สถานะ</th>
                  <!-- <th class="py-2 px-4 border-b border-gray-200">ผู้บันทึก</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in paginatedOrders" :key="order.po_id" class="hover:bg-gray-100 odd:bg-gray-50">
                  <td class="py-2 px-4 border-b border-gray-200">{{ order.po_number }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ formatDate(order.po_date) }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ order.purchase_request_number }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ order.supplier_name }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ order.warehouse_name || 'N/A' }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ formatAmount(order.total_amount) }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ order.status }}</td>
                  <!-- <td class="py-2 px-4 border-b border-gray-200">{{ order.recorder_name || 'N/A' }}</td> -->
                </tr>
                <tr v-if="paginatedOrders.length === 0">
                  <td colspan="8" class="py-4 px-4 text-center text-gray-500">No purchase orders found.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex justify-between items-center mt-4">
  <button 
    @click="currentPage--" 
    :disabled="currentPage === 1" 
    class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
  >
    ย้อนกลับ
  </button>
  <span>Page {{ currentPage }} of {{ totalPages }}</span>
  <button 
    @click="currentPage++" 
    :disabled="currentPage === totalPages" 
    class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
  >
    ถัดไป
  </button>
</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as echarts from 'echarts';
  
  export default {
    data() {
      return {
        orders: [],
        search: '',
        dateFrom: '',
        dateTo: '',
        isLoading: false,
        suppliersCount: 0,
        supplierChartData: null,
        statusChartData: null,
        currentPage: 1,
    itemsPerPage: 10,
      };
    },
    computed: {
      sortedOrders() {
        return [...this.orders].sort((a, b) => new Date(b.po_date) - new Date(a.po_date));
      },
      paginatedOrders() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.sortedOrders.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.sortedOrders.length / this.itemsPerPage);
  },
  totalAmount() {
    return this.sortedOrders.reduce((accumulator, order) => {
      return accumulator + (parseFloat(order.total_amount) || 0);
    }, 0);
  },
    },
    methods: {
      // async fetchData() {
      //   const params = {
      //     search: this.search,
      //     dateFrom: this.dateFrom,
      //     dateTo: this.dateTo,
      //   };
      //   try {
      //     const response = await axios.get('https://brk-group.org/api/purchase-orders/search', { params });
      //     this.orders = Array.isArray(response.data) ? response.data : [];
  
      //     this.totalAmount = this.orders.reduce((total, order) => total + order.total_amount, 0);
      //     this.suppliersCount = new Set(this.orders.map(order => order.supplier_name)).size;
      //   } catch (error) {
      //     console.error('Error fetching data:', error);
      //     this.orders = [];
      //   }
      // },
      async fetchData() {
        this.isLoading = true; 
    const params = {
      search: this.search,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };
    console.log('Fetching data with params:', params);
    try {
      const response = await axios.get('https://brk-group.org/api/purchase-orders/search', { params });
      this.orders = Array.isArray(response.data) ? response.data : [];

      this.totalAmount = this.orders.reduce((total, order) => total + order.total_amount, 0);
      this.suppliersCount = new Set(this.orders.map(order => order.supplier_name)).size;

      // อัปเดตกราฟตามข้อมูลที่ค้นหา
       this.fetchSupplierChartData(params);
       this.fetchStatusChartData(params);
    } catch (error) {
      console.error('Error fetching data:', error);
      this.orders = [];
    }
    finally {
    this.isLoading = false; // สิ้นสุดการโหลด
  }
  },
      // async fetchSupplierChartData() {
      //   try {
      //     const response = await axios.get('https://brk-group.org/api/purchase-orders/total-by-supplier');
      //     const supplierData = response.data;
  
      //     this.supplierChartData = {
      //       labels: supplierData.map(data => data.supplier_name),
      //       datasets: [
      //         {
      //           label: 'Total Amount by Supplier',
      //           data: supplierData.map(data => data.total_amount),
      //         }
      //       ]
      //     };
      //     this.renderSupplierChart();
      //   } catch (error) {
      //     console.error('Error fetching supplier chart data:', error);
      //   }
      // },
      async fetchSupplierChartData(params = {}) {
    try {
      const response = await axios.get('https://brk-group.org/api/purchase-orders/total-by-supplier', { params });
      const supplierData = response.data;

      this.supplierChartData = {
        labels: supplierData.map(data => data.supplier_name),
        datasets: [
          {
            label: 'Total Amount by Supplier',
            data: supplierData.map(data => data.total_amount),
          }
        ]
      };
      this.renderSupplierChart(); // อัปเดตกราฟเมื่อมีข้อมูลใหม่
    } catch (error) {
      console.error('Error fetching supplier chart data:', error);
    }
  },
      // async fetchStatusChartData() {
      //   try {
      //     const response = await axios.get('https://brk-group.org/api/purchase-orders/status-distribution');
      //     if (response.data && response.data.length) {
      //       this.statusChartData = {
      //         labels: response.data.map(item => item.status),
      //         datasets: [
      //           {
      //             label: 'จำนวนสถานะ',
      //             data: response.data.map(item => item.count),
      //           },
      //         ],
      //       };
      //       this.renderStatusChart();
      //     } else {
      //       console.error('No data found for status chart');
      //     }
      //   } catch (error) {
      //     console.error('Error fetching status chart data:', error);
      //   }
      // },
      async fetchStatusChartData(params = {}) {
    try {
      const response = await axios.get('https://brk-group.org/api/purchase-orders/status-distribution', { params });
      if (response.data && response.data.length) {
        this.statusChartData = {
          labels: response.data.map(item => item.status),
          datasets: [
            {
              label: 'จำนวนสถานะ',
              data: response.data.map(item => item.count),
            },
          ],
        };
        this.renderStatusChart(); // อัปเดตกราฟเมื่อมีข้อมูลใหม่
      } else {
        console.error('No data found for status chart');
      }
    } catch (error) {
      console.error('Error fetching status chart data:', error);
    }
  },
  //     renderSupplierChart() {
  // const chartDom = this.$refs.supplierChart;
  // const myChart = echarts.init(chartDom);
  // const option = {
  //   title: {
  //     text: 'Total Amount by Supplier',
  //     left: 'center'
  //   },
  //   tooltip: {
  //     trigger: 'axis',
  //     axisPointer: {
  //       type: 'shadow'
  //     },
  //     formatter: '{b0}: {c0}'
  //   },
  //   xAxis: {
  //     type: 'category',
  //     data: this.supplierChartData.labels
  //   },
  //   yAxis: {
  //     type: 'value'
  //   },
  //   series: [
  //     {
  //       data: this.supplierChartData.datasets[0].data,
  //       type: 'bar'
  //     }
  //   ]
  // };
  // myChart.setOption(option);
  //     },

 renderSupplierChart() {
  console.log('Rendering Supplier Chart with data:', this.supplierChartData); // ตรวจสอบข้อมูลที่ใช้ในการเรนเดอร์
  const chartDom = this.$refs.supplierChart;
  const myChart = echarts.init(chartDom);
  myChart.clear(); // ทำลายกราฟก่อนที่จะสร้างใหม่
  // Define colors for each bar
  const colors = [
    '#5470c6', '#91cc75', '#fac858', '#ee6666',
    '#73c0de', '#3ba272', '#fc8452', '#9a60b4'
  ];
  
  const option = {
    title: {
      text: 'Total Amount by Supplier',
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: '{b0}: {c0}'
    },
    legend: {
      data: this.supplierChartData.labels,
      top: 'bottom',
      type: 'scroll',
      orient: 'horizontal',
      padding: [0, 20]
    },
    xAxis: {
      type: 'category',
      data: this.supplierChartData.labels,
      axisLabel: {
        interval: 0,
        rotate: 30
      }
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: this.supplierChartData.datasets[0].data.map((value, index) => ({
          value: value,
          itemStyle: {
            color: colors[index % colors.length]
          }
        })),
        type: 'bar',
        name: 'Suppliers'
      }
    ]
  };
  
  myChart.setOption(option);
  },
  //     renderStatusChart() {
  // const chartDom = this.$refs.statusChart;
  // const myChart = echarts.init(chartDom);
  // const option = {
  //   title: {
  //     text: 'Order Status Distribution',
  //     left: 'center'
  //   },
  //   tooltip: {
  //     trigger: 'item',
  //     formatter: '{a} <br/>{b}: {c} ({d}%)'
  //   },
  //   series: [
  //     {
  //       name: 'สถานะ',
  //       type: 'pie',
  //       radius: '50%',
  //       data: this.statusChartData.labels.map((label, index) => ({
  //         value: this.statusChartData.datasets[0].data[index],
  //         name: label,
  //       })),
  //       emphasis: {
  //         itemStyle: {
  //           shadowBlur: 10,
  //           shadowOffsetX: 0,
  //           shadowColor: 'rgba(0, 0, 0, 0.5)'
  //         }
  //       }
  //     }
  //   ]
  // };
  // myChart.setOption(option);
  //     },

  renderStatusChart() {
    const chartDom = this.$refs.statusChart;
    const myChart = echarts.init(chartDom);
    myChart.clear(); // ทำลายกราฟก่อนที่จะสร้างใหม่
    const option = {
      title: {
        text: 'Order Status Distribution',
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      series: [
        {
          name: 'สถานะ',
          type: 'pie',
          radius: '50%',
          data: this.statusChartData.labels.map((label, index) => ({
            value: this.statusChartData.datasets[0].data[index],
            name: label.length > 10 ? label.slice(0, 10) + '...' : label,
          })),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
          show: true,
          formatter: (params) => {
            const percentage = params.percent.toFixed(2);  // คำนวณเปอร์เซ็นต์
            return `${params.name}: ${params.value} (${percentage}%)`;  // แสดงผลข้อมูลทั้งจำนวนและเปอร์เซ็นต์
          },
          fontSize: 12,  // ขนาดฟอนต์
          color: '#333',
        },
        labelLine: {
          show: true,  // แสดงเส้นเชื่อม
          length: 15,  // ความยาวของเส้นเชื่อม
          lineStyle: {
            width: 1,
            type: 'solid'
          }
        },
        }
      ]
    };
    myChart.setOption(option);
  },
  formatAmount(amount) {
    // ตรวจสอบว่ามีค่าใน amount หรือไม่ และใช้ Intl.NumberFormat เพื่อจัดรูปแบบ
    return amount !== null && amount !== undefined 
      ? new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)
      : '0.00';
  },
      formatDate(date) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      },
    },
    mounted() {
      this.fetchData();
      // this.fetchSupplierChartData();
      // this.fetchStatusChartData();
    },
  };
  </script>
  
  <style scoped>
  .card {
    border-radius: 0.5rem;
  }
  
  .card-header {
    border-bottom: 2px solid #007bff;
  }
  
  .table-responsive {
    margin-top: 20px;
  }
  
  .input-group-text {
    background-color: #f8f9fa;
  }
  
  .btn {
    margin-left: 5px;
  }
  
  .badge {
    font-size: 90%;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
  }
  </style>
  