<template>
  <div class="flex flex-col md:flex-row h-screen">
  <!-- <div class="flex h-screen bg-gray-100"> -->
    <!-- Sidebar -->
    <!-- <div class="w-64 bg-white shadow-md"> -->
      <div :class="{'block md:block': isSidebarOpen, 'hidden md:block': !isSidebarOpen}" 
         class="fixed inset-y-0 left-0 bg-white shadow-md transition-transform duration-300 ease-in-out transform" 
         :style="{transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)'}">
      <nav > 
        <!-- class="mt-10" -->
       <!-- ปุ่มเปิด/ปิด Sidebar เฉพาะหน้าจอขนาดเล็ก -->
   

    <div :class="{'block md:block': isSidebarOpen, 'hidden md:block': !isSidebarOpen}" class="mt-2">
      <button class="block md:hidden bg-blue-500 text-white p-2" @click="toggleSidebar">ปิดเมนู</button>
        <!-- Heading for PR -->
        <div class="px-4 py-2 font-semibold text-gray-700">
          รายการขอซื้อ PR
        </div>
        <!-- <button 
          v-if="departmentId === 1" 
          class="block py-2.5 px-4 rounded hover:bg-gray-200 w-full text-left"
          @click="setActiveSection('pr-agency')"
          :class="{ 'bg-gray-200': activeSection === 'pr-agency' }"
        >ขอดำเนินการสั่งซื้อ (หน่วยงานอื่น)</button> -->
        <button 
  v-if="canShowPRAgencyButton"
  class="block py-2.5 px-4 rounded hover:bg-gray-200 w-full text-left"
  @click="setActiveSection('pr-agency')"
  :class="{ 'bg-gray-200': activeSection === 'pr-agency' }"
>ขอดำเนินการสั่งซื้อ (หน่วยงานอื่น)</button>
        <button 
         
          class="block py-2.5 px-4 rounded hover:bg-gray-200 w-full text-left"
          @click="setActiveSection('pr-own')"
          :class="{ 'bg-gray-200': activeSection === 'pr-own' }"
        >ขอสั่งซื้อภายใน
      
      </button>


        <!-- Heading for PO -->
        <div class="px-4 py-2 font-semibold text-gray-700 mt-4">
          รายการสั่งซื้อ PO
        </div>
        <button 
          class="block py-2.5 px-4 rounded hover:bg-gray-200 w-full text-left"
          @click="setActiveSection('po-list')"
          :class="{ 'bg-gray-200': activeSection === 'po-list' }"
        >รายการใบสั่งซื้อ</button>
        </div>
      </nav>
    </div>
 <!-- Main Content -->
 <div :class="{'md:ml-64': isSidebarOpen, 'md:ml-0': !isSidebarOpen}" class="transition-all duration-300 ease-in-out p-4 w-full" @click.stop>
      <button class="block bg-blue-500 text-white p-2 mb-4" @click="toggleSidebar">
        {{ isSidebarOpen ? 'ปิดเมนู' : 'เปิดเมนู' }}
      </button>
  <div v-show="activeSection === 'pr-agency'">
    <div class="pr-form">
      <div class="header">
        <div class="bg-white shadow mb-4 p-4 flex justify-between">
          <div class="left">
            <span class="font-semibold text-xl text-gray-800">รายการใบขอซื้อ (หน่วยงาน)</span>
          </div>

       

        </div>
        
      <!-- <label for="agencyStatus" class="font-semibold text-lg">สถานะ : </label>
      <select
        id="agencyStatus"
        v-model="statusFilter"
        class="mt-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
      >
        <option 
          v-for="status in AgencystatusOptions"
          :key="status.value" 
          :value="status.value"
        >
          {{ status.text }} ({{ countAgencyStatus(status.value) }})
        </option>
      </select>  -->
   

        <div class="left flex gap-2">
              <button 
                @click="setPrType('repair')"
                :class="['px-4 py-2 rounded-md text-sm font-medium', prType === 'repair' ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-blue-500']"
              >
                ซื้อเพื่อซ่อม
              </button>
              <button 
                @click="setPrType('stock')"
                :class="['px-4 py-2 rounded-md text-sm font-medium', prType === 'stock' ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-blue-500']"
              >
                ซื้อเพื่อลงสต็อก
              </button>
            </div>
            
      </div>

      <div class="flex items-center mb-4">
      <!-- Dropdown สำหรับเลือกหัวข้อที่ต้องการค้นหา -->
      <select v-model="selectedFilter" class="p-2 border border-gray-300 rounded">
        <option disabled value="">เลือกหัวข้อที่ต้องการค้นหา</option>
        <option value="documentNumber">เลขที่เอกสาร</option>
        <option value="LicensePlate">ทะเบียนรถ</option>
        <option value="purchaseDate">วันที่เสนอใบขอซื้อ</option>
        <option value="reciveLocation">สถานที่รับ</option>
        
        <option value="status">สถานะ</option>
      </select>

      <!-- กำหนดกล่องค้นหาตามประเภทที่เลือก -->
      <template v-if="selectedFilter === 'purchaseDate'">
  <div class="flex items-center ml-2">
    <label class="mr-2">ตั้งแต่:</label>
    <input
      type="date"
      v-model="startDate"
      :max="endDate || undefined"
      class="p-2 border border-gray-300 rounded"
    />
    <label class="mx-2">ถึง:</label>
    <input
      type="date"
      v-model="endDate"
      :min="startDate || undefined"
      class="p-2 border border-gray-300 rounded"
    />
  </div>
</template>

      <template v-else-if="selectedFilter === 'reciveLocation'">
  <select 
    v-model="searchQuery" 
    class="p-2 border border-gray-300 rounded ml-2"
    @change="handleLocationChange"
  >
    <option value="">ทั้งหมด</option>
    <option 
      v-for="location in uniqueLocationsExclude" 
      :key="location" 
      :value="location"
    >
      {{ location }}
    </option>
  </select>
</template>

    

      <template v-else>
        <!-- กล่องค้นหาปกติ -->
        <input
          v-model="searchQuery"
          placeholder="พิมพ์ข้อความที่ต้องการค้นหา"
          class="p-2 border border-gray-300 rounded ml-2"
        />
      </template>

      <!-- Dropdown สำหรับสถานะ -->
      <select
        v-model="statusFilter"
        class="mt-2 ml-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
        v-if="selectedFilter === 'status'"
      >
        <option 
          v-for="status in AgencystatusOptions" 
          :key="status.value" 
          :value="status.value"
        >
          {{ status.text }} ({{ countAgencyStatus(status.value) }})
        </option>
      </select>
    </div>


      <!-- Table -->
      <div class="overflow-x-auto">
      <table class="min-w-full "> 
        <!-- table-fixed -->
        <thead class="bg-gray-100">
          <tr>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">รายการเปิดใบขอซื้อ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">เลขที่เอกสาร</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">เบอร์รถ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">ทะเบียนรถ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">วันที่เสนอใบขอซื้อ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">สถานที่รับ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">จำนวนรายการซื้อ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">สถานะ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">การดำเนินการ</th>
      </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(item, index) in paginatedPrListDataExclude" :key="index">
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.documentNumber }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.VehicleNumber }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.LicensePlate }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ formatDate(item.purchaseDate) }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.reciveLocation }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.itemCount }}</td>
        <td :class="statusClass(item.status)" class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.status }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">
          <router-link 
  :to="{ 
    name: 'PRDetail', 
    params: { id: item.id },
    query: { currentPage: currentPage,prType: prType } 
  }" 
  class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
>
  <i class="fas fa-eye "></i>
</router-link>
        </td>
      </tr>
        </tbody>
      </table>
      </div>
      <!-- Pagination -->
<div class="flex justify-center mt-4 mb-4">
  <button 
    @click="goToPageExclude(1)" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    หน้าแรก
  </button>

  <button 
    @click="prevPageExclude" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ย้อนกลับ
  </button>

  <span v-for="page in pageNumbersExclude" :key="page" @click="goToPageExclude(page)" :class="{ 'bg-gray-400 text-white': currentPage === page }"
    class="cursor-pointer px-4 py-2 mx-1 rounded-md"
  >
    {{ page }}
  </span>

  <button 
    @click="nextPageExclude" 
    :disabled="currentPage === totalPagesExclude"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ถัดไป
  </button>

  <button 
    @click="goToPageExclude(totalPagesExclude)" 
    :disabled="currentPage === totalPagesExclude"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    หน้าสุดท้าย
  </button>
          </div>
          <br>
    </div>
  </div>

      <!-- Main Content -->
      <div class="flex-1 p-10">
      <div v-show="activeSection === 'pr-own'">
        <!-- PR Own List Section -->
        <div class="pr-form">
          <!-- Header Area -->
          <div class="header">
            <div class="bg-white shadow mb-4 p-4 flex justify-between">
              <div class="left">
                <span class="font-semibold text-xl text-gray-800">รายการใบขอซื้อ (Purchase Requisition: PR)</span>
              </div>

             
            </div>



      <!-- <label for="computedStatus" class="font-semibold text-lg">   สถานะ : </label>
      <select
        id="computedStatus"
        v-model="statusFilter"
        class="mt-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
      >
        <option 
          v-for="status in computedStatusOptions"
          :key="status.value" 
          :value="status.value"
        >
          {{ status.text }} ({{ countStatus(status.value) }})
        </option>
      </select>
  -->
            <div class="left flex gap-2">
              <button 
                @click="setPrType('repair')"
                :class="['px-4 py-2 rounded-md text-sm font-medium', prType === 'repair' ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-blue-500']"
              >
                ซื้อเพื่อซ่อม
              </button>
              <button 
                @click="setPrType('stock')"
                :class="['px-4 py-2 rounded-md text-sm font-medium', prType === 'stock' ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-blue-500']"
              >
                ซื้อเพื่อลงสต็อก
              </button>
            </div>
          </div>




          <div class="flex items-center mb-4">
      <!-- Dropdown สำหรับเลือกหัวข้อที่ต้องการค้นหา -->
      <select v-model="selectedFilter" class="p-2 border border-gray-300 rounded">
        <option disabled value="">เลือกหัวข้อที่ต้องการค้นหา</option>
        <option value="documentNumber">เลขที่เอกสาร</option>
        <option value="LicensePlate">ทะเบียนรถ</option>
        <option value="purchaseDate">วันที่เสนอใบขอซื้อ</option>
        <option value="reciveLocation">สถานที่รับ</option>
        <option value="status">สถานะ</option>
      </select>

      <!-- กล่องค้นหาสำหรับแต่ละประเภท -->
      <template v-if="selectedFilter === 'purchaseDate'">
        <!-- กล่องเลือกช่วงวันที่ -->
        <div class="flex items-center">
          <label class="mr-2">ตั้งแต่:</label>
          <input
            type="date"
            v-model="startDate"
            class="p-2 border border-gray-300 rounded ml-2"
          />
          <label class="mx-2">ถึง:</label>
          <input
            type="date"
            v-model="endDate"
            class="p-2 border border-gray-300 rounded ml-2"
          />
        </div>
      </template>

      <template v-else-if="selectedFilter === 'reciveLocation'">
        <!-- Dropdown สำหรับสถานที่รับ -->
        <select v-model="searchQuery" class="p-2 border border-gray-300 rounded ml-2">
          <option value="">ทั้งหมด</option>
          <option v-for="location in uniqueLocations" :key="location" :value="location">
            {{ location }}
          </option>
        </select>
      </template>

      <template v-else>
        <!-- กล่องค้นหาปกติ -->
        <input
          v-model="searchQuery"
          placeholder="พิมพ์ข้อความที่ต้องการค้นหา"
          class="p-2 border border-gray-300 rounded ml-2"
        />
      </template>

      <!-- Dropdown สำหรับสถานะ -->
      <select
        v-model="statusFilter"
        class="mt-2 ml-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
        v-if="selectedFilter === 'status'"
      >
        <option 
          v-for="status in computedStatusOptions" 
          :key="status.value" 
          :value="status.value"
        >
          {{ status.text }} ({{ countStatus(status.value) }})
        </option>
      </select>
    </div>

          <!-- Table -->
          <table class="min-w-full table-fixed">
            <thead class="bg-gray-100">
              <tr>
                <th class="p-3 text-center">รายการเปิดใบขอซื้อ</th>
                <th class="p-3 text-center">เลขที่เอกสาร</th>
                <th class="p-3 text-center">เบอร์รถ</th>
                <th class="p-3 text-center">ทะเบียนรถ</th>
                <th class="p-3 text-center">วันที่เสนอใบขอซื้อ</th>
                <th class="p-3 text-center">สถานที่รับ</th>
                <th class="p-3 text-center">จำนวนรายการซื้อ</th>
                <th class="p-3 text-center">สถานะ</th>
                <th class="p-3 text-center">การดำเนินการ</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="border-b" v-for="(item, index) in paginatedData" :key="index">
                <td class="p-3">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                <td class="p-3">{{ item.documentNumber }}</td>
                <td class="p-3">{{ item.VehicleNumber }}</td>
                <td class="p-3">{{ item.LicensePlate }}</td>
                <td class="p-3">{{ formatDate(item.purchaseDate) }}</td>
                <td class="p-3">{{ item.reciveLocation }}</td>
                <td class="p-3">{{ item.itemCount }}</td>
                <td :class="statusClass(item.status)" class="p-3">{{ item.status }}</td>
                <td class="p-3">
                  <router-link :to="{ name: 'PRDetail', params: { id: item.id }, query: { currentPage: currentPage,prType: prType }  }" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                     <i class="fas fa-eye "></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Pagination -->
<div class="flex justify-center mt-4">
  <button 
    @click="goToPage(1)" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    หน้าแรก
  </button>

  <button 
    @click="prevPage" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ย้อนกลับ
  </button>

  <span v-for="page in pageNumbers" :key="page" @click="goToPage(page)" :class="{ 'bg-gray-400 text-white': currentPage === page }"
    class="cursor-pointer px-4 py-2 mx-1 rounded-md"
  >
    {{ page }}
  </span>

  <button 
    @click="nextPage" 
    :disabled="currentPage === totalPages"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ถัดไป
  </button>

  <button 
    @click="goToPage(totalPages)" 
    :disabled="currentPage === totalPages"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    หน้าสุดท้าย
  </button>
            </div>
            <br>
        </div>
        </div>


     <!---  PO List Section -->

      <div v-show="activeSection === 'po-list'">
        <!-- Content for "รายการใบสั่งซื้อ" -->
        <div class="pr-form">
          <!-- Header Area -->
          <div class="header">
            <div class="bg-white shadow mb-4 p-4 flex justify-between">
              <div class="left">
                <span class="font-semibold text-xl text-gray-800">รายการใบสั่งซื้อ</span>
              </div>
              
            </div>

            
      <label for="poStatus" class="font-semibold text-lg">สถานะการสั่งซื้อ : </label>
      <select
        id="poStatus"
        v-model="statusFilter"
        class="mt-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
      >
        <option 
          v-for="status in poStatusOptions"
          :key="status.value" 
          :value="status.value"
        >
          {{ status.text }} ({{ countPoStatus(status.value) }})
        </option>
      </select>
  
          </div>
          <div class="flex items-center mb-4">
           <!-- Dropdown สำหรับเลือกประเภทตัวกรอง -->
  <select v-model="selectedFilter" class="p-2 border border-gray-300 rounded">
    <option disabled value="">เลือกหัวข้อที่ต้องการค้นหา</option>
    <option value="documentNumber">เลขที่เอกสาร</option>
    <option value="LicensePlate">ทะเบียนรถ</option>
    <option value="purchaseDate">วันที่สั่งซื้อ</option>
    <option value="department">หน่วยงาน</option>
    <option value="reciveLocation">สถานที่รับ</option>
    <option value="status">สถานะ</option>
  </select>

  <!-- แสดงกล่องค้นหาตามประเภทที่เลือก -->
  <template v-if="selectedFilter === 'purchaseDate'">
    <!-- กรองตามช่วงวันที่ -->
    <div class="flex items-center ml-2">
      <label class="mr-2">ตั้งแต่:</label>
      <input type="date" v-model="startDate" class="p-2 border border-gray-300 rounded" />
      <label class="mx-2">ถึง:</label>
      <input type="date" v-model="endDate" class="p-2 border border-gray-300 rounded" />
    </div>
  </template>

  <!-- <template v-else-if="selectedFilter === 'reciveLocation'">

  <select v-model="searchQuery" class="p-2 border border-gray-300 rounded ml-2">
    <option value="">ทั้งหมด</option>
    <option v-for="location in uniqueLocationsExclude" :key="location" :value="location">
      {{ location }}
    </option>
  </select>
</template> -->

  <template v-else-if="selectedFilter === 'department'">
    <!-- กรองตามหน่วยงาน -->
    <select v-model="searchQuery" class="p-2 border border-gray-300 rounded ml-2">
      <!-- <option value="">ทั้งหมด</option> -->
      <option v-for="dept in uniqueDepartments" :key="dept" :value="dept">
        {{ dept }}
      </option>
    </select>
  </template>

  <template v-else>
    <!-- กรองตามค่าอื่น ๆ -->
    <input v-model="searchQuery" placeholder="พิมพ์ข้อความที่ต้องการค้นหา" class="p-2 border border-gray-300 rounded ml-2" />
  </template>

  <!-- Dropdown สำหรับสถานะ -->
  <select v-model="statusFilter" class="mt-2 ml-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150" v-if="selectedFilter === 'status'">
    <option value="ทั้งหมด">ทั้งหมด</option>
    <option v-for="status in poStatusOptions" :key="status.value" :value="status.value">
      {{ status.text }} ({{ countPoStatus(status.value) }})
    </option>
  </select>
</div>


          <!-- Table -->
          <table class="min-w-full table-fixed">
            <thead class="bg-gray-100">
              <tr>
                <th class="p-3 text-center">รายการสั่งซื้อ</th>
                <th class="p-3 text-center">เลขที่เอกสาร</th>
                <th class="p-3 text-center">เบอร์รถ</th>
                <th class="p-3 text-center">ทะเบียนรถ</th>
                <th class="p-3 text-center">วันที่สั่งซื้อ</th>
                <th class="p-3 text-center">หน่วยงาน</th>
                <th class="p-3 text-center">จำนวนรายการ</th>
                <th class="p-3 text-center">สถานะ</th>
                <th class="p-3 text-center">การดำเนินการ</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr 
  class="border-b" 
  v-for="(item, index) in paginatedPOListData" 
  :key="index"
  :class="{ 'bg-yellow-100': !viewedItems.includes(item.id) }"
>
<td class="p-3">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                <td class="p-3">{{ item.documentNumber }}</td>
                <td class="p-3">{{ item.VehicleNumber }}</td>
                <td class="p-3">{{ item.LicensePlate }}</td>
                <td class="p-3">{{ formatDate(item.purchaseDate) }}</td>
                <td class="p-3">{{ item.department }}</td>
                <td class="p-3">{{ item.itemCount }}</td>
                <td :class="statusClass(item.status)" class="p-3">{{ item.status }}</td>
                <td class="p-3">
                  <router-link 
  :to="{ name: 'PODetail', params: { poId: item.id }, query: { fromPRForm: true },
    query: { currentPage: currentPage }  }" 
  class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
  @click="markAsViewed(item.id)"
>
<i class="fas fa-eye "></i>
</router-link>
                </td>
              </tr>
            </tbody>
          </table>

    <!-- Pagination -->
<div class="flex justify-center mt-4">
  <button 
    @click="prevPOPage" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ย้อนกลับ
  </button>

  <!-- Display page numbers -->
  <button
    v-for="page in filteredPageNumbers"
    :key="page"
    @click="goToPage(page)"
    :class="['px-4 py-2 mx-1', { 'bg-blue-500 text-white': page === currentPage, 'bg-gray-200 text-gray-600': page !== currentPage }]">
    {{ page }}
  </button>

  <button 
    @click="nextPOPage" 
    :disabled="currentPage === totalPOPages"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ถัดไป
  </button>
</div>
<br>
        </div>
      </div>
 
    </div>
  </div>
  </div>

</template>

<script>
import axios from 'axios';
// import socket from '../services/socket'; // ตรวจสอบให้แน่ใจว่า path ถูกต้อง
export default {
  props: {
    positionLo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchQuery: "", // ข้อความค้นหา
      MANAGER_POSITIONS: {
        SUPHAN: 'ผู้จัดการสุพรรณบุรี',
        PATHUM: 'ผู้จัดการปทุมธานี'
      },
      GROUP_IDS: {
        HQ: 1,
        OPERATIONS: 3,
        MANAGER: 4,
        SUPHAN: 5,
        SPECIAL: 6
      },
      // selectedFilter: "status", // หัวข้อที่เลือกสำหรับการกรอง
      selectedFilter: 'status',
      activeSection: 'pr-own', // Default section
      startDate: '',
      endDate: '',
      isSearchInputVisible: false, // แสดงหรือซ่อนกล่องค้นหา
      prType: 'repair', // Default type
      testData: [],
      isSidebarOpen: true,  // ตั้งค่าเริ่มต้นให้ Sidebar ปิด
      viewedItems: [], // เก็บข้อมูลรายการที่ถูกคลิกแล้ว
      filteredData: [],
      user: null,
      position: '',
      searchTerm: '',
      statusFilter: 'ทั้งหมด',
      departmentId: null,
      departmentPim: null,
      agency: null, // เพิ่มตัวแปรเพื่อเก็บ agency
      agencyName: '', 
      userAgency: 1, // ตัวแปร userAgency ที่จะถูกใช้ในการตรวจสอบเงื่อนไข
      statusOptions: [
        { value: 'ทั้งหมด', text: 'ทั้งหมด' },
        { value: 'รอตรวจสอบ', text: 'รอตรวจสอบ' },
        { value: 'กำลังดำเนินการ', text: 'กำลังดำเนินการ' },
        { value: 'เสร็จสิ้น', text: 'เสร็จสิ้น' },
        { value: 'ยกเลิก', text: 'ยกเลิก' },
      ],
      AgencystatusOptions: [
        { value: 'ทั้งหมด', text: 'ทั้งหมด' },
        { value: 'รอตรวจสอบ', text: 'รอตรวจสอบ' },
        { value: 'รอผจก.หน่วยงานตรวจสอบ', text: 'รอผจก.หน่วยงานตรวจสอบ' },
        { value: 'รอส่วนกลางตรวจสอบ', text: 'รอส่วนกลางตรวจสอบ' },
        { value: 'รอเจ้าหน้าที่ธุรการจัดซื้อ', text: 'รอเจ้าหน้าที่ธุรการจัดซื้อ' },
        { value: 'อยู่ระหว่างการสั่งซื้อ', text: 'อยู่ระหว่างการสั่งซื้อ' },
        { value: 'ยกเลิกใบ PR', text: 'ยกเลิกใบ PR' },
        { value: 'ปิดใบสั่งซื้อ', text: 'ปิดใบสั่งซื้อ' },
        { value: 'สั่งซื้อครบแล้ว', text: 'สั่งซื้อครบแล้ว' },
      ],
      poDetails: [],
      poStatusOptions: [
        { value: 'ทั้งหมด', text: 'ทั้งหมด' },
        { value: 'รอการอนุมัติ', text: 'รอการอนุมัติ' },
        { value: 'รอการสั่งซื้อ', text: 'รอการสั่งซื้อ' },
        { value: 'รอรับอะไหล่', text: 'รอรับอะไหล่' },
        { value: 'รับอะไหล่แล้ว', text: 'รับอะไหล่แล้ว' },
        { value: 'ไม่อนุมัติ', text: 'ไม่อนุมัติ' },
        { value: 'ยกเลิกใบสั่งซื้อ', text: 'ยกเลิกใบสั่งซื้อ' },
      ],
      prListData: [], // Data for "รายการใบขอซื้อแจ้งซ่อม"
      poListData: [], // Data for "รายการใบสั่งซื้อ"
      prListDataExclude: [], // Data for PR List Exclude
      currentPage: Number(this.$route.query.currentPage) || 1,
      itemsPerPage: 10,

    };
  },
  async created() {
    await this.fetchUser();
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      const response = await axios.get('https://brk-group.org/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.user = response.data; // ตรวจสอบให้แน่ใจว่า login_id ถูกดึงมาใน user object
      this.agency = this.user.agency; // ตั้งค่า agency จากข้อมูลผู้ใช้
      this.departmentId = this.user.group_id; // ตั้งค่า group_id จากข้อมูลผู้ใช้
      this.departmentPim = this.user.login_id;
      console.log('Fetched user agency:', this.user.agency);
      console.log('Fetched user group_id:', this.user.group_id);
      console.log('Fetched user login_id:', this.user.login_id);
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        this.$router.push({ name: 'Login' });
      } else {
        alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
      }
    }
    const queryPage = this.$route.query.currentPage;
  const queryActiveSection = this.$route.query.activeSection;
  const queryPrType = this.$route.query.prType;

  if (queryPage) {
    this.currentPage = Number(queryPage);
  }

  // จัดการ activeSection
  if (queryActiveSection) {
    this.activeSection = queryActiveSection;
  }

  // จัดการ prType
  if (queryPrType) {
    this.prType = queryPrType;
  }
    this.fetchPrListData(); // Fetch initial data for the default section
    this.fetchPoListData();
    this.fetchPrListDataExclude();
    this.loadViewedItems();

    //   // เพิ่มการเชื่อมต่อ WebSocket
    //   this.$socket.on('message', (message) => {
    //   if (message.type === 'pr_status_updated') {
    //     console.log('PR status updated:', message.data);
    //     this.fetchPrListData(); // อัปเดตข้อมูลเมื่อมีการเปลี่ยนแปลงสถานะ PR
    //   }
    // });
  },
  computed: {

    canShowPRAgencyButton() {
    return this.departmentId === 1 || (this.agency === 3 && this.position === 'ผู้จัดการลานกระบือ');
  },
    pageNumbersExclude() {
    let startPage = 1;
    let endPage = this.totalPagesExclude;

    if (this.totalPagesExclude > 5) {
      if (this.currentPage <= 3) {
        endPage = 5;
      } else if (this.currentPage + 2 >= this.totalPagesExclude) {
        startPage = this.totalPagesExclude - 4;
      } else {
        startPage = this.currentPage - 2;
        endPage = this.currentPage + 2;
      }
    }

    let pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  },
    paginatedPrListDataExclude() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      // return this.filteredPrListDataExclude.slice(start, end);
      const paginatedData = this.filteredPrListDataExclude.slice(start, end);
  console.log('Paginated Data:', paginatedData);
  return paginatedData;
    },
    paginatedPOListData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPoItems.slice(start, end);
      
    },
    totalPagesExclude() {
      return Math.ceil(this.filteredPrListDataExclude.length / this.itemsPerPage);
    },
    uniqueLocations() {
      // สร้างตัวเลือกสถานที่รับไม่ให้ซ้ำกัน
      return [...new Set(this.prListData.map(item => item.reciveLocation))];
    },
    computedStatusOptions() {
      return this.departmentId === 1 ? this.statusOptions : this.AgencystatusOptions;
    },
    uniqueLocationsExclude() {
      return [...new Set(
        this.prListDataExclude
          .filter(item => item.reciveLocation)
          .map(item => item.reciveLocation)
          .sort()
      )];
    },
    uniqueDepartmentsExclude() {
    // สร้างรายการหน่วยงานที่ไม่ซ้ำจาก prListDataExclude
    return [...new Set(this.prListDataExclude.map(item => item.department))];
  },
//     filteredPrListDataExclude() {
//   const filtered = this.prListDataExclude.filter(item => {
//     const typeMatches = item.purchaseType === this.prType;
//     const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;

  

//     return typeMatches && statusMatches ;
//   });
//   return filtered;
// },
// filteredPrListDataExclude() {
//     return this.prListDataExclude.filter(item => {
//       const typeMatches = item.purchaseType === this.prType;
//       const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;

//       // ตรวจสอบเงื่อนไขการกรองตามหัวข้อที่เลือก
//       let matches = true;
//       if (this.selectedFilter === 'purchaseDate') {
//         // กรองตามช่วงวันที่
//         matches = (!this.startDate || new Date(item.purchaseDate) >= new Date(this.startDate)) &&
//                   (!this.endDate || new Date(item.purchaseDate) <= new Date(this.endDate));
//       } else if (this.selectedFilter === 'reciveLocation') {
//         // กรองตามสถานที่รับ
//         matches = this.searchQuery ? item.reciveLocation === this.searchQuery : true;
//       } else if (this.selectedFilter) {
//         // กรองตามค่าอื่นๆ
//         matches = item[this.selectedFilter]?.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
//       }

//       return typeMatches && statusMatches && matches;
//     });
//   },

// filteredPrListDataExclude() {
//   console.log('Starting to filter data...');
//   const filteredData = this.prListDataExclude.filter(item => this.checkFilterConditions(item));
//   console.log('Filtered PR List Data:', filteredData);
//   return filteredData;
// },

filteredPrListDataExclude() {
      return this.prListDataExclude.filter(item => {
        // ถ้าไม่ได้เลือกฟิลเตอร์ใดๆ แสดงทั้งหมด
        if (!this.selectedFilter) {
          return true;
        }

        // กรณีเลือกค้นหาตามวันที่
        if (this.selectedFilter === 'purchaseDate') {
          if (!this.startDate && !this.endDate) {
            return true;
          }

          // แปลงวันที่จากข้อมูลในตาราง (DD/MM/YYYY) เป็น timestamp
          const [itemDay, itemMonth, itemYear] = this.formatDate(item.purchaseDate).split('/');
          const itemDate = new Date(itemYear, itemMonth - 1, itemDay).getTime();

          // แปลงวันที่จาก input (YYYY-MM-DD) เป็น timestamp
          const startTimestamp = this.startDate ? new Date(this.startDate).getTime() : null;
          const endTimestamp = this.endDate ? new Date(this.endDate).getTime() : null;

          // เปรียบเทียบ timestamp
          if (startTimestamp && endTimestamp) {
            return itemDate >= startTimestamp && itemDate <= endTimestamp;
          } else if (startTimestamp) {
            return itemDate >= startTimestamp;
          } else if (endTimestamp) {
            return itemDate <= endTimestamp;
          }
        }

        // กรณีค้นหาด้วยฟิลเตอร์อื่นๆ
        if (this.selectedFilter === 'status') {
          return this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;
        }

        if (this.selectedFilter === 'reciveLocation') {
          return !this.searchQuery || item.reciveLocation === this.searchQuery;
        }

        const value = item[this.selectedFilter];
        return value?.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    
  },
// filteredPrListDataExclude() {
//       return this.prListDataExclude.filter(item => {
//         // First check type and department conditions
//         if (!this.checkBaseConditions(item)) {
//           return false;
//         }

//         // Then check specific filter conditions
//         return this.checkSpecificFilters(item);
//       });
//     },

isManager() {
      return this.user.group_id === this.GROUP_IDS.MANAGER;
    },
managerType() {
      if (!this.isManager) return null;
      
      if (this.user.position_lo === this.MANAGER_POSITIONS.SUPHAN) {
        return 'suphan';
      } else if (this.user.position_lo === this.MANAGER_POSITIONS.PATHUM) {
        return 'pathum';
      }
      return 'other';
    },
//   filteredPrListDataExclude() {
//   return this.prListDataExclude.filter(item => {
//     // กรองตาม group_id ก่อน
//     let hasPermission = false;
//     switch (this.user.group_id) {
//       case 1: // HQ
//         hasPermission = true;
//         break;
//       case 4: // สามารถดู group_id 4 และ 5
//         hasPermission = [4, 5].includes(item.group_id);
//         break;
//       case 3: // สามารถดู group_id 3, 9, 10
//         hasPermission = [3, 9, 10].includes(item.group_id);
//         break;
//       case 6: // สามารถดูเฉพาะ group_id 6
//         hasPermission = item.group_id === 6;
//         break;
//       default: // กรณีอื่นๆ ดูได้เฉพาะของตัวเอง
//         hasPermission = item.created_by === this.user.login_id;
//         break;
//     }

//     // ถ้าไม่มีสิทธิ์ดูรายการนี้ ให้คืนค่า false ทันที
//     if (!hasPermission) return false;

//     // กรองตามประเภทและสถานะ (คงเดิม)
//     const typeMatches = item.purchaseType === this.prType;
//     const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;

//     // ตรวจสอบเงื่อนไขการกรองตามหัวข้อที่เลือก (คงเดิม)
//     let matches = true;
//     if (this.selectedFilter === 'purchaseDate') {
//       const purchaseDate = new Date(item.purchaseDate);
//       matches = (!this.startDate || purchaseDate >= new Date(this.startDate)) &&
//                 (!this.endDate || purchaseDate <= new Date(this.endDate));
//     } else if (this.selectedFilter === 'reciveLocation') {
//       matches = this.searchQuery ? item.reciveLocation === this.searchQuery : true;
//     } else if (this.selectedFilter && this.searchQuery) {
//       const fieldValue = item[this.selectedFilter];
//       matches = fieldValue?.toString().toLowerCase().includes(this.searchQuery.toLowerCase()) ?? false;
//     }

//     // ต้องผ่านทุกเงื่อนไขการกรอง
//     return typeMatches && statusMatches && matches;
//   });
// },
    // filteredPrListData() {
    //   const filtered = this.prListData.filter(item => {
    //     const typeMatches = item.purchaseType === this.prType;
    //     const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;

        
    //     return typeMatches && statusMatches;
    //   });
    //   // console.log('Filtered PR data:', filtered);
    //   return filtered;
    // },
    filteredPrListData() {
      if (!this.user) {
    return []; // คืนค่าข้อมูลว่างเปล่าหากยังไม่มีข้อมูลผู้ใช้
  }

  // ดึง group_id ที่อนุญาต
  const allowedGroupIds = this.determineAllowedGroupIds();

  return this.prListData.filter(item => {
    // เงื่อนไขการตรวจสอบ group_id
    const groupMatches = !allowedGroupIds.length || allowedGroupIds.includes(item.departmentId);

    // เงื่อนไขประเภทการซื้อ
    const typeMatches = item.purchaseType === this.prType;

    // เงื่อนไขสถานะ
    const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;

    // เงื่อนไขสำหรับการกรองข้อมูลตามช่วงวันที่
    const dateMatches = this.selectedFilter !== 'purchaseDate' || (
      (!this.startDate || new Date(item.purchaseDate) >= new Date(this.startDate)) &&
      (!this.endDate || new Date(item.purchaseDate) <= new Date(this.endDate))
    );

    // เงื่อนไขการค้นหาข้อความตามหัวข้อที่เลือก
    const matches =
      this.selectedFilter === 'purchaseDate'
        ? dateMatches
        : item[this.selectedFilter]?.toString().toLowerCase().includes(this.searchQuery.toLowerCase());

    // รวมเงื่อนไขทั้งหมด
    return groupMatches && typeMatches && statusMatches && matches;
  });
},

    // filteredPrListData() {
    //    // กรองข้อมูลตาม searchQuery, selectedFilter, และ statusFilter
    //    return this.prListData.filter(item => {
    //     const typeMatches = item.purchaseType === this.prType;
    //     const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;

    //     // เงื่อนไขสำหรับการกรองข้อมูลตามช่วงวันที่
    //     const dateMatches = this.selectedFilter !== 'purchaseDate' || (
    //       (!this.startDate || new Date(item.purchaseDate) >= new Date(this.startDate)) &&
    //       (!this.endDate || new Date(item.purchaseDate) <= new Date(this.endDate))
    //     );

    //     // เงื่อนไขการค้นหาข้อความตามหัวข้อที่เลือก
    //     const matches =
    //       this.selectedFilter === 'purchaseDate'
    //         ? dateMatches
    //         : item[this.selectedFilter]?.toString().toLowerCase().includes(this.searchQuery.toLowerCase());

    //     return typeMatches && statusMatches && matches;
    //   });
    // },

    // จัดการข้อมูลให้เป็นแบบแบ่งหน้า
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPrListData.slice(start, end);
    },
  //   filteredPoItems() {
  //   return this.poListData.filter(po => {
  //     const matchesStatus = this.statusFilter === 'ทั้งหมด' || po.status === this.statusFilter;
  //     const matchesAgency = this.userAgency === 1 && this.group_id === 1
  //       ? po.department === this.agencyName // กรองตาม agencyName เมื่อ userAgency และ group_id ตรงกัน
  //       : true;

  //     let matches = true;

  //     // ตรวจสอบตัวกรองที่เลือกและใช้เงื่อนไขตาม `selectedFilter`
  //     if (this.selectedFilter === 'purchaseDate') {
  //       const purchaseDate = new Date(po.purchaseDate);
  //       matches = (!this.startDate || purchaseDate >= new Date(this.startDate)) &&
  //                 (!this.endDate || purchaseDate <= new Date(this.endDate));
  //     } else if (this.selectedFilter === 'department') {
  //       matches = this.searchQuery ? po.department === this.searchQuery : true;
  //     } else if (this.selectedFilter === 'documentNumber') {
  //       matches = po.documentNumber.toLowerCase().includes(this.searchQuery.toLowerCase());
  //     } else if (this.selectedFilter === 'LicensePlate') {
  //       // ตรวจสอบว่า LicensePlate มีค่าและไม่เป็น null หรือ undefined ก่อนใช้ toLowerCase
  //       matches = po.LicensePlate ? po.LicensePlate.toLowerCase().includes(this.searchQuery.toLowerCase()) : false;
  //     }

  //     return matchesStatus && matchesAgency && matches;
  //   });
  // },
  filteredPoItems() {
  return this.poListData.filter(po => {
    // เงื่อนไขการกรองตามสถานะ
    const matchesStatus = this.statusFilter === 'ทั้งหมด' || po.status === this.statusFilter;

    // ตรวจสอบสิทธิ์การเข้าถึงตาม group_id
    let hasPermission = false;

    if (this.group_id === 1) {
      // HQ สามารถดูได้ทั้งหมด
      hasPermission = true;
    } else if (this.group_id === 3) {
      if (this.user.login_id === 63 || this.user.position_lo === 'ผู้จัดการลานกระบือ') {
        // กรณี login_id = 63 หรือ position_lo = ผู้จัดการลานกระบือ
        hasPermission = [3, 5].includes(po.group_id);
      } else {
        // กรณีอื่นๆ ของ group_id 3
        hasPermission = po.group_id === 3;
      }
    } else if (this.group_id === 4) {
      // group_id 4 ดูเฉพาะ group_id 4
      hasPermission = po.group_id === 4;
    } else if (this.group_id === 6) {
      // group_id 6 ดูเฉพาะ group_id 6
      hasPermission = po.group_id === 6;
    } else {
      // กรณีอื่นๆ ดูเฉพาะที่สร้างโดยผู้ใช้
      hasPermission = po.created_by === this.currentUserId;
    }

    // ตรวจสอบตัวกรองที่เลือก
    let matches = true;
    if (this.selectedFilter === 'purchaseDate') {
      const purchaseDate = new Date(po.purchaseDate);
      matches = (!this.startDate || purchaseDate >= new Date(this.startDate)) &&
                (!this.endDate || purchaseDate <= new Date(this.endDate));
    } else if (this.selectedFilter === 'department') {
      matches = this.searchQuery ? po.department === this.searchQuery : true;
    } else if (this.selectedFilter === 'documentNumber') {
      matches = po.documentNumber.toLowerCase().includes(this.searchQuery.toLowerCase());
    } else if (this.selectedFilter === 'LicensePlate') {
      matches = po.LicensePlate ? po.LicensePlate.toLowerCase().includes(this.searchQuery.toLowerCase()) : false;
    }

    // ต้องผ่านทุกเงื่อนไข
    return matchesStatus && hasPermission && matches;
  });
},
  uniqueDepartments() {
    return [...new Set(this.poListData.map(po => po.department))];
  },
  //   paginatedData() {
  //   const start = (this.currentPage - 1) * this.itemsPerPage;
  //   const end = start + this.itemsPerPage;
  //   const paginated = this.filteredPrListData.slice(start, end);
    
  //   // console.log('Paginated data:', paginated); // ตรวจสอบข้อมูลที่แบ่งหน้า
  //   return paginated;
  // },
    totalPages() {
      return Math.ceil(this.filteredPrListData.length / this.itemsPerPage);
    },
    totalPOPages() {
    return Math.ceil(this.filteredPoItems.length / this.itemsPerPage);
  },
  filteredPageNumbers() {
    const totalPages = this.totalPOPages;
    const currentPage = this.currentPage;
    const pageNumbers = [];

    if (totalPages <= 5) {
      // กรณีที่มีหน้าไม่เกิน 5 หน้า
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // แสดงหน้าแรก
      pageNumbers.push(1);

      // แสดง "..." ถ้าหน้าปัจจุบันห่างจากหน้าแรกมากกว่า 2
      if (currentPage > 2) {
        pageNumbers.push('...');
      }

      // แสดงหน้าก่อนหน้า, หน้าปัจจุบัน, และหน้าถัดไป
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(currentPage + 1, totalPages - 1);
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      // แสดง "..." ถ้าหน้าปัจจุบันห่างจากหน้าสุดท้ายมากกว่า 2
      if (currentPage < totalPages - 2) {
        pageNumbers.push('...');
      }

      // แสดงหน้าสุดท้าย
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
    },
    pageNumbers() {
    let startPage = 1;
    let endPage = this.totalPages;

    if (this.totalPages > 5) {
      if (this.currentPage <= 3) {
        endPage = 5;
      } else if (this.currentPage + 2 >= this.totalPages) {
        startPage = this.totalPages - 4;
      } else {
        startPage = this.currentPage - 2;
        endPage = this.currentPage + 2;
      }
    }

    let pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  },
  },
  mounted() {
    // this.fetchPrListData();
    // เพิ่มการเชื่อมต่อ WebSocket
    // socket.on('message', (message) => {
    //   if (message.type === 'pr_status_updated' || message.type === 'pr_list_updated') {
    //     console.log('PR status updated:', message.data);
    //     this.fetchPrListData(); // อัปเดตข้อมูลเมื่อมีการเปลี่ยนแปลงสถานะ PR
    //   }
    // });
    this.setActiveSectionFromQuery();
    if (this.$route.query.currentPage) {
    this.currentPage = Number(this.$route.query.currentPage);
  }
  },
  methods: {
    determineAllowedGroupIds() {
    if (!this.user) {
      return [];
    }

    const { group_id, position_lo } = this.user;

    // เงื่อนไขสำหรับ HQ (group_id 1)
    if (group_id === 1) {
      return []; // HQ สามารถดูได้ทั้งหมด
    }

    // เงื่อนไขสำหรับ Managers ที่เป็น group_id 3
    if (group_id === 3) {
      if (position_lo === 'ผู้จัดการลานกระบือ') {
        return [3, 5];
      }
      return [3];
    }

    // เงื่อนไขสำหรับ group_id 4
    if (group_id === 4) {
      if (position_lo === 'ผู้จัดการปทุมธานี') {
        return [4];
      }
      // เพิ่มเงื่อนไขสำหรับธุรการซ่อมบำรุง
      if (position_lo === 'ธุรการซ่อมบำรุง') {
        return [4, 5]; // สามารถดู group_id 4 และ 5
      }
      return [4]; // กรณีอื่นๆ ของ group_id 4
    }

    // กรณีอื่นๆ
    return [group_id];
  },
    toggleSearchInput() {
      this.isSearchInputVisible = !this.isSearchInputVisible;
    },
    goToPageExclude(page) { 
  this.currentPage = page;
  this.$router.replace({ 
    name: 'PRForm', 
    query: { 
      ...this.$route.query, 
      currentPage: page 
    } 
  });
},
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;  // สลับสถานะเปิด/ปิด
    },
    handleOutsideClick(event) {
      // ถ้าคลิกข้างนอก sidebar ให้ปิด sidebar
      if (this.isSidebarOpen && !this.$refs.sidebar.contains(event.target)) {
        this.isSidebarOpen = false;
      }
    },
    statusClass(status) {
      return {
        'bg-yellow-100 text-yellow-800': status === 'รอตรวจสอบ',
        'bg-green-100 text-green-800': status === 'เสร็จสิ้น' || status ==='สำเร็จ' || status === 'รับอะไหล่แล้ว' || status === 'สั่งซื้อครบแล้ว',
        'bg-red-100 text-red-800': status === 'ปิดใบ PR',
        'bg-blue-100 text-blue-800': status === 'กำลังดำเนินการ',
        'bg-gray-100 text-gray-800': status === 'ทั้งหมด',
        'bg-purple-100 text-purple-800': status === 'รอผจก.หน่วยงานตรวจสอบ',
        'bg-indigo-100 text-indigo-800': status === 'รอส่วนกลางตรวจสอบ',
        'bg-teal-100 text-teal-800': status === 'รอเจ้าหน้าที่ธุรการจัดซื้อ',
        'bg-orange-100 text-orange-800': status === 'อยู่ระหว่างการสั่งซื้อ',
        'bg-pink-100 text-pink-800': status === 'ยกเลิกใบ PR',
        'bg-cyan-100 text-cyan-800': status === 'ปิดใบสั่งซื้อ',
        // 'bg-lime-100 text-lime-800': status === 'รับอะไหล่แล้ว',
        'bg-rose-100 text-rose-800': status === 'ไม่อนุมัติ',
        'bg-amber-100 text-amber-800': status === 'รอการอนุมัติ',
        'bg-violet-100 text-violet-800': status === 'รอการสั่งซื้อ',
        'bg-fuchsia-100 text-fuchsia-800': status === 'รอรับอะไหล่',
        
      };
    },
    prevPageExclude() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPageExclude() {
      if (this.currentPage < this.totalPagesExclude) {
        this.currentPage++;
      }
    },
     // Existing methods...
  markAsViewed(itemId) {
    if (!this.viewedItems.includes(itemId)) {
      this.viewedItems.push(itemId);
      localStorage.setItem('viewedItems', JSON.stringify(this.viewedItems));
    }
  },
  loadViewedItems() {
    const viewedItems = localStorage.getItem('viewedItems');
    if (viewedItems) {
      this.viewedItems = JSON.parse(viewedItems);
    }
  },
  async fetchUser() {
    const token = localStorage.getItem('userToken');
    if (!token) {
        this.$router.push({ name: 'Login' });
        return;
    }
    try {
        const response = await axios.get('https://brk-group.org/api/user', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const userData = response.data;
        this.user = {
            login_id: userData.login_id,
            fullName: `${userData.prefixname}${userData.fname} ${userData.lname}`,
            agency: userData.agency,
            group_id: userData.group_id, // Assuming you have group_id
            position_lo: userData.position_lo
        };
        this.agency = userData.agency; // ตั้งค่า agency ของผู้ใช้จากข้อมูลที่ดึงมา
        // console.log('Fetched user agency:', this.user.agency);
        // console.log('Fetched user group_id:', this.user.group_id); // ตรวจสอบค่า group_id ที่ได้มา

        // this.agencyName = this.getAgencyName(this.agency);
        this.agencyName = this.getAgencyName(userData.agency);
        this.position = userData.position_lo; // ตั้งค่าตำแหน่งของผู้ใช้
    } catch (error) {
        // console.error('Error fetching user:', error);
        if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'Login' });
        } else {
            alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
        }
    }
},
getAgencyName(agencyNumber) {
    // ฟังก์ชันแปลงหมายเลข agency เป็นชื่อ department
    const agencyMap = {
      1: 'สำนักงานใหญ่',
      2: 'หน่วยงานลานกระบือ',
      3: 'MBV(บีอาร์เคคลองเตย)',
      4: 'สุพรรณบุรี'
    };
    return agencyMap[agencyNumber] || ''; // คืนค่าว่างหากไม่พบ
  },
  goToPage(page) {
  // ตรวจสอบว่าปุ่มที่กดไม่ใช่ปุ่ม "..." ก่อนจะดำเนินการ
  if (page !== '...') {
    this.currentPage = page;
    this.$router.replace({ 
      name: this.$route.name, // ใช้ชื่อ route ปัจจุบัน
      params: this.$route.params, // คงค่า params เดิม
      query: { 
        ...this.$route.query, 
        currentPage: page 
      } 
    });
    }
  },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPOPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPOPage() {
      if (this.currentPage < this.totalPOPages) {
        this.currentPage++;
      }
    },
    handleLocationChange() {
    console.log('Location changed to:', this.searchQuery);
    this.filterList();
  },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    setActiveSectionFromQuery() {
      const query = this.$route.query;
      if (query.activeSection) {
        this.activeSection = query.activeSection;
      }
      // ทำการเรียกฟังก์ชันที่เกี่ยวข้องกับ activeSection
      this.setActiveSection(this.activeSection);
    },
    
    setActiveSection(section) {
      this.currentPage = 1;
      this.activeSection = section;
      if (section === 'pr-list') {
        this.fetchPrListDataExclude()
      } else if (section === 'pr-own') {
        this.fetchPrListData();
      } else if (section === 'po-list') {
        this.fetchPoListData();
      }
    },
    setPrType(type) {
      this.prType = type;
      this.currentPage = 1; // Reset page to 1 when type changes
      this.fetchPrListData(); // Fetch data again when type changes
      this.fetchPrListDataExclude();
    },
//     async fetchPoListData() {
//     try {
//       const token = localStorage.getItem('userToken');
//       const response = await axios.get('https://brk-group.org/api/po_list', {
//         headers: { 'Authorization': `Bearer ${token}` }
//       });
//       // ตั้งค่า poListData จากข้อมูล API
//       this.poListData = response.data.sort((a, b) => b.id - a.id);
//       // console.log('PO List Data:', this.poListData); // ตรวจสอบข้อมูล PO
//       // console.log('User Agency:', this.agency); // ตรวจสอบค่า agency
//     } catch (error) {
//       // console.error('Error fetching PO list data:', error);
//     }
// },
async fetchPoListData() {
  try {
    const token = localStorage.getItem('userToken');
    const response = await axios.get('https://brk-group.org/api/po_list', {
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        group_id: this.user?.group_id,
        login_id: this.user?.login_id,
        position_lo: this.user?.position_lo || ''
      }
    });

    // Log ข้อมูลดิบที่ได้จาก Backend
    // console.log('Raw PO Data:', response.data);

    // กรองข้อมูลตาม group_id
    let filteredData = response.data;

    if (this.user?.group_id === 6) {
      // กรณี group_id 6 ดูได้เฉพาะของ group_id 6
      filteredData = response.data.filter(po => po.group_id === 6);
    } else if (this.user?.group_id === 4) {
      // กรณี group_id 4 ดูได้เฉพาะของ group_id 4
      filteredData = response.data.filter(po => po.group_id === 4);
    } else if (this.user?.group_id === 3) {
      if (this.user.login_id === 63 || this.user.position_lo === 'ผู้จัดการลานกระบือ') {
        // กรณี login_id = 63 หรือ position_lo = ผู้จัดการลานกระบือ ดู group_id 3 และ 5
        filteredData = response.data.filter(po => [3, 5].includes(po.group_id));
      } else {
        // กรณีอื่นๆ ของ group_id 3
        filteredData = response.data.filter(po => [3, 9, 10].includes(po.group_id));
      }
    } else if (this.user?.group_id === 1) {
      // กรณี HQ (group_id 1) ดูได้ทั้งหมด
      filteredData = response.data;
    } else {
      // กรณีอื่นๆ ดูได้เฉพาะของตัวเอง
      filteredData = response.data.filter(po => po.created_by === this.user?.login_id);
    }

    // เรียงลำดับตาม id จากมากไปน้อย
    this.poListData = filteredData.sort((a, b) => b.id - a.id);

    console.log('Filtered and sorted PO List:', this.poListData);

  } catch (error) {
    console.error('Error fetching PO list:', error);
    if (error.response?.status === 401) {
      this.$router.push({ name: 'Login' });
    } else {
      alert('เกิดข้อผิดพลาดในการดึงข้อมูลรายการ PO');
    }
  }
},
// async fetchPoListData() {
//   try {
//     const token = localStorage.getItem('userToken');
//     const response = await axios.get('https://brk-group.org/api/po_list', {
//       headers: { 'Authorization': `Bearer ${token}` },
//       params: {
//         group_id: this.user.group_id, // ส่ง group_id
//         login_id: this.user.login_id, // ส่ง login_id
//         position_lo: this.user.position_lo // ส่ง position_lo
//       }
//     });

//     // Log ข้อมูลดิบที่ได้จาก Backend
//     console.log('Raw PO Data:', response.data);

//     // กรองข้อมูลตาม group_id
//     let filteredData = response.data;

//     if (this.user.group_id === 6) {
//       // กรณี group_id 6 ดูได้เฉพาะของ group_id 6
//       filteredData = response.data.filter(po => po.group_id === 6);
//     } else if (this.user.group_id === 4) {
//       // กรณี group_id 4 ดูได้เฉพาะของ group_id 4
//       filteredData = response.data.filter(po => po.group_id === 4);
//     } else if (this.user.group_id === 3) {
//       if (this.user.login_id === 63 || this.user.position_lo === 'ผู้จัดการลานกระบือ') {
//         // กรณี login_id = 63 หรือ position_lo = ผู้จัดการลานกระบือ ดู group_id 3 และ 5
//         filteredData = response.data.filter(po => [3, 5].includes(po.group_id));
//       } else {
//         // กรณีอื่นๆ ของ group_id 3
//         filteredData = response.data.filter(po => [3, 9, 10].includes(po.group_id));
//       }
//     } else if (this.user.group_id === 1) {
//       // กรณี HQ (group_id 1) ดูได้ทั้งหมด
//       filteredData = response.data;
//     } else {
//       // กรณีอื่นๆ ดูได้เฉพาะของตัวเอง
//       filteredData = response.data.filter(po => po.created_by === this.user.login_id);
//     }

//     // เรียงลำดับตาม id จากมากไปน้อย (เหมือนโค้ดเดิม)
//     this.poListData = filteredData.sort((a, b) => b.id - a.id);

//     console.log('Filtered and sorted PO List:', this.poListData);

//   } catch (error) {
//     console.error('Error fetching PO list:', error);
//     if (error.response && error.response.status === 401) {
//       this.$router.push({ name: 'Login' });
//     } else {
//       alert('เกิดข้อผิดพลาดในการดึงข้อมูลรายการ PO');
//     }
//   }
// }
// ,
    async fetchPrListData() {
  try {
    const token = localStorage.getItem('userToken');
    const response = await axios.get(`https://brk-group.org/api/purchase_requisitions`, {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { 
        type: this.prType,
        group_id: this.user.agency || '' ,// ส่งค่า group_id ไปยัง API
        position_lo: this.user.position_lo, // เพิ่มการส่ง position_lo
        warehouse_id: this.user.position_lo === 'ผู้จัดการปทุมธานี' ? 54 : null
      }
    });
    this.prListData = response.data.sort((a, b) => b.id - a.id);
    // console.log('Data fetched:', this.prListData); // ตรวจสอบข้อมูลที่ดึงมา
  } catch (error) {
    console.error('Error fetching PR list data:', error);
  }
},
// determineGroupIdParam() {
//       const { group_id, position_lo } = this.user;

//       // เงื่อนไขสำหรับผู้จัดการ (group_id 4)
//       if (group_id === this.GROUP_IDS.MANAGER) {
//         switch (position_lo) {
//           case this.MANAGER_POSITIONS.SUPHAN:
//             return this.GROUP_IDS.SUPHAN.toString();
//           case this.MANAGER_POSITIONS.PATHUM:
//             return this.GROUP_IDS.MANAGER.toString();
//           default:
//             return '4,5'; // กรณีอื่นๆ ให้เห็นทั้ง 2 group
//         }
//       }

//       // เงื่อนไขสำหรับ group อื่นๆ
//       const groupMapping = {
//         [this.GROUP_IDS.HQ]: '', // HQ ดูได้ทั้งหมด
//         [this.GROUP_IDS.OPERATIONS]: '3,9,10',
//         [this.GROUP_IDS.SPECIAL]: '6'
//       };

//       return groupMapping[group_id] ?? this.user.agency;
//     },
determineGroupIdParam() {
  const { group_id, position_lo } = this.user;

  console.log('User Info:', { group_id, position_lo });

  if (group_id === 3 && position_lo === 'ผู้จัดการลานกระบือ') {
    console.log('Determine group_id for ผู้จัดการลานกระบือ:', '3');
    return '3';
  }

  console.log('Determine default group_id:', group_id);
  return group_id;
}
,
  
// checkFilterConditions(item) {
//   console.log('Processing item:', item);
//   if (this.user.group_id === 1) {
//     return item.departmentId !== '1'; // กรองเฉพาะ departmentId ที่ไม่ใช่ 1
//   }

//   const typeMatches = item.purchaseType === this.prType;
//   const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;
//   console.log('Status Matches:', statusMatches, 'Item Status:', item.status, 'Selected Status:', this.statusFilter);

//   let filterMatches = true;
//   if (this.selectedFilter === 'purchaseDate') {
//     const purchaseDate = new Date(item.purchaseDate);
//     const startDate = this.startDate ? new Date(this.startDate) : null;
//     const endDate = this.endDate ? new Date(this.endDate) : null;

//     filterMatches = (!startDate || purchaseDate >= startDate) &&
//                    (!endDate || purchaseDate <= endDate);
//                    console.log('Date Filter Matches:', filterMatches, 'Purchase Date:', purchaseDate, 'Start Date:', startDate, 'End Date:', endDate);
  
//   } else if (this.selectedFilter && this.searchQuery) {
//     const fieldValue = item[this.selectedFilter];
//     filterMatches = fieldValue?.toString().toLowerCase().includes(this.searchQuery.toLowerCase()) ?? false;
//     console.log('Generic Filter Matches:', filterMatches, 'Field Value:', fieldValue, 'Search Query:', this.searchQuery);
//   }
// const reciveLocationMatches = this.selectedFilter === 'reciveLocation' 
//     ? this.searchQuery ? item.reciveLocation === this.searchQuery : true 
//     : true;
//   console.log('Recive Location Matches:', reciveLocationMatches, 'Item Location:', item.reciveLocation, 'Selected Location:', this.searchQuery);

//   // เงื่อนไขสุดท้า
//   console.log('Check Filter Conditions Result:', result);
//   // กรองเฉพาะ warehouse_id สำหรับผู้จัดการลานกระบือ
//   const isManagerLanKrabue = this.user.group_id === 3 &&
//                              (this.user.login_id === 63 || this.user.position_lo === 'ผู้จัดการลานกระบือ');
  
//   const warehouseMatches = isManagerLanKrabue
//     ? ['53', '50','51', '52'].includes(String(item.warehouse_id))
//     : true;

//   console.log('Item Warehouse ID:', item.warehouse_id);
//   console.log('Warehouse Matches:', warehouseMatches);

//   const result = typeMatches && statusMatches && filterMatches && warehouseMatches && reciveLocationMatches;
//   console.log('Check Filter Conditions Result:', result);

//   return result;
// }

checkFilterConditions(item) {
  console.log('Processing item:', item);
  console.log('Checking item:', {
    item,
    selectedFilter: this.selectedFilter,
    searchQuery: this.searchQuery,
    reciveLocation: item.reciveLocation
  });
  // กรองสำหรับ Admin (group_id = 1)
  if (this.user.group_id === 1) {
    const departmentMatches = item.departmentId !== '1'; // กรอง departmentId ที่ไม่ใช่ 1
    console.log('Department Matches (Admin):', departmentMatches);
    return departmentMatches;
  }

  // ตรวจสอบประเภท
  const typeMatches = item.purchaseType === this.prType;
  console.log('Type Matches:', typeMatches, 'Item Type:', item.purchaseType, 'Selected Type:', this.prType);

  // ตรวจสอบสถานะ
  const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;
  console.log('Status Matches:', statusMatches, 'Item Status:', item.status, 'Selected Status:', this.statusFilter);

  // ตรวจสอบสถานที่รับ
  const reciveLocationMatches = this.selectedFilter === 'reciveLocation'
      ? !this.searchQuery || item.reciveLocation === this.searchQuery
      : true;
  console.log('Recive Location Matches:', reciveLocationMatches, 'Item Location:', item.reciveLocation, 'Selected Location:', this.searchQuery);

  // ตรวจสอบวันที่เสนอใบขอซื้อ
  let filterMatches = true;
  if (this.selectedFilter === 'purchaseDate') {
    const purchaseDate = new Date(item.purchaseDate);
    const startDate = this.startDate ? new Date(this.startDate) : null;
    const endDate = this.endDate ? new Date(this.endDate) : null;

    filterMatches = (!startDate || purchaseDate >= startDate) &&
                   (!endDate || purchaseDate <= endDate);
    console.log('Date Filter Matches:', filterMatches, 'Purchase Date:', purchaseDate, 'Start Date:', startDate, 'End Date:', endDate);
  } else if (this.selectedFilter && this.searchQuery) {
    const fieldValue = item[this.selectedFilter];
    filterMatches = fieldValue?.toString().toLowerCase().includes(this.searchQuery.toLowerCase()) ?? false;
    console.log('Generic Filter Matches:', filterMatches, 'Field Value:', fieldValue, 'Search Query:', this.searchQuery);
  }

  // กรองเฉพาะ warehouse_id สำหรับผู้จัดการลานกระบือ
  const isManagerLanKrabue = this.user.group_id === 3 &&
                             (this.user.login_id === 63 || this.user.position_lo === 'ผู้จัดการลานกระบือ');
  const warehouseMatches = isManagerLanKrabue
    ? ['53', '50', '51', '52'].includes(String(item.warehouse_id))
    : true;
  console.log('Warehouse Matches:', warehouseMatches, 'Item Warehouse ID:', item.warehouse_id);

  // เงื่อนไขสุดท้าย
  const result = typeMatches && statusMatches && reciveLocationMatches && filterMatches && warehouseMatches;
  console.log('Final Check Filter Conditions Result:', result);

  return result;
}

,
async fetchPrListDataExclude() {
  this.loading = true;
  this.error = null;

  try {
    const token = localStorage.getItem('userToken');
    const groupIdParam = this.determineGroupIdParam();

    console.log('Parameters sent to API:', {
      type: this.prType,
      group_id: groupIdParam,
      position_lo: this.user.position_lo,
    });

    const response = await axios.get('https://brk-group.org/api/purchase_requisitions_exclude', {
      headers: { 'Authorization': `Bearer ${token}` },
      params: {
        type: this.prType,
        group_id: groupIdParam,
        position_lo: this.user.position_lo,
      }
    });

    console.log('Raw data from API:', response.data);

    this.prListDataExclude = response.data.sort((a, b) => b.id - a.id);

    console.log('Sorted PR List Data:', this.prListDataExclude);

  } catch (error) {
    console.error('Error fetching PR list data exclude:', error);
    this.error = error;
  } finally {
    this.loading = false;
  }
    },
    // async fetchPoListData() {
    //   try {
    //     const token = localStorage.getItem('userToken');
    //     const response = await axios.get('https://brk-group.org/api/po_list', {
    //       headers: { 'Authorization': `Bearer ${token}` }
    //     });
    //     this.poListData = response.data;
    //   } catch (error) {
    //     console.error('Error fetching PO list data:', error);
    //   }
    // },
    countStatus(status) {
      if (status === 'ทั้งหมด') {
        return this.prListData.length;
      }
      return this.prListData.filter(item => item.status === status).length;
    },
    countAgencyStatus(status) {
      if (status === 'ทั้งหมด') {
        return this.prListDataExclude.length;
      }
      return this.prListDataExclude.filter(item => item.status === status).length;
    },
    // countPoStatus(status) {
    //   if (status === 'ทั้งหมด') {
    //     return this.poListData.length;
    //   }
    //   return this.poListData.filter(po => po.status === status).length;
    // },
    countPoStatus(status) {
      if (status === 'ทั้งหมด') {
      return this.poListData.length;
    }
    // นับจำนวนสถานะที่ตรงกับ `status` ที่ระบุ
    return this.poListData.filter(item => item.status === status).length;
  },
    filterList() {
      this.filteredData = this.testData.filter(item => {
        return this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;
      });
      console.log('Filtering with query:', this.searchQuery);
    console.log('Selected filter:', this.selectedFilter);
    
    // ทำการกรองข้อมูลใหม่
    this.filteredData = this.prListDataExclude.filter(item => 
      this.checkFilterConditions(item)
    );
    },
    checkBaseConditions(item) {
      // Check admin condition
      if (this.user.group_id === 1) {
        return item.departmentId !== '1';
      }

      // Check warehouse manager condition
      if (this.user.group_id === 3 && 
          (this.user.login_id === 63 || this.user.position_lo === 'ผู้จัดการลานกระบือ')) {
        const validWarehouseIds = ['53', '50', '51', '52'];
        if (!validWarehouseIds.includes(String(item.warehouse_id))) {
          return false;
        }
      }

      // Check purchase type
      return item.purchaseType === this.prType;
    },
    checkSpecificFilters(item) {
      // Check status filter
      if (this.statusFilter !== 'ทั้งหมด' && item.status !== this.statusFilter) {
        return false;
      }

      // If no specific filter is selected, return true
      if (!this.selectedFilter || !this.searchQuery) {
        return true;
      }

      switch (this.selectedFilter) {
        case 'purchaseDate':
          return this.checkDateRange(item.purchaseDate);
        
        case 'reciveLocation':
          return !this.searchQuery || item.reciveLocation === this.searchQuery;
        
        case 'documentNumber':
        case 'LicensePlate':
          return this.checkTextMatch(item[this.selectedFilter]);
        
        case 'status':
          return item.status === this.statusFilter;
        
        default:
          return true;
      }
    },
    checkDateRange(dateStr) {
      if (!this.startDate && !this.endDate) {
        return true;
      }

      try {
        // แปลงวันที่จากข้อมูล DD/MM/YYYY เป็น Date object
        const [day, month, year] = dateStr.split('/');
        const itemDate = new Date(year, month - 1, day);
        itemDate.setHours(0, 0, 0, 0);

        // แปลง input date เป็น Date object
        const startDate = this.startDate ? new Date(this.startDate) : null;
        const endDate = this.endDate ? new Date(this.endDate) : null;

        if (startDate && endDate) {
          return itemDate >= startDate && itemDate <= endDate;
        } else if (startDate) {
          return itemDate >= startDate;
        } else if (endDate) {
          return itemDate <= endDate;
        }

        return true;
      } catch (error) {
        console.error('Error comparing dates:', error);
        return false;
      }
    },
    handleFilterChange() {
      this.searchQuery = '';
      if (this.selectedFilter !== 'purchaseDate') {
        this.startDate = '';
        this.endDate = '';
      }
      if (this.selectedFilter !== 'status') {
        this.statusFilter = 'ทั้งหมด';
      }
      this.currentPage = 1;
    },
    logDateComparison(item) {
      console.log('Item date:', this.formatDate(item.purchaseDate));
      console.log('Start date:', this.startDate);
      console.log('End date:', this.endDate);
    },
    checkTextMatch(value) {
      if (!value || !this.searchQuery) return false;
      return value.toString().toLowerCase()
        .includes(this.searchQuery.toLowerCase());
    },
    // handleLocationChange() {
    //   this.currentPage = 1; // Reset to first page when filter changes
    // },
    resetFilters() {
      this.selectedFilter = '';
      this.searchQuery = '';
      this.startDate = '';
      this.endDate = '';
      this.statusFilter = 'ทั้งหมด';
      this.currentPage = 1;
    }
  },
  // watch: {
  //   statusFilter() {
  //     console.log('Status Filter changed:', this.statusFilter);
  //     this.filterList();
  //     this.filteredPrListDataExclude; // Trigger reactivity
  //   },
  //   searchQuery: {
  //   handler(newVal, oldVal) {
  //     console.log('Search Query changed:', { oldVal, newVal });
  //     this.$nextTick(() => {
  //       this.filterList();
  //     });
  //   },
  // },

  //   prType() {
  //     this.fetchPrListDataExclude();
  //   }
  // },
  watch: {
    // Reset to first page when filters change
    selectedFilter() {
      this.currentPage = 1;
      this.searchQuery = '';
      this.handleFilterChange();
    },
    searchQuery() {
      this.currentPage = 1;
    },
    statusFilter() {
      this.currentPage = 1;
    },
    startDate() {
      this.currentPage = 1;
    },
    endDate() {
      this.currentPage = 1;
    }
  },
}
</script>

<style scoped>
/* Sidebar */
.sidebar {
  background-color: #f8f9fa;
  padding: 1rem;
  width: 256px;
  /* min-height: 100vh;
  position: fixed; */
  min-height: calc(100vh - 50px); /* ลดความสูงให้ Footer ไม่ถูกบัง */
  position: sticky; /* ใช้ sticky แทน fixed */
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.sidebar.open {
  transform: translateX(0); /* แสดง Sidebar */
}

.sidebar h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 0.75rem;
}

.sidebar a,
.sidebar button {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  width: 100%;
  text-align: left;
  font-size: 0.875rem;
  color: #495057;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar a:hover,
.sidebar button:hover {
  background-color: #e9ecef;
  color: #000000;
}

/* Main Content */
/* .main-content {
  margin-left: 256px;
  padding: 1rem;
  transition: margin-left 0.3s ease-in-out;
} */
.main-content {
  flex-grow: 1; /* ทำให้ Main Content ขยายเต็มพื้นที่ */
  padding: 1rem;
  margin-left: 256px;
  transition: margin-left 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }
}

/* Responsive Sidebar */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    position: absolute;
    z-index: 1000;
  }

  .sidebar.open {
    transform: translateX(0);
  }
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
}

table thead {
  background-color: #f1f1f1;
}

table th,
table td {
  padding: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap; /* ทำให้ตารางเลื่อนในแนวนอน */
  }

  table th,
  table td {
    font-size: 0.75rem; /* ลดขนาดฟอนต์ */
    padding: 0.25rem; /* ลด padding */
  }
}

/* Buttons and Dropdowns */
button,
select {
  display: block;
  width: 100%; /* เต็มความกว้างบนมือถือ */
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

@media (min-width: 768px) {
  button,
  select {
    width: auto; /* ความกว้างตามเนื้อหา */
    margin-bottom: 0;
  }
}

</style>
