
// import { createApp } from 'vue';
// import App from './App.vue';
// import router from './router';
// import '@fortawesome/fontawesome-free/css/all.css';
// import './assets/tailwind.css';
// import { messaging } from './firebase'; // ใช้จาก firebase.js
// import { onMessage } from "firebase/messaging";
// import { requestNotificationPermission } from './firebase'; // ใช้ฟังก์ชันจาก firebase.js

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered successfully:', registration);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }
// if (messaging) {
//   onMessage(messaging, (payload) => {
//     console.log('Received foreground message:', payload);
//     console.log('Notification permission status:', Notification.permission);
//     console.log('Service Worker status:', navigator.serviceWorker.controller);
    
//     if ('serviceWorker' in navigator && 'PushManager' in window) {
//       navigator.serviceWorker.ready.then(registration => {
//         console.log('Service Worker is ready');
//         registration.showNotification(
//           payload.notification.title,
//           {
//             body: payload.notification.body,
//             icon: '/icon.webg',
//             badge: '/icon.webg',
//             requireInteraction: true,
//             silent: false,
//             vibrate: [200, 100, 200],
//             data: payload.data,
//             actions: [
//               {
//                 action: 'open_purchase_request',
//                 title: 'ดูรายละเอียด'
//               }
//             ]
//           }
//         ).then(() => {
//           console.log('Notification shown successfully');
//         }).catch(error => {
//           console.error('Error showing notification:', error);
//         });
//       }).catch(error => {
//         console.error('Service Worker ready error:', error);
//       });
//     } else {
//       console.warn('Service Worker or Push not supported');
//     }
//   });
// }

// const app = createApp(App);
// app.use(router);
// app.mount('#app');

// (async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     console.log('Notification permission:', permission);
    
//     if (permission === 'granted') {
//       const token = await requestNotificationPermission();
//       console.log('FCM Token:', token);
//     }
//   } catch (error) {
//     console.error('Error requesting notification permission:', error);
//   }
// })();
// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/tailwind.css';
import { onMessage } from "firebase/messaging";
import { messaging, onMessageListener, requestNotificationPermission } from './firebase';

// Initialize notification handling
const initializeNotifications = async () => {
  try {
    console.log('Checking notification support...');
    
    if (!('Notification' in window)) {
      throw new Error('This browser does not support notifications');
    }

    const permission = await Notification.requestPermission();
    console.log('Initial notification permission:', permission);
    
    if (permission === 'granted') {
      const token = await requestNotificationPermission();
      if (token) {
        console.log('Notification system initialized successfully');
        onMessageListener(); // Start listening for messages
      }
    } else {
      console.warn('Notification permission not granted:', permission);
    }
  } catch (error) {
    console.error('Error initializing notifications:', error);
  }
};
if (messaging) {
    onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);
      console.log('Notification permission status:', Notification.permission);
      console.log('Service Worker status:', navigator.serviceWorker.controller);
      
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then(registration => {
          console.log('Service Worker is ready');
          registration.showNotification(
            payload.notification.title,
            {
              body: payload.notification.body,
              icon: '/icon.webg',
              badge: '/icon.webg',
              requireInteraction: true,
              silent: false,
              vibrate: [200, 100, 200],
              data: payload.data,
              actions: [
                {
                  action: 'open_purchase_request',
                  title: 'ดูรายละเอียด'
                }
              ]
            }
          ).then(() => {
            console.log('Notification shown successfully');
          }).catch(error => {
            console.error('Error showing notification:', error);
          });
        }).catch(error => {
          console.error('Service Worker ready error:', error);
        });
      } 
      else {
        console.warn('Service Worker or Push not supported');
      }
    });
  }
// Create and mount Vue app
const app = createApp(App);
app.use(router);
app.mount('#app');

// Initialize notifications after app mount
initializeNotifications();