<template>
   <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col mt-8">
      <div class="py-2">
        
        <h2 class="text-2xl font-semibold leading-tight">ใบขอซื้อ</h2>
        
        <div class="mt-4 flex justify-end space-x-2">
         


  <div class="" :class="{'watermark': detail.status_pr === 'ยกเลิกใบ PR'}">
  <div v-if="detail.status_pr === 'ยกเลิกใบ PR'" class="cancel-reason">
      <h3>เหตุผลการยกเลิก: {{ detail.revision_reasons }}</h3>
    </div>
  </div>
<!--  อันแรกที่แก้ไข =======================================================================================================
  <button v-if="detail.status_pr === 'รอตรวจสอบ' && agency === 3 && user.position_lo === 'ธุรการซ่อมบำรุง' || detail.status_pr === 'รอดำเนินการ'"  @click="updateStatus('รอหัวหน้าอนุมัติ(หน่วยงาน)')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
    ดำเนินการต่อ
  </button> -->


  <div class="flex justify-between items-center w-full">
  <!-- ปุ่มย้อนกลับ -->
  <button 
    @click="goBack()"
    class="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition"
  >
    <i class="fas fa-arrow-left"></i> ย้อนกลับ
  </button>
  
  <!-- ปุ่มสำหรับแสดง modal เหตุผลการอนุมัติ -->
  <button 
    @click="openStatusApproveModal" 
    class="bg-blue-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
  >
    เหตุผลการอนุมัติ
  </button>
</div>

<!-- <button 
    v-if="canCancelRevision" 
    @click="confirmCancelRevision" 
    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
    ยกเลิก การดำเนินการ
  </button> -->
  <button 
  v-if="canCancelRevision" 
  @click="confirmCancelRevision" 
  :disabled="loading"
  class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center">
  <span v-if="!loading">ยกเลิก การดำเนินการ</span>
  <span v-if="loading">
    <svg class="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 100 8H4z"></path>
    </svg>
    กำลังดำเนินการ...
  </span>
</button>
  
<button v-if="detail.status_pr === 'รอตรวจสอบ' && agency === 3 && user.levelpermission === '1' || detail.status_pr === 'รอดำเนินการ'"  @click="updateStatus('รอผจก.หน่วยงานตรวจสอบ')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
    ดำเนินการต่อ
  </button>

  <button v-if="detail.status_pr === 'รอตรวจสอบ' && agency === 5 && user.levelpermission === '1' || detail.status_pr === 'รอดำเนินการ'"  @click="updateStatus('รอผจก.หน่วยงานตรวจสอบ')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
    ดำเนินการต่อ
  </button>

<button v-if="detail.status_pr === 'รอตรวจสอบ' && agency === 1 && user.levelpermission === '4' && detail.departmentName === 'HQ (สำนักงานใหญ่)' && user.position_lo !== 'ผู้จัดการฝ่ายซ่อมบำรุง' && user.position_lo !== 'หัวหน้าซ่อมบำรุงส่วนกลาง' && user.position_lo !== 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' " @click="updateStatus('กำลังดำเนินการ')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
    ดำเนินการต่อ
  </button>

  <!-- ธุรการซ่อมปทุมสุพรรณ -->
  <button v-if="detail.status_pr === 'รอตรวจสอบ' && agency === 4 && user.levelpermission === '1' && detail.departmentName === 'PT (ปทุม)' && user.position_lo !== 'ผู้จัดการฝ่ายซ่อมบำรุง' && user.position_lo !== 'หัวหน้าซ่อมบำรุงส่วนกลาง' && user.position_lo !== 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' " @click="updateStatus('รอผจก.หน่วยงานตรวจสอบ')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
    ดำเนินการต่อ
  </button>

  <button v-if="detail.status_pr === 'รอตรวจสอบ' && agency === 4 && user.levelpermission === '1' && detail.departmentName === 'SPB (สุพรรณบุรี)' && user.position_lo !== 'ผู้จัดการฝ่ายซ่อมบำรุง' && user.position_lo !== 'หัวหน้าซ่อมบำรุงส่วนกลาง' && user.position_lo !== 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' " @click="updateStatus('รอผจก.หน่วยงานตรวจสอบ')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
    ดำเนินการต่อ
  </button>


  <!-- ธุรการเวียงทอง -->
  <button v-if="detail.status_pr === 'รอตรวจสอบ' && agency === 6 && user.levelpermission === '1' && detail.departmentName === 'VTL (สระบุรี)' && user.position_lo !== 'ผู้จัดการฝ่ายซ่อมบำรุง' && user.position_lo !== 'หัวหน้าซ่อมบำรุงส่วนกลาง' && user.position_lo !== 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' " @click="updateStatus('รอผจก.หน่วยงานตรวจสอบ')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
    ดำเนินการต่อ
  </button>


    <!-- เนื้อหาของ PRDetail -->

 <!-- ปุ่มอนุมัติสำหรับหน่วยงาน MBV อันสองที่แก้ไข =============================================================================================== -->
 <!-- <button  v-if="detail.status_pr === 'รอหัวหน้าอนุมัติ(หน่วยงาน)' && user.agency === 1 && user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' && !isEditing" @click="openConfirmModal('รอผู้จัดการอนุมัติ', 'approved_by_department_head', 'status_approve1')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      ยืนยันใบขอซื้อ
    </button> -->
    <button  v-if="detail.status_pr === 'รอผจก.หน่วยงานตรวจสอบ' && user.agency === 3 && user.levelpermission === '2' && !isEditing" @click="openConfirmModal('รอส่วนกลางตรวจสอบ', 'approved_by_department_head', 'status_approve1')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      ตรวจสอบขั้นต้น
    </button> 

<!-- ตรวจสอบขั้นต้นปทุม -->
<button  v-if="detail.status_pr === 'รอผจก.หน่วยงานตรวจสอบ' && user.agency === 4 && user.levelpermission === '2' && !isEditing" @click="openConfirmModal('รอส่วนกลางตรวจสอบ', 'approved_by_department_head', 'status_approve1')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      ตรวจสอบขั้นต้น
    </button> 
<!-- ตรวจสอบขั้นต้นเวียงทอง -->
<button  v-if="detail.status_pr === 'รอผจก.หน่วยงานตรวจสอบ' && user.agency === 6 && user.levelpermission === '2' && !isEditing" @click="openConfirmModal('รอส่วนกลางตรวจสอบ', 'approved_by_department_head', 'status_approve1')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      ตรวจสอบขั้นต้น
    </button> 
 <!-- ปุ่มอนุมัติสำหรับหน่วยงาน MBV -->
 <button v-if="detail.status_pr === 'รอตรวจสอบ'  && user.agency === 1 && user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' && detail.creatorName === 'นาย ภานุพงศ์ จันทะคุณ' && !isEditing" @click="openConfirmModal('รอผู้จัดการอนุมัติ')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
   ยืนยันใบขอซื้อ
</button>
<!-- อันสามที่แก้ไข ====================================================================================================================================
<button v-if="detail.status_pr === 'รอผู้จัดการอนุมัติ' && user.agency === 1 && user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' && !isEditing" @click="openConfirmModal('รอเจ้าหน้าที่ธุรการจัดซื้อ', 'approved_by_manager' , 'status_approve2')" class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      อนุมัติ
    </button> -->
    <button 
  v-if="
    detail.status_pr === 'รอส่วนกลางตรวจสอบ' &&
    user.levelpermission === '3' &&
    !isEditing
  "
  @click="openConfirmModal('รอเจ้าหน้าที่ธุรการจัดซื้อ', 'approved_by_manager' , 'status_approve2')"
  class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
  ตรวจสอบขั้นสูง
</button>

    <button v-if="detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข' && user.agency === 1 && user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' && !isEditing" @click="openConfirmModalForManagerApproval" class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      อนุมัติ
    </button>

<!-- เพิ่มปุ่มอนุมัติที่จะแสดงเมื่อมาจากหน้า ApprovePO.vue -->
<button v-if="detail.status_pr === 'รอเจ้าหน้าที่ธุรการจัดซื้อ' && user.agency === 1 && user.levelpermission === '4' && !isEditing" @click="confirmClerkApproval" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
  ดำเนินการต่อ
</button>
 <!-- ปุ่มสำหรับดูเหตุผลการแก้ไข -->
 <!-- <button @click="showRevisionReasonModal = true"  =====================================================================================================
  v-if="detail.revision_reasons && detail.status_pr !== 'ยกเลิกใบ PR' && 
        (detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข' || 
        (user.position_lo === 'ธุรการซ่อมบำรุง' && detail.status_pr === 'รอธุรการซ่อมแก้ไข'))" 
  class="bg-orange-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
  ดูเหตุผลการแก้ไข
</button> -->

<button @click="showRevisionReasonModal = true" 
  v-if="detail.revision_reasons && detail.status_pr !== 'ยกเลิกใบ PR' && 
        (detail.status_pr === 'รอแก้ไข' || 
        (user.levelpermission === '1' || user.levelpermission === '2' || user.levelpermission === '3' && detail.status_pr === 'รอแก้ไข'))" 
  class="bg-orange-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
  ดูเหตุผลการแก้ไข
</button>
<!-- ปุ่มส่งกลับแก้ไข -->

  
<!-- เพิ่มปุ่มอนุมัติที่จะแสดงเมื่อมาจากหน้า ApprovePO.vue -->
<button v-if="$route.query.fromApprovePO && detail.status_pr === 'รอผู้จัดการฝ่ายจัดซื้อ' && user.agency === 1 && user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' && !isEditing" @click="openConfirmModal('รอการสั่งซื้อ', 'approved_by_purchase_manager', 'status_approve3')" class="bg-lime-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
       อนุมัติ
    </button>

<!-- พี่เบน -->
<button v-if=" detail.status_pr === 'รอตรวจสอบ' && detail.creatorName === 'นางสาว สาวิตรี มั่นคง' && user.agency === 1 && user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' && !isEditing" 
        @click="openConfirmModal('รอการสั่งซื้อ', 'approved_by_purchase_manager', 'status_approve3')" 
        class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
  อนุมัติ
</button>

<button v-if="detail.status_pr === 'อยู่ระหว่างการสั่งซื้อ' && !allItemsOrdered && user.agency === 1 && user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ'" @click="openPurchaseOrderModal" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
      เปิดใบสั่งซื้อ
    </button>



<button v-if="detail.status_pr === 'รอการสั่งซื้อ' && !allItemsOrdered && user.agency === 1 && user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' && detail.purchaseType !== 'stock' && detail.purchaseType !== 'repair' " @click="openPurchaseOrderModal" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
    เปิดใบสั่งซื้อ
  </button>
<!-- <button v-if="$route.query.fromApprovePO && detail.status_pr === 'รอธุรการฝ่ายจัดซื้อ' && user.agency === 1 && user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' && !isEditing" 
        @click="approvePR('รอการสั่งซื้อ')" 
        class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
  อนุมัติ
</button> -->



<!-- ปุ่มบันทึกการแก้ไข -->
<button v-if="isEditingProduct && user.agency === 1 && (user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง') && detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข'" @click="saveNewProduct" class="bg-purple-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
  บันทึกรายการ
</button>

<div v-if="isLoading" class="loading-overlay">
  <div class="loader"></div>
</div>

  <button v-if="detail.status_pr === 'กำลังดำเนินการ' && !allItemsOrdered && user.agency === 1 && user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ' " @click="openPurchaseOrderModal" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
    เปิดใบสั่งซื้อ
  </button>

  <button v-if="detail.status_pr === 'รอการสั่งซื้อ' && !allItemsOrdered && user.agency === 1 && user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ' " @click="openPurchaseOrderModal" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
      เปิดใบสั่งซื้อ
    </button>
  <button v-if=" detail.status_pr !== 'อยู่ระหว่างการสั่งซื้อ' && detail.status_pr === 'สั่งซื้อครบแล้ว' && detail.status_pr === 'สั่งซื้อครบแล้ว'  "  @click="openExportModal" class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
    Export PDF
  </button>
  <button v-if=" detail.status_pr === 'อยู่ระหว่างการสั่งซื้อ'  && user.agency === 1 "  @click="openExportModal" class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
    Export PDF
  </button>
  <button v-if="detail.status_pr === 'กำลังดำเนินการ' && anyPOReceived && agency === 1" @click="openNoteModal" class="bg-orange-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
    ปิดใบสั่งซื้อ
  </button>

   <!-- Modal สำหรับเหตุผลในการปิดใบสั่งซื้อ -->
   <div v-if="showDisapproveModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เหตุผลในการปิดใบสั่งซื้อ</h3>
            <div class="mt-2">
              <textarea v-model="disapproveReason" class="w-full p-2 border rounded" rows="4" placeholder="กรุณาใส่เหตุผลในการปิดใบสั่งซื้อ"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeDisapproveModal">ยกเลิก</button>
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-red-600 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="disapproveOrder">ไม่อนุมัติ</button>
      </div>
    </div>
  </div>
</div>
    <!-- Modal สำหรับเลือกหน่วยงาน -->
    <div v-if="showExportModal" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกหน่วยงาน</h3>
                  <div class="mt-2">
                    <table class="min-w-full table-auto">
                      <thead class="bg-gray-100">
                        <tr>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รูปภาพ</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อหน่วยงาน</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลือก</th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="department in departments" :key="department.group_id">
                          <td class="px-6 py-4 whitespace-nowrap">
                            <img :src="transformImageUrl(department.image_url)" alt="Department Image" class="h-10 w-10 rounded-full">
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            {{ department.group_name }}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <input type="radio" :value="department" v-model="selectedDepartment">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button @click="exportPDF" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                ยืนยัน
              </button>
              <button @click="closeExportModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>


          </div>
<br>          
<div class="bg-white shadow mb-4 p-4">
  <div class="grid-container mb-4">
    <div><strong class="font-bold" >เลขที่ใบขอซื้อ:</strong> {{ detail.purchaseRequestNumber }}</div>
    <div><strong class="font-bold">วันที่ขอซื้อ:</strong> {{ formatDate(detail.requestDate) }}</div>
    <div><strong class="font-bold">ชื่อผู้สร้าง:</strong> {{ detail.creatorName }}</div>
    <div><strong class="font-bold">หน่วยงาน:</strong> {{ detail.departmentName }}</div>
    <template v-if="detail.purchaseType !== 'stock'">
      <div><strong class="font-bold">ทะเบียนรถ:</strong> {{ detail.vehicleRegistration }}</div>
      <div><strong class="font-bold">รหัสรถ:</strong> {{ detail.vehicleCode }}</div>
      <div><strong class="font-bold">รุ่น:</strong> {{ detail.vehicleModel }}</div>
      <div><strong class="font-bold">เวอร์ชั่นรถ:</strong> {{ detail.vehicleVersion }}</div>
      <div><strong class="font-bold">เลขคัสซีรถ:</strong> {{ detail.vehicleChassisNumber }}</div>
      <div><strong class="font-bold">หน่วยงานรถ:</strong> {{ detail.vehicleDepartment }}</div>
      <div><strong class="font-bold">ยี่ห้อ:</strong> {{ detail.vehicleBrand }}</div>
      <div><strong class="font-bold">เลขไมค์:</strong> {{ detail.vehicleMileage }}</div>
      <div><strong class="font-bold">ดูใบแจ้งซ่อม: </strong> 
        <a :href="detail.attachmentUrl" target="_blank" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ">
          <i class="fas fa-eye"></i> <!-- แทนคำว่า 'ดู' ด้วยไอคอน -->
        </a>
      </div>
      <div><strong class="font-bold">ดูประวัติซ่อม: </strong> 
        <a :href="detail.attachmentUrl2" target="_blank" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <i class="fas fa-eye"></i> <!-- แทนคำว่า 'ดู' ด้วยไอคอน -->
        </a>
      </div>
      
    </template>
    
    <div class="bg-red-100"><strong class="font-bold">สถานที่รับ:</strong> {{ detail.warehouseName }}</div>
   <!--- <div class="bg-red-100"><strong class="font-bold">รายละเอียด:</strong> {{ detail.details }}</div> -->
   <div class="bg-red-100 p-2 rounded">
  <strong class="font-bold">รายละเอียด:</strong>
  <div v-if="detail.status_pr === 'รอแก้ไข'">
    <input 
      v-model="detail.details" 
      type="text" 
      class="w-full bg-white border rounded px-2 py-1"
      placeholder="แก้ไขรายละเอียด"
    />
  </div>
  <div v-else>
    {{ detail.details }}
  </div>
</div>


  </div>
  
</div>
  <!-- ปุ่มแก้ไขไฟล์แนบ -->
  <!-- <div v-if="user.position_lo === 'ธุรการซ่อมบำรุง' && detail.status_pr === 'รอธุรการซ่อมแก้ไข'"> อันที่ 6 ============================================== -->
    <div v-if=" detail.status_pr === 'รอแก้ไข' && detail.purchaseType === 'repair'" >
    <div><strong class="font-bold">แก้ไขไฟล์แนบใบแจ้งซ่อม:</strong>
      <input type="file" @change="handleFileUpload2($event, 'attachmentUrl')" class="bg-blue-500 text-white font-bold py-2 px-4 rounded">
    </div>
    <div><strong class="font-bold">แก้ไขไฟล์แนบประวัติซ่อม:</strong>
      <input type="file" @change="handleFileUpload2($event, 'attachmentUrl2')" class="bg-blue-500 text-white font-bold py-2 px-4 rounded">
    </div>
  </div>

  
        <div class="bg-white shadow mb-4 p-4">
          <!-- เพิ่มปุ่มอนุมัติที่จะแสดงเมื่อมาจากหน้า ApprovePO.vue -->
<button v-if="$route.query.fromApprovePO && detail.status_pr === 'รอผู้จัดการฝ่ายจัดซื้อ' && detail.status_pr !== 'รอการสั่งซื้อ' && user.agency === 1 && user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' && !isEditing" @click="approvePR('ไม่อนุมัติ', '')" class="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      ไม่อนุมัติ
    </button>
          <h3 class="text-xl font-bold text-gray-700">รายการสินค้า</h3>               <br><br>
                      <!-- ปุ่มเพิ่มสินค้า -->
 <!-- <button v-if="((detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข' && user.position_lo !== 'ธุรการซ่อมบำรุง') || detail.status_pr === 'รอธุรการซ่อมแก้ไข') && (user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' || user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' || user.position_lo === 'ธุรการซ่อมบำรุง')" -->
 <button v-if="(detail.status_pr === 'รอแก้ไข')"         
 @click="openProductModal" 
          class="bg-blue-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 mt-4">
    เพิ่มสินค้า
  </button><br><br>
  


          <table class="min-w-full table-auto">

            <thead class="bg-gray-100">
              <tr>
               
                <!-- <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider"></th> -->
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ลำดับ</th>
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รหัสสินค้า</th>
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รายการสินค้า</th>
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทสินค้า</th>
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวน</th>
               
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">หน่วยนับ</th>
                <!-- <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ราคาต่อหน่วย</th>
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวนเงิน</th> -->
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">คงเหลือสั่งซื้อ</th>
                <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ดูไฟล์ประกอบ</th>
                <th v-if="user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ' " class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ขอแก้ไข</th>
                <th v-if="user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง'" class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">อนุมัติการแก้ไข</th>
                <th v-if="isEditingProduct && user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' || user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' && detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข'" class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">แก้ไข/ลบ</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(item, index) in detail.items" :key="index">
                <!-- Checkbox for each item -->
                <!-- <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">
  <input type="checkbox" v-if="user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' && detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข'" v-model="selectedItemsCheckbox" @change="handleCheckboxChange(item)">
</td> -->

                <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-900 text-center">{{ index + 1 }}</td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ item.productCode }}</td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ item.productName }}</td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ item.productType }}</td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">
   <!-- <span v-if="user.position_lo === 'ธุรการซ่อมบำรุง' || (detail.status_pr !== 'รอหัวหน้าอนุมัติ(หน่วยงาน)' && detail.status_pr !== 'รอผู้จัดการอนุมัติ' && detail.status_pr !== 'รอผู้จัดการซ่อมแก้ไข')">===================================================================-->
    <span v-if="detail.status_pr === 'รอแก้ไข'">
     
    {{ item.quantity }}
        </span>

            <input 
              v-else-if="user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' || user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง'|| user.position_lo === 'ธุรการซ่อมบำรุง' || user.position_lo === 'ผู้จัดการลานกระบือ'" 
          
              v-model.number="item.quantity" 
              disabled
              @input="handleInputChange(item)" 
              type="number" 
              min="1" 
              class="form-input rounded-md shadow-sm mt-1 block w-full">
            <span v-else>{{ item.quantity }}</span>
</td>


                <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ item.unit }}</td>
                <!-- <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ item.unitPrice }}</td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ item.totalPrice }}</td> -->
                <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center bg-red-100">{{ calculateRemainingOrder(item) }}</td>
          <!-- ปุ่มอัพโหลดไฟล์ -->
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">
    <template v-if="isEditingProduct && user.agency === 1 && (user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' || user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' || user.position_lo === 'ธุรการซ่อมบำรุง')">
      <input type="file" @change="handleFileUpload($event, item)" class="form-input rounded-md shadow-sm mt-1 block w-full">
    </template>
    <template v-else-if="item.isNew">
      <input type="file" @change="handleFileUpload($event, item)" class="form-input rounded-md shadow-sm mt-1 block w-full">
    </template>
    <template v-else>
      <a :href="transformAttachmentUrl(item.productAttachmentUrl)" target="_blank" 
         class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
         <i class="fas fa-eye"></i> <!-- แทนคำว่า 'ดู' ด้วยไอคอน -->
      </a>
    </template>
  </td>
            <!-- ปุ่มแก้ไข/ลบ สำหรับผู้จัดการฝ่ายซ่อมบำรุง  ===============================================================================================================-->
  <!-- ปุ่มลบ -->
  <!-- <td v-if="((detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข' && user.position_lo !== 'ธุรการซ่อมบำรุง') || detail.status_pr === 'รอธุรการซ่อมแก้ไข') && (user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' || user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' || user.position_lo === 'ธุรการซ่อมบำรุง')" -->
  <td v-if="(detail.status_pr === 'รอแก้ไข')"
  
  class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">
    <button @click="confirmDeleteItem(index, item.itemId)" 
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
      ลบ
    </button>
  </td>

  <!-- <td v-if="(detail.status_pr === 'รอธุรการซ่อมแก้ไข' && (user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' || user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' || user.position_lo === 'ธุรการซ่อมบำรุง')) || (detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข' && user.position_lo !== 'ธุรการซ่อมบำรุง' && (user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' || user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง'))" -->
  <td v-if="(detail.status_pr === 'รอแก้ไข' )"
   
  class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">
    <button @click="openEditQuantityModal(index)" 
            class="bg-green-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      แก้ไขรายการ
    </button>
  </td>
  
 <!-- ปุ่มขอแก้ไข -->
 <td v-if="user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ' && detail.status_pr === 'อยู่ระหว่างการสั่งซื้อ' && calculateRemainingOrder(item) > 0" class="px-6 py-4 text-center">
            <button class="bg-blue-500 text-white px-4 py-2 rounded" @click="openEditModal(item)">ขอแก้ไข</button>
          </td>

          <!-- ปุ่มยืนยัน -->
   <td v-if="user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' && item.pendingApproval > 0" class="px-6 py-4 text-center">
            <button class="bg-blue-500 text-white px-4 py-2 rounded" @click="openModal(item)">ดูรายละเอียด</button>
          </td>

          <!-- <td v-if="user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' && item.pendingApproval > 0" class="px-6 py-4 text-center">
            <button class="bg-green-500 text-white px-4 py-2 rounded" @click="confirmChange(item, true)">ยืนยัน</button>
            <button class="bg-red-500 text-white px-4 py-2 rounded ml-2" @click="confirmChange(item, false)">ยกเลิก</button>
          </td> -->
              </tr>
              <tr v-if="detail.items.length === 0">
                <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500 text-center" colspan="9">ไม่พบข้อมูล</td>
              </tr>
            </tbody>
          </table>
          <!-- ปุ่มบันทึกการแก้ไข -->
<!-- <button v-if="canSaveChanges" @click="saveQuantities" class="bg-purple-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
  บันทึกการแก้ไข
</button> -->

<br>
<!-- ปุ่มบันทึกการแก้ไข -->
<button v-if="canSaveChanges" 
        @click="saveQuantities" 
        class="bg-purple-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
  บันทึกการแก้ไข
</button>
          <div v-if="detail.status_pr === 'รอตรวจสอบ' && agency === 1 && user.position_lo !== 'ผู้จัดการฝ่ายซ่อมบำรุง' && user.position_lo !== 'หัวหน้าซ่อมบำรุงส่วนกลาง'" class="mt-4 flex justify-end">
    <button 
      v-if="canCancelPR" 
      @click="cancelPR" 
      class="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      ยกเลิกใบ PR
    </button>
  </div>


<!-- ปุ่มยกเลิกใบ PR -->
<div class="mt-4 flex justify-end">
<button 
  v-if="canCancelPR" 
  @click="openCancelPRModal" 
  class="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
  ยกเลิกใบ PR
</button>
</div>

<!-- Modal สำหรับเหตุผลในการยกเลิกใบ PR -->
<div v-if="showCancelPRModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เหตุผลในการยกเลิกใบ PR</h3>
            <div class="mt-2">
              <textarea v-model="cancelReason" class="w-full p-2 border rounded" rows="4" placeholder="กรุณาใส่เหตุผลในการยกเลิกใบ PR"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeCancelPRModal">ยกเลิก</button>
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-red-600 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelPR">ยกเลิกใบ PR</button>
      </div>
    </div>
  </div>
</div>
<div v-if="detail.status_pr === 'รอเจ้าหน้าที่ธุรการจัดซื้อ' && user.agency === 1 && user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ' && !isEditingProduct && hasSelectedItemsForRevision">
    
  <button @click="confirmRevision" class="bg-orange-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      ส่งกลับแก้ไข
    </button>
    <textarea v-model="revisionReason" class="form-textarea mt-1 block w-full" placeholder="กรุณาใส่เหตุผลในการส่งกลับแก้ไข"></textarea>
  </div>


  <div v-if="canClosePR && agency === 1" class="mt-4 flex justify-end">
    <button @click="closePR" class="bg-orange-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
      ปิดใบ PR
    </button>
  </div>
        </div>
      </div>
    </div>

    <!-- <div class="bg-white shadow mb-4 p-4">
  <div class="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
    <div>
      <label for="totalAmount" class="block text-sm font-medium text-gray-700">รวมเงินทั้งสิ้น</label>
      <input v-model="form.totalAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="totalAmount" readonly>
    </div>
    <div>
      <label for="discountPercent" class="block text-sm font-medium text-gray-700">ส่วนลด (%)</label>
      <input v-model="form.discountPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50" id="discountPercent" readonly>
    </div>
    <div>
      <label for="amountAfterDiscount" class="block text-sm font-medium text-gray-700">ราคาหลังหักส่วนลด</label>
      <input v-model="form.amountAfterDiscount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-orange-50" id="amountAfterDiscount" readonly>
    </div>
    <div>
      <label for="vatPercent" class="block text-sm font-medium text-gray-700">ภาษีมูลค่าเพิ่ม (%)</label>
      <input v-model="form.vatPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" id="vatPercent" readonly>
    </div>
    <div>
      <label for="vatAmount" class="block text-sm font-medium text-gray-700">คิดเป็นจำนวนเงิน</label>
      <input v-model="form.vatAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" id="vatAmount" readonly>
    </div>
    <div>
      <label for="grandTotal" class="block text-sm font-medium text-gray-700">เป็นเงินทั้งสิ้น</label>
      <input v-model="form.grandTotal" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="grandTotal" readonly>
    </div>
  </div>
</div> -->
 <!-- Modal -->
 <div v-if="showBatchModal" class="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
      <div class="bg-white p-4 rounded shadow-lg w-1/2">
        <h3 class="text-lg font-bold">{{ confirmAction ? 'ยืนยันการแก้ไขหลายรายการ' : 'ยกเลิกการแก้ไขหลายรายการ' }}</h3>
        <p>จำนวนรายการที่เลือก: {{ selectedItems.length }}</p>
        <div class="flex justify-end mt-4">
          <button class="bg-gray-500 text-white px-4 py-2 rounded mr-2" @click="closeBatchModal">ปิด</button>
          <button 
            :class="confirmAction ? 'bg-green-500' : 'bg-red-500'"
            class="text-white px-4 py-2 rounded"
            @click="processBatchConfirmation"
          >
            {{ confirmAction ? 'ยืนยัน' : 'ยกเลิก' }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="showModal" class="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
        <div class="bg-white p-4 rounded shadow-lg w-2/3">
          <h3 class="text-lg font-bold mb-4">รายละเอียดคำขอแก้ไข</h3>
          
          <!-- Current Product Info -->
          <div class="mb-4 p-4 bg-gray-50 rounded">
            <h4 class="font-semibold mb-2">ข้อมูลปัจจุบัน</h4>
            <div class="grid grid-cols-2 gap-4">
              <p>รหัสสินค้า: {{ selectedItem?.productCode }}</p>
              <p>ชื่อสินค้า: {{ selectedItem?.productName }}</p>
              <p>ประเภท: {{ selectedItem?.productType }}</p>
              <p>จำนวน: {{ selectedItem?.quantity }}</p>
            </div>
          </div>

          <div class="mb-4 p-4 bg-blue-50 rounded">
  <h4 class="font-semibold mb-2">ข้อมูลที่ขอแก้ไข</h4>
  <div class="grid grid-cols-2 gap-4">
    <template v-if="newProduct">
      <p><strong>รหัสสินค้าใหม่:</strong> {{ newProduct.product_code }}</p>
      <p><strong>ชื่อสินค้าใหม่:</strong> {{ newProduct.product_name }}</p>
      <p><strong>ประเภทใหม่:</strong> {{ newProduct.product_type }}</p>
      <p><strong>ยี่ห้อ:</strong> {{ newProduct.brand }}</p>
      <p><strong>ประเภทยานพาหนะ:</strong> {{ newProduct.vehicle_type }}</p>
      <p><strong>ประเภทชิ้นส่วน:</strong> {{ newProduct.part_type }}</p>
    </template>
    <template v-else>
      <p>ใช้สินค้าเดิม</p>
    </template>
    <p><strong>จำนวนที่ขอแก้ไข:</strong> {{ selectedItem?.newQuantity }}</p>
    <p class="col-span-2"><strong>เหตุผล:</strong> {{ selectedItem?.revisionReason }}</p>
  </div>
</div>
          <div class="flex justify-end mt-4 space-x-2">
            <button class="bg-gray-500 text-white px-4 py-2 rounded" @click="closeModal">ปิด</button>
            <button v-if="user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง'" 
                    class="bg-green-500 text-white px-4 py-2 rounded"
                    @click="confirmChange(selectedItem, true)">
              ยืนยัน
            </button>
            <button v-if="user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง'"
                    class="bg-red-500 text-white px-4 py-2 rounded"
                    @click="confirmChange(selectedItem, false)">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>

 <!-- Modal แก้ไขจำนวนสินค้าหลายรายการ -->
  
<!-- <div v-if="showEditModal" 
     class="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
  <div class="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
    <h3 class="text-lg font-bold mb-4">ขอแก้ไขจำนวนสินค้า</h3>
    
    <div class="space-y-4">

      <div class="max-h-96 overflow-y-auto">
        <div v-for="item in selectedItems" :key="item.itemId" class="border-b pb-4 mb-4">
          <div class="flex items-center justify-between">
            <div class="flex-1">
              <label class="block text-sm font-medium text-gray-700">สินค้า</label>
              <p class="mt-1">{{ item.productName }}</p>
            </div>
            <button 
              @click="removeItem(item.itemId)"
              class="text-red-500 hover:text-red-700"
            >
              <span class="sr-only">ลบ</span>
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div class="mt-2">
            <label class="block text-sm font-medium text-gray-700">จำนวนสินค้าที่ต้องการแก้ไข</label>
            <div class="mt-1 relative">
              <input 
                v-model.number="item.newQuantity"
                type="number"
                min="0"
                :max="item.maxQuantity"
                class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="กรอกจำนวนสินค้า" />
            </div>
          </div>

          <div class="mt-2">
            <label class="block text-sm font-medium text-gray-700">เหตุผลในการแก้ไข</label>
            <textarea 
              v-model="item.reason"
              class="form-textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows="2"
              placeholder="กรุณาระบุเหตุผลในการแก้ไข"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between mt-6">
      <button 
        class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition duration-200"
        @click="submitAllChanges"
        :disabled="!isValidForm">
        ส่งคำขอทั้งหมด
      </button>
      <div class="space-x-3">
        <button 
          class="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-200"
          @click="closeModal">
          ยกเลิก
        </button>
        <button 
          class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-200"
          @click="submitChangeRequest"
          :disabled="!isValidForm">
          บันทึก
        </button>
      </div>
</div>
</div>
</div> -->
<div v-if="showEditModal" class="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
  <div class="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
    <h3 class="text-lg font-bold mb-4">ขอแก้ไขจำนวนสินค้า</h3>

    <div class="space-y-4 max-h-96 overflow-y-auto">
      <div v-for="item in selectedItems" :key="item.itemId" class="border-b pb-4 mb-4">
        <div class="flex items-center justify-between">
          <div class="flex-1">
            <label class="block text-sm font-medium text-gray-700">สินค้า</label>
            <p class="mt-1">{{ item.productName }}</p>
            <button @click="openProductSelectionModal(item)" class="text-blue-500 hover:underline">
              เปลี่ยนอะไหล่สินค้า
            </button>
          </div>
          <button @click="removeItem(item.itemId)" class="text-red-500 hover:text-red-700">
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div class="mt-2">
          <label class="block text-sm font-medium text-gray-700">จำนวนสินค้าที่ต้องการแก้ไข</label>
          <input
            v-model.number="item.newQuantity"
            type="number"
            min="0"
            :max="item.maxQuantity"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="กรอกจำนวนสินค้า"
          />
        </div>

        <div class="mt-2">
          <label class="block text-sm font-medium text-gray-700">เหตุผลในการแก้ไข</label>
          <textarea
            v-model="item.reason"
            class="form-textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            rows="2"
            placeholder="กรุณาระบุเหตุผลในการแก้ไข"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="flex justify-between mt-6">
      <button @click="submitAllChanges" :disabled="!isValidForm" class="bg-green-500 text-white px-4 py-2 rounded">
        ส่งคำขอทั้งหมด
      </button>
      <button @click="closeModal" class="bg-gray-500 text-white px-4 py-2 rounded">ยกเลิก</button>
    </div>
  </div>
</div>

<!-- Modal สำหรับเลือกสินค้า -->
<div v-if="showProductSelectionModal" class="fixed z-60 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
    <!-- Background overlay -->
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>

    <!-- Modal content -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full w-full h-screen sm:h-4/5 max-h-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เลือกสินค้า</h3>

            <!-- Search Fields -->
            <div class="mt-4">
              <div class="flex flex-row space-x-4 mb-4">
                <input
                  v-model="productSearchQuery"
                  @input="fetchProducts"
                  type="text"
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  placeholder="ค้นหาจากชื่อสินค้า"
                />
                <input
                  v-model="productTypeSearchQuery"
                  @input="fetchProducts"
                  type="text"
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  placeholder="ค้นหาจากประเภทสินค้า"
                />
                <input
                  v-model="productBrandSearchQuery"
                  @input="fetchProducts"
                  type="text"
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  placeholder="ค้นหาจากยี่ห้อ"
                />
              </div>

              <!-- Table of Products -->
              <table class="min-w-full divide-y divide-gray-200 mt-4">
                <thead>
                  <tr>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รหัสสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ยี่ห้อ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทพาหนะ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทอะไหล่</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ราคา/หน่วย</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลือก</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="product in paginatedFilteredProducts" :key="product.product_id">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium text-gray-900">{{ product.product_name }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_code }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.brand }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.vehicle_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.part_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.price_per_unit }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium">
                      <button
                        @click="selectProductForItem(product)"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        เลือก
                      </button>
                    </td>
                  </tr>
                  <tr v-if="paginatedFilteredProducts.length === 0">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="8">ไม่พบข้อมูล</td>
                  </tr>
                </tbody>
              </table>

              <!-- Pagination Controls -->
              <div class="mt-4 flex justify-between">
                <button
                  @click="prevProductPage"
                  :disabled="currentProductPage === 1"
                  class="px-4 py-2 bg-gray-300 rounded-md"
                >
                  ก่อนหน้า
                </button>
                <span>หน้าที่ {{ currentProductPage }} จาก {{ totalProductPages }}</span>
                <button
                  @click="nextProductPage"
                  :disabled="currentProductPage === totalProductPages"
                  class="px-4 py-2 bg-gray-300 rounded-md"
                >
                  ถัดไป
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            @click="closeProductSelectionModal"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            ปิด
          </button>
        </div>
      </div>
    </div>
  </div>
</div>






<!-- Modal แก้ไขจำนวนสินค้า -->
<div v-if="showEditQuantityModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">แก้ไขจำนวนสินค้า</h3>
            <div class="mt-2">
              <input v-model.number="editingItem.quantity" type="number" min="1" class="form-input rounded-md shadow-sm mt-1 block w-full">
            </div>
             <!-- ฟิลด์อัปโหลดรูปภาพ -->
             <div class="mt-4">
              <label class="block text-sm font-medium text-gray-700">แนบรูปสินค้าใหม่</label>
              <input type="file" @change="uploadProductImage($event, editingItem)" accept="image/*" class="form-input rounded-md shadow-sm mt-1 block w-full">
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="updateItemQuantity" type="button" class="w-full inline-flex justify-center rounded-md border border-green-600 shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
          บันทึก
        </button>
        <button @click="closeEditQuantityModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</div>
    <!-- Modal สำหรับเลือกสินค้า -->
    <div v-if="showProductModal" class="fixed z-10 inset-0 overflow-y-auto">
  <!-- Content of the modal -->
  <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full w-full h-screen sm:h-4/5 max-h-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เลือกสินค้า</h3>
            <div class="mt-4">
              <!-- Search fields for product search -->
              <div class="flex flex-row space-x-4 mb-4">
                <input 
                  v-model="productSearchQuery" 
                  @input="fetchProducts" 
                  type="text" 
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                  placeholder="ค้นหาจากชื่อสินค้า">
                <input 
                  v-model="productTypeSearchQuery" 
                  @input="fetchProducts" 
                  type="text" 
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                  placeholder="ค้นหาจากประเภทสินค้า">
                <input 
                  v-model="productBrandSearchQuery" 
                  @input="fetchProducts" 
                  type="text" 
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                  placeholder="ค้นหาจากยี่ห้อ">
              </div>

              <!-- Table for displaying product search results -->
              <table class="min-w-full divide-y divide-gray-200 mt-4">
                <thead>
                  <tr>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รหัสสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ยี่ห้อ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทพาหนะ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทอะไหล่</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ราคา/หน่วย</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลือก</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="product in paginatedFilteredProducts" :key="product.product_id">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium text-gray-900">{{ product.product_name }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_code }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.brand }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.vehicle_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.part_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.price_per_unit }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium">
                      <button 
                        @click="selectProducts(product)" 
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        เลือก
                      </button>
                    </td>
                  </tr>
                  <tr v-if="paginatedFilteredProducts.length === 0">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="8">ไม่พบข้อมูล</td>
                  </tr>
                </tbody>
              </table>

              <!-- Pagination controls -->
              <div class="mt-4 flex justify-between">
                <button @click="prevProductPage" :disabled="currentProductPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                <span>หน้าที่ {{ currentProductPage }} จาก {{ totalProductPages }}</span>
                <button @click="nextProductPage" :disabled="currentProductPage === totalProductPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="showProductModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            ปิด
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white shadow mb-4 p-4">
  <h3 class="text-xl font-bold text-gray-700">รายการใบสั่งซื้อ (PO)</h3>
  <br><br>
  <table class="min-w-full table-auto">
    <thead class="bg-gray-100">
      <tr>
        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ลำดับ</th>
        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">วันที่เปิดใบสั่งซื้อ</th>
        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">เลขที่ใบสั่งซื้อ</th>
        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวนที่สั่งซื้อ</th>
        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">สถานะ</th>
        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">Action</th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr v-for="(order, index) in purchaseOrders" :key="index">
  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-900 text-center">{{ index + 1 }}</td>
  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ formatDate(order.po_date) }}</td>
  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ order.po_number }}</td>
  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500 text-center">{{ order.total_quantity }}</td>
  <td 
  class="px-6 py-4 whitespace-no-wrap text-sm text-center"
  :class="{
    'bg-red-500 text-white': order.status === 'ไม่อนุมัติ',
    'bg-orange-500 text-white': order.status === 'ยกเลิกใบสั่งซื้อ',
    'bg-purple-500 text-white': order.status === 'รอการสั่งซื้อ',
    'bg-pink-500 text-black': order.status === 'รอรับอะไหล่',
    'bg-green-500 text-white': order.status === 'รับอะไหล่แล้ว'
  }"
>
  {{ order.status }}
</td>
<button @click="goToPODetail(order.po_id)" class="bg-blue-500 text-white font-bold py-1 px-3 rounded">
    ดูรายละเอียด
  </button>
</tr>
      <tr v-if="purchaseOrders.length === 0">
        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500 text-center" colspan="4">ไม่พบข้อมูล</td>
      </tr>
    </tbody>
  </table>
</div>
  </div>






<!-- Modal สำหรับเลือกสินค้า -->
  <!-- Modal สำหรับเลือกสินค้า -->
  <!-- <div v-if="showProductModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full w-full h-screen sm:h-4/5 max-h-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  เลือกสินค้า
                </h3>
                <div class="mt-4">
                  <div class="flex flex-row space-x-4 mb-4">
                    <input 
                      v-model="productSearchQuery" 
                      @input="fetchProducts" 
                      type="text" 
                      class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                      placeholder="ค้นหาจากชื่อสินค้า">
                    <input 
                      v-model="productTypeSearchQuery" 
                      @input="fetchProducts" 
                      type="text" 
                      class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                      placeholder="ค้นหาจากประเภทสินค้า">
                    <input 
                      v-model="productBrandSearchQuery" 
                      @input="fetchProducts" 
                      type="text" 
                      class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                      placeholder="ค้นหาจากยี่ห้อ">
                  </div>

                  <table class="min-w-full divide-y divide-gray-200 mt-4">
                    <thead>
                      <tr>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อสินค้า</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รหัสสินค้า</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทสินค้า</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ยี่ห้อ</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทพาหนะ</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทอะไหล่</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ราคา/หน่วย</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลือก</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="product in paginatedFilteredProducts" :key="product.product_id">
                        <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium text-gray-900">{{ product.product_name }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_code }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_type }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.brand }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.vehicle_type }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.part_type }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.price_per_unit }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium">
                          <button 
                            @click="selectProduct(product)" 
                            class="text-indigo-600 hover:text-indigo-900"
                          >
                            เลือก
                          </button>
                        </td>
                      </tr>
                      <tr v-if="paginatedFilteredProducts.length === 0">
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="8">ไม่พบข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-4 flex justify-between">
                    <button @click="prevProductPage" :disabled="currentProductPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                    <span>หน้าที่ {{ currentProductPage }} จาก {{ totalProductPages }}</span>
                    <button @click="nextProductPage" :disabled="currentProductPage === totalProductPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showProductModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div> -->

 <!-- Modal สำหรับแสดงเหตุผลการแก้ไข -->
 <div v-if="showRevisionReasonModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">เหตุผลการแก้ไข</h3>
            <div class="mt-4">
              <p class="text-sm text-red-500 font-bold">{{ detail.revision_reasons }}</p>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showRevisionReasonModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>


   <!-- Modal สำหรับเปิดใบสั่งซื้อ -->
    <div v-if="showCreatePOModal" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full h-4/5 w-full max-w-5xl" style="max-height: 80vh;max-width: 85%; overflow-y:auto;">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                รายการสั่งซื้อ
              </h3>
 
              <div class="mt-2">
                  <div class="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <div class="mb-4">
                      <label class="block text-gray-700 text-sm font-bold mb-2">รหัสใบสั่งซื้อ:</label>
                      <input v-model="newPurchaseOrderNumber" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" readonly>
                    </div>
                  <div class="mb-4">
                      <label class="block text-gray-700 text-sm font-bold mb-2">วันที่:</label>
                      <input v-model="newPurchaseOrderDate" type="date" class="form-input rounded-md shadow-sm mt-1 block w-full">
                  </div>
                  
                  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2">จำนวนเครดิต (วัน):</label>
    <input 
      v-model.number="form.creditTerms" 
      type="number" 
      class="form-input rounded-md shadow-sm mt-1 block w-full" 
      placeholder="จำนวนเครดิต (วัน)" 
      @input="enforceMin" 
    />
  </div>
  <!-- เพิ่มก่อนปุ่ม createPO -->
<div class="mb-4">
  <label class="block text-gray-700 text-sm font-bold mb-2">หมายเหตุ:</label>
  <textarea 
    v-model="form.remarks" 
    class="form-textarea rounded-md shadow-sm mt-1 block w-full" 
    rows="3"
    placeholder="กรอกหมายเหตุหรือรายละเอียดเพิ่มเติม (ถ้ามี)"
  ></textarea>
</div>
                  </div>
                  <table class="min-w-full table-auto">
                    <thead class="bg-gray-100">
                      <tr>
                        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">เลือก</th>
                        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รายการสินค้า</th>
                        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทสินค้า</th>
                        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวนที่สั่งซื้อ</th>
                        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">หน่วยนับ</th>
                        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ราคาต่อหน่วย</th>
                        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวนเงิน</th>
                        <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">คงเหลือสั่งซื้อ</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(item, index) in detail.items" :key="index">
                        <td class="px-6 py-4 bg-orange-100 font-bold whitespace-no-wrap text-sm text-black-500 text-center">
          <input type="checkbox" v-model="item.selected" v-if="calculateRemainingOrder(item) > 0">
        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.productName }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.productType }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap  text-sm text-black-500">
                          <input 
  v-model.number="item.orderQuantity" 
  type="number" 
  class="form-input bg-orange-100 font-bold mt-1 block w-full" 
  :max="calculateRemainingOrder(item)" 
  @input="item.orderQuantity = Math.min(calculateRemainingOrder(item), Math.max(0.01, item.orderQuantity))" 
  min="0.01" 
  step="0.01" 
>
                      </td>

                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.unit }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
                          <input 
        v-model.number="item.unitPrice" 
        type="number" 
        class="form-input mt-1 bg-orange-100 font-bold block w-full" 
        @input="item.unitPrice = item.unitPrice <= 0 ? 1 : item.unitPrice"
        @blur="item.unitPrice = item.unitPrice || 1" 
        min="1"
      >
                      </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ (item.orderQuantity * item.unitPrice).toFixed(2) }}</td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ calculateRemainingOrder(item) }}</td>
                      </tr>
                      <tr v-if="detail.items.length === 0">
                        <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500 text-center" colspan="6">ไม่พบข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="mt-2 grid grid-cols-1 ">
                  <!-- ซ้าย: เทียบราคาสินค้า -->
                   <br>
                  <button
      v-if="user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ'"
      @click="showMappingFormModal = true"
      class="bg-blue-500 text-white py-2 px-4 rounded"
    >
      แมปรหัสสินค้า
    </button>
<br>

                  <!-- <div class="compare-price border shadow p-4 rounded" style="flex: 0 0 100%;">
                    <h4 class="text-lg font-semibold">เทียบราคาสินค้า</h4> <br>
                    <div class="mb-4">
                      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหาชื่อสินค้า:</label>
                      <input v-model="searchProductName" @input="debouncedFetchComparePrices" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full">
                    </div>
                    <div class="mb-4">
                      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหารหัสสินค้า:</label>
                      <input v-model="searchProductCode" @input="debouncedFetchComparePrices" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full">
                    </div>
                    <div class="mb-4">
                      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหาประเภทอะไหล่:</label>
                      <select v-model="searchPartType" @change="fetchComparePrices" class="form-select mt-1 block w-full">
                        <option value="">-- กรุณาเลือกประเภทอะไหล่ --</option>
                        <option v-for="partType in partTypes" :key="partType.type_name" :value="partType.type_name">
                          {{ partType.type_name }}
                        </option>
                      </select>
                    </div>
                    <div class="mb-4">
                      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหาประเภทสินค้า:</label>
                      <select v-model="searchProductType" @change="fetchComparePrices" class="form-select mt-1 block w-full">
                        <option value="">-- กรุณาเลือกประเภทสินค้า --</option>
                        <option v-for="productType in productTypes" :key="productType.type_name" :value="productType.type_name">
                          {{ productType.type_name }}
                        </option>
                      </select>
                    </div>
                    <div class="mb-4">
                      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหายี่ห้อ:</label>
                      <select v-model="searchBrand" @change="fetchComparePrices" class="form-select mt-1 block w-full">
                        <option value="">-- กรุณาเลือกยี่ห้อ --</option>
                        <option v-for="brand in brands" :key="brand.brand_name" :value="brand.brand_name">
                          {{ brand.brand_name }}
                        </option>
                      </select>
                    </div>
                    <div class="mb-4">
                      <label class="block text-gray-700 text-sm font-bold mb-2">คู่ค้า:</label>
                      <multiselect 
      v-model="searchSupplierId"
      :options="suppliers"
      label="company_name"
      track-by="supplier_id"
      placeholder="-- กรุณาเลือกคู่ค้า --"
      @input="fetchComparePrices"
      :searchable="true"
      :close-on-select="true"
      :allow-empty="true"
      class="form-select mt-1 block w-full"
    />
                    </div>
                    <table class="min-w-full table-auto mt-4">
                      <thead class="bg-gray-100">
                        <tr>
                          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">วันที่</th>
                          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ชื่อสินค้า</th>
                          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รหัสสินค้า</th>
                          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทอะไหล่</th>
                          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทสินค้า</th>
                          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ยี่ห้อ</th>
                          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ราคาต่อหน่วย</th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(item, index) in paginatedComparePrices" :key="index">
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ formatDate(item.po_date) }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_name }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_code }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.part_type }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_type }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.brand }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.unit_price }}</td>
                        </tr>
                        <tr v-if="paginatedComparePrices.length === 0">
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500 text-center" colspan="7">ไม่พบข้อมูล</td>
                        </tr>
                      </tbody>
                    </table>
      <div class="mt-4 flex justify-between items-center">
        <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">Next</button>
      </div>
</div> -->
                <!-- ขวา: ฟิลด์สำหรับใบสั่งซื้อ -->
                <div class="order-summary border shadow p-4 rounded" style="flex: 0 0 100%;">
                 
                  <div class="mb-4">
                     <label class="block text-gray-700 text-sm font-bold mb-2">คู่ค้า:</label>

                     <button 
          @click="showAddSupplierModal"
          class="inline-flex items-center text-sm text-blue-600 hover:text-blue-700"
        >
          <i class="fas fa-plus-circle mr-1"></i>
          เพิ่มคู่ค้า
        </button>
        <multiselect
          v-model="selectedSupplier"
          :options="supplierOptions"
          label="company_name"
          track-by="supplier_id"
          placeholder="-- กรุณาเลือกคู่ค้า --"
          :searchable="true"
          :close-on-select="true"
          :allow-empty="true"
          class="form-select mt-1 block w-full"
          @select="onSupplierSelect"
        />
    </div>
   <!-- Add Supplier Modal -->
   <AddSupplier 
      v-if="isAddSupplierModalVisible"
      @close="closeAddSupplierModal"
      @supplierAdded="onSupplierAdded"
    />
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2">เลขประจำตัวผู้เสียภาษี:</label>
      <!-- ใช้ computed property taxId -->
      <input 
        v-model="taxId" 
        type="text" 
        class="form-input rounded-md shadow-sm mt-1 block w-full" 
        readonly
      />
       <!-- Modal สำหรับแสดง MappingForm -->
    <div v-if="showMappingFormModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded shadow-lg w-full max-w-3xl">
        <!-- Component MappingForm -->
        <MappingForm @close="closeModalMap" />
        <button @click="closeModalMap" class="mt-4 bg-red-500 text-white px-4 py-2 rounded">
          ปิด
        </button>
      </div>
    </div>
    </div>
     
       
  
                  <!-- <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2">สถานที่รับอะไหล่:</label>
      <select v-model="selectedWarehouse" class="form-select mt-1 block w-full">
        <option v-for="warehouse in warehouses" :key="warehouse.warehouse_id" :value="warehouse.warehouse_id">
          {{ warehouse.name }}
        </option>
      </select>
                  </div> -->
                  <div class="mb-4">
  <label class="block text-gray-700 text-sm font-bold mb-2">สถานที่รับอะไหล่:</label>
  <input type="text" v-model="selectedWarehouseName" class="form-input mt-1 block w-full" disabled />
</div>
                  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทราคา:</label>
    <select v-model="form.priceType" class="form-select mt-1 block w-full">
      <option value="0">ไม่มี VAT</option>
      <option value="1">รวม VAT</option>
      <option value="2">แยก VAT</option>
    </select>
  </div>
  <div class="bg-white shadow mb-4 p-4">
      <div class="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div class="total-amount">
          <label for="totalAmount" class="block text-lg font-bold text-gray-700">รวมเงินทั้งสิ้น</label>
          <input v-model="form.totalAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50 text-lg" id="totalAmount" readonly>
        </div>
        <div class="discount-percent">
          <label for="discountPercent" class="block text-lg font-bold text-gray-700">ส่วนลด (%)</label>
          <input v-model="form.discountPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50 text-lg" id="discountPercent" @input="calculateAmounts">
        </div>
        <div class="discount-amount">
          <label for="discountAmount" class="block text-lg font-bold text-gray-700">ส่วนลดเป็นเงิน</label>
  <input 
    v-model="form.discountAmount" 
    @input="calculateDiscountPercent" 
    type="number" 
    class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50 text-lg" 
    id="discountAmount" 
    :max="form.totalAmount" 
    step="0.01"
  >
      </div>
        <div class="amount-after-discount">
          <label for="amountAfterDiscount" class="block text-lg font-bold text-gray-700">ราคาหลังหักส่วนลด</label>
          <input v-model="form.amountAfterDiscount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-orange-50 text-lg" id="amountAfterDiscount" readonly>
        </div>
        <div class="vat-percent">
          <label for="vatPercent" class="block text-lg font-bold text-gray-700">ภาษีมูลค่าเพิ่ม (%)</label>
          <input v-model="form.vatPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full text-lg" id="vatPercent" @input="calculateAmounts">
        </div>
        <div class="vat-amount">
          <label for="vatAmount" class="block text-lg font-bold text-gray-700">คิดเป็นจำนวนเงิน</label>
          <input v-model="form.vatAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full text-lg" id="vatAmount" readonly>
        </div>
        <div class="grand-total">
          <label for="grandTotal" class="block text-lg font-bold text-gray-700">เป็นเงินทั้งสิ้น</label>
          <input v-model="form.grandTotal" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50 text-lg" id="grandTotal" readonly>
        </div>
        
  </div>
  <div class="mt-4 flex justify-end">
    <button 
      @click="createPO" 
      :disabled="loading" 
      class="bg-blue-500 text-white font-bold uppercase text-lg px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 flex items-center"
    >
      <span v-if="loading" class="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2"></span>
      {{ loading ? 'กำลังสร้างใบสั่งซื้อ...' : 'เปิดใบสั่งซื้อ' }} 
    </button>
  </div>
</div>
                  </div>
                </div>


                 
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showCreatePOModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal สำหรับเหตุผลในการอนุมัติ -->
    <div v-if="showConfirmModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <!-- <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เหตุผลในการอนุมัติ</h3> -->
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เหตุผลในการตรวจสอบ</h3>
            <div class="mt-2">
              <textarea v-model="revisionReason" class="w-full p-2 border rounded" rows="4" placeholder="กรุณาใส่เหตุผลในการตรวจสอบ"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeConfirmModal">ยกเลิก</button>
        <!-- <button type="button" class="w-full inline-flex justify-center rounded-md border border-blue-600 shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" @click="approvePR">อนุมัติ</button> -->
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-blue-600 shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" @click="approvePR">ยืนยัน</button>
      </div>
    </div>
  </div>




</div>
   <!-- Confirm Modal -->
   <div v-if="showConfirmModal2" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เหตุผลในการอนุมัติ</h3>
                <div class="mt-2">
                  <textarea v-model="revisionReason" class="w-full p-2 border rounded" rows="4" placeholder="กรุณาใส่เหตุผลในการอนุมัติ"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeConfirmModal">ยกเลิก</button>
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-emerald-600 shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:ml-3 sm:w-auto sm:text-sm" @click="sendManagerApproval">ยืนยัน</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal สำหรับการแสดงสถานะการอนุมัติ -->
<div v-if="showStatusApproveModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-bold text-gray-900" id="modal-title">เหตุผลการอนุมัติ</h3>
            <div class="mt-2"><br>
              <p><strong class="font-bold">หัวหน้าซ่อมบำรุงส่วนกลาง:</strong> {{ detail.status_approve1 }}</p>
              <p><strong class="font-bold">ผู้จัดการฝ่ายซ่อมบำรุง:</strong> {{ detail.status_approve2 }}</p>
              <p><strong class="font-bold">เจ้าหน้าที่ธุรการจัดซื้อ:</strong> {{ detail.status_approve3 }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeStatusApproveModal">ปิด</button>
      </div>
    </div>
  </div>
</div>

 <!-- Confirm Modal สำหรับเจ้าหน้าที่ธุรการจัดซื้อ -->
 <div v-if="showConfirmModal3" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เหตุผลในการดำเนินการต่อ</h3>
                <div class="mt-2">
                  <label for="revisionReason" class="block text-sm font-medium text-gray-700">เหตุผลการอนุมัติ</label>
                  <textarea id="revisionReason" v-model="revisionReason" class="w-full p-2 border rounded" rows="4" placeholder="กรุณาใส่เหตุผลในการดำเนินการต่อ"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeConfirmModal">ยกเลิก</button>
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-yellow-600 shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm" @click="sendPurchasingClerkApproval">ยืนยัน</button>

        </div>
      </div>
    </div>
  </div>
  
  <div>
  
  </div>

</template>



<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import debounce from 'lodash.debounce';
import Multiselect from 'vue-multiselect'; // อย่าลืมนำเข้า Multiselect
import AddSupplier from '@/views/Purchase/AddSupplier.vue'
import MappingForm from '@/components/Purchase/MappingForm.vue';
export default {
  components: {
    Multiselect, // นำเข้าและใช้ Multiselect ที่นี่
    AddSupplier,
    MappingForm,
    
  },
  props: {
    // รับค่า prId จาก parent component
    // prId: {
    //   type: String,
    //   required: true
    // }

  },
  data() {
  return {
    isAddSupplierModalVisible: false,
    activeSection: this.$route.query.activeSection || 'pr-own',
    socket: null,
    editingItem: null,
    showConfirmModal: false,
    showConfirmModal2: false,
    showConfirmModal3: false, // ตัวแปรสำหรับควบคุมการแสดง modal
    showEditQuantityModal: false,
    showStatusApproveModal: false,
    isLoading: false,
    newStatus: '',
    approveField: '',
    statusApproveField: '',
    showProductModal: false, // ตัวแปรสำหรับควบคุมการแสดง modal
    revisionReason: '', // เพิ่มฟิลด์สำหรับเหตุผลในการแก้ไข
    productSearchQuery: '', // คำค้นหาสำหรับชื่อสินค้า
    showRevisionReasonModal: false, // เพิ่มฟิลด์นี้
    productTypeSearchQuery: '', // คำค้นหาสำหรับประเภทสินค้า
    productBrandSearchQuery: '', // คำค้นหาสำหรับยี่ห้อสินค้า
    currentProductPage: 1, // หน้าปัจจุบันของการแสดงผลลัพธ์
    editingItemIndex: null,
    productsPerPage: 10,
    products: [],
    showModal: false,
      selectedItem: null,
    selectedProduct: null, // สินค้าที่เลือกไว้
    detail: {
      revision_reasons: '',
      pr_id: '',
      purchaseRequestNumber: '',
      purchaseType: '',
      requestDate: '',
      creatorName: '',
      departmentName: '',
      vehicleRegistration: '',
      vehicleCode: '',
      vehicleModel: '',
      vehicleVersion: '',
      vehicleChassisNumber: '',
      vehicleBrand: '',
      vehicleMileage: '',
      vehicleDepartment: '',
      repairOrder: '',
      receivingDepartment: '',
      attachmentUrl: '',  // เพิ่มฟิลด์นี้
      attachmentUrl2: '',
      status_pr: '',
      note_pr: '', // เพิ่มฟิลด์นี้
      details: '',
      status_approve1: '',
      status_approve2: '',
      status_approve3: '',
      items: [],
      warehouseName: '',
      warehouseId: '',
      pendingApproval: '',
    },
    isEditingProduct: false,
    purchaseOrders: [],
    form: {
      totalAmount: 0.00,
      discountPercent: 0,
      discountAmount: 0.00, // เพิ่มฟิลด์นี้
      amountAfterDiscount: 0.00,
      vatPercent: 7.00,  // สมมติว่า VAT 7%
      vatAmount: 0.00,
      grandTotal: 0.00,
      priceType: '0', // 0: ไม่มี VAT, 1: รวม VAT, 2: แยก VAT
      creditTerms: '',
      remarks: '',
      files: [],
      attachmentFiles: {},
      items: []
    },
    warehouses: [],
    suppliers: [],
    user: {}, // เพิ่ม user ที่จะเก็บข้อมูลผู้ใช้
    showCreatePOModal: false,
    selectedSupplier: null,
    newPurchaseOrderNumber: '',
    newPurchaseOrderDate: '',
    poDate: '',
    newPONumber: '',
    selectedSupplierTaxId: '',
    showDisapproveModal: false,
    disapproveReason: '', // เพิ่ม disapproveReason สำหรับ modal
    anyPOReceived: false, // เพิ่มตัวแปรนี้

    searchProductName: '',
    searchProductCode: '',
    comparePrices: [],
    searchSupplierId: '',
    departments: [],
    selectedDepartment: null,
    showExportModal: false,
    showMappingFormModal: false,
    searchPartType: '',
    searchProductType: '',
    searchBrand: '',

    selectedWarehouseName: '',
    selectedWarehouseId: '',
    partTypes: [],
    productTypes: [],
    brands: [],
    currentPage: 1,
    itemsPerPage: 10,
    agency: null,
    isEditing: false, 
    showCancelPRModal: false,
    cancelReason: '', // เพิ่มตัวแปรนี้
    isSubmitting: false,
    selectedItemsCheckbox: [],
    messages: [],
    loading: false,
    isEdited: false,
    showEditModal: false,
    showBatchModal: false,
    showProductSelectionModal: false,
    confirmAction: true,
      currentItem: null,
      newQuantity: 0,
      reason: '',
      // prId: this.$route.params.id,
      maxQuantity: 100,
    itemsToDelete: [],
    selectedItems: [], // Array of items to edit
    items: [], 
    newProduct: null,
  };
},
  computed: {

    isValidForm() {
      return this.selectedItems.every(item => 
        item.newQuantity >= 0 && 
        item.newQuantity >= item.orderedQuantity &&
        item.reason?.trim()
      );
    },
    supplierOptions() {
    return this.suppliers.map(supplier => ({
      ...supplier,
      company_name: `${supplier.company_name} (${supplier.supplier_id})`
    }));
  },
    taxId() {
      return this.selectedSupplier ? this.selectedSupplier.tax_id : '';
    },
    canCancelRevision() {
      // ตรวจสอบว่าผู้ใช้เป็นธุรการซ่อมบำรุง และสถานะ PR เป็น 'รอหัวหน้าอนุมัติ(หน่วยงาน)'
      return this.user.levelpermission === '1' && this.detail.status_pr === 'รอผจก.หน่วยงานตรวจสอบ';
    },
    canSaveChanges() {
  // if (this.user.position_lo === 'ธุรการซ่อมบำรุง') {
  //   return false;
  // }

// ====================================================================================================================================================
  // return this.isEdited && (
  //       (this.user.agency === 1 && this.user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' && this.detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข') ||
  //       (this.user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' && (this.detail.status_pr === 'รอผู้จัดการซ่อมแก้ไข')) || // Added condition this.detail.status_pr === 'รอหัวหน้าอนุมัติ(หน่วยงาน)' || 
  //       (this.user.position_lo === 'ธุรการซ่อมบำรุง' && this.detail.status_pr === 'รอธุรการซ่อมแก้ไข')
  //     );
  return this.isEdited && (
       this.detail.status_pr === 'รอแก้ไข')
      
  },
   paginatedFilteredProducts() {
      const start = (this.currentProductPage - 1) * this.productsPerPage;
      const end = start + this.productsPerPage;
      return this.filteredProducts.slice(start, end);
    },
    paginatedProducts() {
    if (!this.products) return [];
    const start = (this.currentProductPage - 1) * this.productsPerPage;
    const end = start + this.productsPerPage;
    return this.products.slice(start, end);
  },
 
  filteredProducts() {
      return this.products.filter(product => {
        return (
          product.product_name.toLowerCase().includes(this.productSearchQuery.toLowerCase()) &&
          product.product_type.toLowerCase().includes(this.productTypeSearchQuery.toLowerCase()) &&
          product.brand.toLowerCase().includes(this.productBrandSearchQuery.toLowerCase())
        );
      });
    },
    totalProductPages() {
      return Math.ceil(this.filteredProducts.length / this.productsPerPage);
    },
    canCancelPR() {
    console.log('Checking status_pr for cancelable status:', this.detail.status_pr);
    return (
      ['รอตรวจสอบ', 'กำลังดำเนินการ', 'รอหัวหน้าอนุมัติ(หน่วยงาน)', 'รอผู้จัดการอนุมัติ'].includes(this.detail.status_pr) &&
      ((this.detail.status_pr === 'รอตรวจสอบ' && this.user.position_lo === 'ธุรการซ่อมบำรุง') ||
       this.user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' || 
       this.user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง') || (this.detail.status_pr === 'รอธุรการซ่อมแก้ไข' && this.user.position_lo === 'ธุรการซ่อมบำรุง')
    ) || 
    (this.detail.status_pr === 'รอเจ้าหน้าที่ธุรการจัดซื้อ' && this.user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' );
  },
    allItemsOrdered() {
      return this.detail.items.every(item => this.calculateRemainingOrder(item) === 0);
    },
  //   canCancelPR() {
  //     console.log('Checking status_pr for cancelable status:', this.detail.status_pr);
  //     return ['รอตรวจสอบ', 'กำลังดำเนินการ','รอหัวหน้าอนุมัติ(หน่วยงาน)' ,'รอผู้จัดการอนุมัติ' ].includes(this.detail.status_pr);
  // },
  canClosePR() {
    return this.detail.status_pr !== 'ปิดใบ PR'&& this.user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ' && this.purchaseOrders.length > 0 && this.detail.items.some(item => this.calculateRemainingOrder(item) > 0);
  },
  paginatedComparePrices() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.comparePrices.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.comparePrices.length / this.itemsPerPage);
    },
  },
  async created() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      const response = await axios.get('https://brk-group.org/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.user = response.data; // ตรวจสอบให้แน่ใจว่า login_id ถูกดึงมาใน user object
      this.agency = response.data.agency;
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        // ถ้า token ไม่ถูกต้อง ให้ redirect ไปยังหน้า Login
        this.$router.push({ name: 'Login' });
      }
    }
  // ตรวจสอบว่า $socket ถูกกำหนดอย่างถูกต้องและเป็นฟังก์ชัน
  if (this.$socket && typeof this.$socket.on === 'function') {
    this.$socket.on('message', async (message) => {
      if (message.type === 'pr_status_updated' && message.data.pr_id === this.$route.params.id) {
        this.updatePRDetail(message.data);
        Swal.fire('อัปเดต', 'สถานะ PR ได้รับการอัปเดตโดยผู้ใช้อื่น', 'info');
      }
    });
  } else {
    console.error('Socket is not initialized or does not have on method.');
  }
    
    const prId = this.$route.params.id;
    const queryPrType = this.$route.query.prType;


    this.prId = prId;  // เพิ่มบรรทัดนี้
    this.detail.pr_id = prId; // ตั้งค่า pr_id จากพารามิเตอร์ที่ได้รับ
     // ตั้งค่า prType หากมีใน query
  if (queryPrType) {
    this.prType = queryPrType;
  }
    await this.fetchDetail(prId);
    await this.fetchWarehouses();
    await this.fetchProducts();
    await this.fetchSuppliers();
    await this.fetchUser(); // ดึงข้อมูลผู้ใช้
    await this.fetchPartTypes();
    await this.fetchProductTypes();
    await this.fetchBrands();
    await this.fetchPurchaseOrders(prId);
    await this.fetchDepartments();
    this.newPurchaseOrderDate = new Date().toISOString().substr(0, 10); // Set current date
    this.debouncedFetchComparePrices = debounce(this.fetchComparePrices, 300);
  },
  
  mounted() {
    // this.prId = this.$route.params.prId;
    this.fetchComparePrices();
    this.fetchProducts();
  this.checkAnyPOReceived();
  this.detail.items.forEach(item => {
    item.orderQuantity = this.calculateRemainingOrder(item);
  });
  // ฟังก์ชันอื่น ๆ
   
    // ถ้าคุณต้องการส่งข้อความไปยัง WebSocket เมื่อมีการอัพเดทข้อมูล
    // this.$watch('yourData', (newVal) => {
    // if (this.$socket && typeof this.$socket.emit === 'function') {
    //   this.$socket.emit('message', newVal);
    // } else {
    //   console.error('Socket is not initialized or does not have emit method.');
    // }
  
  },
  methods: {
    // openProductSelectionModal(item) {
      openProductSelectionModal() {
      Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'info',  // ไอคอนที่ใช้เป็น 'info' แสดงว่าเป็นข้อมูล
      title: 'ยังไม่เปิดใช้งาน',  // ข้อความแจ้งเตือน
      showConfirmButton: false,  // ไม่ให้มีปุ่มยืนยัน
      timer: 2000,  // ตั้งเวลาให้แสดง 2 วินาที
      timerProgressBar: true,  // แสดงบาร์เวลาคืนตัว
    });
      // this.currentItem = item; // เก็บข้อมูล item ที่ต้องการเปลี่ยน
      // this.showProductSelectionModal = true;
      // this.fetchProducts(); // โหลดรายการสินค้าใหม่
    },
    closeProductSelectionModal() {
      this.showProductSelectionModal = false;
      this.currentItem = null;
    },
    selectProductForItem(product) {
  if (this.currentItem) {
    // เพิ่ม newProductId เพื่อส่งไป API
    this.currentItem.newProductId = product.product_id;
    this.currentItem.productName = product.product_name;
    this.currentItem.productCode = product.product_code;
    this.currentItem.productType = product.product_type;

  }
  this.closeProductSelectionModal();
    },

    async fetchProductDetails(itemId) {
  if (!itemId) {
    Swal.fire('ข้อมูลไม่สมบูรณ์!', 'ไม่สามารถดึงข้อมูลรายการได้เนื่องจาก Item ID ไม่ถูกต้อง', 'error');
    return;
  }

  const token = localStorage.getItem('userToken');
  this.loading = true;
  try {
    const { data } = await axios.get(`https://brk-group.org/api/purchase-requisition-item/${itemId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    this.newProduct = data;
    console.log('Item details fetched successfully:', data);
  } catch (error) {
    Swal.fire('ผิดพลาด!', 'ไม่สามารถดึงข้อมูลรายการจากระบบได้', 'error');
    console.error('Error fetching item details:', error);
  } finally {
    this.loading = false;
  }
},
  // selectProducts(product) {
  //   if (this.currentItem) {
  //     this.currentItem.productId = product.product_id;
  //     this.currentItem.productName = product.product_name;
  //     this.currentItem.productCode = product.product_code;
  //     this.currentItem.productType = product.type_name;
  //   }
  //   this.closeProductSelectionModal();
  // },
  searchProducts() {
    // Call an API or use a method to fetch the filtered products list
    // Example:
    fetch(`https://brk-group.org/api/products?query=${this.searchProductQuery}`)
      .then((response) => response.json())
      .then((data) => {
        this.filteredProducts = data;
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  },

  openModal(item) {
  this.selectedItem = item;
  this.showModal = true;
  if (item.itemId) {
    this.fetchProductDetails(item.itemId);
  } else {
    console.warn('Item ID is undefined in the selected item:', item);
    Swal.fire(
      'ข้อมูลไม่สมบูรณ์!',
      'ไม่สามารถดึงข้อมูลสินค้าได้เนื่องจาก Item ID ไม่ถูกต้อง',
      'error'
    );
  }
 
},
    closeModalView() {
      this.showModal = false;
      this.selectedItem = null;
    },
    closeModalMap() {
      this.showMappingFormModal = false;
    },
    onSupplierSelect(selected) {
  console.log('Selected supplier:', selected);
  // ทำอย่างอื่นตามต้องการเมื่อเลือกคู่ค้า
},
    showAddSupplierModal() {
      this.isAddSupplierModalVisible = true;
    },

    closeAddSupplierModal() {
      this.isAddSupplierModalVisible = false;
    },
    async onSupplierAdded(newSupplier) {
  // ตรวจสอบว่า newSupplier มีข้อมูลที่ถูกต้อง
  if (newSupplier && newSupplier.supplier_id) {
    // เพิ่มคู่ค้าใหม่เข้าไปใน suppliers
    this.suppliers.push(newSupplier);
    
    // อัปเดต supplierOptions ให้รวมคู่ค้าใหม่
    this.supplierOptions = [...this.suppliers]; // หรือใช้ this.supplierOptions.push(newSupplier) ถ้าต้องการ
    
    // เลือกคู่ค้าที่เพิ่งเพิ่ม
    this.selectedSupplier = newSupplier;
    
    this.closeAddSupplierModal();
  } else {
    console.error('Invalid supplier data:', newSupplier);
  }
},
async confirmBatchChange(confirm) {
      this.confirmAction = confirm
      this.showBatchModal = true
    },
    closeBatchModal() {
      this.showBatchModal = false
    },
goBack() {
  const currentPage = this.$route.query.currentPage || 1;
  if (this.agency === 1) {
    this.$router.push({ 
      name: 'PRForm', 
      query: { 
        activeSection: 'pr-agency',
        prType: this.prType,
        currentPage: currentPage
      } 
    });
  } else {
    this.$router.push({ 
      name: 'PRForm', 
      query: { 
        activeSection: 'pr-own',
        prType: this.prType,
        currentPage: currentPage
      } 
    });
  }
    },

    async cancelRevision() {
      const prId = this.$route.params.id;
      const token = localStorage.getItem('userToken');
      this.loading = true; // เริ่มการโหลด
      try {
        await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/status`, { status_pr: 'รอแก้ไข',   approved_by: this.user.login_id }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.detail.status_pr = 'รอแก้ไข';
        Swal.fire(
          'สำเร็จ!',
          'สถานะใบ PR ถูกเปลี่ยนเป็น รอแก้ไข เรียบร้อยแล้ว',
          'success'
        );
        if (this.$socket && typeof this.$socket.emit === 'function') {
  this.$socket.emit('message', {
    type: 'pr_status_updated',
          data: { pr_id: prId, status_pr: 'รอแก้ไข' }
  });
} else {
  console.warn('Socket is not initialized or emit method is not available for order_approved.');
}
      
      } catch (error) {
        console.error('Error updating status:', error);
        Swal.fire(
          'ผิดพลาด!',
          'ไม่สามารถเปลี่ยนสถานะใบ PR ได้',
          'error'
        );
      } finally {
      this.loading = false; // ปิดการโหลด
    }
    },
    async confirmCancelRevision() {
      const result = await Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณต้องการยกเลิกการดำเนินการแก้ไขนี้หรือไม่",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยันการยกเลิก',
        cancelButtonText: 'ปิด'
      });

      if (result.isConfirmed) {
        this.cancelRevision();
      }
    },

    openEditModal(item) {
      const existingItem = this.selectedItems.find(i => i.itemId === item.itemId);
      if (!existingItem) {
        this.selectedItems.push({
          ...item,
          newQuantity: item.quantity,
          reason: ''
        });
      }
      // this.prId = item.pr_id;
      this.showEditModal = true;
    },

    closeEditModal() {
      this.showEditModal = false;
      this.currentItem = null;
    },
    removeItem(itemId) {
      this.selectedItems = this.selectedItems.filter(item => item.itemId !== itemId);
      if (this.selectedItems.length === 0) {
        this.closeModal();
      }
    },
    closeModal() {
      this.showEditModal = false;
      this.showModal = false
      this.currentItem = null;
      // this.prId = null;
      this.reason = '';
    },
    // async submitChangeRequest() {
    //   if (this.newQuantity < 0) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'ข้อผิดพลาด',
    //       text: 'จำนวนต้องไม่น้อยกว่า 0',
    //       toast: true,
    //       position: 'top-right',
    //       timer: 3000,
    //       showConfirmButton: false,
    //     });
    //     return;
    //   }

    //   try {
    //     const response = await fetch('https://brk-group.org/api/request-quantity-change', {
    //       method: 'POST',
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify({
    //         itemId: this.currentItem.itemId,
    //         newQuantity: this.newQuantity,
    //         reason: this.reason,
    //       }),
    //     });

    //     if (!response.ok) {
    //       const error = await response.json();
    //       throw new Error(error.message);
    //     }
      
    // //     this.currentItem.pendingApproval = true;
    // // if (this.currentItem.pr) {
    // //   this.currentItem.pr.status_pr = data.newStatus;
    // // }
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'สำเร็จ',
    //       text: 'ส่งคำขอแก้ไขสำเร็จ',
    //       toast: true,
    //       position: 'top-right',
    //       timer: 3000,
    //       showConfirmButton: false,
    //     });
    //     // this.closeModal();
    //     // this.currentItem.pendingApproval = true;

    //     setTimeout(() => {
    //   window.location.reload();
    // }, 2000); // หน่วงเวลา 3 วินาที

    //   } catch (error) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'ข้อผิดพลาด',
    //       text: error.message,
    //       toast: true,
    //       position: 'top-right',
    //       timer: 3000,
    //       showConfirmButton: false,
    //     });
    //   }
    // },
    async submitChangeRequest() {
      console.log('Current prId:', this.prId);
      console.log('Selected Items:', this.selectedItems);

      if (!this.prId) {
        console.log('PR ID is missing');
        Swal.fire({
          icon: 'error',
          title: 'ข้อผิดพลาด',
          text: 'ไม่พบรหัส PR',
          toast: true,
          position: 'top-right',
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      try {
    const requestBody = {
      prId: this.prId,
      items: this.selectedItems.map(item => ({
        itemId: item.itemId,
        newQuantity: item.newQuantity,
        reason: item.reason
      })),
      updatePrStatus: false
    };
    console.log('Request Body:', requestBody);

    const response = await fetch('https://brk-group.org/api/request-quantity-changes', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });

    console.log('Response status:', response.status);
    if (!response.ok) {
      const error = await response.json();
      console.log('Error response:', error);
      throw new Error(error.message);
    }


        Swal.fire({
          icon: 'success',
          title: 'สำเร็จ',
          text: 'บันทึกการแก้ไขสำเร็จ',
          toast: true,
          position: 'top-right',
          timer: 3000,
          showConfirmButton: false,
        });

        this.selectedItems = this.selectedItems.map(item => ({
          ...item,
          pendingApproval: true
        }));
      } catch (error) {
        console.error('Error in submitChangeRequest:', error);
        Swal.fire({
          icon: 'error',
          title: 'ข้อผิดพลาด',
          text: error.message,
          toast: true,
          position: 'top-right',
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },

  async submitAllChanges() {
  console.log('Current prId:', this.prId);
  console.log('Selected Items:', this.selectedItems);

  if (!this.prId) {
    console.log('PR ID is missing');
    Swal.fire({
      icon: 'error',
      title: 'ข้อผิดพลาด',
      text: 'ไม่พบรหัส PR',
      toast: true,
      position: 'top-right',
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  try {
    // แสดง Dialog ยืนยันการส่งคำขอแก้ไข
    const { isConfirmed } = await Swal.fire({
      title: 'ยืนยันการส่งคำขอ',
      text: 'คำขอแก้ไขนี้จะถูกส่งให้หัวหน้าซ่อมบำรุงส่วนกลางพิจารณา ต้องการดำเนินการต่อหรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ส่งคำขอ',
      cancelButtonText: 'ยกเลิก',
    });

    if (!isConfirmed) {
      return;
    }

    const requestBody = {
      prId: this.prId,
      items: this.selectedItems.map(item => ({
        itemId: item.itemId,
        newQuantity: item.newQuantity,
        reason: item.reason,
        newProductId: item.newProductId,
        requiresMaintenanceApproval: item.newProductId ? true : false // เพิ่มฟิลด์สำหรับตรวจสอบว่าต้องการการอนุมัติจากหัวหน้าซ่อมบำรุงหรือไม่
      })),
      updatePrStatus: true
    };

    console.log('Request Body:', requestBody);

    const response = await fetch('https://brk-group.org/api/request-quantity-changes', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });

    console.log('Response status:', response.status);
    if (!response.ok) {
      const error = await response.json();
      console.log('Error response:', error);
      throw new Error(error.message);
    }

     await response.json();

    Swal.fire({
      icon: 'success',
      title: 'สำเร็จ',
      text: 'ส่งคำขอแก้ไขให้หัวหน้าซ่อมบำรุงส่วนกลางพิจารณาแล้ว',
      toast: true,
      position: 'top-right',
      timer: 3000,
      showConfirmButton: false,
    });

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'ข้อผิดพลาด',
      text: error.message,
      toast: true,
      position: 'top-right',
      timer: 3000,
      showConfirmButton: false,
    });
  }
},
    // async submitAllChanges() {
    //   console.log('Current prId:', this.prId);
    //   console.log('Selected Items:', this.selectedItems);

    //   if (!this.prId) {
    //     console.log('PR ID is missing');
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'ข้อผิดพลาด',
    //       text: 'ไม่พบรหัส PR',
    //       toast: true,
    //       position: 'top-right',
    //       timer: 3000,
    //       showConfirmButton: false,
    //     });
    //     return;
    //   }

    //   try {
    // const requestBody = {
    //   prId: this.prId,
    //   items: this.selectedItems.map(item => ({
    //     itemId: item.itemId,
    //     newQuantity: item.newQuantity,
    //     reason: item.reason
    //   })),
    //   updatePrStatus: true
    // };
    // console.log('Request Body:', requestBody);

    // const response = await fetch('https://brk-group.org/api/request-quantity-changes', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify(requestBody),
    // });

    // console.log('Response status:', response.status);
    // if (!response.ok) {
    //   const error = await response.json();
    //   console.log('Error response:', error);
    //   throw new Error(error.message);
    // }

    //     Swal.fire({
    //       icon: 'success',
    //       title: 'สำเร็จ',
    //       text: 'ส่งคำขอแก้ไขทั้งหมดสำเร็จ',
    //       toast: true,
    //       position: 'top-right',
    //       timer: 3000,
    //       showConfirmButton: false,
    //     });

    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 2000);
    //   } catch (error) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'ข้อผิดพลาด',
    //       text: error.message,
    //       toast: true,
    //       position: 'top-right',
    //       timer: 3000,
    //       showConfirmButton: false,
    //     });
    //   }
    // },

    async confirmChange(item, confirm) {
  try {
    const { isConfirmed } = await Swal.fire({
      title: confirm ? 'ยืนยันการดำเนินการ' : 'ยืนยันการยกเลิก',
      text: confirm 
        ? 'คุณต้องการยืนยันการแก้ไขจำนวนสินค้าใช่หรือไม่?'
        : 'คุณต้องการยกเลิกคำขอแก้ไขจำนวนสินค้าใช่หรือไม่?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm ? 'ยืนยัน' : 'ยกเลิกคำขอ',
      cancelButtonText: 'ยกเลิก',
    });

    if (!isConfirmed) {
      return;
    }

    const response = await fetch('https://brk-group.org/api/confirm-quantity-change', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ itemId: item.itemId, confirm }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }

    const result = await response.json();

    Swal.fire({
      icon: 'success',
      title: confirm ? 'สำเร็จ' : 'ยกเลิกสำเร็จ',
      text: confirm ? 'การแก้ไขได้รับการยืนยันแล้ว' : 'คำขอแก้ไขถูกยกเลิก',
      toast: true,
      position: 'top-right',
      timer: 3000,
      showConfirmButton: false,
    });

    item.pendingApproval = false;
    item.newQuantity = null;

    if (result.statusChanged) {
      Swal.fire({
        icon: 'info',
        title: 'สถานะ PR อัปเดตแล้ว',
        text: 'สถานะของใบ PR เปลี่ยนเป็น "อยู่ระหว่างการสั่งซื้อ"',
        toast: true,
        position: 'top-right',
        timer: 3000,
        showConfirmButton: false,
      });
    } else if (result.pendingItems > 0) {
      Swal.fire({
        icon: 'info',
        title: 'รายการคงเหลือ',
        text: `ยังมี ${result.pendingItems} รายการที่รอการยืนยัน`,
        toast: true,
        position: 'top-right',
        timer: 3000,
        showConfirmButton: false,
      });
    }

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'ข้อผิดพลาด',
      text: error.message,
      toast: true,
      position: 'top-right',
      timer: 3000,
      showConfirmButton: false,
    });
  }
},

//     async confirmChange(item, confirm) {
//   try {
//     // แสดงการแจ้งเตือนยืนยันการดำเนินการ
//     const { isConfirmed } = await Swal.fire({
//       title: confirm ? 'ยืนยันการดำเนินการ' : 'ยืนยันการยกเลิก',
//       text: confirm 
//         ? 'คุณต้องการยืนยันการแก้ไขจำนวนสินค้าใช่หรือไม่?'
//         : 'คุณต้องการยกเลิกคำขอแก้ไขจำนวนสินค้าใช่หรือไม่?',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonText: confirm ? 'ยืนยัน' : 'ยกเลิกคำขอ',
//       cancelButtonText: 'ยกเลิก',
//     });

//     // หากผู้ใช้กดยกเลิกใน SweetAlert2
//     if (!isConfirmed) {
//       return;
//     }

//     // เรียก API
//     const response = await fetch('https://brk-group.org/api/confirm-quantity-change', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ itemId: item.itemId, confirm }),
//     });

//     if (!response.ok) {
//       const error = await response.json();
//       throw new Error(error.message);
//     }

//     const result = await response.json();

//     // แสดงการแจ้งเตือนเมื่อสำเร็จ
//     Swal.fire({
//       icon: 'success',
//       title: confirm ? 'สำเร็จ' : 'ยกเลิกสำเร็จ',
//       text: confirm ? 'การแก้ไขได้รับการยืนยันแล้ว' : 'คำขอแก้ไขถูกยกเลิก',
//       toast: true,
//       position: 'top-right',
//       timer: 3000,
//       showConfirmButton: false,
//     });

//     // อัปเดตสถานะของสินค้า
//     item.pendingApproval = false;
//     item.newQuantity = null;

//     // หากสถานะ PR เปลี่ยนเป็น "อยู่ระหว่างการสั่งซื้อ"
//     if (result.status === 'อยู่ระหว่างการสั่งซื้อ') {
//       Swal.fire({
//         icon: 'info',
//         title: 'สถานะ PR อัปเดตแล้ว',
//         text: 'สถานะของใบ PR เปลี่ยนเป็น "อยู่ระหว่างการสั่งซื้อ"',
//         toast: true,
//         position: 'top-right',
//         timer: 3000,
//         showConfirmButton: false,
//       });
//     }

//     setTimeout(() => {
//       window.location.reload();
//     }, 2000); // หน่วงเวลา 2 วินาที
//   } catch (error) {
//     // แสดงการแจ้งเตือนเมื่อเกิดข้อผิดพลาด
//     Swal.fire({
//       icon: 'error',
//       title: 'ข้อผิดพลาด',
//       text: error.message,
//       toast: true,
//       position: 'top-right',
//       timer: 3000,
//       showConfirmButton: false,
//     });
//   }
// }
// ,
    requestQuantityChange() {
      if (this.currentItem) {
        this.$set(this.detail.items[this.currentItem.index], 'pendingApproval', true);
        this.$set(this.detail.items[this.currentItem.index], 'newQuantity', this.newQuantity);
      }
      this.closeEditModal();
    },
    confirmQuantityChange(item) {
      item.quantity = item.newQuantity;
      item.pendingApproval = false;
      delete item.newQuantity;
    },


    openEditQuantityModal(index) {
    this.editingItemIndex = index;
    this.editingItem = { ...this.detail.items[index] };
    this.showEditQuantityModal = true;
  },

  closeEditQuantityModal() {
    this.editingItemIndex = null;
    this.editingItem = null;
    this.showEditQuantityModal = false;
    this.form.files = [];
  },
  updateItemQuantity() {
    if (this.editingItemIndex !== null) {
      this.detail.items.splice(this.editingItemIndex, 1, this.editingItem);
      this.closeEditQuantityModal();
    }
  },
    updatePRDetail(data) {
    if (data.pr_id === this.detail.pr_id) {
      this.detail.status_pr = data.status_pr;
      this.detail.revision_reasons = data.revision_reasons || this.detail.revision_reasons;
      this.detail.status_approve1 = data.status_approve1 || this.detail.status_approve1;
      this.detail.status_approve2 = data.status_approve2 || this.detail.status_approve2;
      this.detail.status_approve3 = data.status_approve3 || this.detail.status_approve3;
      // อัพเดทรายละเอียดอื่นๆ ถ้ามี
    }
  },
    async confirmClerkApproval() {
    const result = await Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: "คุณต้องการดำเนินการต่อสำหรับใบ PR นี้หรือไม่",
      icon: 'warning',
      input: 'textarea',
      inputPlaceholder: 'กรุณาใส่เหตุผลในการอนุมัติ',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'ยืนยันการดำเนินการต่อ',
      cancelButtonText: 'ปิด'
    });

    if (result.isConfirmed) {
    if (!result.value || result.value.trim() === '') {
      Swal.fire('เกิดข้อผิดพลาด', 'กรุณาใส่เหตุผลในการอนุมัติ', 'error');
      return;
    }
    
    this.newStatus = 'อยู่ระหว่างการสั่งซื้อ';
    this.revisionReason = result.value.trim(); // เก็บเหตุผลที่กรอกใน SweetAlert และลบช่องว่างออก
    this.sendClerkApproval();
  }
},
  async sendClerkApproval() {
    const prId = this.$route.params.id;
    const token = localStorage.getItem('userToken');
    this.isEditingProduct = true;
    try {
      const payload = {
        status_pr: this.newStatus,
        status_approve3: this.revisionReason, // ส่งเหตุผลไปยังฟิลด์ status_approve3
        approved_by_level4: this.user.login_id // ส่ง user_id ของผู้อนุมัติไปยังฟิลด์ approved_by_level4
      };
      console.log('Payload being sent to API:', payload); // ตรวจสอบ payload ที่จะถูกส่งไปยัง API
      const response = await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/purchasing_clerk_approval`, payload, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('API response:', response);
      this.detail.status_pr = this.newStatus;
//       const message = { 
//   type: 'pr_status_updated', 
//   data: { pr_id: prId, status_pr: this.newStatus } 
// };
// this.$socket.emit('message');
      Swal.fire(
        'สำเร็จ!',
        `สถานะใบ PR ถูกเปลี่ยนเป็น ${this.newStatus} เรียบร้อยแล้ว`,
        'success'
      ).then(() => {
        // const message = `PR ID: ${prId} has been approved with status: ${this.newStatus}.`;
        // this.$socket.send(message);
          window.location.reload(); // รีเฟรชหน้าเมื่อกด "OK" บนการแจ้งเตือน
          // WebSocket message
         
      });
    } catch (error) {
      console.error('Error updating status:', error);
      Swal.fire(
        'ผิดพลาด!',
        'ไม่สามารถเปลี่ยนสถานะใบ PR ได้',
        'error'
      );
    } finally {
      this.isEditingProduct = false;
    }
  },
    openStatusApproveModal() {
    this.showStatusApproveModal = true;
  },
  // ฟังก์ชันสำหรับปิด modal
  closeStatusApproveModal() {
    this.showStatusApproveModal = false;
  },
    openConfirmModalForManagerApproval() {
    this.newStatus = 'รอเจ้าหน้าที่ธุรการจัดซื้อ';
    this.approveField = 'approved_by_manager';
    this.statusApproveField = 'status_approve2';
    this.showConfirmModal2 = true; // แสดง modal สำหรับการยืนยัน
  },
  async sendManagerApproval() {
    const prId = this.$route.params.id;
    const token = localStorage.getItem('userToken');
    this.isEditingProduct = true;

    if (!this.revisionReason || this.revisionReason.trim() === '') {
    Swal.fire('เกิดข้อผิดพลาด', 'กรุณาใส่เหตุผลในการอนุมัติ', 'error');
    this.isEditingProduct = false;
    return;
  }

    try {
      const payload = {
        status_pr: this.newStatus,
        approved_by: this.user.login_id, // เพิ่ม id ของผู้อนุมัติ
        [this.statusApproveField]: this.revisionReason // ส่งเหตุผลไปยังฟิลด์ status_approve2
      };
      // console.log('Payload being sent to API:', payload); // ตรวจสอบ payload ที่จะถูกส่งไปยัง API
      await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/manager_approval`, payload, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      // console.log('API response:', response);

      this.detail.status_pr = this.newStatus;
      Swal.fire(
        'สำเร็จ!',
        `สถานะใบ PR ถูกเปลี่ยนเป็น ${this.newStatus} เรียบร้อยแล้ว`,
        'success'
      ).then(() => {
          window.location.reload(); // รีเฟรชหน้าเมื่อกด "OK" บนการแจ้งเตือน
          // WebSocket message
          const message = { 
          type: 'pr_status_updated', 
          data: { pr_id: prId, status_pr: this.newStatus } 
        };
        this.$socket.emit('message', message);
      });
    } catch (error) {
      console.error('Error updating status:', error);
      Swal.fire(
        'ผิดพลาด!',
        'ไม่สามารถเปลี่ยนสถานะใบ PR ได้',
        'error'
      );
    } finally {
      this.isEditingProduct = false;
    }
  },
    async confirmRevision() {
    if (!this.revisionReason) {
      Swal.fire('เกิดข้อผิดพลาด', 'กรุณาใส่เหตุผลในการส่งกลับแก้ไข', 'error');
      return;
    }

    const result = await Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: "คุณต้องการส่งกลับแก้ไขใบ PR นี้หรือไม่",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'ยืนยันการส่งกลับแก้ไข',
      cancelButtonText: 'ปิด'
    });

    if (result.isConfirmed) {
      this.sendRevision();
    }
  },


  async sendRevision() {
    const prId = this.$route.params.id;
    const reason = this.revisionReason; // ดึงเหตุผลที่ผู้ใช้กรอก
    const token = localStorage.getItem('userToken');
    this.isEditingProduct = true;
    try {
      const payload = {
        revision_reasons: reason
      };
      console.log('Payload being sent to API:', payload); // ตรวจสอบ payload ที่จะถูกส่งไปยัง API
      const response = await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/revision`, payload, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('API response:', response);
      // this.detail.status_pr = 'รอธุรการซ่อมแก้ไข'; อันที่ 5 ========================================================================
      this.detail.status_pr = 'รอแก้ไข';
      Swal.fire(
        'สำเร็จ!',
        'เหตุผลในการส่งกลับแก้ไขถูกบันทึกเรียบร้อยแล้ว',
        'success'
      ).then(() => {

          // WebSocket message
          const message = {
          type: 'pr_status_updated',
          data: { pr_id: prId, status_pr: this.detail.status_pr, revision_reasons: reason }
        };
        this.$socket.emit('message', message);
        window.location.reload(); // รีเฟรชหน้าเมื่อกด "OK" บนการแจ้งเตือน
      });
    } catch (error) {
      console.error('Error updating revision reason:', error);
      Swal.fire(
        'ผิดพลาด!',
        'ไม่สามารถบันทึกเหตุผลในการส่งกลับแก้ไขได้',
        'error'
      );
    } finally {
      this.isEditingProduct = false;
    }
  },
    openConfirmModal(newStatus, approveField, statusApproveField) {
    this.newStatus = newStatus;
    this.approveField = approveField;
    this.statusApproveField = statusApproveField;
    this.showConfirmModal = true;
  },
  closeConfirmModal() {
    this.showConfirmModal = false;
    this.showConfirmModal2 = false;
    this.revisionReason = ''; // Clear the reason input field
  },
  async confirmApproval() {
    const prId = this.$route.params.id;
    const token = localStorage.getItem('userToken');
    this.isLoading = true;
    try {
      const payload = {
        status_pr: this.newStatus,
        [this.statusApproveField]: this.revisionReason,
        approved_by: this.user.login_id // เพิ่ม id ของผู้อนุมัติ
      };
      await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/status`, payload, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.detail.status_pr = this.newStatus;
      this.isLoading = false;
      this.showConfirmModal = false;
      Swal.fire(
        'สำเร็จ!',
        `สถานะใบ PR ถูกเปลี่ยนเป็น ${this.newStatus} เรียบร้อยแล้ว`,
        'success'
      ).then(() => {
        this.socket.emit('message', `PR ID: ${prId} has been approved with status: ${this.newStatus}.`);
        window.location.reload(); // รีเฟรชหน้าเมื่อกด "OK" บนการแจ้งเตือน
      });
    } catch (error) {
      console.error('Error updating status:', error);
      this.isLoading = false;
      Swal.fire(
        'ผิดพลาด!',
        'ไม่สามารถเปลี่ยนสถานะใบ PR ได้',
        'error'
      );
    }
  },
    openProductModal(index) {
      this.editingItemIndex = index;
      this.showProductModal = true;
      this.fetchProducts();
    },
    async fetchProducts() {
    try {
      const response = await axios.get('https://brk-group.org/api/productselect', {
        params: { search: this.productSearchQuery }
      });
      this.products = response.data;
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  },
  isProductSelected(product) {
    return this.form.items.some(item => item.productId === product.product_id);
  },
  // selectProduct(product) {
  //   if (this.isProductSelected(product)) {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'สินค้าถูกเลือกไปแล้ว',
  //       text: 'คุณได้เลือกสินค้านี้ไปแล้ว กรุณาเลือกสินค้ารายการอื่น',
  //       confirmButtonText: 'ตกลง'
  //     });
  //   } else {
  //     this.form.items.push({
  //       productId: product.product_id,
  //       productCode: product.product_code,
  //       productName: product.product_name,
  //       productType: product.product_type,
  //       quantity: 0,
  //       unit: product.unit_name,
  //       unitPrice: product.price_per_unit,
  //       totalPrice: 0.00,
  //       status: 'Available'
  //     });
  //     this.showProductModal = false;
  //   }
  // },
  selectProduct(product, index = null) {
  // ตรวจสอบว่าสินค้าที่เลือกมีอยู่ในรายการแล้วหรือไม่
  const existingProductIndex = this.detail.items.findIndex(item => item.productId === product.product_id);

  if (existingProductIndex !== -1) {
    // แสดงแจ้งเตือนว่ารายการสินค้ามีอยู่แล้ว
    Swal.fire('เกิดข้อผิดพลาด', 'สินค้านี้มีอยู่ในรายการแล้ว', 'error');
  } else {
    // ลบรายการสินค้าเก่า
    if (index !== null) {
      this.detail.items.splice(index, 1);
    }

    // เพิ่มรายการสินค้าใหม่
    this.detail.items.push({
      productId: product.product_id,
      productCode: product.product_code,
      productName: product.product_name,
      productType: product.product_type,
      quantity: 1, // ตั้งค่าจำนวนเริ่มต้นเป็น 1
      unit: product.unit_name,
      unitPrice: product.price_per_unit,
      totalPrice: product.price_per_unit, // คำนวณราคารวมเริ่มต้น
      status: 'Available',
      isNew: true, // เพิ่ม property isNew เพื่อระบุว่าสินค้านี้เป็นรายการใหม่
      product_attachment_url: '', // เพิ่ม field นี้เพื่อเก็บ path ของรูปภาพ
      fileName: '' // เก็บชื่อไฟล์ที่จะถูกแนบ
    });
    Swal.fire('เพิ่มสินค้า', 'สินค้านี้ได้ถูกเพิ่มในรายการแล้ว', 'success');
  
  }

  this.isEdited = true; // Set isEdited to true
  this.showProductModal = false;
},
    prevProductPage() {
      if (this.currentProductPage > 1) {
        this.currentProductPage--;
        this.fetchProducts();
      }
    },
    nextProductPage() {
      if (this.currentProductPage < this.totalProductPages) {
        this.currentProductPage++;
        this.fetchProducts();
      }
    },
    hasSelectedItemsForRevision() {
      return this.selectedItemsCheckbox.length > 0;
    },

    // Handle checkbox change
    handleCheckboxChange(item) {
      if (this.selectedItemsCheckbox.includes(item.id)) {
        // Remove item ID from selectedItemsCheckbox if already selected
        const index = this.selectedItemsCheckbox.indexOf(item.id);
        if (index > -1) {
          this.selectedItemsCheckbox.splice(index, 1);
        }
      } else {
        // Add item ID to selectedItemsCheckbox if not already selected
        this.selectedItemsCheckbox.push(item.id);
      }
    },
    enforceMin() {
      if (this.form.creditTerms < 0) {
        this.form.creditTerms = 0; // ถ้าค่าติดลบ ให้ตั้งเป็น 0
      }
    },
    preventDecimal(event) {
      if (event.key === '.' || event.key === 'e') {
        event.preventDefault();
      }
    },
    handleInputChange(item) {
    if (item.quantity < 1) {
      item.quantity = 1;
    }
    item.totalPrice = item.quantity * item.unitPrice;
    this.isEditingProduct = true;
    this.isEdited = true; // Set isEdited to true when quantity is changed
    this.isEditing = true; // แสดงปุ่มบันทึกการแก้ไข
    
  },
    openCancelPRModal() {
    this.showCancelPRModal = true;
  },
  closeCancelPRModal() {
    this.showCancelPRModal = false;
    this.cancelReason = '';
  },
    validateQuantity(item) {
    // ถ้าจำนวนสินค้าต่ำกว่า 1 ให้ตั้งค่าเป็น 1
    if (item.quantity < 1) {
      item.quantity = 1;
    }
    this.isEditing = true; // แสดงปุ่มบันทึกการแก้ไข
    this.isEdited = true; // Set isEdited to true when quantity is changed
  },
  async saveQuantities() {
      const token = localStorage.getItem('userToken');
      
      const itemsToUpdate = this.detail.items.map(item => ({
        item_id: item.itemId,
        pr_id: this.detail.pr_id,
        product_id: item.productId,
        product_code: item.productCode,
        product_name: item.productName,
        product_type: item.productType,
        quantity: item.quantity,
        unit: item.unit,
        product_attachment_url: item.product_attachment_url || '',
        fileName: item.fileName || ''
      }));

      const formData = new FormData();
      formData.append('items', JSON.stringify(itemsToUpdate));
      formData.append('user_position', this.user.position_lo);
      formData.append('pr_status', this.detail.status_pr);
      formData.append('details', this.detail.details || ''); // เพิ่มฟิลด์ details

  // แนบไฟล์ product_attachment_url สำหรับแต่ละสินค้าใน FormData
  this.detail.items.forEach((item, index) => {
    if (item.imageFiles && item.imageFiles.length > 0) {
      item.imageFiles.forEach((file) => {
        formData.append(`product_attachment_url_${index}`, file);  // กำหนดฟิลด์ด้วยชื่อที่ไม่ซ้ำกัน
      });
    }
  });

      // เพิ่มไฟล์แนบสำหรับใบแจ้งซ่อมและประวัติซ่อม
      for (const [key, file] of Object.entries(this.form.attachmentFiles)) {
        formData.append(key, file);
      }

      const itemsToDelete = this.itemsToDelete;

      try {
        await axios.put('https://brk-group.org/api/purchase_requisition_items/update_quantities', formData, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        for (const itemId of itemsToDelete) {
          await axios.delete(`https://brk-group.org/api/purchase_requisition_items/${itemId}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
        }

        this.detail.items.forEach(item => {
          delete item.isNew;
        });

        this.isEditing = false;
        this.isEdited = false;
        this.itemsToDelete = [];
        await this.fetchDetail(this.detail.pr_id);

        // อัพเดทสถานะ PR เป็น "รอเจ้าหน้าที่ธุรการจัดซื้อ" ถ้าผู้ใช้เป็นธุรการซ่อมบำรุงและสถานะ PR เป็น "รอธุรการซ่อมแก้ไข ================================================================"
        // if (this.user.position_lo === 'ธุรการซ่อมบำรุง' && this.detail.status_pr === 'รอธุรการซ่อมแก้ไข') {
        //   await this.updatePRStatus('รอตรวจสอบ');
        // }

        if (this.user.position_lo === 'ธุรการซ่อมบำรุง' && this.detail.status_pr === 'รอแก้ไข') {
          await this.updatePRStatus('รอตรวจสอบ');
        }

        if (this.user.position_lo === 'ผู้จัดการลานกระบือ' && this.detail.status_pr === 'รอแก้ไข') {
          await this.updatePRStatus('รอส่วนกลางตรวจสอบ');
        }


        if (this.user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง' && this.detail.status_pr === 'รอแก้ไข') {
          await this.updatePRStatus('รอเจ้าหน้าที่ธุรการจัดซื้อ');
        }

        if (this.user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' && this.detail.status_pr === 'รอแก้ไข') {
          await this.updatePRStatus('รอเจ้าหน้าที่ธุรการจัดซื้อ');
        }


        
        Swal.fire('สำเร็จ', 'ข้อมูลสินค้าได้รับการบันทึกแล้ว', 'success').then(() => {
        //  this.$socket.emit('message', `Items updated for PR ID: ${this.detail.pr_id}`);
          window.location.reload();
        });
      } catch (error) {
        console.error('Error updating quantities:', error);
        Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถบันทึกข้อมูลสินค้าได้', 'error');
      }
    },
  // async saveQuantities() {
  // const token = localStorage.getItem('userToken');
  // const itemsToUpdate = this.detail.items.map(item => ({
  //   item_id: item.itemId,
  //   pr_id: this.detail.pr_id,
  //   product_id: item.productId,
  //   product_code: item.productCode,
  //   product_name: item.productName,
  //   product_type: item.productType,
  //   quantity: item.quantity,
  //   unit: item.unit,
  //   product_attachment_url: item.product_attachment_url || '',
  //   fileName: item.fileName || ''
  // }));

  // const formData = new FormData();
  // formData.append('items', JSON.stringify(itemsToUpdate));
  // formData.append('user_position', this.user.position_lo);
  // formData.append('pr_status', this.detail.status_pr);
  // this.form.files.forEach(file => {
  //   formData.append('files', file);
  // });

  // const itemsToDelete = this.itemsToDelete;

  // try {
  //   await axios.put('https://brk-group.org/api/purchase_requisition_items/update_quantities', formData, {
  //     headers: { 
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   });

  //   for (const itemId of itemsToDelete) {
  //     await axios.delete(`https://brk-group.org/api/purchase_requisition_items/${itemId}`, {
  //       headers: { 'Authorization': `Bearer ${token}` }
  //     });
  //   }

  //   this.detail.items.forEach(item => {
  //     delete item.isNew;
  //   });

  //   this.isEditing = false;
  //   this.isEdited = false;
  //   this.itemsToDelete = [];
  //   await this.fetchDetail(this.detail.pr_id);

  //   if (this.user.position_lo === 'ธุรการซ่อมบำรุง' && this.detail.status_pr === 'รอธุรการซ่อมแก้ไข') {
  //     await this.updatePRStatus('รอดำเนินการ');
  //   }

  //   Swal.fire('สำเร็จ', 'ข้อมูลสินค้าได้รับการบันทึกแล้ว', 'success').then(() => {
  //     this.$socket.emit('message', `Items updated for PR ID: ${this.detail.pr_id}`);
  //     window.location.reload();
  //   });
  // } catch (error) {
  //   console.error('Error updating quantities:', error);
  //   Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถบันทึกข้อมูลสินค้าได้', 'error');
  // }
  //   },
  async confirmDeleteItem(index, itemId) {
  const result = await Swal.fire({
    title: 'คุณแน่ใจหรือไม่?',
    text: "คุณต้องการลบรายการสินค้านี้หรือไม่",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'ยืนยันการลบ',
    cancelButtonText: 'ยกเลิก'
  });

  if (result.isConfirmed) {
    this.itemsToDelete.push(itemId); // เพิ่ม itemId ที่ต้องการลบลงใน itemsToDelete
    this.deleteItem(index); // ลบรายการจาก state
    this.isEdited = true;
    Swal.fire('ลบเรียบร้อย!', 'รายการสินค้าถูกลบเรียบร้อยแล้ว', 'success');
  }
  },
  deleteItem(index) {
  this.detail.items.splice(index, 1); // ลบรายการที่ index ที่ระบุออกจาก this.detail.items
  this.isEdited = true; // Set isEdited to true
},
  // async deleteItem(index, itemId) {
  //   const token = localStorage.getItem('userToken');
  //   try {
  //     await axios.delete(`https://brk-group.org/api/purchase_requisition_items/${itemId}`, {
  //       headers: { 'Authorization': `Bearer ${token}` }
  //     });
  //     this.detail.items.splice(index, 1); // ลบรายการที่ index ที่ระบุออกจาก this.detail.items
  //     Swal.fire('สำเร็จ!', 'รายการสินค้าถูกลบเรียบร้อยแล้ว', 'success');
  //   } catch (error) {
  //     console.error('Error deleting item:', error);
  //     Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถลบรายการสินค้าได้', 'error');
  //   }
  // },

  // handleFileUpload(event, item) {
  //   const file = event.target.files[0];
  //   const validExtensions = ['pdf', 'png', 'jpg', 'jpeg'];
  //   const fileExtension = file.name.split('.').pop().toLowerCase();

  //   if (!validExtensions.includes(fileExtension)) {
  //     alert('แนบไฟล์ได้เฉพาะ PDF, PNG, JPG, or JPEG file.');
  //     event.target.value = ''; // Clear the input
  //     return;
  //   }

  //   item.fileName = file.name;
  //   item.product_attachment_url = URL.createObjectURL(file); // Create a temporary URL to display the file
  //   this.form.files.push(file);
  //   // console.log('Selected file:', file);
  // }
  async uploadProductImage(event, item) {
  const file = event.target.files[0];
  const validExtensions = ['pdf', 'png', 'jpg', 'jpeg'];
  const fileExtension = file.name.split('.').pop().toLowerCase();

  if (!validExtensions.includes(fileExtension)) {
    Swal.fire({
      icon: 'error',
      title: 'แนบไฟล์ไม่ถูกต้อง',
      text: 'แนบไฟล์ได้เฉพาะ PDF, PNG, JPG, หรือ JPEG เท่านั้น'
    });
    event.target.value = '';
    return;
  }

  // เก็บไฟล์ไว้ในอาร์เรย์ของรายการที่กำลังแก้ไข
  if (!item.imageFiles) {
    item.imageFiles = [];
  }
  item.imageFiles.push(file);
  
  // สร้าง URL ชั่วคราวสำหรับแสดงผล
  item.product_attachment_url = URL.createObjectURL(file);
  item.fileName = file.name;
  this.isEdited = true;
},

  handleFileUpload(event, item) {
  const file = event.target.files[0];
  const validExtensions = ['pdf', 'png', 'jpg', 'jpeg'];
  const fileExtension = file.name.split('.').pop().toLowerCase();

  if (!validExtensions.includes(fileExtension)) {
    Swal.fire({
      icon: 'error',
      title: 'แนบไฟล์ไม่ถูกต้อง',
      text: 'แนบไฟล์ได้เฉพาะ PDF, PNG, JPG, หรือ JPEG เท่านั้น.',
    });
    event.target.value = ''; // Clear the input
    return;
  }

  item.fileName = file.name;
  item.product_attachment_url = URL.createObjectURL(file); // Create a temporary URL to display the file
  if (!this.form.files) {
    this.form.files = []; // Initialize if not already an array
  }
  this.form.files.push(file);
  this.isEdited = true; // Mark the form as edited
},
  handleFileUpload2(event, field) {
      const file = event.target.files[0];
      const validExtensions = ['pdf', 'png', 'jpg', 'jpeg'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        Swal.fire({
          icon: 'error',
          title: 'แนบไฟล์ไม่ถูกต้อง',
          text: 'แนบไฟล์ได้เฉพาะ PDF, PNG, JPG, หรือ JPEG เท่านั้น.',
        });
        event.target.value = ''; // Clear the input
        return;
      }

      // const encodedFileName = encodeURIComponent(file.name); // Encode the file name to handle special characters

      this.form.attachmentFiles[field] = file;
      this.detail[field] = URL.createObjectURL(file); // Create a temporary URL to display the file
      this.isEdited = true; // Mark the form as edited
    },
  // async saveNewProduct() {
  //   const token = localStorage.getItem('userToken');
  //   const prId = this.detail.pr_id;
    
  //   try {
  //     // ลบรายการสินค้าทั้งหมดที่เกี่ยวข้องกับ prId
  //     await axios.delete(`https://brk-group.org/api/purchase_requisitions/${prId}/items`, 
  //     {
  //       headers: { 'Authorization': `Bearer ${token}` }
  //     });

  //     // เพิ่มรายการสินค้าใหม่ที่แก้ไข
  //     const formData = new FormData();
  //     formData.append('prId', prId);
  //     formData.append('items', JSON.stringify(this.detail.items.map(item => ({
  //       pr_id: prId,
  //       product_id: item.productId,
  //       product_code: item.productCode,
  //       product_name: item.productName,
  //       product_type: item.productType,
  //       quantity: item.quantity,
  //       unit: item.unit,
  //       unit_price: item.unitPrice,
  //       total_price: item.quantity * item.unitPrice,
  //       status: item.status,
  //       fileName: item.fileName || null,
  //       product_attachment_url: item.product_attachment_url
  //     }))));
  //     this.detail.items.forEach(item => {
  //       if (item.file) {
  //         formData.append('files', item.file);
  //         console.log('Appending file to FormData:', item.file); 
  //       }
  //     });

  //     const response = await axios.post(`https://brk-group.org/api/purchase_requisitions/${prId}/items`, formData, 
  //     {
  //       headers: { 
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });

  //     if (response.status === 200) {
  //       Swal.fire(
  //         'สำเร็จ!',
  //         'บันทึกรายการสินค้าสำเร็จแล้ว',
  //         'success'
  //       );
  //       this.isEditingProduct = false; // Reset the editing state
  //     } else {
  //       Swal.fire(
  //         'เกิดข้อผิดพลาด',
  //         'ไม่สามารถบันทึกรายการสินค้าได้',
  //         'error'
  //       );
  //     }
  //   } catch (error) {
  //     console.error('Error saving item quantities:', error);
  //     Swal.fire(
  //       'เกิดข้อผิดพลาด',
  //       'ไม่สามารถบันทึกรายการสินค้าได้',
  //       'error'
  //     );
  //   }
  // },
  async saveNewProduct() {
      const token = localStorage.getItem('userToken');
      const prId = this.detail.pr_id;

      this.isLoading = true; // 

      try {
        await axios.delete(`https://brk-group.org/api/purchase_requisitions/${prId}/items`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        const formData = new FormData();
        formData.append('items', JSON.stringify(this.detail.items));
        this.form.files.forEach(file => {
          formData.append('files', file);
          console.log('Appending file to FormData:', file);
        });

        const response = await axios.post(`https://brk-group.org/api/purchase_requisitions/${prId}/items`, 
        formData, 
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกรายการสินค้าสำเร็จแล้ว',
            'success'
          ).then(() => {
            this.$socket.emit('message', `Items updated for PR ID: ${this.detail.pr_id}`);
        window.location.reload(); // รีเฟรชหน้าเมื่อกด "OK" บนการแจ้งเตือน
      });
          this.isEditingProduct = false;
        } else {
          Swal.fire(
            'เกิดข้อผิดพลาด',
            'ไม่สามารถบันทึกรายการสินค้าได้',
            'error'
          );
        }
      } catch (error) {
        console.error('Error saving item quantities:', error);
        Swal.fire(
          'เกิดข้อผิดพลาด',
          'ไม่สามารถบันทึกรายการสินค้าได้',
          'error'
        );
      } finally {
        this.isLoading = false; // จบสถานะการโหลดไม่ว่าจะเกิดข้อผิดพลาดหรือไม่ก็ตาม
      }
    },
    async fetchPartTypes() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/part_types', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.partTypes = response.data;
      } catch (error) {
        console.error('Error fetching part types:', error);
      }
    },
    async fetchProductTypes() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/product_types', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.productTypes = response.data;
      } catch (error) {
        console.error('Error fetching product types:', error);
      }
    },
    async fetchBrands() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/brandss', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.brands = response.data;
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
    async fetchDepartments() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/user-groups', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.departments = response.data;
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },
    openExportModal() {
      this.showExportModal = true;
    },
    closeExportModal() {
      this.showExportModal = false;
      this.selectedDepartment = null;
    },
    transformImageUrl(url) {
      return url ? url.replace('https://brknew.synology.me/web/imageProducts', 'https://brkmeeting-room.online') : '';
    },
    async exportPDF() {
      if (!this.selectedDepartment) {
        Swal.fire('Error', 'กรุณาเลือกหน่วยงาน', 'error');
        return;
      }

      try {
        const token = localStorage.getItem('userToken');
        const imageUrl = this.transformImageUrl(this.selectedDepartment.image_url);
        const response = await axios.post(`https://brk-group.org/generate-pr/${this.detail.pr_id}`, {
          // const response = await axios.post(`https://brk-group.org/generate-pr/${this.detail.pr_id}`, {
          imageUrl: imageUrl,
          groupId: this.selectedDepartment.group_id
        }, {
          headers: { 'Authorization': `Bearer ${token}` },
          responseType: 'blob' // Important to handle the binary response
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank'); // Open the PDF in a new tab

      } catch (error) {
        console.error('Error exporting PDF:', error);
        Swal.fire('Error', 'Unable to export PDF', 'error');
      } finally {
        this.closeExportModal();
      }
    },
    async fetchComparePrices() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/compare_prices', {
          params: {
            productCode: this.searchProductCode,
            productName: this.searchProductName,
            supplierId: this.searchSupplierId,
            partType: this.searchPartType,
            productType: this.searchProductType,
            brand: this.searchBrand,
            page: this.currentPage,
            limit: this.itemsPerPage
          },
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.comparePrices = response.data;
      } catch (error) {
        console.error('Error fetching compare prices:', error);
      }
    },
    async checkAnyPOReceived() {
    try {
      const response = await axios.get(`https://brk-group.org/api/purchase_orders?prId=${this.detail.pr_id}`);
      const anyReceived = response.data.some(po => po.status === 'รับอะไหล่');
      this.anyPOReceived = anyReceived;
    } catch (error) {
      console.error('Error checking PO statuses:', error);
    }
  },
    async disapproveOrder() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }

      try {
        const response = await axios.put(`https://brk-group.org/api/purchase_requisitions/${this.detail.pr_id}/closepr`, 
        { 
          loginId: this.user.login_id, // ใช้ login_id จาก user object
          reason: this.disapproveReason,
          items: this.poDetail.items // ส่งรายการสินค้าด้วย
        }, 
        {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 200) {
          this.detail.status_pr = 'เสร็จสิ้น';
          this.detail.close_id = this.user.login_id;
          this.showDisapproveModal = false;
          this.showCreatePOModal = false;
          Swal.fire(
            'สำเร็จ!',
            'สถานะใบสั่งซื้อถูกอัปเดตเป็นเสร็จสิ้นแล้ว',
            'success'
          );
          const message = `PR ID: ${this.detail.pr_id} has been disapproved.`;
          this.$socket.emit('message', message);
        } else {
          Swal.fire(
            'เกิดข้อผิดพลาด',
            'ไม่สามารถอัปเดตสถานะใบสั่งซื้อได้',
            'error'
          );
        }
      } catch (error) {
        console.error('Error disapproving purchase order:', error);
        Swal.fire(
          'เกิดข้อผิดพลาด',
          'ไม่สามารถอัปเดตสถานะใบสั่งซื้อได้',
          'error'
        );
      }
    },
    openNoteModal() {
      this.showDisapproveModal = true;
    },
    closeDisapproveModal() {
      this.showDisapproveModal = false;
    },
    goToPODetail(poId) {
      const currentPRId = this.$route.params.id; // เก็บ ID ของ PR ปัจจุบัน
    this.$router.push({
      name: 'PODetail',
      params: {
        poId: poId
      },
      query: {
        fromPRForm: true,
        prId: currentPRId, // ส่ง current PR ID ไปด้วย
      }
    });
  },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async fetchPurchaseOrders(prId) {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(`https://brk-group.org/api/purchase_orders`, {
          params: { prId },
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.purchaseOrders = response.data;
      } catch (error) {
        console.error('Error fetching purchase orders:', error);
      }
    },
    async fetchUser() {
  const token = localStorage.getItem('userToken');
  if (!token) {
    this.$router.push({ name: 'Login' });
    return;
  }

  try {
    const response = await axios.get('https://brk-group.org/api/user', {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    this.user = response.data;
    this.agency = response.data.agency; // ตั้งค่า agency จากข้อมูลผู้ใช้
    console.log('Fetched user:', this.user); // ตรวจสอบข้อมูล user ที่ได้มา
    console.log('Fetched user agency:', this.agency); // ตรวจสอบค่า agency ที่ได้มา
  } catch (error) {
    console.error('Error fetching user data:', error);
    if (error.response && error.response.status === 401) {
      this.$router.push({ name: 'Login' });
    } else {
      alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
    }
  }
},
async fetchDetail(prId) {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`https://brk-group.org/api/purchase_requisitions/${prId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.detail = response.data;
      console.log('Fetched detail:', this.detail); // เพิ่ม console.log เพื่อดูข้อมูลที่ดึงมา

      if (this.detail.attachmentUrl) {
        this.detail.attachmentUrl = this.detail.attachmentUrl.replace(
          'https://brknew.synology.me/web/imageProducts',
          'https://brkmeeting-room.online'
        );
        console.log('Transformed Attachment URL:', this.detail.attachmentUrl);
      }
      if (this.detail.attachmentUrl2) {
        this.detail.attachmentUrl2 = this.detail.attachmentUrl2.replace(
          'https://brknew.synology.me/web/imageProducts',
          'https://brkmeeting-room.online'
        );
        console.log('Transformed Attachment URL:', this.detail.attachmentUrl2);
      }
      this.detail.items.forEach(item => {
        item.orderQuantity = 0;
        if (item.productAttachmentUrl) {
          item.productAttachmentUrl = item.productAttachmentUrl.replace(
            'https://brknew.synology.me/web/imageProducts',
            'https://brkmeeting-room.online'
          );
        }
      });

      // อัปเดตฟอร์มด้วยข้อมูลที่ดึงมา
      this.form.totalAmount = this.detail.totalAmount;
      this.form.discountPercent = this.detail.discountPercent;
      this.form.amountAfterDiscount = this.detail.amountAfterDiscount;
      this.form.vatPercent = this.detail.vatPercent;
      this.form.vatAmount = this.detail.vatAmount;
      this.form.grandTotal = this.detail.grandTotal;

      this.detail.items.forEach(item => {
        item.orderQuantity = 0;
      });

       // ส่งข้อความผ่าน WebSocket เพื่อแจ้ง client อื่น ๆ ว่ามีการอัปเดตข้อมูล
       this.$socket.emit('message', {
        type: 'pr_detail_updated',
        data: { prId: prId }
      });
    } catch (error) {
      // console.error('Error fetching purchase requisition details:', error);
    }
  },
    transformAttachmentUrl(url) {
      if (url) {
        return url.replace(
          'https://brknew.synology.me/web/imageProducts',
          'https://brkmeeting-room.online'
        );
      }
      return url;
    },
    async fetchWarehouses() {
      try {
        const response = await axios.get('https://brk-group.org/api/warehouses');
        this.warehouses = response.data;
      } catch (error) {
        console.error('Error fetching warehouses:', error);
      }
    },
    async fetchSuppliers() {
      try {
        const response = await axios.get('https://brk-group.org/api/suppliers');
        this.suppliers = response.data;
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }
    },
    openPurchaseOrderModal() {
  const prNumber = this.detail.purchaseRequestNumber;
  this.newPurchaseOrderNumber = `PO-${prNumber}`;
  this.newPurchaseOrderDate = new Date().toISOString().substr(0, 10);
  this.detail.items.forEach(item => {
    item.orderQuantity = this.calculateRemainingOrder(item);
    item.unitPrice = 1; // Set unitPrice to 1 for each item
  });
  this.selectedWarehouseName = this.detail.warehouseName; // ตั้งค่าชื่อคลังให้ถูกต้อง
  this.selectedWarehouseId = this.detail.warehouseId; // เพิ่มการตั้งค่า id ของคลัง
  this.calculateAmounts(); // Calculate total amounts after setting order quantity
  this.showCreatePOModal = true;
},

  async createPO() {


 // เริ่มการยืนยันก่อนบันทึก
 const result = await Swal.fire({
    title: 'ยืนยันการสร้างใบสั่งซื้อ',
    text: "คุณต้องการสร้างใบสั่งซื้อนี้หรือไม่?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'สร้าง',
    cancelButtonText: 'ยกเลิก'
  });

  // ถ้าผู้ใช้ยืนยันการสร้างใบสั่งซื้อ
  if (!result.isConfirmed) {
    return;
  }




    this.loading = true;
  const token = localStorage.getItem('userToken');
  if (!token) {
    this.$router.push({ name: 'Login' });
    return;
  }

  if (!this.selectedSupplier) {
    Swal.fire(
      'เกิดข้อผิดพลาด',
      'กรุณาเลือกคู่ค้าก่อนสร้างใบสั่งซื้อ',
      'error'
      
    );
    this.loading = false;
    return;
    
  }

  if (this.form.creditTerms === null || this.form.creditTerms === undefined || isNaN(this.form.creditTerms)) {
  Swal.fire(
    'เกิดข้อผิดพลาด',
    'กรุณากรอกจำนวนเครดิต (วัน) เป็นตัวเลข', 
    'error'
  );
  this.loading = false;
  return;
}

  if (!this.selectedWarehouseId) { // ใช้ selectedWarehouseId แทน selectedWarehouse
    Swal.fire(
      'เกิดข้อผิดพลาด',
      'กรุณาเลือกสถานที่รับอะไหล่ก่อนสร้างใบสั่งซื้อ',
      'error'
    );
    this.loading = false;
    return;
  }

  const selectedItems = this.detail.items.filter(item => item.selected);

  if (selectedItems.length === 0) {
    Swal.fire(
      'เกิดข้อผิดพลาด',
      'กรุณาเลือกสินค้าอย่างน้อยหนึ่งรายการ',
      'error'
    );
    this.loading = false;
    return;
  }

  try {
    await axios.post('https://brk-group.org/api/purchase_orders', {
      po_number: `PO-${this.detail.purchaseRequestNumber}`,
      po_date: this.newPurchaseOrderDate,
      pr_id: this.$route.params.id,
      supplier_id: this.selectedSupplier.supplier_id,
      warehouse_id: this.selectedWarehouseId, // ส่ง warehouse_id แทน
      total_amount: parseFloat(this.form.totalAmount),
      discount_percent: parseFloat(this.form.discountPercent),
      amount_after_discount: parseFloat(this.form.amountAfterDiscount),
      vat_percent: parseFloat(this.form.vatPercent),
      vat_amount: parseFloat(this.form.vatAmount),
      grand_total: parseFloat(this.form.grandTotal),
      credit_terms: parseInt(this.form.creditTerms, 10), // เพิ่มจำนวนเครดิต
      remarks: this.form.remarks,
      status: 'รอการอนุมัติ',
      recorder_id: this.user.login_id,
      approver_id: null,
      items: selectedItems.map(item => ({
        product_id: item.productId,
        product_code: item.productCode,
        product_name: item.productName,
        product_type: item.productType,
        quantity: item.orderQuantity,
        unit: item.unit,
        unit_price: item.unitPrice,
        total_price: item.orderQuantity * item.unitPrice
      }))
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });

    // อัปเดตสถานะใบขอซื้อหากรายการคงเหลือสั่งซื้อเป็น 0
    await this.checkAndUpdatePRStatus(this.$route.params.id);

    this.showCreatePOModal = false;
    Swal.fire(
      'สำเร็จ!',
      'ใบสั่งซื้อของคุณถูกสร้างเรียบร้อยแล้ว',
      'success'
    );
    
//     if (this.$socket && typeof this.$socket.send === 'function') {
//   const message = `PO for PR ID: ${this.$route.params.id} has been created.`;
//   this.$socket.send(message);
// } else {
//   console.warn('Socket is not initialized or send method is not available.');
// }

    const prId = this.$route.params.id;
    await this.fetchPurchaseOrders(prId);
    await this.fetchDetail(prId);
  } catch (error) {
    console.error('Error creating purchase order:', error);
    if (error.response && error.response.status === 401) {
      this.$router.push({ name: 'Login' });
    } else {
      Swal.fire(
        'เกิดข้อผิดพลาด',
        'ไม่สามารถสร้างใบสั่งซื้อได้',
        'error'
      );

      } 
    } finally {
        this.loading = false;
      }
  },

    async checkAndUpdatePRStatus(prId) {
  const token = localStorage.getItem('userToken');
  try {
    const response = await axios.get(`https://brk-group.org/api/purchase_requisitions/${prId}/remaining_orders`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });

    const allCompleted = response.data.every(item => item.remainingOrder === 0);

    if (allCompleted) {
      await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/status`, 
      { status_pr: 'สั่งซื้อครบแล้ว' }, 
      { headers: { 'Authorization': `Bearer ${token}` } });

       // ส่งข้อความผ่าน WebSocket
       const message = {
        type: 'pr_status_updated',
        data: { pr_id: prId, status_pr: 'สั่งซื้อครบแล้ว' }
      };
      this.$socket.emit('message', message);
      Swal.fire(
        'สำเร็จ!',
        'สถานะใบขอซื้อถูกอัปเดตเป็นสำเร็จแล้ว',
        'success'
      );
    }
  } catch (error) {
    console.error('Error checking remaining orders:', error);
  }
},

  addItem() {
    this.detail.items.push({
      productCode: '',
      productName: '',
      productType: '',
      quantity: 0,
      unit: '',
      productAttachmentUrl: '',
      needsRevision: false // Ensure this matches the checkbox v-model
    });
  },
  async confirmApprovePR() {
      if (!this.revisionReason) {
        Swal.fire('เกิดข้อผิดพลาด', 'กรุณาใส่เหตุผลในการส่งกลับแก้ไข', 'error');
        return;
      }

      const result = await Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณต้องการส่งกลับแก้ไขใบ PR นี้หรือไม่",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'ยืนยันการส่งกลับแก้ไข',
        cancelButtonText: 'ปิด'
      });

      if (result.isConfirmed) {
        this.approvePR('รอผู้จัดการซ่อมแก้ไข');
      }
    },
    async approvePR() {
    const prId = this.$route.params.id;
    const reason = this.revisionReason; // ดึงเหตุผลที่ผู้ใช้กรอก
    const token = localStorage.getItem('userToken');
    this.isEditingProduct = true;

    if (!reason || reason.trim() === '') {
    Swal.fire('เกิดข้อผิดพลาด', 'กรุณาใส่เหตุผลในการอนุมัติ', 'error');
    this.isEditingProduct = false;
    return;
  }

    try {
      const payload = {
        status_pr: this.newStatus,
        reason: reason,
        approved_by: this.user.login_id // เพิ่ม id ของผู้อนุมัติ
      };
      console.log('Payload being sent to API:', payload); // ตรวจสอบ payload ที่จะถูกส่งไปยัง API
      const response = await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/status`, payload, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('API response:', response);
      this.detail.status_pr = this.newStatus;
      Swal.fire(
        'สำเร็จ!',
        `สถานะใบ PR ถูกเปลี่ยนเป็น ${this.newStatus} เรียบร้อยแล้ว`,
        'success'
      ).then(() => {
        // const message = `PR ID: ${prId} has been approved with status: ${this.newStatus}.`;
        // this.$socket.send(message);
          window.location.reload(); // รีเฟรชหน้าเมื่อกด "OK" บนการแจ้งเตือน
          // WebSocket message
       
      });
    } catch (error) {
      console.error('Error updating status:', error);
      Swal.fire(
        'ผิดพลาด!',
        'ไม่สามารถเปลี่ยนสถานะใบ PR ได้',
        'error'
      );
      } finally {
        this.isEditingProduct = false;
      }
    },
    // async updateStatus(newStatus) {
    //   const prId = this.$route.params.id;
    //   const token = localStorage.getItem('userToken');
   
    //   try {
    //     await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/status`, { status_pr: newStatus }, {
    //       headers: { 'Authorization': `Bearer ${token}` }
    //     });
    //     this.detail.status_pr = newStatus;
    //        // ส่งข้อความผ่าน WebSocket
    //        const message = {
    //   type: 'pr_status_updated',
    //   data: { pr_id: prId, status_pr: newStatus }
    // };
    // this.$socket.emit('message', message);
    //     Swal.fire(
    //       'สำเร็จ!',
    //       `สถานะใบ PR ถูกเปลี่ยนเป็น ${newStatus} เรียบร้อยแล้ว`,
    //       'success'
    //     );
    //   } catch (error) {
    //     console.error('Error updating status:', error);
    //     Swal.fire(
    //       'ผิดพลาด!',
    //       'ไม่สามารถเปลี่ยนสถานะใบ PR ได้',
    //       'error'
    //     );
    //   }
    // },
    async updateStatus(newStatus) {
  const prId = this.$route.params.id;
  const token = localStorage.getItem('userToken');

  // const userId = 'U6ae8cdfeead145840a29dad5444286b0'; // ใส่ user ID ที่ต้องการ
  this.loading = true; // เริ่มการโหลด
  try {
    await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/status`, 
    { 
      status_pr: newStatus,
      // user_id: userId // เพิ่ม user_id ใน request body
      approved_by: this.user.login_id // เพิ่ม user_id ใน request body
    }, 
    {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    
    this.detail.status_pr = newStatus;

    // ส่งข้อความผ่าน WebSocket
    // const message = {
    //   type: 'pr_status_updated',
    //   data: { pr_id: prId, status_pr: newStatus }
    // };
    // this.$socket.emit('message', message);

    Swal.fire(
      'สำเร็จ!',
      `สถานะใบ PR ถูกเปลี่ยนเป็น ${newStatus} เรียบร้อยแล้ว`,
      'success'
    ).then(() => {
      // รีเฟรชหน้าเว็บเมื่อ Swal ปิด
      location.reload();
    });
    
  } catch (error) {
    console.error('Error updating status:', error);
    Swal.fire(
      'ผิดพลาด!',
      'ไม่สามารถเปลี่ยนสถานะใบ PR ได้',
      'error'
    );
  } finally {
    this.loading = false; // ปิดการโหลด
  }
},
    updateSupplierDetails() {
      const selectedSupplier = this.suppliers.find(supplier => supplier.supplier_id === this.selectedSupplierId);
      this.selectedSupplierTaxId = selectedSupplier ? selectedSupplier.tax_id : '';
    },
  //   async cancelPR() {
  //   const result = await Swal.fire({
  //     title: 'คุณแน่ใจหรือไม่?',
  //     text: "คุณต้องการยกเลิกใบ PR นี้หรือไม่",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#d33',
  //     cancelButtonColor: '#3085d6',
  //     confirmButtonText: 'ยืนยันการยกเลิก',
  //     cancelButtonText: 'ปิด'
  //   });

  //   if (result.isConfirmed) {
  //     await this.updatePRStatus('ยกเลิก'); // เรียกฟังก์ชัน updatePRStatus เพื่อเปลี่ยนสถานะเป็น "ยกเลิก"
  //     Swal.fire(
  //       'ยกเลิกแล้ว!',
  //       'ใบ PR ของคุณถูกยกเลิกแล้ว',
  //       'success'
  //     );
  //   }
  // },
   // ฟังก์ชันยกเลิกใบ PR
   async cancelPR() {
  if (!this.cancelReason) {
    Swal.fire('เกิดข้อผิดพลาด', 'กรุณาใส่เหตุผลในการยกเลิก', 'error');
    return;
  }

  const result = await Swal.fire({
    title: 'คุณแน่ใจหรือไม่?',
    text: "คุณต้องการยกเลิกใบ PR นี้หรือไม่",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'ยืนยันการยกเลิก',
    cancelButtonText: 'ปิด'
  });

  if (result.isConfirmed) {
    const prId = this.$route.params.id;
    const token = localStorage.getItem('userToken');
    try {
      await axios.put(`https://brk-group.org/api/purchase_requisitions/${prId}/statuss`, 
      { status_pr: 'ยกเลิกใบ PR', revision_reasons: this.cancelReason }, 
      { headers: { 'Authorization': `Bearer ${token}` } });

      this.detail.status_pr = 'ยกเลิกใบ PR';
      Swal.fire('ยกเลิกแล้ว!', 'ใบ PR ของคุณถูกยกเลิกแล้ว', 'success');
        // ส่งข้อความไปยัง WebSocket
      const message = {
        type: 'pr_status_updated',
        data: { pr_id: prId, status_pr: 'ยกเลิกใบ PR' }
      };
      this.$socket.emit('message', message);
    } catch (error) {
      console.error('Error cancelling PR:', error);
      Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถยกเลิกใบ PR ได้', 'error');
    } finally {
      this.closeCancelPRModal();
    }
  }
},

  async closePR() {
    const result = await Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: "คุณต้องการปิดใบ PR นี้หรือไม่",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'ยืนยันการปิด',
      cancelButtonText: 'ปิด'
    });

    if (result.isConfirmed) {
      const newStatus = 'ปิดใบ PR'; // Define the status here
      await this.updatePRStatus(newStatus);
      Swal.fire(
        'ปิดแล้ว!',
        'ใบ PR ของคุณถูกปิดแล้ว',
        'success'
      );
      const message = `PR ID: ${this.$route.params.id} status updated to ${newStatus}.`;
      this.$socket.send(message);
    }
  },

  // async fetchPRDetail() {
  //   const token = localStorage.getItem('userToken');
  //   if (!token) {
  //     this.$router.push({ name: 'Login' });
  //     return;
  //   }

  //   try {
  //     const response = await axios.get(`https://brk-group.org/api/purchase_requisitions/${this.$route.params.id}`, {
  //       headers: { 'Authorization': `Bearer ${token}` }
  //     });
  //     this.detail = response.data;
  //   } catch (error) {
  //     console.error('Error fetching PR detail:', error);
  //   }
  // },
  async updatePRStatus(newStatus) {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      await axios.put(`https://brk-group.org/api/purchase_requisitions/${this.$route.params.id}/status`, 
      { status_pr: newStatus }, 
      {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.detail.status_pr = newStatus;
      Swal.fire(
        'สำเร็จ!',
        `สถานะใบ PR ถูกเปลี่ยนเป็น ${newStatus} เรียบร้อยแล้ว`,
        'success'
      );
      const message = {
      type: 'pr_status_updated',
      data: { pr_id: this.$route.params.id, status_pr: newStatus }
    };
    this.$socket.emit('message', message);
      // await this.fetchDetail(this.$route.params.id); // รีเฟรชข้อมูล PR
    } catch (error) {
      console.error('Error updating PR status:', error);
      Swal.fire(
        'ผิดพลาด!',
        'ไม่สามารถเปลี่ยนสถานะใบ PR ได้',
        'error'
      );
    }
  },
    calculateRemainingOrder(item) {
      // Assume we have a field "orderedQuantity" in each item to track the quantity already ordered in purchase orders
      return item.quantity - (item.orderedQuantity || 0);
    },
    validateOrderQuantity(item) {
    const remainingOrder = this.calculateRemainingOrder(item);
    if (item.orderQuantity < 0) {
      item.orderQuantity = 1;
    } else if (item.orderQuantity > remainingOrder) {
      item.orderQuantity = remainingOrder;
    }
  },
  validateUnitPrice(item) {
      if (item.unitPrice < 0) {
        item.unitPrice = 0;
      }
      this.calculateAmounts(); // Update totalAmount
    },
    calculateTotalAmount() {
      return this.detail.items.reduce((total, item) => {
        return total + (item.orderQuantity * item.unitPrice);
      }, 0);
    },
    calculateDiscountPercent() {
      if (this.form.totalAmount > 0) {
        this.form.discountPercent = (this.form.discountAmount / this.form.totalAmount) * 100;
        this.calculateAmounts(); // เรียกฟังก์ชันนี้เพื่อคำนวณจำนวนเงินอื่นๆ
      }
    },
    calculateAmounts() {
      let totalAmount = 0;
      for (const item of this.detail.items) {
        if (item.selected) {
          totalAmount += parseFloat(item.orderQuantity * item.unitPrice) || 0;
        }
      }
      const discountAmount = Math.min(this.form.discountAmount, totalAmount);
      const discountPercent = parseFloat(this.form.discountPercent) || 0;
      let amountAfterDiscount = totalAmount - (totalAmount * discountPercent / 100);
      let vatAmount = 0;
      let grandTotal = amountAfterDiscount;

      if (this.form.priceType === '1') { // รวม VAT
        const vatPercent = 7;
        const amountWithoutVat = amountAfterDiscount / (1 + vatPercent / 100);
        vatAmount = amountAfterDiscount - amountWithoutVat;
        grandTotal = amountAfterDiscount;
        this.form.vatPercent = 0;
      } else if (this.form.priceType === '2') { // แยก VAT
        const vatPercent = 7;
        vatAmount = amountAfterDiscount * vatPercent / 100;
        grandTotal = amountAfterDiscount + vatAmount;
        this.form.vatPercent = vatPercent;
      } else { // ไม่มี VAT
        this.form.vatPercent = 0;
      }
      this.form.discountPercent = discountPercent.toFixed(2);
      this.form.discountAmount = discountAmount.toFixed(2); // อัปเดตส่วนลดเป็นเงิ
      this.form.totalAmount = totalAmount.toFixed(2);
      this.form.amountAfterDiscount = amountAfterDiscount.toFixed(2);
      this.form.vatAmount = vatAmount.toFixed(2);
      this.form.grandTotal = grandTotal.toFixed(2);
    },
    handlePriceTypeChange() {
      if (this.form.priceType === '1') {
        this.form.vatPercent = 0;
      } else if (this.form.priceType === '2') {
        this.form.vatPercent = 7;
      }
      this.calculateAmounts();
    },
    calculateItemTotal(item) {
      item.totalPrice = (item.quantity * item.unitPrice).toFixed(2);
      this.calculateAmounts();
    },
    updateTotalAmount() {
    // คำนวณราคาหลังหักส่วนลด
    const discountAmount = this.form.totalAmount * (this.form.discountPercent / 100);
    this.form.amountAfterDiscount = this.form.totalAmount - discountAmount;

    // คำนวณภาษีมูลค่าเพิ่ม
    const vatAmount = this.form.amountAfterDiscount * (this.form.vatPercent / 100);
    this.form.vatAmount = vatAmount;

    // คำนวณราคาสุทธิ
    this.form.grandTotal = this.form.amountAfterDiscount + this.form.vatAmount;
  },
  },
  watch: {
    'selectedItem.new_product_id': {
      handler(newProductId) {
        if (newProductId) {
          this.fetchProductDetails(newProductId);
        } else {
          this.newProduct = null;
        }
      },
    },
    '$route.query.activeSection'(newVal) {
      this.activeSection = newVal; // อัปเดต activeSection เมื่อ query เปลี่ยน
    },
    productSearchQuery() {
      this.currentProductPage = 1;
    },
    productTypeSearchQuery() {
      this.currentProductPage = 1;
    },
    productBrandSearchQuery() {
      this.currentProductPage = 1;
    },
    selectedSupplier(newSupplierId) {
    const selectedSupplier = this.suppliers.find(supplier => supplier.supplier_id === newSupplierId);
    this.selectedSupplierTaxId = selectedSupplier ? selectedSupplier.tax_id : '';
  },
  isEdited(newVal) {
    if (newVal) {
      console.log('isEdited changed to true');
    }
  },
    'detail.items': {
      handler() {
        this.calculateAmounts();
        this.isEdited = true;
      },
      deep: true
    },
    'form.priceType': 'calculateAmounts', // เพิ่มการ watch สำหรับ priceType
     currentPage: 'fetchComparePrices'
  //     'form.discountPercent': 'calculateAmounts',
  // 'form.vatPercent': 'calculateAmounts'
  }
};
</script>

<style scoped>
@import 'vue-multiselect/dist/vue-multiselect.min.css';
.table-auto {
  width: 100%;
  border-collapse: collapse;
}

.table-auto th, .table-auto td {
  border: 1px solid #dee2e6;
  padding: 8px;
}

.table-auto thead th {
  background-color: #f8f9fa;
}

.table-auto tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
 .compare-price {
    width: 100%;
  }
  .order-summary {
    width: 100%;
  }
  .table-auto {
  table-layout: auto;
}

.spinner-border {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: white;
}

.modal-enter-active, .modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.pr-detail-container {
  position: relative;
  padding: 20px;
}

.watermark::before {
  content: 'ยกเลิกใบ PR';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: rgba(255, 0, 0, 0.3);
  z-index: 1;
  pointer-events: none;
}

.cancel-reason {
  margin-top: 20px;
  padding: 10px;
  background-color: #ffebeb;
  border: 1px solid #ffcccc;
  color: #ff0000;
  font-weight: bold;
}

.form-textarea {
  border: 1px solid #d1d5db; /* สีของกรอบ */
  border-radius: 8px; /* มุมของกรอบ */
  padding: 10px; /* ระยะห่างภายใน */
  font-size: 16px; /* ขนาดตัวอักษร */
  background-color: #f9fafb; /* สีพื้นหลัง */
  transition: border-color 0.2s; /* การเปลี่ยนสีกรอบ */
}

.form-textarea:focus {
  border-color: #3b82f6; /* สีของกรอบเมื่อถูกโฟกัส */
  outline: none; /* ลบเส้นโฟกัสดั้งเดิม */
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3); /* เพิ่มเงาเมื่อโฟกัส */
}

.form-textarea::placeholder {
  color: #9ca3af; /* สีของข้อความใน placeholder */
}

.z-60 {
  z-index: 60;
}
.z-50 {
  z-index: 50;
}
</style>