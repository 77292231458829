<template>
    <TransportLayout>
      <div class="container mx-auto px-4 py-6">
        <!-- Header -->
        <div class="flex justify-between items-center mb-6">
          <h1 class="text-2xl font-bold text-gray-800">รายการตั๋วขนส่ง</h1>
          <!-- <button 
            @click="exportTickets" 
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-9.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            ส่งออก
          </button> -->
          <button 
          v-if="selectedTickets.length > 0" 
          @click="openVerifyModal"
          class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Verify
        </button>
        </div>
  
        <!-- Filter Section -->
        <div class="bg-white shadow-md rounded-lg p-6 mb-6">
          <div class="grid md:grid-cols-3 gap-4">
            <!-- Multiselect ค้นหาชื่อ พขร. -->
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2">พขร.</label>
        <Multiselect 
          v-model="selectedDriver" 
          :options="driverOptions"
          placeholder="เลือก พขร."
          label="name"
          track-by="id"
        ></Multiselect>
      </div>
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">หน่วยงาน</label>
              <Multiselect 
                v-model="filterDepartment" 
                :options="departmentOptions"
                placeholder="เลือกหน่วยงาน"
                label="name"
                track-by="id"
              ></Multiselect>
            </div>
            
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">วันที่เริ่มงาน</label>
              <input 
                type="date" 
                v-model="startDateFilter"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>
            
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">วันที่จบงาน</label>
              <input 
                type="date" 
                v-model="endDateFilter"
                :min="startDateFilter"
                :disabled="!startDateFilter"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>

             <!-- Dropdown ยืนยัน -->
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">สถานะยืนยัน</label>
      <select 
        v-model="confirmationStatus" 
        class="w-full px-3 py-2 border rounded"
      >
        <option value="">ทั้งหมด</option>
        <option value="confirmed">ยืนยันแล้ว</option>
        <option value="not_confirmed">ยังไม่ยืนยัน</option>
      </select>
    </div>

          </div>
          
          <div class="mt-4 flex justify-end space-x-2">
            <button 
              @click="searchTickets"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
              ค้นหา
            </button>
            
            <button 
              @click="resetFilters"
              class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm14 5a1 1 0 00-1-1h-5a1 1 0 100 2h3.101a7.002 7.002 0 01-11.601 2.566 1 1 0 11-1.885.666A9.002 9.002 0 0016.101 7H11a1 1 0 010-2h5a1 1 0 011 1v5a1 1 0 11-2 0z" clip-rule="evenodd" />
              </svg>
              รีเซ็ต
            </button>
          </div>
        </div>
  
        <!-- Loading Spinner -->
        <div v-if="isLoading" class="flex justify-center items-center h-64">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
  
        <!-- Tickets Table -->
        <div v-else-if="tickets.length" class="bg-white shadow-md rounded-lg overflow-hidden">
            <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-100">
          <tr>
            <th class="px-4 py-3">
                  <input 
                    type="checkbox" 
                    @change="toggleSelectAll" 
                    :checked="allSelected"
                  />
                </th>
            <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              วันที่
            </th>
            <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              หมายเลขตั๋ว
            </th>
            <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              พขร.
            </th>
            <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              คู่รถ (เบอร์รถ + ทะเบียน)
            </th>
            <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              จำนวน Trip
            </th>
            <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              รวมระยะทาง
            </th>
            <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              การกระทำ
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr 
            v-for="ticket in tickets" 
            :key="ticket.ticket_id" 
            :class="{'bg-green-100 hover:bg-green-200': ticket.status === 'ยืนยันแล้ว'}"
            class="hover:bg-gray-50"
          >
          <td v-if="ticket.status !== 'ยืนยันแล้ว'" class="px-4 py-4">
    <input 
      type="checkbox" 
      :value="ticket.ticket_id" 
      v-model="selectedTickets"
    />
  </td>
  <td v-else class="px-4 py-4"></td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ formatDate(ticket.start_date) }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.ticket_number }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.fullnameDriver1 }} 
              {{ ticket.fullnameDriver2 ? `& ${ticket.fullnameDriver2}` : '' }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
  <template v-if="ticket.pairedcarcode">
    {{ ticket.pairedcarcode }} ({{ ticket.pairedcarlicense }})
  </template>
  <template v-else-if="ticket.unpairedCarCode">
    <span class="text-red-600 font-bold" title="รถคู่นี้ได้ถูกยกเลิกการจับคู่แล้ว">&#10005;</span>
    {{ ticket.unpairedCarCode }} ({{ ticket.unpairedCarLicense }})
  </template>
  <template v-else>
    <span class="text-red-600">ไม่มีข้อมูลรถ</span>
  </template>
</td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.total_trips }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.total_distance }} กม.
            </td>
            <td class="px-4 py-4 whitespace-nowrap text-center">
              
              <div class="flex justify-center space-x-2">
                <button 
                  @click="openTicketModal(ticket.ticket_id)"
                  class="text-blue-500 hover:text-blue-700 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button 
                v-if="ticket.status !== 'ยืนยันแล้ว'" 
                @click="editTicket(ticket.ticket_id)" 
                  class="text-green-500 hover:text-green-700 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button 
                v-if="ticket.status !== 'ยืนยันแล้ว'" 
                  @click="confirmDeleteTicket(ticket.ticket_id)" 
                  class="text-red-500 hover:text-red-700 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
</div>
      <!-- Pagination -->
      <div class="px-4 py-3 bg-gray-50 flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0">
        <span class="text-sm text-gray-700">
          แสดง {{ tickets.length }} รายการ จากทั้งหมด {{ totalTickets }}
        </span>
        <div class="flex items-center space-x-2">
          <button 
            @click="prevPage" 
            :disabled="currentPage === 1"
            class="px-3 py-1 bg-gray-200 text-gray-700 rounded disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-300"
          >
            ก่อนหน้า
          </button>
          <span class="text-sm text-gray-700">
            หน้า {{ currentPage }} / {{ totalPages }}
          </span>
          <button 
            @click="nextPage"
            :disabled="currentPage === totalPages"
            class="px-3 py-1 bg-gray-200 text-gray-700 rounded disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-300"
          >
            ถัดไป
          </button>
        </div>
      </div>
    </div>

    <!-- No Data State -->
    <div v-else class="text-center py-6 bg-white rounded-lg shadow">
      ไม่พบข้อมูลตั๋วขนส่ง
        </div>

<!-- Verify Modal -->
<div 
      v-if="isVerifyModalOpen" 
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div class="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 class="text-xl font-bold mb-4">ยืนยันตั๋วขนส่ง</h2>
        <form @submit.prevent="submitVerification">
          <div>
            <label class="block text-gray-700 mb-2">แนบไฟล์ตั๋ว:</label>
            <input type="file" @change="handleFileUpload" />
          </div>
          <div v-if="filePreview" class="mt-4">
     <p class="text-gray-600 text-sm mb-2">ตัวอย่างไฟล์ที่แนบ:</p>
     <!-- Image Preview -->
     <img 
       v-if="isImageFile(file.type)"
       :src="filePreview" 
       alt="Preview" 
       class="rounded shadow-md w-full max-h-96 object-cover"
     />
     <!-- PDF Preview -->
     <div v-else-if="isPDFFile(file.type)" class="bg-blue-50 border border-blue-200 p-4 rounded flex items-center">
  <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mr-3 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0013.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
  </svg>
  <div>
    <p class="text-sm font-semibold text-blue-800">📄 ไฟล์ PDF</p>
    <p class="text-xs text-gray-600">{{ file.name }}</p>
    <p class="text-xs text-gray-500">ขนาด: {{ formatFileSize(file.size) }}</p>
    <p class="text-xs text-gray-500">วันที่อัปโหลด: {{ new Date().toLocaleDateString('th-TH') }}</p>
  </div>
</div>
     <!-- Excel Preview -->
     <div v-else-if="isExcelFile(file.type)" class="bg-green-100 p-4 rounded">
       <p>📊 ไฟล์ Excel: {{ file.name }}</p>
     </div>
     </div>
          <div class="mt-6 flex justify-end space-x-4">
            <button 
              type="button" 
              @click="closeVerifyModal" 
              class="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
            >
              ยกเลิก
            </button>
            <button 
              type="submit"
              class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              ยืนยัน
            </button>
          </div>
        </form>
      </div>
    </div>


        <!-- modal รายละเอียดขนส่ง -->
        <div 
  v-if="isViewModalOpen" 
  class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black bg-opacity-50"
>
  <div class="relative w-full max-w-5xl mx-auto my-6">
    <div class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg">
      <!-- Header -->
      <div class="flex items-start justify-between p-5 border-b border-solid rounded-t">
        <h3 class="text-xl font-semibold">รายละเอียดตั๋วขนส่ง #{{ viewTicketData.ticketNumber }}</h3>
        <button 
          @click="isViewModalOpen = false"
          class="text-red-400 hover:text-red-600 text-2xl"
        >
          &times;
        </button>
      </div>

      <!-- Modal Body -->
      <div class="relative p-6 flex-auto max-h-[80vh] overflow-y-auto">
        <!-- ข้อมูลตั๋วขนส่ง -->
        <div class="bg-gray-50 p-4 rounded-lg mb-6">
          <h4 class="text-lg font-bold text-gray-700 mb-4">ข้อมูลตั๋วขนส่ง</h4>
          <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div>
  <label class="block text-gray-600 text-sm font-semibold mb-1">หน่วยงาน</label>
  <div class="bg-white border rounded p-2">   {{ viewTicketData.departmentId ? viewTicketData.departmentId.name : 'ไม่ระบุ' }}</div>
</div>
            <div>
              <label class="block text-gray-600 text-sm font-semibold mb-1">กลุ่มงาน</label>
              <div class="bg-white border rounded p-2">{{ viewTicketData.workGroupId ? viewTicketData.workGroupId.name : 'ไม่ระบุ' }}</div>
            </div>
            <div>
  <label class="block text-gray-600 text-sm font-semibold mb-1">วันที่เริ่มงาน</label>
  <div class="bg-white border rounded p-2">{{ viewTicketData.startDate }}</div>
</div>
<div>
  <label class="block text-gray-600 text-sm font-semibold mb-1">วันที่จบงาน</label>
  <div class="bg-white border rounded p-2">
    {{ viewTicketData.completionDate  }}
  </div>
</div>
<div>
      <label class="block text-gray-600 text-sm font-semibold mb-1">พนักงานขับรถคนที่ 1</label>
      <div class="bg-white border rounded p-2">
        {{ viewTicketData.driver1Id ? viewTicketData.driver1Id.fullName : 'ไม่ระบุ' }}
      </div>
    </div>

    <div>
      <label class="block text-gray-600 text-sm font-semibold mb-1">พนักงานขับรถคนที่ 2</label>
      <div class="bg-white border rounded p-2">
        {{ viewTicketData.driver2Id ? viewTicketData.driver2Id.fullName : 'ไม่ระบุ' }}
      </div>
    </div>
    <div>
      <label class="block text-gray-600 text-sm font-semibold mb-1">คู่รถ</label>
      <div class="bg-white border rounded p-2">
        {{ viewTicketData.vehiclePairId ? viewTicketData.vehiclePairId.vehicleCodeLabel2 : 'ไม่ระบุ' }}
      </div>
    </div>
            <div>
              <label class="block text-gray-600 text-sm font-semibold mb-1">จำนวน Trip</label>
              <div class="bg-white border rounded p-2">{{ viewTicketData.totalTrips }}</div>
            </div>
            <div>
              <label class="block text-gray-600 text-sm font-semibold mb-1">รวมระยะทาง</label>
              <div class="bg-white border rounded p-2">{{ viewTicketData.totalDistance }} กม.</div>
            </div>
            <!-- <div>
              <label class="block text-gray-600 text-sm font-semibold mb-1">หมายเหตุ</label>
              <div class="bg-white border rounded p-2">{{ viewTicketData.remarks || 'ไม่มีหมายเหตุ' }}</div>
            </div> -->
          </div>
        </div>
        <div v-if="viewTicketData.attachment" class="bg-gray-50 p-4 rounded-lg mb-6">
  <h4 class="text-lg font-bold text-gray-700 mb-4">ไฟล์แนบ</h4>
  <div class="flex items-center space-x-4">
    <button 
      @click="previewAttachment(viewTicketData.attachment)"
      class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
        <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
      </svg>
      ดูไฟล์แนบ
    </button>
    <!-- <a 
    @click.prevent="downloadAttachment(viewTicketData.id)"
  class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-9.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
  </svg>
  ดาวน์โหลด
</a> -->
  </div>
</div>
<!-- รายการ Trips -->
<h4 class="text-lg font-bold text-gray-700 mb-4">รายการ Trips</h4>
<div class="overflow-x-auto">
  <table class="min-w-full bg-white border border-gray-400">
    <thead>
      <tr class="bg-gray-300 text-gray-600 uppercase text-sm leading-normal">
        <th class="py-3 px-6 text-left w-full">ลำดับ</th>
        <th class="py-3 px-6 text-left w-full">หมายเลขตั๋ว</th>
        <th class="py-3 px-6 text-left w-full">ประเภทสินค้า</th>
        <th class="py-3 px-6 text-left w-full">เที่ยวที่</th>
        <th class="py-3 px-6 text-left w-full">ความจุ</th>
        <th class="py-3 px-6 text-center w-1/3">เส้นทาง</th>
        <th class="py-3 px-6 text-left w-full">ระยะทาง</th>
        <th class="py-3 px-6 text-left w-full">ค่าขนส่ง</th>
        <th class="py-3 px-6 text-left w-full">ค่าทางด่วน</th>
        <th class="py-3 px-6 text-left w-full">นอนโหลด</th>
        <th class="py-3 px-6 text-left w-full">หมายเหตุ</th>
      </tr>
    </thead>
    <tbody class="text-gray-600 text-sm font-light">
      <tr v-for="(trip, index) in viewTrips" :key="index" class="border-b border-gray-200 hover:bg-gray-100">
        <td class="py-3 px-6">{{ index + 1 }}</td>
        <td class="py-3 px-6">{{ trip.ticketNumber }}</td>
        <td class="py-3 px-6">{{ trip.productType ? trip.productType.name : 'ไม่ระบุ' }}</td>
        <td class="py-3 px-6">{{ trip.tripSequence }}</td>
        <td class="py-3 px-6">{{ trip.capacity }}</td>
        <td class="py-3 px-6">{{ trip.route ? trip.route.name : 'ไม่ระบุ' }}</td>
        <td class="py-3 px-6">{{ trip.distance }} กม.</td>
        <td class="py-3 px-6">{{ trip.transportFee }} บาท</td>
        <td class="py-3 px-6">{{ trip.tollFee }} บาท</td>
        <td class="py-3 px-6">{{ trip.loadStatus || 'ไม่มีข้อมูล' }}</td>
        <td class="py-3 px-6">{{ trip.remarks || 'ไม่มีหมายเหตุ' }}</td>
      </tr>
    </tbody>
  </table>
</div>
      </div>

      <!-- Modal Footer -->
      <div class="flex items-center justify-end p-6 border-t border-solid rounded-b">
        <button
          @click="isViewModalOpen = false"
          class="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
        >
          ปิด
        </button>
      </div>
    </div>
  </div>
</div>





<!-- modal แก้ไข -->
<div 
  v-if="isEditModalOpen" 
  class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black bg-opacity-50"
>
  <div class="relative w-full max-w-5xl mx-auto my-6">
    <div class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg">
      <!-- Header -->
      <div class="flex items-start justify-between p-5 border-b border-solid rounded-t">
        <h3 class="text-xl font-semibold">แก้ไขตั๋วขนส่ง</h3>
        <button 
          @click="closeEditModal"
          class="text-gray-400 hover:text-gray-600 text-2xl"
        >
          &times;
        </button>
      </div>

      <!-- Modal Body -->
      <div class="relative p-6 flex-auto max-h-[80vh] overflow-y-auto">
        <!-- ข้อมูลตั๋วขนส่ง -->
        <h4 class="text-lg font-bold text-gray-700 mb-4">ข้อมูลตั๋วขนส่ง</h4>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
          <!-- หน่วยงาน -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">หน่วยงาน</label>
            <multiselect 
              v-model="editTicketData.departmentId" 
              :options="departmentOptions" 
              placeholder="เลือกหน่วยงาน"
              label="name"
              track-by="id"
            />
          </div>

          <!-- กลุ่มงาน -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">กลุ่มงาน</label>
            <multiselect 
              v-model="editTicketData.workGroupId" 
              :options="workGroupOptions" 
              placeholder="เลือกกลุ่มงาน"
              label="name"
              track-by="id"
            />
          </div>

          <!-- วันที่เริ่มงาน -->
          <div>
  <label class="block text-gray-700 text-sm font-bold mb-2">วันที่เริ่มงาน</label>
  <input 
  v-model="editTicketData.startDate" 
  type="date" 
  class="w-full px-3 py-2 border rounded"
/>
</div>

          <!-- วันที่จบงาน -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">วันที่จบงาน</label>
            <input 
  v-model="editTicketData.completionDate" 
  type="date" 
  :min="editTicketData.startDate"
  class="w-full px-3 py-2 border rounded"
/>
          </div>

          <!-- จำนวน Trip -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">จำนวน Trip</label>
            <input 
              :value="trips.length" 
              disabled 
              class="w-full px-3 py-2 border rounded bg-gray-100"
            />
          </div>

          <!-- รวมระยะทาง -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">รวมระยะทาง</label>
            <input 
              :value="calculateOverallDistance()" 
              disabled 
              class="w-full px-3 py-2 border rounded bg-gray-100"
            />
          </div>

          <!-- พขร.1 -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">พนักงานขับรถคนที่ 1</label>
            <multiselect 
              v-model="editTicketData.driver1Id" 
              :options="employeeOptions" 
              placeholder="เลือก พขร.1"
              label="fullName"
              track-by="id"
            />
          </div>

          <!-- พขร.2 -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">พนักงานขับรถคนที่ 2</label>
            <multiselect 
              v-model="editTicketData.driver2Id" 
              :options="employeeOptions" 
              placeholder="เลือก พขร.2"
              label="fullName"
              track-by="id"
            />
          </div>

          <!-- คู่รถ -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">คู่รถ</label>
            <multiselect 
              v-model="editTicketData.vehiclePairId" 
              :options="vehiclePairOptions" 
              placeholder="เลือกคู่รถ"
              label="vehicleCodeLabel"
              track-by="id"
            />
          </div>
        </div><br>
<!-- เพิ่มปุ่ม เพิ่ม Trip -->
<div>
  <button 
    @click="addTrip" 
    :disabled="trips.length >= 6"
    class="bg-blue-500 text-white font-bold px-4 py-2 rounded shadow hover:bg-blue-600 transition"
  >
    + เพิ่ม Trip
  </button>
</div>
        <!-- รายการ Trips -->
        <h4 class="text-lg font-bold text-gray-700 mt-6 mb-4">รายการ Trips</h4>
        <div class="space-y-6">
          <div 
            v-for="(trip, index) in trips" 
            :key="index" 
            class="grid grid-cols-2 md:grid-cols-3 gap-4 border p-4 rounded"
          >
    <!-- ปุ่มลบอยู่ใน div ของแต่ละ trip -->
    <div class="col-span-full flex justify-end">
    <button 
      @click="deleteTrip(index)" 
      class="text-red-500 hover:text-white bg-red-100 hover:bg-red-500 px-3 py-1 rounded transition-colors"
    >
      🗑️ ลบ
    </button>
  </div>

            <!-- Trip No -->
            <div>
              
              <label class="block text-gray-700 text-sm font-bold mb-2">Trip No</label>
              <input 
                type="text" 
                :value="`Trip ${index + 1}`" 
                disabled 
                class="w-full px-3 py-2 border rounded bg-gray-100"
              />
            </div>

            <!-- หมายเลขตั๋ว -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">หมายเลขตั๋ว</label>
              <input 
                v-model="trip.ticketNumber" 
                class="w-full px-3 py-2 border rounded"
                placeholder="กรอกหมายเลขตั๋ว"
              />
            </div>

            <!-- ประเภทสินค้า -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทสินค้า</label>
              <multiselect 
                v-model="trip.productType" 
                :options="productTypeOptions"
                placeholder="เลือกประเภทสินค้า"
                label="name"
                track-by="id"
              />
            </div>

            <!-- เที่ยวที่ -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">เที่ยวที่</label>
              <input 
                v-model="trip.tripSequence" 
                type="number" 
                readonly
                class="w-full px-3 py-2 border rounded"
                placeholder="กรอกเที่ยวที่"
              />
            </div>

            <!-- ความจุ -->
            <div>
  <label class="block text-gray-700 text-sm font-bold mb-2">ความจุ</label>
  <select 
    v-model="trip.capacity" 
    class="w-full px-3 py-2 border rounded"
  >
    <option disabled value="">กรุณาเลือกความจุ</option>
    <option value="43000">43,000</option>
    <!-- <option value="45000">45,000</option>
    <option value="50000">50,000</option> -->
  </select>
</div>

            <!-- เส้นทาง -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">เส้นทาง</label>
              <multiselect 
                v-model="trip.route" 
                :options="routeOptions"
                placeholder="เลือกเส้นทาง"
                label="name"
                track-by="id"
                 @input="updateRouteDistance(trip, $event?.id)"
              />
            </div>

            <!-- ระยะทาง -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ระยะทาง (กม.)</label>
              <input 
                v-model="trip.distance" 
                type="number" 
                readonly
                class="w-full px-3 py-2 border rounded"
                placeholder="ระยะทาง"
              />
            </div>

            <!-- ค่าขนส่ง -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ค่าขนส่ง</label>
              <input 
                v-model="trip.transportFee" 
                type="number" 
                readonly
                class="w-full px-3 py-2 border rounded"
                placeholder="ค่าขนส่ง"
              />
            </div>

            <!-- ค่าทางด่วน -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ค่าทางด่วน</label>
              <input 
                v-model="trip.tollFee" 
                type="number" 
                class="w-full px-3 py-2 border rounded"
                placeholder="ค่าทางด่วน"
              />
            </div>

            <!-- Checkbox นอนโหลด -->
            <div>
              <label class="flex items-center text-gray-700 text-sm font-bold mb-2">
                <input 
                  type="checkbox" 
                  v-model="trip.isLoadStatusEnabled"
                  class="mr-2"
                />
                นอนโหลด
              </label>
            </div>

            <!-- นอนโหลด -->
            <div v-if="trip.isLoadStatusEnabled">
              <label class="block text-gray-700 text-sm font-bold mb-2">นอนโหลด</label>
              <multiselect 
                v-model="trip.loadStatus" 
                :options="loadStatusOptions"
                placeholder="เลือกสถานะโหลด"
              ></multiselect>
            </div>

            <!-- หมายเหตุ -->
            <div class="col-span-2 md:col-span-3">
              <label class="block text-gray-700 text-sm font-bold mb-2">หมายเหตุ</label>
              <textarea 
                v-model="trip.remarks"
                class="w-full px-3 py-2 border rounded"
                placeholder="กรอกหมายเหตุ"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Footer -->
      <div class="flex items-center justify-end p-6 border-t border-solid rounded-b">
        <button
          @click="closeEditModal"
          class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-4"
        >
          ยกเลิก
        </button>
        <button
          @click="confirmSaveEditDetails"
          class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
        >
          บันทึก
        </button>
      </div>
    </div>
  </div>
</div>





      </div>
    </TransportLayout>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import TransportLayout from '@/components/transport/TransportSidebar.vue'
  
  export default {
    name: 'ViewTicket',
    components: {
      TransportLayout,
      Multiselect
    },
    data() {
      return {
        isLoadStatusEnabled: false, // ควบคุมสถานะโหลด
        tickets: [],
        selectedTickets: [], // ID ของตั๋วที่เลือก
        isVerifyModalOpen: false,
      allSelected: false,
      file: null, // ไฟล์ที่แนบ
      filePreview: null, // ตัวอย่างไฟล์
     vehiclePairLogs: [],
        totalTickets: 0,
        currentPage: 1,
        totalPages: 1,
        filterDepartment: null,
        startDateFilter: null,
        endDateFilter: null,
        confirmationStatus: '', // For confirmed/not confirmed filter
        isLoading: false,
        selectedDriver: null, // ฟิลด์ใหม่สำหรับเลือก พขร.
        departmentOptions: [],
        driverOptions: [], // ตัวเลือก พขร.
        isViewModalOpen: false, // สำหรับเปิด/ปิด Modal
        selectedTicket: null, // เก็บข้อมูลตั๋วที่เลือก
        isEditModalOpen: false, // เปิด/ปิด Modal
        viewTicketData: {},
        viewTrips: [],
        loading: false,
    loadStatusOptions: [
        'NTM', 
        'NSG', 
        'NOH',
        'PTO',
        'STN'
      ],
   
        editTicketData: {
      tripNo: '',
      driver1Id: null,
      driver2Id: null,
      vehiclePairId: null,
      ticketNumber: '',
      productTypeId: null,
      tripSequence: null,
      capacity: null,
      routeId: null,
      distance: null,
      transportFee: null,
      tollFee: null,
      loadStatus: null,
      startDate: null,
      completionDate: null,
      remarks: ''
    },
    deleteTrips: [],
    trips: [], // รายการ trips
    employeeOptions: [],
    vehiclePairOptions: [],
    productTypeOptions: [],
    routeOptions: [],
      }
    },

    computed: {
      isCompletionDateValid() {
    // ตรวจสอบว่า completionDate ไม่เป็นค่าว่างและมากกว่าหรือเท่ากับ startDate
    return this.viewTicketData.completionDate && this.viewTicketData.completionDate >= this.viewTicketData.startDate;
  },
  formattedStartDate: {
    get() {
      return this.formatDateForInput(this.editTicketData.startDate);
    },
    set(newValue) {
      this.editTicketData.startDate = newValue;
    }
  },
  formattedcompletionDate: {
    get() {
      return this.formatDateForInput(this.editTicketData.completionDate);
    },
    set(newValue) {
      this.editTicketData.completionDate = newValue;
    }
  },
},

    methods: {
      async downloadAttachment(ticketId) {
  if (!ticketId) {
    console.error('No ticket ID provided');
    Swal.fire({
      icon: 'error', 
      title: 'Download Error',
      text: 'ไม่มีรหัสตั๋ว'
    });
    return;
  }

  try {
    const response = await axios({
      url: `https://brk-group.org/api/ts/download-attachment/${ticketId}`,
      method: 'GET',
      responseType: 'blob',
      // เพิ่ม timeout เพื่อป้องกันการรอนาน
      timeout: 10000 
    });

    // เพิ่มการตรวจสอบเพิ่มเติม
    if (response.data.size === 0) {
      throw new Error('ไฟล์ว่างเปล่า');
    }

    // ดึง filename จาก headers
    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition 
      ? decodeURIComponent(contentDisposition.split('filename=')[1]?.replace(/"/g, '') || 'download')
      : 'download';

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

  } catch (error) {
    console.error('Download error:', error);
    
    // จัดการข้อผิดพลาดที่แตกต่างกัน
    if (error.response) {
      // The request was made and the server responded with a status code
      Swal.fire({
        icon: 'error', 
        title: 'Download Error',
        text: error.response.data.error || 'ไม่สามารถดาวน์โหลดไฟล์ได้'
      });
    } else if (error.request) {
      // The request was made but no response was received
      Swal.fire({
        icon: 'error', 
        title: 'Network Error',
        text: 'ไม่สามารถติดต่อเซิร์ฟเวอร์ได้'
      });
    } else {
      // Something happened in setting up the request
      Swal.fire({
        icon: 'error', 
        title: 'Error',
        text: error.message || 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ'
      });
    }
  }
},
      previewAttachment(filePath) {
  const baseUrl = 'https://brkmeeting-room.online/Ticket/';
  
  // ใช้ฟังก์ชัน split() เพื่อดึงชื่อไฟล์จาก path
  const fileName = filePath.split('/').pop(); // ดึงชื่อไฟล์จาก path

  // สร้าง URL ใหม่ที่แสดงแค่ baseUrl + ชื่อไฟล์
  const fileUrl = baseUrl + fileName;

  // เปิดไฟล์ในโหมดพรีวิว
  window.open(fileUrl, '_blank');
}
,
      toggleSelectAll() {
  this.allSelected = !this.allSelected;
  this.selectedTickets = this.allSelected
    ? this.tickets
        .filter(ticket => ticket.status !== 'ยืนยันแล้ว')
        .map(ticket => ticket.ticket_id)
    : [];
},
    openVerifyModal() {
      if (this.selectedTickets.length === 0) return;
      this.isVerifyModalOpen = true;
    },
   
    handleFileUpload(event) {
     const selectedFile = event.target.files[0];

     if (!selectedFile) return;

     try {
       // ตรวจสอบประเภทไฟล์
       if (!this.isValidFileType(selectedFile.type)) {
         alert('กรุณาเลือกไฟล์ที่ถูกต้อง (รูปภาพ, PDF, Excel)');
         return;
       }

       // ตรวจสอบขนาดไฟล์ (จำกัด 10MB)
       const maxSizeInBytes = 10 * 1024 * 1024;
       if (selectedFile.size > maxSizeInBytes) {
         alert('ขนาดไฟล์ต้องไม่เกิน 10MB');
         return;
       }

       // บันทึกไฟล์และ URL preview
       this.file = selectedFile;
       
       // สร้าง preview สำหรับรูปภาพ
       if (this.isImageFile(selectedFile.type)) {
         this.filePreview = URL.createObjectURL(selectedFile);
       }  else if (this.isPDFFile(selectedFile.type)) {
        this.filePreview = URL.createObjectURL(selectedFile);
    } else {
      this.filePreview = null;
    }
     } catch (error) {
       this.resetFileUpload();
     }
   },
      // ตรวจสอบประเภทไฟล์
      isValidFileType(fileType) {
     const validTypes = [
       // รูปภาพ
       'image/jpeg', 'image/png', 'image/gif', 'image/webp',
       // PDF
       'application/pdf',
       // Excel
       'application/vnd.ms-excel', 
       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
     ];
     return validTypes.includes(fileType);
   },
    // ตรวจสอบว่าเป็นไฟล์รูปภาพ
   isImageFile(fileType) {
     const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
     return imageTypes.includes(fileType);
   },
    // ตรวจสอบว่าเป็นไฟล์ PDF
    isPDFFile(fileType) {
     return fileType === 'application/pdf';
   },

   // ตรวจสอบว่าเป็นไฟล์ Excel
   isExcelFile(fileType) {
     const excelTypes = [
       'application/vnd.ms-excel', 
       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
     ];
     return excelTypes.includes(fileType);
   },

    resetFileUpload() {
     this.file = null;
     this.filePreview = null;
  
    },
  beforeDestroy() {
    // ล้าง URL เมื่อ component ถูกทำลาย
    if (this.filePreview) {
      URL.revokeObjectURL(this.filePreview)
    }
  },
  formatFileSize(bytes) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
},
    closeVerifyModal() {
      this.isVerifyModalOpen = false;
      this.file = null;
      this.filePreview = null;
    },

    
    async submitVerification() {
  // ตรวจสอบว่ามีตั๋วที่เลือกหรือไม่
  if (this.selectedTickets.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'คำเตือน',
      text: 'กรุณาเลือกตั๋วที่ต้องการยืนยัน',
      confirmButtonText: 'ตกลง',
    });
    return;
  }

  // ตรวจสอบว่ามีไฟล์แนบหรือไม่
  if (!this.file) {
    Swal.fire({
      icon: 'warning',
      title: 'คำเตือน',
      text: 'กรุณาแนบไฟล์',
      confirmButtonText: 'ตกลง',
    });
    return;
  }

  try {
    const formData = new FormData();
    formData.append('file', this.file);
    
    // กรณีไม่มีตั๋วที่เลือก ส่ง '[null]'
    const ticketIdsToVerify = this.selectedTickets.length > 0 
    ? this.selectedTickets.map(id => id) 
    : [null];
    formData.append('ticketIds', JSON.stringify(ticketIdsToVerify));

    // เริ่มต้นการแสดงการโหลด
    Swal.fire({
      title: 'กำลังยืนยัน...',
      text: 'โปรดรอสักครู่',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // ส่งข้อมูลไปยัง Backend
    const response = await axios.post('https://brk-group.org/api/ts/verify-tickets', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // แจ้งเตือนเมื่อสำเร็จ
    Swal.fire({
      icon: 'success',
      title: 'ยืนยันสำเร็จ!',
      html: `
        <p>ข้อมูลตั๋วได้รับการยืนยันเรียบร้อยแล้ว</p>
        <p>จำนวนตั๋วที่ยืนยัน: ${response.data.updatedTickets || ticketIdsToVerify.length} ตั๋ว</p>
      `,
      confirmButtonText: 'ตกลง',
    });

    // รีเซ็ตค่าหลังจากยืนยันสำเร็จ
    this.closeVerifyModal();
    this.selectedTickets = []; // ล้างรายการตั๋วที่เลือก
    this.file = null; // ล้างไฟล์
    this.filePreview = null; // ล้างตัวอย่างไฟล์
    this.allSelected = false; // ยกเลิกการเลือกทั้งหมด

    // โหลดข้อมูลใหม่
    this.fetchTickets(); 

  } catch (error) {
    console.error('Error verifying tickets:', error);

    // แจ้งเตือนเมื่อเกิดข้อผิดพลาด
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: error.response?.data?.error || 'ไม่สามารถยืนยันข้อมูลได้ กรุณาลองใหม่',
      footer: error.response?.data?.details || '',
      confirmButtonText: 'ตกลง',
    });
  }
},
      calculateOverallDistance() {
  return this.trips.reduce((total, trip) => total + (Number(trip.distance) || 0), 0);
}
,
async fetchInitialData() {
  try {
    // ดึงข้อมูลพร้อมกัน
    const [
      employeesResponse,
      vehiclePairsResponse,
      departmentsResponse,
      workGroupsResponse,
      productTypesResponse,
      routesResponse
    ] = await Promise.allSettled([
      axios.get('https://brk-group.org/api/ts/employees'),
      axios.get('https://brk-group.org/api/ts/vehicle-pairs'),
      axios.get('https://brk-group.org/api/ts/departments'),
      axios.get('https://brk-group.org/api/ts/groups'),
      axios.get('https://brk-group.org/api/ts/product-types'),
      axios.get('https://brk-group.org/api/ts/routes')
    ]);

    // พนักงาน
    if (employeesResponse.status === 'fulfilled') {
      this.employeeOptions = employeesResponse.value.data.map(emp => ({
        id: emp.id,
        fullName: `${emp.prefix || ''} ${emp.firstName || ''} ${emp.lastName || ''}`.trim(),
        employeeCode: emp.employee_code || ''
      }));
    } else {
      console.error('Error fetching employees:', employeesResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลพนักงานได้'
      });
    }

    // คู่รถ
    if (vehiclePairsResponse.status === 'fulfilled') {
      this.vehiclePairOptions = vehiclePairsResponse.value.data.map(pair => ({
        id: pair.id,
        departmentId: pair.departmentId || null,
        departmentName: pair.departmentName || '',
        groupId: pair.groupId || null,
        groupName: pair.groupName || '',
        headLicensePlate: pair.headLicensePlate || '',
        headVehicleCode: pair.headVehicleCode || '',
        trailerLicensePlate: pair.trailerLicensePlate || '',
        trailerVehicleCode: pair.trailerVehicleCode || '',
        vehicleCodeLabel: `${pair.headVehicleCode || ''} + ${pair.trailerVehicleCode || ''}`.trim(),
        vehicleCodeLabel2: `${pair.headVehicleCode || ''} - ${pair.trailerVehicleCode || ''} ( ${pair.headLicensePlate || ''} - ${pair.trailerLicensePlate || ''} )`.trim()
      }));
    } else {
      console.error('Error fetching vehicle pairs:', vehiclePairsResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลคู่รถได้'
      });
    }

    // หน่วยงาน
    if (departmentsResponse.status === 'fulfilled') {
      this.departmentOptions = departmentsResponse.value.data.map(department => ({
        id: Number(department.id),
        name: department.name
      }));
    } else {
      console.error('Error fetching departments:', departmentsResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลหน่วยงานได้'
      });
    }

    // กลุ่มงาน
    if (workGroupsResponse.status === 'fulfilled') {
      this.workGroupOptions = workGroupsResponse.value.data.map(group => ({
        id: group.id,
        name: group.name
      }));
    } else {
      console.error('Error fetching work groups:', workGroupsResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลกลุ่มงานได้'
      });
    }

    // ประเภทสินค้า
    if (productTypesResponse.status === 'fulfilled') {
      this.productTypeOptions = productTypesResponse.value.data.map(option => ({
        id: option.id,
        name: option.name
      }));
    } else {
      console.error('Error fetching product types:', productTypesResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลประเภทสินค้าได้'
      });
    }

    // เส้นทาง
    if (routesResponse.status === 'fulfilled') {
      const routes = routesResponse.value.data;
      this.routeOptions = routes.map(route => ({
        id: route.id,
        name: `${route.route_name} (ต้นทาง: ${route.origin || ''}, ปลายทาง: ${route.destination || ''})`,
        distance_km: route.distance_km || 0,
        transport_fee: route.transport_fee || 0
      }));
      this.routeDestinationOptions = routes.map(route => ({
        id: route.id,
        name: route.destination || ''
      }));
    } else {
      console.error('Error fetching routes:', routesResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลเส้นทางได้'
      });
    }

  } catch (error) {
    console.error('Error in fetchInitialData:', error);
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'ไม่สามารถโหลดข้อมูลเริ่มต้นได้ กรุณาลองอีกครั้ง'
    });
  }
//   console.log('Department Options:', this.departmentOptions);
// console.log('WorkGroup Options:', this.workGroupOptions);
// console.log('Employee Options:', this.employeeOptions);
// console.log('VehiclePair Options:', this.vehiclePairOptions);

}
,
async updateRouteDistance(trip) {
    try {
      // ตรวจสอบว่ามีการเลือกเส้นทาง
      if (trip.route && trip.route.id) {
        const response = await axios.get(`https://brk-group.org/api/ts/route-distance/${trip.route.id}`);
        
       // อัปเดตระยะทางและค่าใช้จ่าย
      trip.distance = response.data.distance || 0;
      trip.transportFee = response.data.transport_fee || 0; // Update trip cost
    } else {
      // หากไม่มีการเลือกเส้นทาง ให้ระยะทางและค่าใช้จ่ายเป็น 0
      trip.distance = 0;
      trip.transportFee = 0; // Set cost to 0 if no route is selected
    
      }
    } catch (error) {
      console.error('Error fetching route distance:', error);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถดึงข้อมูลระยะทางได้'
      });
  }
},
formatDateForInput(dateString) {
    if (!dateString) return '';

    try {
      // Handle different possible input formats
      const date = new Date(dateString);
      
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateString);
        return '';
      }

      // Ensure consistent YYYY-MM-DD format
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error('Error parsing date:', error);
      return '';
    }
  },
  
  // เมธอดแปลงกลับเป็นรูปแบบฐานข้อมูล
  convertToDbFormat(inputDate) {
    if (!inputDate) return null;
    
    // ถ้าอยู่ในรูปแบบ YYYY-MM-DD อยู่แล้ว
    if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) return inputDate;
    
    // กรณีอื่นๆ ให้พยายามแปลง
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      console.error('Invalid date for conversion:', inputDate);
      return null;
    }
    
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  },
async editTicket(ticketId) {
  try {
    console.log('Fetching ticket data for ID:', ticketId);
    const response = await axios.get(`https://brk-group.org/api/ts/transport-tickets/${ticketId}`);

    // console.log('Full Response:', response.data);
    // console.log('Ticket Data:', response.data.ticket);
    // console.log('Trips Data:', response.data.trips);
    // console.log('Number of Trips:', response.data.trips?.length);

    const ticket = response.data.ticket?.[0] || response.data.ticket || {};
    const trips = response.data.trips || [];

    this.editTicketData.id = ticketId; // ตรวจสอบว่ามีการตั้งค่า id
    // ตั้งค่าข้อมูลตั๋วขนส่ง
    this.editTicketData = {
      id: ticketId, // ตั้งค่า id ให้กับ editTicketData
      // ใช้ Number() เพื่อแปลงค่า ID 
      departmentId: this.departmentOptions.find(dep => 
        Number(dep.id) === Number(ticket.department_id)
      ) || null,
      workGroupId: this.workGroupOptions.find(wg => 
        Number(wg.id) === Number(ticket.work_group_id)
      ) || null,
      startDate: ticket.start_date 
        ? this.formatDateForInput(ticket.start_date) 
        : '',
      completionDate: ticket.completion_date 
        ? this.formatDateForInput(ticket.completion_date) 
        : '',
      driver1Id: this.employeeOptions.find(emp => 
        Number(emp.id) === Number(ticket.driver1_id)
      ) || null,
      driver2Id: this.employeeOptions.find(emp => 
        Number(emp.id) === Number(ticket.driver2_id)
      ) || null,
      vehiclePairId: this.vehiclePairOptions.find(pair => 
        Number(pair.id) === Number(ticket.vehicle_pair_id)
      ) || null,
      remarks: ticket.remarks || '',
    };
    // console.log('Mapped editTicketData:', this.editTicketData);
    // ตั้งค่ารายการ Trips
    this.trips = trips.map((trip, index) => ({
      tripNo: trip.trip_no || `Trip ${index + 1}`,
      ticketNumber: trip.ticket_number || '',
      productType: this.productTypeOptions.find(pt => 
        pt.id === Number(trip.product_type_id)
      ) || null,
      tripSequence: trip.trip_sequence || null,
      capacity: Number(trip.capacity) || null, // Ensure capacity is a number
      route: this.routeOptions.find(rt => 
        rt.id === Number(trip.route_id)
      ) || null,
      distance: trip.distance || 0,
      transportFee: trip.transport_fee || 0,
      tollFee: trip.toll_fee || 0,
      isLoadStatusEnabled: !!trip.load_status,
      loadStatus: trip.load_status || null,
      remarks: trip.remarks || '',
    }));
    // console.log('Mapped trips:', this.trips);
    this.isEditModalOpen = true;
  } catch (error) {
    Swal.fire({ 
      icon: 'error', 
      title: 'เกิดข้อผิดพลาด', 
      text: 'ไม่สามารถโหลดข้อมูลตั๋วขนส่งได้' 
    });
  }
}
,
addTrip() {
  
      // เพิ่ม Trip ใหม่ในรายการ
      this.trips.push({
        ticketNumber: '',
        productType: null,
        tripSequence: this.trips.length + 1,
        capacity: 43000,
        route: null,
        distance: 0,
        transportFee: 0,
        tollFee: 0,
        isLoadStatusEnabled: false,
        loadStatus: null,
        remarks: '',
      });
    },
async deleteTrip(index) {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'ยืนยันการลบ',
        text: 'คุณแน่ใจหรือไม่ว่าต้องการลบ Trip นี้?',
        showCancelButton: true,
        confirmButtonText: 'ลบ',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
      });

      if (result.isConfirmed) {
        const deletedTrip = this.trips[index];
        if (deletedTrip && deletedTrip.id) {
          this.deletedTrips.push(deletedTrip.id); // เก็บ ID ที่ลบ
        }
        this.trips.splice(index, 1); // ลบ Trip ออกจากรายการ
        this.updateTripSequence();
        Swal.fire('ลบสำเร็จ', 'Trip นี้ถูกลบเรียบร้อยแล้ว', 'success');
      }
    },
    updateTripSequence() {
    // อัปเดตค่า tripSequence ให้เรียงลำดับใหม่
    this.trips.forEach((trip, index) => {
      trip.tripSequence = index + 1;
    });
  },
// ยืนยันก่อนบันทึก
async confirmSaveEditDetails() {
  // Validation checks
  if (!this.validateForm()) {
    return;
  }
  // console.log('Preparing to save data for Ticket ID:', this.editTicketData.id);
  try {
    // แปลงข้อมูลให้ตรงกับ backend
    const updatedData = {
      ticketNumber: this.trips.map(trip => trip.ticketNumber).filter(Boolean).join(',') || '',
      departmentId: this.editTicketData.departmentId?.id || null,
      workGroupId: this.editTicketData.workGroupId?.id || null,
      startDate: this.editTicketData.startDate || null,
      completionDate: this.editTicketData.completionDate || null,
      driver1Id: this.editTicketData.driver1Id?.id || null,
      driver2Id: this.editTicketData.driver2Id?.id || null,
      vehiclePairId: this.editTicketData.vehiclePairId?.id || null,
      remarks: this.editTicketData.remarks || '',
      trips: this.trips.map((trip, index) => ({
        tripNo: index + 1,
        ticketNumber: trip.ticketNumber || '',
        productTypeId: trip.productType?.id || null,
        tripSequence: trip.tripSequence || index + 1,
        capacity: Number(trip.capacity) || 0,
        routeId: trip.route?.id || null,
        distance: Number(trip.distance) || 0,
        transportFee: Number(trip.transportFee) || 0,
        tollFee: Number(trip.tollFee) || 0,
        loadStatus: trip.isLoadStatusEnabled ? trip.loadStatus : null,
        remarks: trip.remarks || '',
      })),
      deletedTrips: this.deletedTrips, // ส่งรายการ Trips ที่ลบไป Backend
    };

    // Confirm before saving
    const result = await Swal.fire({
      icon: 'question',
      title: 'ยืนยันการบันทึก',
      text: 'คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่?',
      showCancelButton: true,
      confirmButtonText: 'ใช่, บันทึก',
      cancelButtonText: 'ยกเลิก',
    });

    if (!result.isConfirmed) {
      return;
    }

    // ส่งข้อมูลไปยัง backend
    const response = await axios.put(
      `https://brk-group.org/api/ts/transport-tickets/${this.editTicketData.id}`,
      updatedData
    );

    // แสดงผลลัพธ์จาก backend
    if (response.data) {
      Swal.fire({
        icon: 'success',
        title: 'บันทึกสำเร็จ',
        text: response.data.message || 'บันทึกข้อมูลตั๋วขนส่งและรายการ Trips เรียบร้อยแล้ว',
      });

      this.closeEditModal();
      this.fetchTickets(); // โหลดข้อมูลใหม่
    }
  } catch (error) {
    console.error('Error updating transport ticket:', error);

    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: error.response?.data?.error || 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง',
      footer: error.response?.data?.details || '',
    });
  }
}
,
  validateForm() {
  // ตรวจสอบข้อมูลที่จำเป็น
  const requiredFields = [
    { field: 'departmentId', message: 'กรุณาเลือกหน่วยงาน' },
    { field: 'workGroupId', message: 'กรุณาเลือกกลุ่มงาน' },
    { field: 'driver1Id', message: 'กรุณาเลือกพนักงานขับรถคนที่ 1' },
    { field: 'vehiclePairId', message: 'กรุณาเลือกคู่รถ' }
  ];

  for (const { field, message } of requiredFields) {
    if (!this.editTicketData[field]?.id) {
      Swal.fire({
        icon: 'warning',
        title: 'ข้อมูลไม่ครบถ้วน',
        text: message
      });
      return false;
    }
  }

  // ตรวจสอบ trips
  if (this.trips.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'ข้อมูลไม่ครบถ้วน',
      text: 'กรุณาเพิ่มรายการ Trips อย่างน้อย 1 รายการ'
    });
    return false;
  }

  // ตรวจสอบข้อมูลใน trips
  for (const [index, trip] of this.trips.entries()) {
    if (!trip.route?.id) {
      Swal.fire({
        icon: 'warning',
        title: 'ข้อมูลไม่ครบถ้วน',
        text: `กรุณาเลือกเส้นทางสำหรับ Trip ${index + 1}`
      });
      return false;
    }
    if (!trip.productType?.id) {
      Swal.fire({
        icon: 'warning',
        title: 'ข้อมูลไม่ครบถ้วน',
        text: `กรุณาเลือกประเภทสินค้าสำหรับ Trip ${index + 1}`
      });
      return false;
    }
   
    if (!trip.capacity) {
      Swal.fire({
        icon: 'warning',
        title: 'ข้อมูลไม่ครบถ้วน',
        text: `กรุณาเลือกความจุสำหรับ Trip ${index + 1}`
      });
      return false;
    }
  }

  return true;
},
  validateEditFormData() {
  if (!this.editTicketData.startDate) {
    Swal.fire({ icon: 'error', title: 'ข้อมูลไม่ครบถ้วน', text: 'กรุณากรอกวันที่เริ่มต้น' });
    return false;
  }

  if (!this.editTicketData.productTypeId) {
    Swal.fire({ icon: 'error', title: 'ข้อมูลไม่ครบถ้วน', text: 'กรุณาเลือกประเภทสินค้า' });
    return false;
  }

  if (!this.trips.every(trip => trip.ticketNumber && trip.productType?.id && trip.route?.id)) {
    Swal.fire({ icon: 'error', title: 'ข้อมูลไม่ครบถ้วน', text: 'กรุณากรอกข้อมูลการเดินทางให้ครบถ้วน' });
    return false;
  }

  return true;
},
  async saveEditDetails() {
  if (!this.validateEditFormData()) return;

  try {
    // ตรวจสอบความพร้อมของพนักงานขับรถ
    const checkDriverResponse = await axios.post('https://brk-group.org/api/ts/check-driver-availability', {
      driver1Id: this.editTicketData.driver1Id,
      driver2Id: this.editTicketData.driver2Id,
      startDate: this.editTicketData.startDate,
    });

    // ไม่มีข้อขัดแย้ง: ดำเนินการต่อ
    if (checkDriverResponse.status === 200) {
      return this.performEditSave(); // ดำเนินการบันทึก
    }
  } catch (error) {
    if (error.response?.status === 409) {
      const conflicts = error.response.data.conflicts;

      // สร้างข้อความแจ้งเตือน
      let conflictMessage = 'พนักงานขับรถมีงานในวันดังกล่าว:\n\n';
      if (conflicts.driver1?.length) {
        conflictMessage += `พขร.1 (${this.getEmployeeName(this.editTicketData.driver1Id)}):\n`;
        conflicts.driver1.forEach(job => {
          const formattedDate = new Date(job.start_date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });
          conflictMessage += `- Trip No: ${job.trip_no}, Ticket No: ${job.ticket_number}, วันที่: ${formattedDate}\n`;
        });
        conflictMessage += '\n';
      }
      if (conflicts.driver2?.length) {
        conflictMessage += `พขร.2 (${this.getEmployeeName(this.editTicketData.driver2Id)}):\n`;
        conflicts.driver2.forEach(job => {
          const formattedDate = new Date(job.start_date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });
          conflictMessage += `- Trip No: ${job.trip_no}, Ticket No: ${job.ticket_number}, วันที่: ${formattedDate}\n`;
        });
        conflictMessage += '\n';
      }

      // ยืนยันการดำเนินการต่อ
      const confirmResult = await Swal.fire({
        icon: 'warning',
        title: 'พนักงานขับรถมีงานในวันดังกล่าว',
        html: `<pre style="text-align:left;">${conflictMessage}</pre>`,
        showCancelButton: true,
        confirmButtonText: 'ยืนยันบันทึก',
        cancelButtonText: 'ยกเลิก',
      });

      if (!confirmResult.isConfirmed) {
        return; // ยกเลิกการบันทึก
      }
    } else {
      // ข้อผิดพลาดอื่น ๆ
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.response?.data?.message || 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง',
      });
      return;
    }
  }

  // บันทึกข้อมูลที่แก้ไข
  this.performEditSave();
},
getEmployeeName(employeeId) {
  const employee = this.employeeOptions.find(emp => emp.id === employeeId);
  return employee ? employee.fullName : 'ไม่ทราบชื่อ';
},
async performEditSave() {
  try {
    const updatedData = {
      ...this.editTicketData,
      trips: this.trips.map((trip, index) => ({
        tripNo: index + 1,
        ticketNumber: trip.ticketNumber,
        productTypeId: trip.productType?.id || null,
        tripSequence: trip.tripSequence || index + 1,
        capacity: Number(trip.capacity) || 0,
        routeId: trip.route?.id || null,
        distance: Number(trip.distance) || 0,
        transportFee: Number(trip.transportFee) || 0,
        tollFee: Number(trip.tollFee) || 0,
        loadStatus: trip.loadStatus || null,
        remarks: trip.remarks || '',
      }))
    };

    await axios.put(`https://brk-group.org/api/ts/transport-tickets/${this.editTicketData.tripNo}`, updatedData);

    Swal.fire({
      icon: 'success',
      title: 'บันทึกสำเร็จ',
      text: 'บันทึกข้อมูลตั๋วขนส่งและการเดินทางเรียบร้อยแล้ว',
    });

    this.closeEditModal();
    this.fetchTickets();
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: error.response?.data?.error || 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง',
    });
  }
}
,
  // ปิด Modal
  closeEditModal() {
    this.isEditModalOpen = false;
    this.editTicketData = {};
  },

//   async openTicketModal(ticketId) {
//   // console.log('Ticket ID received:', ticketId);
//  if (!ticketId || Object.keys(ticketId).length === 0) {
//   console.error(`Ticket data is empty or invalid for Ticket ID: ${ticketId}`);
//   Swal.fire({
//     icon: 'error',
//     title: 'เกิดข้อผิดพลาด',
//     text: 'ไม่พบข้อมูลตั๋วขนส่ง',
//   });
//   return;
// }

//   this.loading = true; // เริ่มแสดง Loading Indicator
//   try {
//     console.log('Calling API with Ticket ID:', ticketId);
//     const response = await axios.get(`https://brk-group.org/api/ts/transport-ticketsview/${ticketId}`, {
//       timeout: 10000,
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${this.accessToken}`,
//       },
//     });

//     console.log('API Response:', response.data);
//     // const { ticket: ticketData, trips } = response.data;
//     const { ticket, trips } = response.data;
//     console.log('Ticket Data:', ticket);
//     if (!ticket) {
//       console.error(`No ticket data found for Ticket ID: ${ticketId}`);
//       throw new Error(`ไม่พบตั๋วขนส่งที่มี ID: ${ticketId}`);
//     }

//     console.log('Mapping ticket data...');
//     this.viewTicketData = {
//       department: this.departmentOptions.find(dep => Number(dep.id) === Number(ticket.department_id))?.name || 'ไม่ระบุ',
//       workgroup: this.workGroupOptions.find(wg => Number(wg.id) === Number(ticket.work_group_id))?.name || 'ไม่ระบุ',
//       startDate: ticket.start_date || 'ไม่ระบุ',
//       completionDate: ticket.completion_date || 'ไม่ระบุ',
//       driver1: this.employeeOptions.find(emp => Number(emp.id) === Number(ticket.driver1_id))?.fullName || 'ไม่ระบุ',
//       driver2: this.employeeOptions.find(emp => Number(emp.id) === Number(ticket.driver2_id))?.fullName || 'ไม่ระบุ',
//       vehiclePair: this.vehiclePairOptions.find(pair => Number(pair.id) === Number(ticket.vehicle_pair_id))?.vehicleCodeLabel || 'ไม่ระบุ',
//       remarks: ticket.remarks || 'ไม่มีหมายเหตุ',
//       totalTrips: trips.length,
//       totalDistance: this.calculateOverallDistance(trips),
//     };

//     console.log('Ticket data mapped successfully:', this.viewTicketData);

//     console.log('Mapping trips data...');
//     this.viewTrips = trips.map((trip, index) => ({
//       tripNo: trip.trip_no || `Trip ${index + 1}`,
//       ticketNumber: trip.ticket_number || 'ไม่ระบุ',
//       productType: this.productTypeOptions.find(pt => pt.id === Number(trip.product_type_id))?.name || 'ไม่ระบุ',
//       tripSequence: trip.trip_sequence || 'ไม่ระบุ',
//       capacity: trip.capacity || 'ไม่ระบุ',
//       route: this.routeOptions.find(rt => rt.id === Number(trip.route_id))?.name || 'ไม่ระบุ',
//       distance: trip.distance || 0,
//       transportFee: trip.transport_fee || 0,
//       tollFee: trip.toll_fee || 0,
//       loadStatus: trip.load_status 
//         ? this.loadStatusOptions.find(ls => ls.value === trip.load_status)?.label 
//         : 'ไม่มีสถานะโหลด',
//       remarks: trip.remarks || 'ไม่มีหมายเหตุ',
//     }));

//     console.log('Trips data mapped successfully:', this.viewTrips);
//     this.isViewModalOpen = true; // เปิด Modal
//   } catch (error) {
//     console.error('Error fetching ticket data:', error);
//     Swal.fire({
//       icon: 'error',
//       title: 'เกิดข้อผิดพลาด',
//       text: error.response?.data?.message || 'ไม่สามารถโหลดข้อมูลตั๋วขนส่งได้',
//     });
//   } finally {
//     console.log('Finished loading ticket data');
//     this.loading = false; // หยุดแสดง Loading Indicator
//   }
// },
async openTicketModal(ticketId) {
    try {
      // เปิด Modal
      this.isViewModalOpen = true;
      
      // รีเซ็ตข้อมูลเก่า
      this.viewTicketData = {};
      this.viewTrips = [];

      // ดึงข้อมูล
      const response = await axios.get(`https://brk-group.org/api/ts/transport-ticketsview/${ticketId}`);
      
      // พิมพ์ข้อมูลดิบเพื่อตรวจสอบ
      // console.log('Raw Response Data:', response.data);

      // จัดรูปแบบข้อมูล Ticket
      const ticket = response.data.ticket || {};
      const trips = Array.isArray(response.data.trips) ? response.data.trips : [];

      // พิมพ์ข้อมูลดิบเพื่อตรวจสอบ
      // console.log('Raw Ticket Data:', ticket);
      // console.log('Raw Trips Data:', trips);

      // ตรวจสอบว่ามีข้อมูล ticket หรือไม่
      if (ticket && Object.keys(ticket).length > 0) {
        // จัดรูปแบบข้อมูล Ticket
        this.viewTicketData = {
          id: ticket.id || null,
          departmentId: this.departmentOptions.find(dep => 
        Number(dep.id) === Number(ticket.department_id)
      ) || null,
      workGroupId: this.workGroupOptions.find(wg => 
        Number(wg.id) === Number(ticket.work_group_id)
      ) || null,
          startDate: this.formatDate(ticket.start_date),
          completionDate: this.formatDate(ticket.completion_date),
          driver1Id: this.employeeOptions.find(emp => 
    Number(emp.id) === Number(ticket.driver1_id)
  ) || null,
  driver2Id: this.employeeOptions.find(emp => 
    Number(emp.id) === Number(ticket.driver2_id)
  ) || null,
  vehiclePairId: this.vehiclePairOptions.find(pair => 
    Number(pair.id) === Number(ticket.vehicle_pair_id)
  ) || null,
          totalTrips: ticket.total_trips || 0,
          totalDistance: ticket.total_distance || 0,
          tripNo: ticket.trip_no,
          ticketNumber: ticket.ticket_number,
          remarks: ticket.remarks || 'ไม่มีหมายเหตุ',
          attachment: ticket.attachment
        };
      } else {
        // หากไม่มีข้อมูล ticket
        Swal.fire({
          icon: 'warning',
          title: 'ไม่พบข้อมูล',
          text: 'ไม่พบข้อมูลตั๋วที่ต้องการ'
        });
      }

      // จัดรูปแบบข้อมูล Trips
      this.viewTrips = trips.map(trip => ({
        tripNo: trip.trip_no || 'ไม่ระบุ',
        ticketNumber: trip.ticket_number || 'ไม่ระบุ',
        productType: this.productTypeOptions.find(option => 
      Number(option.id) === Number(trip.product_type_id)
    ) || null,
        tripSequence: trip.trip_sequence || 'ไม่ระบุ',
        capacity: trip.capacity || 'ไม่ระบุ',
        route: this.routeOptions.find(option => 
      Number(option.id) === Number(trip.route_id)
    ) || null,
        distance: trip.distance || 0,
        transportFee: trip.transport_fee || 0,
        tollFee: trip.toll_fee || 0,
        loadStatus: trip.load_status || 'ไม่ระบุ',
        remarks: trip.remarks || 'ไม่มีหมายเหตุ'
      }));

      // console.log('Formatted Ticket Data:', this.viewTicketData);
      // console.log('Formatted Trips Data:', this.viewTrips);

    } catch (error) {
      // จัดการข้อผิดพลาดอย่างครอบคลุม
      console.error('เกิดข้อผิดพลาดในการเปิดโมดอล:', error);
      
      // ปิดโมดอล
      this.isViewModalOpen = false;

      // แสดงข้อความแจ้งเตือน
      Swal.fire({
        icon: 'error',
        title: 'ข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลตั๋วได้ กรุณาลองใหม่อีกครั้ง'
      });
    }
  },
extractTicketId(ticketId) {
  if (typeof ticketId === 'object') {
    return ticketId.ticket_id || ticketId.id;
  }
  return ticketId;
},
    async fetchProductTypes() {
      try {
        const response = await axios.get('/api/product-types');
        this.productTypes = response.data.reduce((acc, type) => {
          acc[type.id] = type.name;
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching product types', error);
      }
    },

// เพิ่มเมธอดนี้
correctDate(dateString) {
  if (!dateString) return undefined;

  try {
    const originalDate = new Date(dateString);
    
    // เพิ่ม 1 วัน
    const correctedDate = new Date(originalDate);
    correctedDate.setDate(originalDate.getDate() + 1);

    // แปลงเป็นรูปแบบ YYYY-MM-DD
    return correctedDate.toISOString().split('T')[0];
  } catch (error) {
    console.error('แก้ไขวันที่ผิดพลาด:', error);
    return undefined;
  }
},

        async fetchTickets() {
      this.isLoading = true;
      try {
        const adjustedStartDate = this.correctDate(this.startDateFilter);
        const adjustedEndDate = this.correctDate(this.endDateFilter);

        const response = await axios.get('https://brk-group.org/api/ts/transport-tickets', {
          
          params: {
            page: this.currentPage,
            department: this.filterDepartment?.id,
            startDate: adjustedStartDate,
            endDate: adjustedEndDate,
            driverName: this.selectedDriver?.name, // ส่งชื่อ พขร.
            confirmationStatus: this.confirmationStatus
          }
        });
        if (response.data && response.data.tickets) {

          // this.tickets = response.data.tickets;
          // this.totalTickets = response.data.total || 0;
          // this.totalPages = response.data.totalPages || 1;
          this.tickets = response.data.tickets.map(ticket => ({
        ...ticket,
        displayCarCode: ticket.pairedcarcode || ticket.unpairedCarCode || 'ไม่มีข้อมูล',
        displayCarLicense: ticket.pairedcarlicense || ticket.unpairedCarLicense || 'ไม่มีข้อมูล'
      }));

      this.totalTickets = response.data.total || 0;
      this.totalPages = response.data.totalPages || 1;
        } else {
          this.tickets = [];
          this.totalTickets = 0;
          this.totalPages = 1;
        }
      } catch (error) {
        this.showErrorToast('เกิดข้อผิดพลาดในการดึงข้อมูล');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDriverOptions() {
      try {
        const response = await axios.get('https://brk-group.org/api/ts/drivers');
        this.driverOptions = response.data.map(driver => ({
          id: driver.id,
          name: `${driver.first_name} ${driver.last_name}`
        }));
      } catch (error) {
        this.showErrorToast('ไม่สามารถโหลดข้อมูล พขร. ได้');
      }
    },
      async fetchDepartmentOptions() {
        try {
          const response = await axios.get('https://brk-group.org/api/ts/departments')
          this.departmentOptions = response.data
        } catch (error) {
          this.showErrorToast('ไม่สามารถโหลดข้อมูลหน่วยงานได้')
        }
      },
      searchTickets() {
        this.currentPage = 1
        this.fetchTickets()
      },
      resetFilters() {
        this.filterDepartment = null
        this.startDateFilter = null
        this.endDateFilter = null
        this.currentPage = 1
        this.fetchTickets()
      },
 
    formatDate(date) {
      return new Date(date).toLocaleDateString('th-TH')
    },
    viewTicketDetails(ticketId) {
      this.$router.push(`/ticket-details/${ticketId}`)
    },
    async confirmDeleteTicket(ticketId) {
      try {
        // ยืนยันรอบแรก
        const firstConfirm = await Swal.fire({
          title: 'ยืนยันการลบ',
          text: 'คุณแน่ใจหรือไม่ว่าต้องการลบตั๋วขนส่งนี้?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        });

        if (!firstConfirm.isConfirmed) return;

        // รอบสอง: ขอหมายเหตุการลบ
        const secondConfirm = await Swal.fire({
          title: 'กรอกหมายเหตุการลบ',
          input: 'textarea',
          inputPlaceholder: 'กรุณากรอกหมายเหตุ...',
          inputAttributes: {
            'aria-label': 'กรุณากรอกหมายเหตุ'
          },
          showCancelButton: true,
          confirmButtonText: 'ยืนยันการลบ',
          cancelButtonText: 'ยกเลิก',
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
        });

        if (!secondConfirm.isConfirmed || !secondConfirm.value) {
          Swal.fire('การลบถูกยกเลิก', '', 'info');
          return;
        }

        const reason = secondConfirm.value;

        // ส่งคำขอไปยัง Backend
        await axios.delete(`https://brk-group.org/api/ts/transport-tickets/${ticketId}`, {
          data: { reason },
        });

        this.showSuccessToast('ลบตั๋วขนส่งสำเร็จ');
        this.fetchTickets(); // โหลดข้อมูลใหม่
      } catch (error) {
        this.showErrorToast('เกิดข้อผิดพลาดในการลบ');
      }
    },
    async deleteTicket(ticketId) {
      try {
        const result = await Swal.fire({
          title: 'ยืนยันการลบ',
          text: 'คุณแน่ใจหรือไม่ว่าต้องการลบตั๋วขนส่งนี้?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })

        if (result.isConfirmed) {
          await axios.delete(`https://brk-group.org/api/transport-tickets/${ticketId}`)
          this.showSuccessToast('ลบตั๋วขนส่งสำเร็จ')
          this.fetchTickets()
        }
      } catch (error) {
        this.showErrorToast('เกิดข้อผิดพลาดในการลบ')
      }
    },
    async exportTickets() {
      try {
        const response = await axios.get('/api/transport-tickets/export', {
          params: {
            department: this.filterDepartment?.id,
            startDate: this.startDateFilter,
            endDate: this.endDateFilter
          },
          responseType: 'blob'
        })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'transport_tickets.csv'
        link.click()
      } catch (error) {
        this.showErrorToast('ไม่สามารถส่งออกข้อมูลได้')
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.fetchTickets()
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.fetchTickets()
      }
    },
    showSuccessToast(message) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
    },
    showErrorToast(message) {
      Swal.fire({
        icon: 'error',
        title: 'ข้อผิดพลาด',
        text: message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
    }
  },
  created() {
    this.fetchDriverOptions()
    this.fetchTickets()
    // this.fetchDepartmentOptions()
    this.fetchInitialData()
  },
  watch: {
    'trips': {
    deep: true,
    handler(newTrips) {
      newTrips.forEach(trip => {
        if (trip.route) {
          this.updateRouteDistance(trip);
        }
      });
    }
  }
}

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>