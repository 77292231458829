<template>
     <TransportLayout>
<div class="container mx-auto px-4 py-6">
  <!-- Search Section -->
  <div class="bg-white rounded-lg shadow-lg p-8 mb-8">
    <h2 class="text-2xl font-bold text-gray-800 mb-6">ค้นหาข้อมูล</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      <!-- Date Range -->
      <div class="space-y-3">
        <label class="block text-gray-700 text-sm font-bold 0">วันที่เริ่ม</label>
        <input type="date" v-model="filters.startDate" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700">
      </div>
      <div class="space-y-3">
        <label class="block text-sm font-semibold text-gray-700">วันที่สิ้นสุด</label>
        <input type="date" v-model="filters.endDate" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700">
      </div>
      <!-- Ticket Number -->
      <div class="space-y-3">
        <label class="block text-sm font-semibold text-gray-700">เลขตั๋ว</label>
        <input type="text" v-model="filters.ticketNumber" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700">
      </div>
      <!-- Vehicle Pair -->
      <div class="space-y-3">
        <label class="block text-sm font-semibold text-gray-700">คู่รถ</label>
        <select v-model="filters.vehiclePairId" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none">
          <option value="">ทั้งหมด</option>
          <option v-for="pair in vehiclePairs" :key="pair.id" :value="pair.id">
            {{ pair.name }}
          </option>
        </select>
      </div>
      <!-- Route -->
      <div class="space-y-3">
        <label class="block text-sm font-semibold text-gray-700">เส้นทาง</label>
        <select v-model="filters.routeId" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none">
          <option value="">ทั้งหมด</option>
          <option v-for="route in routes" :key="route.id" :value="route.id">
            {{ route.route_name }}
          </option>
        </select>
      </div>
      <!-- Product Type -->
      <div class="space-y-3">
        <label class="block text-sm font-semibold text-gray-700">ประเภทสินค้า</label>
        <select v-model="filters.productTypeId" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none">
          <option value="">ทั้งหมด</option>
          <option v-for="type in productTypes" :key="type.id" :value="type.id">
            {{ type.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-6 flex justify-end">
      <button @click="searchTickets" class="bg-blue-600 text-white px-6 py-2 rounded-lg font-medium shadow hover:bg-blue-700 focus:ring-2 focus:ring-blue-500">
        ค้นหา
      </button>
    </div>
  </div>

  <!-- Results Section -->
  <div class="bg-white rounded-lg shadow-lg p-8">
    <div class="flex flex-wrap justify-between items-center mb-6">
      <h2 class="text-2xl font-bold text-gray-800">ผลการค้นหา: {{ totalTrips }} ทริป</h2>
      <button 
        @click="exportToExcel" 
        :disabled="selectedTickets.length === 0"
        class="bg-green-600 text-white px-6 py-2 rounded-lg font-medium shadow hover:bg-green-700 disabled:bg-gray-400"
      >
        Export Excel
      </button>
    </div>

    <!-- ตาราง -->
    <div class="bg-white rounded-lg shadow overflow-hidden">
      <div class="overflow-x-auto">
        <table class="min-w-full table-auto border-collapse">
          <thead class="bg-gray-100">
            <tr>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">
                <input 
                  type="checkbox" 
                  v-model="selectAll"
                  @change="toggleSelectAll"
                  class="rounded"
                />
              </th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">วันที่</th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">ชื่อพขร.</th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">เลขตั๋ว</th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">รหัสหัว</th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">จำนวน Trip</th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">เส้นทาง</th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">ประเภทสินค้า</th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">รวม Trip</th>
              <th class="px-6 py-4 text-center text-gray-700 font-medium">การกระทำ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ticket in paginatedTickets" :key="ticket.id" class="border-b hover:bg-gray-50">
              <td class="px-6 py-2">
                <input 
                  type="checkbox" 
                  v-model="selectedTickets" 
                  :value="ticket.id"
                  class="rounded"
                />
              </td>
              <td class="px-6 py-2">{{ formatDate(ticket.start_date) }}</td>
              <td class="px-6 py-2">
    {{ ticket.driver_name }}<br>
    {{ ticket.driver_name2 }}
</td>
              <td class="px-6 py-2">{{ ticket.ticket_number }}</td>
              <td class="px-6 py-2">{{ ticket.head_vehicle_code }}</td>
              <td class="px-6 py-2">{{ ticket.trip_no }}</td>
              <td class="px-6 py-2">{{ ticket.route_name }}</td>
              <td class="px-6 py-2">{{ ticket.product_type_name }}</td>
              <td class="px-6 py-2">{{ ticket.total_trips }}</td>
              <td class="px-6 py-2">
                <button 
                  @click="showTicketDetails(ticket)" 
                  class="text-blue-600 hover:text-blue-800"
                >
                  ดูข้อมูล
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-6 flex flex-wrap justify-between items-center">
  <div class="text-sm text-gray-700">
    แสดง {{ paginationStart }} ถึง {{ paginationEnd }} จากทั้งหมด {{ tickets.length }} รายการ
  </div>
  <div class="flex space-x-2">
    <button 
      @click="prevPage" 
      :disabled="currentPage === 1"
      class="px-3 py-1 rounded border"
      :class="currentPage === 1 ? 'bg-gray-100' : 'hover:bg-gray-100'"
    >
      ก่อนหน้า
    </button>
    <button 
      @click="nextPage" 
      :disabled="currentPage === totalPages"
      class="px-3 py-1 rounded border"
      :class="currentPage === totalPages ? 'bg-gray-100' : 'hover:bg-gray-100'"
    >
      ถัดไป
    </button>
  </div>
</div>
  </div>


  
      <!-- Ticket Details Modal -->
      <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div class="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
          <div class="p-6">
            <div class="flex justify-between items-center mb-4">
              <h3 class="text-xl font-semibold">รายละเอียดตั๋ว</h3>
              <button @click="showModal = false" class="text-gray-500 hover:text-gray-700">
                <span class="text-2xl">&times;</span>
              </button>
            </div>
            <div v-if="selectedTicket" class="space-y-4">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <p class="font-medium">เลขตั๋ว:</p>
                  <p>{{ selectedTicket.ticket_number }}</p>
                </div>
                <div>
                  <p class="font-medium">คู่รถ:</p>
                  <p>{{ selectedTicket.vehicle_pair_name }}</p>
                </div>
                <div>
                  <p class="font-medium">พขร.:</p>
                  <p>{{ selectedTicket.driver_name }}</p>
                </div>
                <div>
                  <p class="font-medium">เส้นทาง:</p>
                  <p>{{ selectedTicket.route_name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </TransportLayout>
  </template>
  
  <script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
    import TransportLayout from '@/components/transport/TransportSidebar.vue'
  export default {
    components: {
      TransportLayout,
    },
    data() {
      return {
        tickets: [],
        routes: [],
        vehiclePairs: [],
        productTypes: [],
        selectedTickets: [],
        selectedTicket: null,
        showModal: false,
        loading: false,
        currentPage: 1,
        perPage: 10,
        itemsPerPage: 10,
        selectAll: false,
        filters: {
          startDate: '',
          endDate: '',
          ticketNumber: '',
          vehiclePairId: '',
          routeId: '',
          productTypeId: ''
        }
      }
    },
    async created() {
    await this.fetchMasterData()
    await this.searchTickets()
  },
    computed: {
      totalTrips() {
        return this.tickets.length
      },
  
      totalPages() {
        return Math.ceil(this.tickets.length / this.perPage)
      },
  
      paginationStart() {
        return (this.currentPage - 1) * this.perPage + 1
      },
  
      paginationEnd() {
        return Math.min(this.currentPage * this.perPage, this.tickets.length)
      },
  
      paginatedTickets() {
        const start = (this.currentPage - 1) * this.perPage
        const end = start + this.perPage
        return this.tickets.slice(start, end)
      }
    },
  
    methods: {

      formatDate(date) {
      return new Date(date).toLocaleDateString('th-TH')
    },
      async fetchMasterData() {
        try {
          const [routesRes, pairsRes, typesRes] = await Promise.all([
            axios.get('https://brk-group.org/api/ts/routes'),
            axios.get('https://brk-group.org/api/ts/vehicle-pairs'),
            axios.get('https://brk-group.org/api/ts/product-types')
          ])
          this.routes = routesRes.data
          this.vehiclePairs = pairsRes.data
          this.productTypes = typesRes.data
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'ไม่สามารถดึงข้อมูลได้'
          })
        }
      },
  
      async searchTickets() {
      this.loading = true
      try {
        const response = await axios.get('https://brk-group.org/api/ts/verified-tickets', { 
          params: this.filters 
        })
        this.tickets = response.data
        this.currentPage = 1
        this.selectedTickets = []
        this.selectAll = false
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถค้นหาข้อมูลได้'
        })
      } finally {
        this.loading = false
      }
    },
  
      showTicketDetails(ticket) {
        this.selectedTicket = ticket
        this.showModal = true
      },
  
    //   toggleSelectAll() {
    //   if (this.selectAll) {
    //     this.selectedTickets = this.paginatedTickets.map(ticket => ticket.id)
    //   } else {
    //     this.selectedTickets = []
    //   }
    // },
    toggleSelectAll() {
  if (this.selectAll) {
    // เลือกทั้งหมด
    this.selectedTickets = this.tickets.map(ticket => ticket.id);
  } else {
    // ยกเลิกการเลือกทั้งหมด
    this.selectedTickets = [];
  }
},
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    async exportToExcel() {
  if (this.selectedTickets.length === 0) return;

  this.loading = true;
  try {
    const response = await axios.post(
      'https://brk-group.org/api/ts/export-tickets',
      { ticketIds: this.selectedTickets },
      { responseType: 'blob' } // เพื่อรองรับการดาวน์โหลดไฟล์
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `verified-tickets-${new Date().toISOString()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'ไม่สามารถ export ข้อมูลได้',
    });
  } finally {
    this.loading = false;
  }
}
    },
  
    mounted() {
      this.fetchMasterData()
    }
  }
  </script>