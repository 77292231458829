<template>
   <link href="https://fonts.googleapis.com/css2?family=Mitr:wght@200;400&display=swap" rel="stylesheet">
  <div class="min-h-screen flex items-center justify-center bg-gray-100 px-4">
    <div class="w-full max-w-md">
      <h1 class="text-4xl font-bold text-center text-gray-700 mb-8">เข้าสู่ระบบ</h1>
      <form @submit.prevent="login" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-8">
          <label for="username" class="block text-gray-700 text-sm font-bold mb-2">ชื่อผู้ใช้</label>
          <input id="username" v-model="username" type="text" placeholder="กรอกชื่อผู้ใช้" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div class="mb-6">
          <label for="password" class="block text-gray-700 text-sm font-bold mb-2">รหัสผ่าน</label>
  <div class="relative">
    <input 
      id="password" 
      :type="passwordFieldType" 
      v-model="password" 
      placeholder="กรอกรหัสผ่าน" 
      required 
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline pr-10" 
    />
    <button 
      type="button" 
      @click="togglePasswordVisibility" 
      class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
    >
      <span v-if="passwordFieldType === 'password'">👁️</span>
      <span v-else>🙈</span>
    </button>
  </div>
        </div>
        <div class="flex justify-center">
          <button 
      @click="handleSubmit" 
      type="submit" 
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      เข้าสู่ระบบ
    </button>
        </div>
        <p v-if="error" class="text-center text-red-500 text-xs mt-4">{{ error }}</p>
      </form>
    </div>
  </div>
  <div v-if="isLoading" class="loading-overlay">
  <div class="loading-content">
    <!-- ไอคอนหมุน (Spinner) -->
    <div class="spinner"></div>
    <!-- ข้อความแสดงสถานะ -->
    <div class="loading-text">กำลังโหลดข้อมูล กรุณารอสักครู่...</div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { getMessagingToken } from '../firebase'; 
export default {
  data() {
    return {
      username: '',
      password: '',
      error: null,
      fcmToken: null,
      isLoading: false,

      passwordFieldType: 'password',
    };
  },
  name: 'LoginView',
  created() {
    this.fetchUser();
  },
  methods: {
    togglePasswordVisibility() {
    // สลับ type ระหว่าง 'password' และ 'text'
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  },
    handleSubmit() {
      this.isLoading = true;
      // Simulate a network request
      setTimeout(() => {
        this.isLoading = false;
        // Handle the actual submit logic here
      }, 3000); // Simulate a 3-second delay
    },
    async fetchUser() {
  const token = localStorage.getItem('userToken');
  if (!token) {
    this.$router.push({ name: 'Login' });
    return;
  }

  try {
    const response = await axios.get('https://brk-group.org/api/user', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    // Set user name in localStorage
    localStorage.setItem('userName', `${response.data.user.prefixname} ${response.data.user.fname} ${response.data.user.lname}`);
  } catch (error) {
    // console.error('Error fetching user:', error);
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userName');
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'Invalid token. Please log in again.',
      }).then(() => {
        this.$router.push({ name: 'Login' }).then(() => {
          window.location.reload();
        });
      });
    }
  }
},
async requestNotificationPermission() {
      try {
        console.log('Requesting Notification Permission...');
        const permission = await Notification.requestPermission();
        console.log('Notification permission status:', permission);

        if (permission !== 'granted') {
          console.warn('Notification permission not granted');
          return null;
        }

        const token = await getMessagingToken(
          "BGLtzh9ViUgr2Zr4e1V9sJdkpPRsAJkjmRZreEt9csRi_yiXjAwuq4zyksEh76tPCcPAB_x19feQusOIa7NU7nQ"
        );

        if (token) {
          // console.log('Successfully got FCM token:', token);
          localStorage.setItem('fcmToken', token);
          this.fcmToken = token;
          console.log('FCM Token stored:', token);
          return token;
        } else {
          console.warn('Failed to get FCM token');
          this.fcmToken = null;
          return null;
        }
      } catch (error) {
        console.error('Error in requestNotificationPermission:', error);
        this.fcmToken = null;
        return null;
      }
    },

    async login() {
      this.isLoading = true;
      console.log("Login started"); // Debug point
      try {
        const fcmToken = await this.requestNotificationPermission();
        console.log("Received FCM Token:", fcmToken);

        // const response = await axios.post('https://brk-group.org/login', {
          const response = await axios.post('https://brk-group.org/login', {
          username: this.username,
          password: this.password,
           fcmToken: fcmToken || ''
        }, {
          headers: { 'Content-Type': 'application/json' }
        });
        localStorage.setItem('userToken', response.data.token);
        localStorage.setItem('userPosition', response.data.position);
        localStorage.setItem('userName', `${response.data.user.prefixname} ${response.data.user.fname} ${response.data.user.lname}`); // เพิ่มการเก็บ userName ลงใน localStorage
        localStorage.setItem('level_permission', response.data.user.level_permission);
        localStorage.setItem('userAgency', response.data.user.agency);
        localStorage.setItem('position_lo', response.data.user.position_lo);

        this.$emit('auth-success', {
        token: response.data.token,
        user: response.data.user
      });

        this.$emit('auth-success');
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

       
        console.log("Login API Response:", response.data); // Debug point
      

       // ตรวจสอบตำแหน่งและเปลี่ยนเส้นทางสำหรับ Boss
       if (response.data.position === 'Boss' || response.data.user.position_lo === 'ประธานกรรมการ') {
          this.$router.push({ name: 'HomePur' });
        } else {
          // กรณีตำแหน่งอื่น ๆ
          switch (response.data.position) {
            case 'Insurance':
              this.$router.push({ name: 'InsuranceHome' });
              break;
            case 'Maintenance':
              this.$router.push({ name: 'HomeRepair' });
              break;
            case 'Admin':
              this.$router.push({ name: 'AdminSettings' });
              break;
            case 'Purchase':
              this.$router.push({ name: 'HomePur' });
              break;
            case 'Stock':
              this.$router.push({ name: 'StockMenu' });
              break;
            case 'All':
              this.$router.push({ name: 'Allmenu' });
              break;
            case 'Transport':
              this.$router.push({ name: 'TransportDashboard'})
              break;
            default:
              this.$router.push('/');
              break;
          }
        }
      } catch (error) {
        this.error = error.response ? error.response.data : 'Login failed. Please try again later.';
      }  finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;        /* ติดตั้งอยู่กับที่บนหน้าจอ */
  top: 0;
  left: 0;
  width: 100%;           /* ครอบคลุมทั้งหน้าจอ */
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);  /* พื้นหลังสีขาวโปร่งแสง */
  display: flex;         /* จัดการวางตำแหน่งเนื้อหาตรงกลาง */
  justify-content: center;
  align-items: center;
  z-index: 9999;        /* แสดงทับด้านบนสุด */
}

.content-blocked {
  pointer-events: none;  /* ป้องกันการคลิก */
  opacity: 0.6;         /* ทำให้เนื้อหาจางลง */
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>