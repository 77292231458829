<template>
  <TransportLayout>
    <div class="container mx-auto px-4 py-6">
      <h2 class="text-2xl font-bold mb-6 text-gray-800">จัดการข้อมูลรถ</h2>

      <div class="mb-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 items-center">
        <!-- Search Input -->
        <div class="w-full md:flex-grow">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="ค้นหารถ (ทะเบียน/รหัส)..."
            class="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
          />
        </div>

        <!-- Bulk Delete Button -->
        <div>
          <button 
            @click="deleteSelectedVehicles" 
            :disabled="isLoading || selectedVehicles.length === 0"
            class="w-full md:w-auto bg-red-600 hover:bg-red-700 text-white py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50"
          >
            <i class="fas fa-trash-alt mr-2" v-if="selectedVehicles.length > 0"></i> ลบที่เลือก
          </button>
        </div>

        <!-- Add Vehicle Button -->
        <div>
          <button 
            @click="showAddModal" 
            :disabled="isLoading"
            class="w-full md:w-auto bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50"
          >
            <i class="fas fa-plus mr-2"></i> เพิ่มรถ
          </button>
        </div>

        <!-- Vehicle Pairing Button -->
        <div>
          <button 
            @click="showVehiclePairingModal" 
            :disabled="isLoading"
            class="w-full md:w-auto bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50"
          >
            <i class="fas fa-link mr-2"></i> จับคู่รถ
          </button>
        </div>
      </div>

      <!-- Loading Spinner -->
      <div v-if="isLoading" class="flex justify-center items-center py-6">
        <div class="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500"></div>
      </div>

      <!-- Vehicles Table -->
      <div v-else-if="vehicles.length" class="overflow-x-auto rounded-lg shadow-md">
        <table class="w-full bg-white">
          <thead class="bg-gray-100">
            <tr>
              <th class="w-16 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input 
                  type="checkbox" 
                  @change="toggleSelectAll" 
                  :checked="isAllSelected" 
                />
              </th>
              <th class="w-16 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</th>
              <th class="w-16 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภท</th>
              <th class="w-16 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ทะเบียน</th>
              <th class="w-16 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">เบอร์รถ</th>
              <th class="w-16 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สถานะ</th>
              <th class="w-16 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">การดำเนินการ</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr 
              v-for="(vehicle, index) in paginatedVehicles" 
              :key="vehicle.id" 
              class="hover:bg-gray-50 transition duration-150"
            >
              <td class="px-4 py-4 whitespace-nowrap">
                <input 
                  type="checkbox" 
                  v-model="selectedVehicles" 
                  :value="vehicle.id" 
                />
              </td>
              <td class="px-4 py-4 whitespace-nowrap">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
              <td class="px-4 py-4 whitespace-nowrap">
                <span :class="{
                  'bg-blue-100 text-blue-800': vehicle.type === 'head',
                  'bg-green-100 text-green-800': vehicle.type === 'trailer'
                }" class="px-2 py-1 rounded-full text-xs">
                  {{ vehicle.type === 'head' ? 'หัวลาก' : 'พ่วง' }}
                </span>
              </td>
              <td class="px-4 py-4 whitespace-nowrap">{{ vehicle.license_plate }}</td>
              <td class="px-4 py-4 whitespace-nowrap">{{ vehicle.vehicle_code }}</td>
              <td class="px-4 py-4 whitespace-nowrap relative">
              <span v-if="vehicle.status === 'AVAILABLE'">
                <i class="fas fa-check-circle text-green-500"></i>
                ไม่มีคู่
              </span>
              <span 
                v-else-if="vehicle.status === 'PAIRED'" 
                class="relative group cursor-pointer"
              >
                <i class="fas fa-times-circle text-red-500"></i>
                มีคู่แล้ว
                <!-- Tooltip -->
                <div 
                  class="absolute z-10 left-full ml-2 top-1/2 -translate-y-1/2 w-60 p-2 text-sm text-white bg-gray-800 rounded shadow-lg 
                         opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200"
                >
                  <div v-if="vehicle.pairedWith">
                    คู่กับ: 
                    <div>เบอร์รถ: {{ vehicle.pairedWith.vehicle_code }}</div>
                    <div>ทะเบียน: {{ vehicle.pairedWith.license_plate }}</div>
                  </div>
                  <div v-else>
                    ไม่มีข้อมูลคู่
                  </div>
                </div>
              </span>
            </td>
            
              <td class="px-4 py-4 whitespace-nowrap text-center">
                <div class="flex justify-center space-x-2">
                  <button 
  v-if="vehicle.status === 'PAIRED'"
  @click="confirmUnpairVehicle(vehicle)" 
  :disabled="isLoading"
  class="text-orange-500 hover:text-orange-600 transition duration-300 transform hover:scale-110 disabled:opacity-50"
>
  🔗 ยกเลิกการจับคู่
</button>

                  <button 
                  v-if="vehicle.status === 'AVAILABLE'"
                    @click="editVehicle(vehicle)" 
                    :disabled="isLoading"
                    class="text-blue-500 hover:text-blue-600 transition duration-300 transform hover:scale-110 disabled:opacity-50"
                  >
                    <!-- ✏️ แก้ไข -->
                    <i class="fas fa-edit"></i>
                  </button>
                  <button 
                       v-if="vehicle.status === 'AVAILABLE'"
                    @click="confirmDeleteVehicle(vehicle.id)" 
                    :disabled="isLoading"
                    class="text-red-500 hover:text-red-600 transition duration-300 transform hover:scale-110 disabled:opacity-50"
                  >
                    <!-- 🗑️ ลบ -->
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <div class="flex justify-between items-center p-4 bg-white">
          <div>
            แสดง {{ (currentPage - 1) * itemsPerPage + 1 }} - 
            {{ Math.min(currentPage * itemsPerPage, vehicles.length) }} 
            จาก {{ vehicles.length }} รายการ
          </div>
          <div class="flex space-x-2">
            <button 
              @click="currentPage--" 
              :disabled="currentPage === 1"
              class="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
            >
              ก่อนหน้า
            </button>
            <button 
              @click="currentPage++" 
              :disabled="currentPage === totalPages"
              class="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
            >
              ถัดไป
            </button>
          </div>
        </div>
      </div>

      <!-- No Data State -->
      <div v-else class="text-center py-6 bg-gray-100 rounded-lg">
        <p class="text-gray-600">ไม่มีข้อมูลรถ</p>
      </div>

     <!-- Add/Edit Vehicle Modal -->
<div 
  v-if="isModalVisible" 
  class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black bg-opacity-50"
>
  <div class="relative w-full max-w-2xl mx-4 my-6">
    <div class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg">
      <!-- Modal Header -->
      <div class="flex items-start justify-between p-5 border-b border-gray-200 rounded-t">
        <h3 class="text-xl font-semibold text-gray-800">
          {{ isEditing ? 'แก้ไขข้อมูลรถ' : 'เพิ่มรถ' }}
        </h3>
        <button 
          @click="closeModal"
          class="float-right p-1 ml-auto text-3xl font-semibold leading-none text-gray-500 bg-transparent border-0 outline-none opacity-50 hover:opacity-75 focus:outline-none"
        >
          ×
        </button>
      </div>
      
      <!-- Modal Body -->
      <form @submit.prevent="submitVehicle" class="relative flex-auto p-6">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label class="block mb-2 text-sm font-bold text-gray-700">ประเภทรถ</label>
            <select 
              v-model="vehicle.type" 
              class="w-full px-3 py-2 border rounded-lg" 
              required
            >
              <option value="head">หัวลาก</option>
              <option value="trailer">พ่วง</option>
            </select>
          </div>
          <div>
            <label class="block mb-2 text-sm font-bold text-gray-700">ทะเบียนรถ</label>
            <input 
              type="text" 
              v-model="vehicle.license_plate" 
              class="w-full px-3 py-2 border rounded-lg" 
              required 
              placeholder="กรอกทะเบียนรถ"
            />
          </div>
          <div>
            <label class="block mb-2 text-sm font-bold text-gray-700">เบอร์รถ</label>
            <input 
              type="text" 
              v-model="vehicle.vehicle_code" 
              class="w-full px-3 py-2 border rounded-lg" 
              required 
              placeholder="กรอกรหัสรถ"
            />
          </div>
          <div>
            <label class="block mb-2 text-sm font-bold text-gray-700">หน่วยงาน</label>
            <select 
              v-model="vehicle.department_id" 
              class="w-full px-3 py-2 border rounded-lg" 
              required
            >
              <option 
                v-for="department in departments" 
                :key="department.id" 
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </div>
          <div>
  <label class="block mb-2 text-sm font-bold text-gray-700">กลุ่มงาน</label>
  <select 
    v-model="vehicle.group_id" 
    class="w-full px-3 py-2 border rounded-lg" 
    required
  >
    <option 
      v-for="group in groups" 
      :key="group.id" 
      :value="group.id"
    >
      {{ group.name }}
    </option>
  </select>
</div>
        </div>
        
        <!-- Modal Footer -->
        <div class="flex justify-end mt-6 space-x-4">
          <button 
            type="button" 
            @click="closeModal" 
            class="px-6 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            ยกเลิก
          </button>
          <button 
            type="submit" 
            class="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            บันทึก
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Vehicle Pairing Modal -->
<div 
  v-if="isVehiclePairingVisible" 
  class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black bg-opacity-50"
>
  <div class="relative w-full max-w-2xl mx-4 my-6">
    <div class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg">
      <!-- Modal Header -->
      <div class="flex items-start justify-between p-5 border-b border-gray-200 rounded-t">
        <h3 class="text-xl font-semibold text-gray-800">
          จับคู่รถ
        </h3>
        <button 
          @click="closeVehiclePairingModal"
          class="float-right p-1 ml-auto text-3xl font-semibold leading-none text-gray-500 bg-transparent border-0 outline-none opacity-50 hover:opacity-75 focus:outline-none"
        >
          ×
        </button>
      </div>
      
   <!-- Modal Body -->
   <form @submit.prevent="submitVehiclePairing" class="relative flex-auto p-6">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <!-- Department Dropdown -->
      <div>
        <label class="block mb-2 text-sm font-bold text-gray-700">เลือกหน่วยงาน</label>
        <select 
          v-model="vehiclePairing.department_id" 
          class="w-full px-3 py-2 border rounded-lg"
        >
          <option value="" disabled selected>เลือกหน่วยงาน</option>
          <option 
            v-for="department in departments" 
            :key="department.id" 
            :value="department.id"
          >
            {{ department.name }}
          </option>
        </select>
      </div>

      <!-- Group Dropdown -->
      <div>
        <label class="block mb-2 text-sm font-bold text-gray-700">เลือกกลุ่มงาน</label>
        <select 
          v-model="vehiclePairing.group_id" 
          class="w-full px-3 py-2 border rounded-lg"
        >
          <option value="" disabled selected>เลือกกลุ่มงาน</option>
          <option 
            v-for="group in groups" 
            :key="group.id" 
            :value="group.id"
          >
            {{ group.name }}
          </option>
        </select>
      </div>

   <!-- Head Vehicle Section -->
   <div class="form-group">
      <label>เลือกเบอร์รถหัว</label>
      <Multiselect 
        v-model="headVehicleSearch" 
        :options="filteredHeadVehicles" 
        :custom-label="vehicleLabel"
        placeholder="เลือกเบอร์รถหัว"
        @select="onHeadVehicleSelect"
      />
    </div>

    <div class="form-group">
      <label>เลือกทะเบียนหัวรถ</label>
      <Multiselect 
        v-model="headLicensePlateSearch" 
        :options="filteredHeadVehicles"
        :custom-label="licensePlateLabel"
        placeholder="เลือกทะเบียนหัวรถ"
        @select="onHeadLicensePlateSelect"
      />
    </div>

    <!-- Trailer Vehicle Section -->
    <div class="form-group">
      <label>เลือกเบอร์รถหาง</label>
      <Multiselect 
        v-model="trailerVehicleSearch" 
        :options="filteredTrailerVehicles"
        :custom-label="vehicleLabel"
        placeholder="เลือกเบอร์รถหาง"
        @select="onTrailerVehicleSelect"
      />
    </div>

    <div class="form-group">
      <label>เลือกทะเบียนหางรถ</label>
      <Multiselect 
        v-model="trailerLicensePlateSearch" 
        :options="filteredTrailerVehicles"
        :custom-label="licensePlateLabel"
        placeholder="เลือกทะเบียนหางรถ"
        @select="onTrailerLicensePlateSelect"
      />
    </div>
        </div>
    
    <!-- Modal Footer -->
    <div class="flex justify-end mt-6 space-x-4">
      <button 
        type="button" 
        @click="closeVehiclePairingModal" 
        class="px-6 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
      >
        ยกเลิก
      </button>
      <button 
        type="submit" 
        class="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
      >
        จับคู่รถ
      </button>
    </div>
  </form>
    </div>
    </div>
    </div>
    </div>
  </TransportLayout>
</template>

<script>
import TransportLayout from '@/components/transport/TransportSidebar.vue';
// import Modal from '@/components/common/Modal.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
// import { toRaw } from 'vue'
export default {
  components: {
    TransportLayout,
    Multiselect,
    // Modal
  },
  data() {
    return {
      // Vehicle Lists
      vehicles: [],
      departments: [],
      groups: [],
      // Filtering and Pagination
      searchQuery: '',
      currentPage: 1,
      itemsPerPage: 10,
      // headVehicles: [],
      // trailerVehicles: [], 
      availableHeadVehicles: [], // Available head vehicles
      availableTrailerVehicles: [], // Available trailer vehicles
      // Vehicle Data
      vehicle: {
        type: 'head',
        license_plate: '',
        vehicle_code: '',
        department_id: null
      },
      
      vehicleNumbers: [], // New property to store vehicle numbers
    vehiclePairing: {
      department_id: null,
      group_id: null,
      head_vehicle_number: null,  // New property for head vehicle number
      head_vehicle_code: null,
      head_license_plate: null,
      trailer_vehicle_number: null,  // New property for trailer vehicle number
      trailer_vehicle_code: null,
      trailer_license_plate: null
    },
    headVehicleFilter: '', // ตัวกรองเบอร์รถหัว
      headLicensePlateFilter: '', // ตัวกรองทะเบียนรถหัว
      trailerVehicleFilter: '', // ตัวกรองเบอร์รถหาง
      trailerLicensePlateFilter: '', // ตัวกรองทะเบียนรถหาง
      // State Management
      headVehicleSearch: null,
      headLicensePlateSearch: null,
      trailerVehicleSearch: null,
      trailerLicensePlateSearch: null,

      isLoading: false,
      isModalVisible: false,
      isVehiclePairingVisible: false,
      isEditing: false,
      editingVehicleId: null,
      selectedVehicles: []
    };
  },
  computed: {
    filteredHeadVehicles() {
    return this.vehicles
      .filter(v => 
        v.type === 'head' && 
        v.status === 'AVAILABLE' &&
        v.license_plate && // ตรวจสอบว่ามี license_plate
        v.vehicle_code    // ตรวจสอบว่ามี vehicle_code
      );
  },
    // กรองรถหาง
    filteredTrailerVehicles() {
      return this.vehicles
        .filter(v => v.type === 'trailer' && v.status === 'AVAILABLE');
    },
    // Pagination Computed Properties
    headVehicleNumbers() {
    return this.vehicleNumbers.filter(vn => vn.type === 'head');
  },
  trailerVehicleNumbers() {
    return this.vehicleNumbers.filter(vn => vn.type === 'trailer');
  },
    filteredVehicles() {
      return this.vehicles.filter(vehicle => 
        vehicle.license_plate.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        vehicle.vehicle_code.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    paginatedVehicles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredVehicles.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredVehicles.length / this.itemsPerPage);
    },
    isAllSelected() {
      return this.selectedVehicles.length === this.paginatedVehicles.length;
    },
    
    // Vehicle Pairing Computed Properties
    headVehicles() {
      return this.vehicles.filter(v => v.type === 'head' && v.status === 'AVAILABLE')
    },
    // กรองรถหาง
    trailerVehicles() {
      return this.vehicles.filter(v => v.type === 'trailer' && v.status === 'AVAILABLE')
    }
  },
  methods: {

// ตรวจสอบการเรียก API ใน Vue.js
async unpairVehicle(vehicleId) {
  const userToken = localStorage.getItem('userToken');
  const userName = userToken ? localStorage.getItem('userName') : 'system';

  try {
    // เรียกข้อมูลการจับคู่รถ
    const pairDetailsResponse = await axios.get(`https://brk-group.org/api/ts/vehicles/${vehicleId}/pair-details`, {
      headers: { 
        'Authorization': `Bearer ${userToken}` 
      }
    });

    // ตรวจสอบว่า pairDetailsResponse มีข้อมูลหรือไม่
    console.log('Pair Details Response:', pairDetailsResponse);

    if (!pairDetailsResponse.data || !pairDetailsResponse.data.data) {
      throw new Error('Invalid pair details structure');
    }

    // ดึงข้อมูล pair details
    const pairDetails = pairDetailsResponse.data.data;
    
    
    // ตรวจสอบข้อมูลใน pairDetails ก่อนทำการ destructure
    if (!pairDetails.head_id || !pairDetails.trailer_id) {
      throw new Error('Missing head_id or trailer_id in pair details');
    }

    const { head_id, trailer_id } = pairDetails;  // ดึงข้อมูล head_id และ trailer_id

    // ดำเนินการยกเลิกการจับคู่
    const unpairResponse = await axios.post('https://brk-group.org/api/ts/vehicles/unpair', {
      headId: head_id,  // ใช้ค่าที่ได้จากการตอบกลับ
      trailerId: trailer_id,
      performedBy: userName
    }, {
      headers: { 'Authorization': `Bearer ${userToken}` }
    });

    if (unpairResponse.data.success) {
      Swal.fire({
        icon: 'success',
        title: 'ยกเลิกการจับคู่สำเร็จ',
        text: 'รถได้ถูกยกเลิกการจับคู่เรียบร้อยแล้ว',
        confirmButtonText: 'ตกลง'
      });
    } else {
      throw new Error('Unpair failed');
    }

  } catch (error) {
    console.error('Error unpairing vehicle:', error);
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: error.message || 'ไม่สามารถยกเลิกการจับคู่ได้ กรุณาลองใหม่อีกครั้ง',
      confirmButtonText: 'ตกลง'
    });
  }
}


,

    // Add button to the existing template
    confirmUnpairVehicle(vehicle) {


  Swal.fire({
    title: 'ยืนยันการยกเลิกการจับคู่',
    text: `คุณต้องการยกเลิกการจับคู่รถ ${vehicle.license_plate} หรือไม่?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'ยืนยัน',
    cancelButtonText: 'ยกเลิก'
  }).then(async (result) => {
    if (result.isConfirmed) {
        // ตั้งค่าสถานะการโหลด
  this.isLoading = true;
      try {
        // เรียกใช้ฟังก์ชันยกเลิกการจับคู่
        await this.unpairVehicle(vehicle.id);
        
        // รีเฟรชข้อมูลรถเมื่อการยกเลิกเสร็จสิ้น
        await this.fetchVehicles();
        
        // แสดงข้อความสำเร็จ
        Swal.fire({
          icon: 'success',
          title: 'ยกเลิกการจับคู่สำเร็จ',
          text: `รถ ${vehicle.license_plate} ถูกยกเลิกการจับคู่เรียบร้อยแล้ว`,
          confirmButtonText: 'ตกลง'
        });
      } catch (error) {
        // แสดงข้อความข้อผิดพลาด
        this.showErrorAlert('เกิดข้อผิดพลาดในการยกเลิกการจับคู่', error);
      } finally {
        // ปิดสถานะการโหลด
        this.isLoading = false;
      }
    } else {
      // ปิดสถานะการโหลดถ้ายกเลิก
      this.isLoading = false;
    }
  });
},
    vehicleLabel(vehicle) {
    if (!vehicle) return '';
    return `${vehicle.vehicle_code} `;
  },
  licensePlateLabel(vehicle) {
    if (!vehicle) return '';
    return `${vehicle.license_plate} `;
  },
  onHeadVehicleSelect(vehicle) {
 console.log('Head Vehicle Select:', vehicle);
 
 if (vehicle) {
   this.headVehicleSearch = vehicle;
   this.headLicensePlateSearch = vehicle;
   
   // อัปเดต vehiclePairing กับข้อมูลที่ถูกเลือก
   this.vehiclePairing.head_vehicle_code = vehicle.vehicle_code;
   this.vehiclePairing.head_license_plate = vehicle.license_plate;
   this.vehiclePairing.head_vehicle_id = vehicle.id; // เพิ่ม id
 } else {
   this.headVehicleSearch = null;
   this.headLicensePlateSearch = null;
   
   // ล้างข้อมูลเมื่อไม่ได้เลือก
   this.vehiclePairing.head_vehicle_code = null;
   this.vehiclePairing.head_license_plate = null;
   this.vehiclePairing.head_vehicle_id = null;
 }
},
onHeadLicensePlateSelect(vehicle) {
 console.log('Head License Plate Select:', vehicle);
 
 if (vehicle) {
   this.headVehicleSearch = vehicle;
   this.headLicensePlateSearch = vehicle;
   
   // อัปเดต vehiclePairing กับข้อมูลที่ถูกเลือก
   this.vehiclePairing.head_vehicle_code = vehicle.vehicle_code;
   this.vehiclePairing.head_license_plate = vehicle.license_plate;
   this.vehiclePairing.head_vehicle_id = vehicle.id; // เพิ่ม id
 } else {
   this.headVehicleSearch = null;
   this.headLicensePlateSearch = null;
   
   // ล้างข้อมูลเมื่อไม่ได้เลือก
   this.vehiclePairing.head_vehicle_code = null;
   this.vehiclePairing.head_license_plate = null;
   this.vehiclePairing.head_vehicle_id = null;
 }
},
onTrailerVehicleSelect(vehicle) {
 console.log('Trailer Vehicle Select:', vehicle);
 
 if (vehicle) {
   this.trailerVehicleSearch = vehicle;
   this.trailerLicensePlateSearch = vehicle;
   
   // อัปเดต vehiclePairing กับข้อมูลที่ถูกเลือก
   this.vehiclePairing.trailer_vehicle_code = vehicle.vehicle_code;
   this.vehiclePairing.trailer_license_plate = vehicle.license_plate;
   this.vehiclePairing.trailer_vehicle_id = vehicle.id; // เพิ่ม id
 } else {
   this.trailerVehicleSearch = null;
   this.trailerLicensePlateSearch = null;
   
   // ล้างข้อมูลเมื่อไม่ได้เลือก
   this.vehiclePairing.trailer_vehicle_code = null;
   this.vehiclePairing.trailer_license_plate = null;
   this.vehiclePairing.trailer_vehicle_id = null;
 }
},
onTrailerLicensePlateSelect(vehicle) {
 console.log('Trailer License Plate Select:', vehicle);
 
 if (vehicle) {
   this.trailerVehicleSearch = vehicle;
   this.trailerLicensePlateSearch = vehicle;
   
   // อัปเดต vehiclePairing กับข้อมูลที่ถูกเลือก
   this.vehiclePairing.trailer_vehicle_code = vehicle.vehicle_code;
   this.vehiclePairing.trailer_license_plate = vehicle.license_plate;
   this.vehiclePairing.trailer_vehicle_id = vehicle.id; // เพิ่ม id
 } else {
   this.trailerVehicleSearch = null;
   this.trailerLicensePlateSearch = null;
   
   // ล้างข้อมูลเมื่อไม่ได้เลือก
   this.vehiclePairing.trailer_vehicle_code = null;
   this.vehiclePairing.trailer_license_plate = null;
   this.vehiclePairing.trailer_vehicle_id = null;
 }
},
    // Fetch Vehicles
    async fetchVehicles() {
      this.isLoading = true;
      try {
        const { data } = await axios.get('https://brk-group.org/api/ts/vehicles');
        this.vehicles = data.filter(v => v.status === 'AVAILABLE' || v.status ==='PAIRED');
      } catch (error) {
        this.showErrorAlert('เกิดข้อผิดพลาดในการโหลดข้อมูล', error);
      } finally {
        this.isLoading = false;
      }
    },
  
    onHeadVehicleNumberSelect() {
    // Find the selected vehicle number
    const selectedVehicleNumber = this.vehicleNumbers.find(
      vn => vn.number === this.vehiclePairing.head_vehicle_number
    );

    if (selectedVehicleNumber) {
      // Find the corresponding vehicle
      const matchedHeadVehicle = this.headVehicles.find(
        vehicle => vehicle.vehicle_code === selectedVehicleNumber.vehicleCode
      );

      if (matchedHeadVehicle) {
        // Update vehicle pairing data
        this.vehiclePairing.head_vehicle_code = matchedHeadVehicle.vehicle_code;
        this.vehiclePairing.head_license_plate = matchedHeadVehicle.license_plate;
        
        // Filter available trailer vehicles
        this.availableTrailerVehicles = this.trailerVehicles.filter(
          trailer => !this.isVehiclePaired(trailer.vehicle_code)
        );
      }
    }
  },

  onTrailerVehicleNumberSelect() {
    // Similar logic to head vehicle number selection
    const selectedVehicleNumber = this.vehicleNumbers.find(
      vn => vn.number === this.vehiclePairing.trailer_vehicle_number
    );

    if (selectedVehicleNumber) {
      const matchedTrailerVehicle = this.trailerVehicles.find(
        vehicle => vehicle.vehicle_code === selectedVehicleNumber.vehicleCode
      );

      if (matchedTrailerVehicle) {
        this.vehiclePairing.trailer_vehicle_code = matchedTrailerVehicle.vehicle_code;
        this.vehiclePairing.trailer_license_plate = matchedTrailerVehicle.license_plate;
      }
    }
  },
  resetVehiclePairingForm() {
    this.vehiclePairing = {
      department_id: null,
      group_id: null,
      head_vehicle_number: null,
      head_vehicle_code: null,
      head_license_plate: null,
      trailer_vehicle_number: null,
      trailer_vehicle_code: null,
      trailer_license_plate: null
    };
    // Reset vehicle pairing form
  this.vehiclePairing = {
    head_vehicle_code: '',
    trailer_vehicle_code: ''
  };

  // Reset Multiselect fields
  // Head Vehicle Section
  this.headVehicleSearch = null;
  this.headLicensePlateSearch = null;

  // Trailer Vehicle Section
  this.trailerVehicleSearch = null;
  this.trailerLicensePlateSearch = null;

  // Reset any additional related search or filter states
  this.filteredHeadVehicles = []; // Assuming this is the original full list
  this.filteredTrailerVehicles = []; // Assuming this is the original full list
  this.fetchVehicles();
  },
    // Fetch Departments
    async fetchDepartments() {
      try {
        const response = await axios.get('https://brk-group.org/api/ts/departments');
        this.departments = response.data;
      } catch (error) {
        this.showErrorAlert('เกิดข้อผิดพลาดในการโหลดหน่วยงาน', error);
      }
    },

    // Show Add Vehicle Modal
    showAddModal() {
      this.resetVehicleForm();
      this.isModalVisible = true;
      this.isEditing = false;
    },

    // Edit Vehicle
    editVehicle(vehicle) {
      this.vehicle = { 
        type: vehicle.type,
        license_plate: vehicle.license_plate,
        vehicle_code: vehicle.vehicle_code,
        department_id: vehicle.department_id,
        group_id: vehicle.group_id
      };
      this.isModalVisible = true;
      this.isEditing = true;
      this.editingVehicleId = vehicle.id;
    },
    async fetchDropdownData() {
    try {
      const [departmentsResponse, groupsResponse, vehicleNumbersResponse] = await Promise.all([
        axios.get('https://brk-group.org/api/ts/departments'),
        axios.get('https://brk-group.org/api/ts/groups'),
        axios.get('https://brk-group.org/api/ts/vehicle-numbers'),
      ]);
      this.departments = departmentsResponse.data;
      this.groups = groupsResponse.data;
      this.vehicleNumbers = vehicleNumbersResponse.data;
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
      this.showErrorAlert('ไม่สามารถโหลดข้อมูล Dropdown ได้');
    }
  },
  async fetchVehiclePairs() {
  try {
    const response = await axios.get('https://brk-group.org/api/ts/vehicle-pairs')
    
    console.log('Raw Response:', response.data) // ตรวจสอบข้อมูลดิบ
    
    this.vehicles = response.data.map(pair => {
      console.log('Individual Pair:', pair) // ตรวจสอบแต่ละ pair
      return {
        ...pair,
        status: 'PAIRED',
        pairedWith: {
          vehicle_code: pair.head_vehicle_code || pair.trailer_vehicle_code,
          license_plate: pair.head_license_plate || pair.trailer_license_plate
        }
      }
    })

    this.isLoading = false
  } catch (err) {
    console.error('Error in fetchVehiclePairs:', err)
    this.error = err
    this.isLoading = false
  }
    },
    // Submit Vehicle (Add/Edit)
    async submitVehicle() {
  // Retrieve user token and username
  const userToken = localStorage.getItem('userToken');
  const userName = userToken ? localStorage.getItem('userName') : 'system';

  // Prepare the vehicle payload
  const vehiclePayload = {
    ...this.vehicle,
    // Add created_by or updated_by based on operation
    ...(this.isEditing 
      ? { updated_by: userName } 
      : { created_by: userName }
    )
  };

  this.isLoading = true;
  try {
    if (this.isEditing) {
      // Update existing vehicle
      await axios.put(
        `https://brk-group.org/api/ts/vehicles/${this.editingVehicleId}`, 
        vehiclePayload
      );
      this.showSuccessAlert('แก้ไขข้อมูลรถสำเร็จ');
    } else {
      // Create new vehicle
      await axios.post(
        'https://brk-group.org/api/ts/vehicles', 
        vehiclePayload
      );
      this.showSuccessAlert('เพิ่มรถสำเร็จ');
    }
    
    // Refresh the list
    await this.fetchVehicles();
    this.closeModal();
  } catch (error) {
    this.showErrorAlert('เกิดข้อผิดพลาดในการบันทึกข้อมูล', error);
  } finally {
    this.isLoading = false;
  }
},

    // Delete Single Vehicle
    async confirmDeleteVehicle(id) {
      try {
        const result = await Swal.fire({
          title: 'ยืนยันการลบ',
          text: 'คุณต้องการลบรถนี้หรือไม่?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่, ลบเลย',
          cancelButtonText: 'ยกเลิก'
        });

        if (result.isConfirmed) {
          this.isLoading = true;
          await axios.delete(`https://brk-group.org/api/ts/vehicles/${id}`);
          
          // Refresh vehicles list
          await this.fetchVehicles();
          
          // Show success message
          this.showSuccessAlert('ลบรถสำเร็จ');
        }
      } catch (error) {
        this.showErrorAlert('เกิดข้อผิดพลาดในการลบข้อมูล', error);
      } finally {
        this.isLoading = false;
      }
    },

    // Delete Multiple Vehicles
    async deleteSelectedVehicles() {
      try {
        const result = await Swal.fire({
          title: 'ยืนยันการลบ',
          text: 'คุณต้องการลบรถที่เลือกหรือไม่?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่, ลบเลย',
          cancelButtonText: 'ยกเลิก'
        });

        if (result.isConfirmed) {
          this.isLoading = true;
          await axios.delete('https://brk-group.org/api/ts/vehicles', {
            data: { ids: this.selectedVehicles }
          });
          await this.fetchVehicles();
          this.showSuccessAlert('ลบรถสำเร็จ');
          this.selectedVehicles = [];
        }
      } catch (error) {
        this.showErrorAlert('เกิดข้อผิดพลาดในการลบข้อมูล', error);
      } finally {
        this.isLoading = false;
      }
    },

    // Vehicle Pairing Methods
    showVehiclePairingModal() {
      this.resetVehiclePairingForm();
      this.isVehiclePairingVisible = true;
    },


      // ตรวจสอบข้อมูลที่จำเป็น
      async submitVehiclePairing() {
  // ตรวจสอบ userToken และตั้งค่า userName
  const userToken = localStorage.getItem('userToken');
  this.userName = userToken ? localStorage.getItem('userName') : 'system';

  // ตรวจสอบข้อมูลที่จำเป็น
  const missingFields = [
    'head_vehicle_code', 
    'trailer_vehicle_code'
  ].filter(field => !this.vehiclePairing[field]);

  if (missingFields.length > 0) {
    Swal.fire({
      icon: 'error',
      title: 'ข้อผิดพลาด',
      text: `กรุณากรอกข้อมูลให้ครบ: ${missingFields.join(', ')}`
    });
    return;
  }

  this.isLoading = true;
  try {
    const payload = {
      ...this.vehiclePairing,
      created_by: this.userName || 'system'
    };

    // เพิ่มการตรวจสอบข้อมูลก่อนส่ง
    console.log('Vehicle Pairing Payload:', payload);

  await axios.post('https://brk-group.org/api/ts/vehicle-pairs', payload);
    
    Swal.fire({
      icon: 'success',
      title: 'สำเร็จ',
      text: 'จับคู่รถสำเร็จ'
    });

    this.closeVehiclePairingModal();
    await this.fetchVehicles(); // รีเฟรชข้อมูลรถ
  } catch (error) {
    console.error('Vehicle Pairing Error:', error);
    
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: error.response?.data?.error || 'เกิดข้อผิดพลาดในการจับคู่รถ'
    });
  } finally {
    this.isLoading = false;
  }
}
,
// onHeadVehicleSelect() {
//       if (!this.vehiclePairing.head_vehicle_search) return

//       const [vehicleCode, licensePlate] = this.vehiclePairing.head_vehicle_search.split('|')
//       const vehicle = this.vehicles.find(v => v.vehicle_code === vehicleCode)

//       if (vehicle) {
//         this.vehiclePairing.head_vehicle_search = vehicleCode
//         this.vehiclePairing.head_license_plate_search = licensePlate
//       }
//     },

//     // เมื่อเลือกทะเบียนรถหัว
//     onHeadLicensePlateSelect() {
//       if (!this.vehiclePairing.head_license_plate_search) return

//       const [vehicleCode, licensePlate] = this.vehiclePairing.head_license_plate_search.split('|')
//       const vehicle = this.vehicles.find(v => v.license_plate === licensePlate)

//       if (vehicle) {
//         this.vehiclePairing.head_vehicle_search = vehicleCode
//         this.vehiclePairing.head_license_plate_search = licensePlate
//       }
//     },

//     // เมื่อเลือกเบอร์รถหาง
//     onTrailerVehicleSelect() {
//       if (!this.vehiclePairing.trailer_vehicle_search) return

//       const [vehicleCode, licensePlate] = this.vehiclePairing.trailer_vehicle_search.split('|')
//       const vehicle = this.vehicles.find(v => v.vehicle_code === vehicleCode)

//       if (vehicle) {
//         this.vehiclePairing.trailer_vehicle_search = vehicleCode
//         this.vehiclePairing.trailer_license_plate_search = licensePlate
//       }
//     },

//     // เมื่อเลือกทะเบียนรถหาง
//     onTrailerLicensePlateSelect() {
//       if (!this.vehiclePairing.trailer_license_plate_search) return

//       const [vehicleCode, licensePlate] = this.vehiclePairing.trailer_license_plate_search.split('|')
//       const vehicle = this.vehicles.find(v => v.license_plate === licensePlate)

//       if (vehicle) {
//         this.vehiclePairing.trailer_vehicle_search = vehicleCode
//         this.vehiclePairing.trailer_license_plate_search = licensePlate
//       }
//     },

isVehiclePaired(vehicleCode) {
  // ตรวจสอบว่ารถถูกจับคู่แล้วหรือยัง
  return this.vehicles.some(
    vehicle => vehicle.paired_status === 'paired' && 
               (vehicle.vehicle_code === vehicleCode)
  );
},
validateVehiclePairing() {
  const errors = [];
  
  if (!this.vehiclePairing.head_license_plate) {
    errors.push('กรุณากรอกเลขทะเบียนรถหัว');
  }
  if (!this.vehiclePairing.head_vehicle_code) {
    errors.push('กรุณากรอกรหัสรถหัว');
  }
  // Similar checks for trailer
  
  if (errors.length > 0) {
    this.showErrorAlert(errors.join('\n'));
    return false;
  }
  return true;
},
    // Utility Methods
    resetVehicleForm() {
      this.vehicle = {
        type: 'head',
        license_plate: '',
        vehicle_code: '',
        department_id: null
      };
      // Reset vehicle pairing form
  this.vehiclePairing = {
    head_vehicle_code: '',
    trailer_vehicle_code: ''
  };

  // Reset Multiselect fields
  // Head Vehicle Section
  this.headVehicleSearch = null;
  this.headLicensePlateSearch = null;

  // Trailer Vehicle Section
  this.trailerVehicleSearch = null;
  this.trailerLicensePlateSearch = null;

  // Reset any additional related search or filter states
  this.filteredHeadVehicles = []; // Assuming this is the original full list
  this.filteredTrailerVehicles = []; // Assuming this is the original full list
      this.editingVehicleId = null;
      this.fetchVehicles();
    },

    // resetVehiclePairingForm() {
    //   this.vehiclePairing = {
    //     department_id: null,
    //     group_id: null,
    //     head_license_plate: '',
    //     head_vehicle_code: '',
    //     trailer_license_plate: '',
    //     trailer_vehicle_code: ''
    //   };
    // },

    closeModal() {
      this.isModalVisible = false;
      this.resetVehicleForm();
      
    },

    closeVehiclePairingModal() {
      this.isVehiclePairingVisible = false;
      this.resetVehiclePairingForm();
    },

    // Select All Vehicles
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedVehicles = [];
      } else {
        this.selectedVehicles = this.paginatedVehicles.map(vehicle => vehicle.id);
      }
    },

    // Alert Utility Methods
    showSuccessAlert(message) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: message,
        timer: 2000,
        showConfirmButton: false
      });
    },

    showErrorAlert(title, error) {
  const errorMessage = error?.response?.data?.error || 
                       error?.message || 
                       'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ';

  Swal.fire({
    icon: 'error',
    title: title,
    text: errorMessage,
    confirmButtonText: 'ตกลง'
  });

  console.error(title, error);
}
  },
  mounted() {
    this.fetchVehicles();
    // this.fetchDepartments();
    this.fetchDropdownData();
    // this.fetchVehiclePairs();
  },
  watch: {
    // Reset to first page when search query changes
    searchQuery() {
      this.currentPage = 1;
    }
  }
};
</script>
